import { FpApi } from "@tcs-rliess/fp-core";
import { castArray } from "lodash-es";
import { DateTime } from "luxon";

import { ScheduleBalanceContactBucket } from "./ScheduleBalanceContactBucket";
import { ScheduleBalanceResponse } from "./ScheduleBalanceResponse";
import { BalanceRecord, CheckInKey, CheckInQuery, CustomKey, CustomQuery, PremiumKey, PremiumQuery, Query, ScheduleKey, ScheduleQuery } from "./types";


export abstract class BalanceBucket<Key extends string, Q extends string> {
	public abstract name: string;
	public abstract fromBalance(balance: FpApi.Resource.Duty.ScheduleBalance): BalanceRecord;
	public static getEmpty(): ScheduleBalanceResponse {
		return ScheduleBalanceResponse.fromObject({ cnt: 0, min: 0, day: 0, dur: 0 });
	}
	public abstract getDate(balance: FpApi.Resource.Duty.ScheduleBalance): DateTime;
	public static getSpan(date: DateTime): number {
		return +`${date.year}${date.month.toString().padStart(2, "0")}`;
	}
	// checkIn is same defined as schedule, we just chose schedule here.
	protected data: BalanceRecord = {};
	protected queryCache: Map<Q, FpApi.Resource.Duty.ScheduleBalanceObject> = new Map();
	public updated: DateTime;
	public get dscaid() {
		return this._dscaid;
	}

	public get timeSpan() {
		return this._timeSpan;
	}

	constructor(private _dscaid: number, private _timeSpan: number, private parent: ScheduleBalanceContactBucket, balance?: FpApi.Resource.Duty.ScheduleBalance) {
		if(balance) {
			const data = this.fromBalance(balance);
			const date = this.getDate(balance);
			this.setData(data);
			this.setUpdated(date);
		}
	}
	public query(query: Q | Q[]): { cnt: number, min: number, day: number, dur: number } {
		const queries = castArray(query);
		let response = BalanceBucket.getEmpty();
		for(const key in this.data) {
			if(queries.some(q => BalanceBucket.isMatch(key, q))) {
				const value = ScheduleBalanceResponse.fromObject(this.data[key]);
				response = response.add(value);
			}
		}

		queries.forEach(q => this.queryCache.set(q, response.json()));
		return response;
	}

	public queryMap(queryMap: Map<string, Q[]>): Map<string, ScheduleBalanceResponse> {
		// mapped by id
		// each query 
		const returnMap = new Map<string, ScheduleBalanceResponse>(Array.from(queryMap.keys()).map(e => [ e, BalanceBucket.getEmpty() ]));
		for(const key in this.data) {
			queryMap.forEach((queries, id) => {
				if(queries.some(q => BalanceBucket.isMatch(key, q))) {
					returnMap.set(id, returnMap.get(id).add(ScheduleBalanceResponse.fromObject(this.data[key])));
				}
			});
		}

		queryMap.forEach((_, id) => {
			queryMap.get(id).forEach(q =>
				this.queryCache.set(q, returnMap.get(id).json())
			);
		});
		return returnMap;
	}

	public setData(data: BalanceRecord): void {
		this.data = data;
	}

	public setUpdated(dateTime: DateTime): void {
		this.updated = dateTime;
	}

	// only update data, when its indeed newer
	public updateData(data: BalanceRecord, dateTime: DateTime): boolean {
		if(this.updated == null) {
			this.setData(data);
			this.setUpdated(dateTime);
			return true;
		}
		if(dateTime > this.updated) {
			this.setData(data);
			this.setUpdated(dateTime);
			return true;
		}
		return false;
	}

	public insertBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		const data = this.fromBalance(balance);
		const date = this.getDate(balance);
		this.setData(data);
		this.setUpdated(date);
	}

	public updateBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		if(this.updated == null) {
			this.insertBalance(balance);
			return;
		}
		if(this.getDate(balance) > this.updated) {
			this.insertBalance(balance);
		}
	}

	public addValue(key: Key, value: BalanceRecord[string]): void {
		if(this.data == null) this.data = {};
		const current = ScheduleBalanceResponse.fromObject(this.data[key]);
		if(current) {
			this.data[key] = current.add(ScheduleBalanceResponse.fromObject(value)).json();
		} else {
			(this.data as any)[key] = value;
		}
		this.invalidateKey(key);
	}

	private dropKey(key: Q): void {
		this.queryCache.delete(key);
		this.parent.emit("drop", `${this.name}:${this.timeSpan}:${key}`);
	}
	protected invalidateKey(key: Key): void {
		this.dropKey(key as unknown as Q);
		this.queryCache.forEach((value, query) => {
			if(BalanceBucket.isMatch(key, query)) {
				this.dropKey(query);
			}
		});
	}

	protected static isEqual<T extends (number | boolean | string)>(key: string, queryKey: Query<T>) {
		if(queryKey === "*") return true;
		return key === queryKey;
	}

	public static isMatch<K extends string, Q extends string>(key: K, query: Q): boolean {
		const keys = key.split(",");
		const queries = query.split(",");
		return keys.every((key, i) => this.isEqual(key, queries[i] as Query<any>));
	}
}

export class ScheduleBucket extends BalanceBucket<ScheduleKey, ScheduleQuery> {
	public name = "schedule";
	public fromBalance(balance: FpApi.Resource.Duty.ScheduleBalance) {
		return balance?.schedule?.items;
	}
	public getDate(balance: FpApi.Resource.Duty.ScheduleBalance): DateTime {
		return DateTime.fromISO(balance.scheduleUpdated);
	}

	public static fromSchedule(schedule: FpApi.Resource.Duty.Schedule): ScheduleBalanceResponse {
		if(schedule?.children?.length) {
			return schedule.children.reduce((acc, e) => {
				if(e.status !== FpApi.Resource.Duty.ScheduleStatus.Approved) return acc;
				return acc.add(ScheduleBalanceResponse.fromObject({
					cnt: 1, min: e.data.minutes ?? 0, day: e.data.days ?? 0, dur: DateTime.fromISO(e.dateTo).diff(DateTime.fromISO(e.dateFrom)).as("minutes")
				}));
			}, ScheduleBalanceResponse.fromObject({ cnt: 0, min: 0, day: 0 }));
		}
		return ScheduleBalanceResponse.fromObject({
			cnt: 1,
			min: schedule.data.minutes ?? 0,
			day: schedule.data.days ?? 0,
			dur: DateTime.fromISO(schedule.dateTo).diff(DateTime.fromISO(schedule.dateFrom)).as("minutes"),
		});
	}
}

export class CheckInBucket extends BalanceBucket<CheckInKey, CheckInQuery> {
	public name = "checkIn";
	public fromBalance(balance: FpApi.Resource.Duty.ScheduleBalance) {
		return balance?.checkIn?.items ?? {};
	}
	public getDate(balance: FpApi.Resource.Duty.ScheduleBalance): DateTime {
		return DateTime.fromISO(balance.checkInUpdated);
	}
}

export class CustomBucket extends BalanceBucket<CustomKey, CustomQuery> {
	public name = "custom";
	public fromBalance(balance: FpApi.Resource.Duty.ScheduleBalance) {
		return balance.custom ?? {};
	}
	public getDate(balance: FpApi.Resource.Duty.ScheduleBalance): DateTime {
		return DateTime.fromISO(balance.customUpdated);
	}
}

export class PremiumBucket extends BalanceBucket<PremiumKey, PremiumQuery> {
	public name = "premium";
	public fromBalance(balance: FpApi.Resource.Duty.ScheduleBalance) {
		return balance?.checkIn?.premium ?? {};
	}
	public getDate(balance: FpApi.Resource.Duty.ScheduleBalance): DateTime {
		return DateTime.fromISO(balance.checkInUpdated);
	}
}

