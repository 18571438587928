import { DockedManager } from "@tcs-rliess/fp-web-ui";
import React from "react";

import { FpArticleBottom } from "../modules/Common/Base/FpLinkArticle";

export function withDockedManager<T>(Component: React.FC<T>): React.FC<T> {
	// eslint-disable-next-line react/display-name
	return (props) => {
		return <DockedManager>
			<FpArticleBottom>
				<Component {...props}/>
			</FpArticleBottom>
		</DockedManager>;
	};
}
