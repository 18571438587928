import { SortableTreeWithoutDndContext } from "@nosferatu500/react-sortable-tree";
import React from "react";
import { useMatchMedia } from "../../lib";
import { FleetplanTreeTheme, FleetplanTreeThemeCoursePointer } from "./Theme/FleetplanTreeTheme";
export const SideScrollingCtx = React.createContext(null);
export const Tree = function Tree(props) {
    const coarsePointer = useMatchMedia("(pointer: coarse)");
    const theme = coarsePointer ? FleetplanTreeThemeCoursePointer : FleetplanTreeTheme;
    /* there some weird feedback loop behavior in Chrome on scaled display (OS level scaling)
     * for example internal display of MacBooks or 4k monitors "not running at native resolution".
     * 1. <AutoSize/> component to set a size that triggers scrollbars to appears on a parent node
     * 2. scrollbars take of space, so <AutoSize/> sets a smaller size causing them disappear this time
     * 3. <AutoSize/> component to set a size that triggers scrollbars to appears on a parent node
     * 4. repeat...
     * We can workaround this if we set `overflow: hidden;` on the parent, meaning no scrollbars appear, canceling the loop.
     */
    const style = props.style ?? {};
    style.overflow = "hidden";
    return React.createElement(SideScrollingCtx.Provider, { value: { useSideScrolling: props?.useSideScrolling } },
        React.createElement(SortableTreeWithoutDndContext, { theme: theme, ...props, style: style }));
};
Tree.defaultProps = {
    canDrag: false,
    treeData: [],
    onChange: () => undefined,
    getNodeKey: (p) => p.node.key,
    useSideScrolling: false,
};
