import { Country } from "@tcs-rliess/fp-core";

import { FpLandingFieldClientDataProcessor } from "../../fp-query";

export class CountryStore {
	private byCode: Map<string, Country>;
	private byName: Map<string, Country>;

	public async getCode(code: string): Promise<Country> {
		await this.initialLoad();
		return this.byCode.get(code);
	}

	public async getCodes(codes: string[]): Promise<Country[]> {
		await this.initialLoad();
		return codes
			.map(c => this.byCode.get(c))
			.filter(c => c != null);
	}

	public async getName(name: string): Promise<Country> {
		await this.initialLoad();
		return this.byName.get(name);
	}

	public async getNames(names: string[]): Promise<Country[]> {
		await this.initialLoad();
		return names
			.map(c => this.byName.get(c))
			.filter(c => c != null);
	}

	public async getAll(): Promise<Country[]> {
		await this.initialLoad();
		return Array.from(this.byCode.values());
	}

	private async fetchAll(): Promise<void> {
		const countries = await new FpLandingFieldClientDataProcessor().getCountries({});

		this.byCode = new Map(countries.rows.map(c => [ c.code, c ]));
		this.byName = new Map(countries.rows.map(c => [ c.name, c ]));
	}

	private async initialLoad(): Promise<void> {
		if (this.byCode == null || this.byName == null) {
			await this.fetchAll();
		}
	}
}
