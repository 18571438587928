var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled, { css } from "styled-components";
export class InfoTableBodyState {
    constructor(condensed) {
        this.condensed = false;
        this.condensed = condensed;
    }
    setCondensed(state) {
        this.condensed = state;
    }
}
__decorate([
    observable
], InfoTableBodyState.prototype, "condensed", void 0);
__decorate([
    action
], InfoTableBodyState.prototype, "setCondensed", null);
export const InfoTableBodyStateCtx = React.createContext(null);
export const InfoTableBody = observer(function InfoTableBody(props) {
    const [state] = React.useState(() => new InfoTableBodyState(props.condensed));
    return React.createElement("table", { className: props.className },
        React.createElement("tbody", null,
            React.createElement(InfoTableBodyStateCtx.Provider, { value: state }, props.children)));
});
export const StyledInfoTableBody = styled(InfoTableBody) `
	width: 100%;
	max-width: 100%;

	& > tbody > tr > td.field, & > tbody > tr > td.field-condensed {
		${props => props.hideStripes ? css `border-top: none;` : css `border-top: 1px solid #ddd;`}
	}

	& > tbody > tr:first-child > td.field, & > tbody > tr:first-child > td.field-condensed {
		border-top: none;
	}

	& > tbody > tr:last-child > td {
		border-bottom: none;
	}

	& > tbody > tr > td {
		padding: 5px;
	}
`;
