import { FpApi } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export interface AircraftStatus {
	status: string;
	variant: string;
	description: string;
}

export class AircraftUtil {
	public static isArchived(app: FleetplanApp, fpvid: number): boolean {
		return app.ctx.getSubscriptionLevel("fpvid.aircraft", fpvid.toString()) === (FpApi.Security.PermissionAircraftLvl.LegReader | FpApi.Security.PermissionAircraftLvl.LegOwnReader | FpApi.Security.PermissionAircraftLvl.AircraftViewer);
	}
	public static getStatus(aircraft: FpApi.Resource.Aircraft): AircraftStatus {
		/*
			Status:
				-  MEL
			Color: 
				- yellow
			Description
				- "Under MEL"


			Status:
				- AOG
				- MX
			Color:
				- red
			Description
				- "Info Text"

		*/

		const status: AircraftStatus = {
			status: null,
			variant: "success",
			description: ""
		};

		if (aircraft?.$nextMaintenanceBooking?.etd &&
			DateTime.fromISO(aircraft?.$nextMaintenanceBooking?.etd)) {
			status.status = "MX";
			status.variant = "danger";
			status.description="Aircraft in scheduled Maintenance!";
		}

		if (aircraft?.$total?.nextMaintenanceAt &&
			aircraft?.$total?.flightTime) {
			const diff = aircraft?.$total?.nextMaintenanceAt - aircraft?.$total?.flightTime;
			if (Math.sign(diff) !== 1) {
				status.status = "MX";
				status.variant = "danger";
				status.description="Flight Time overdue!";
			}
		}

		if (aircraft?.$total?.nextMaintenanceAtDate &&
			(DateTime.fromISO(aircraft?.$total?.nextMaintenanceAtDate).year > 1901) &&
			(DateTime.fromISO(aircraft?.$total?.nextMaintenanceAtDate) <= DateTime.now())) {
			status.status = "MX";
			status.variant = "danger";
			status.description="Next Maintenance Date is overdue!";
		}

		return status;
	}
}
