import { FpWebUiProvider } from "@tcs-rliess/fp-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HashRouter } from "react-router-dom";

import { appContext, FleetplanApp, useApp } from "../FleetplanApp";
import { FleetplanGridTheme } from "../modules/Common/Util/FpGrid/css/FleetplanGridTheme";

export const FpProvider: React.FC<{
	app: FleetplanApp;
	children?: React.ReactNode;
}> = observer(function FpProvider(props) {
	// do a check so be do nothing if there already is a another <FpProvider/>
	const check = useApp();
	if (check != null) return <>{props.children}</>;

	return (
		<appContext.Provider value={props.app}>
			<DndProvider backend={HTML5Backend}>
				<FpWebUiProvider theme={props.app.theme} formatter={props.app.formatter}>
					<FleetplanGridTheme/>
					<HashRouter>
						{props.children}
					</HashRouter>
				</FpWebUiProvider>
			</DndProvider>
		</appContext.Provider>
	);
});
