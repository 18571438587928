import React from "react";
import { DateRangePicker } from "../DateRange/DateRangePicker";
import { DateRange } from "../util";
export const WeekPicker = (props) => {
    const [dateRange, setDateRange] = React.useState(new DateRange(props.value.startOf("week"), props.value.endOf("week")));
    return React.createElement(DateRangePicker, { className: props.className, dateRange: dateRange, todayButtonLabel: props.todayButton, numberOfMonths: props.numberOfMonth ?? 2, onChange: dates => {
            setDateRange(dates);
            props.onChange(dates.from);
        } });
};
