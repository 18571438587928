import { castArray } from "lodash-es";
import { useEffect } from "react";

import { useApp } from "../../FleetplanApp";
import { handleError } from "../../handleError";

import { MqttListener } from "./Mqtt";

/**
 * 
 * @param topics 
 * @param listener use an React.useCallback function for this, and not an anonymous function, since after each render the listener would change.
 *                 With React.useCallback the function never changes thus there is never an unexpected effect
 */
export function useMqtt(topics: string | string[], listener: MqttListener): void {
	const { mqtt } = useApp();

	useEffect(() => {
		mqtt.subscribe(topics, listener).catch(handleError);
		return () => mqtt.unsubscribe(topics, listener);
	}, [
		castArray(topics).join(","),
		listener,
		mqtt,
	]);
}
