import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React from "react";
import styled from "styled-components";
const ToggleButtonWrapper = function ToggleButtonWrapper(props) {
    const labels = {
        offlabel: React.createElement("div", { className: "offlabel" }, props.offlabel),
        onlabel: React.createElement("div", { className: "onlabel" }, props.onlabel)
    };
    return React.createElement("div", { className: props.className },
        React.createElement(BootstrapSwitchButton, { ...props, ...labels }),
        " ");
};
ToggleButtonWrapper.defaultProps = {
    offlabel: "Off",
    onlabel: "On",
};
export const ToggleButton = styled(ToggleButtonWrapper) `
	.btn-notaudited {
		background-color: #777;
		color: white;
	}
	.switch.btn-notaudited .switch-handle {
		border-color: #777;
	}

	.offlabel {
		text-align: left;
		margin-left: 10px;
	}
	.onlabel {
		text-align: left;
		margin-left: 10px;
	}

	.btn {
		text-transform: uppercase;
	}
`;
