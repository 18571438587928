import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class SecurityUserStore extends BaseStoreMulti<FpApi.Security.SecurityUser, number> {
	protected itemId(item: FpApi.Security.SecurityUser): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Security.SecurityUser[]> {
		return apiManager
			.getService(FpApi.Security.UserService)
			.get(this.app.ctx);
	}
}
