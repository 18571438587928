import { ResolvedHoliday, HolidayUtil } from "@tcs-rliess/fp-core";

import { FpEventModel, FpTimeRangeModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class HolidaySetBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.holiday?.enabled) loads.push(this.holiday(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: unknown): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		return Promise.resolve();
	}

	private async holiday(state: RangeBuilderState): Promise<void> {
		let holidaySets = await this.app.store.holidaySet.getAll();
		if (this.store.configuration.events.holiday.setId) {
			holidaySets = holidaySets.filter(hs => hs.id === this.store.configuration.events.holiday.setId);
		}
		const holidayUtil = new HolidayUtil();

		const days = holidayUtil.resolveRange({
			sets: holidaySets,
			start: state.from,
			end: state.to,
			subdivision: this.store.configuration.events.holiday.subdivision,
		});

		for (const day of days) {
			if (this.store.configuration.events.holiday.outputEvent) this.day2Event(state, day);
			if (this.store.configuration.events.holiday.outputTimeRange) this.day2TimeRange(state, day);
		}
	}

	private day2Event(state: BuilderState, day: ResolvedHoliday): void {
		this.makeEvent(state, () => {
			const startDate = day.date.setZone(this.store.tz, { keepLocalTime: true }).toJSDate();
			const endDate = day.date.setZone(this.store.tz, { keepLocalTime: true }).plus({ day: 1 }).toJSDate();

			const event = new FpEventModel({
				id: `${day.holiday.id}:${day.date.year}`,
				startDate: this.toTimeZone(startDate),
				endDate: this.toTimeZone(endDate),
				allDay: true,
				name: day.holiday.nameLocal,
				draggable: false,
				resizable: false,

				fpLinkType: "dschsid",
				fpLinkId: day.holiday.id,
				fpData: day,
			});

			return event;
		}, function*() {
			yield { linkType: "dschsid", linkId: day.set.id };
		}, { includeAlways: true });
	}

	private day2TimeRange(state: BuilderState, day: ResolvedHoliday): void {
		const startDate = day.date.setZone(this.store.tz, { keepLocalTime: true }).toJSDate();
		const endDate = day.date.setZone(this.store.tz, { keepLocalTime: true }).plus({ day: 1 }).toJSDate();

		const timeRange = new FpTimeRangeModel({
			id: `${day.holiday.id}:${day.date.year}`,
			startDate: this.toTimeZone(startDate),
			endDate: this.toTimeZone(endDate),
			// name: day.holiday.nameLocal,
			cls: "fp-holiday",
			iconCls: "b-fa b-fa-island-tropical",

			fpLinkType: "dschsid",
			fpLinkId: day.holiday.id,
			fpData: day,
		});

		state.timeRanges.push(timeRange);
	}
}
