import { ApiContext, IFleetplanApi } from "@tcs-rliess/fp-core";
import Lock from "async-lock";
import EventEmitter from "events";

import { FleetplanApp } from "../../FleetplanApp";

/**
 * Async store, loads "all" items initially, after that, if we get an unknown ID attempt to load it.
 * Each ID will be be attempted once!
 * 
 * @fires put
 * @fires delete
 * @fires reload
 */
export abstract class BaseStoreSingleMulti<ITEM, ID> extends EventEmitter {
	protected readonly app: FleetplanApp;
	protected readonly fleetplanApi: IFleetplanApi;
	protected get ctx(): ApiContext { return this.app.ctx; }

	private load: Promise<void>;
	private itemsById = new Map<ID, ITEM>();
	/** ID's we tried to load, but couldn't */
	private itemsIdTried = new Set<ID>();
	private lock = new Lock();

	constructor(app: FleetplanApp) {
		super();

		this.app = app;
		this.fleetplanApi = app.fleetplanApi;
	}

	/** return id of item */
	protected abstract itemId(item: ITEM): ID;
	/** fetches the given id list */
	protected abstract fetchIdList(idList: ID[]): Promise<ITEM[]>;
	protected abstract fetchAll(): Promise<ITEM[]>;

	public async getId(id: ID): Promise<ITEM> {
		await this.ensureLoad();

		// check if we have it loaded
		const item = this.itemsById.get(id);
		if (item != null) return item;

		// load if missing
		return await this.lock.acquire("getId", async () => {
			const items = await this.loadIdList([ id ]);
			return items[0];
		});
	}

	public async getIds(idList: ID[]): Promise<ITEM[]> {
		await this.ensureLoad();

		// check if we have it loaded
		const missingIdList: ID[] = [];
		const items = idList
			.map(id => {
				const item = this.itemsById.get(id);
				if (item == null) missingIdList.push(id);
				return item;
			})
			.filter(i => i != null);

		// nothing missing
		if (missingIdList.length === 0) {
			return items;
		}

		return await this.lock.acquire("getId", async () => {
			// load missing
			await this.loadIdList(missingIdList);

			// return items
			return idList
				.map(id => this.itemsById.get(id))
				.filter(i => i != null);
		});
	}

	public async getAll(): Promise<ITEM[]> {
		await this.ensureLoad();
		return Array.from(this.itemsById.values());
	}

	public async getFilter(filter: (item: ITEM) => boolean): Promise<ITEM[]> {
		await this.ensureLoad();
		return Array.from(this.itemsById.values()).filter(filter);
	}

	public async getMap(): Promise<Map<ID, ITEM>> {
		await this.ensureLoad();
		return new Map(this.itemsById);
	}

	public update(item: ITEM): void {
		this.itemsById.set(this.itemId(item), item);
		this.emit("put", item);
	}

	public remove(id: ID): void {
		this.itemsById.delete(id);
		this.emit("delete", id);
	}

	public flush(): void {
		this.load = null;
		this.itemsById = null;
	}

	private async loadIdList(idList: ID[]): Promise<ITEM[]> {
		idList = idList
			// drop loaded ids
			.filter(id => this.itemsById.has(id) === false)
			// drop ids we tried before
			.filter(id => this.itemsIdTried.has(id) === false);

		// add to tried id list
		idList.forEach(id => this.itemsIdTried.add(id));

		// return if empty array
		if (idList.length === 0) return [];

		const items = await this.fetchIdList(idList);
		if (items == null) return;

		this.setItems(items);

		return items;
	}

	private async ensureLoad(): Promise<void> {
		if (this.load == null) {
			this.load = this.fetchAll()
				.then(data => {
					this.itemsById = new Map(
						data.map(i => [ this.itemId(i), i ])
					);
				})
				.then(() => {
					this.emit("reload");
				});
		}

		await this.load;
	}

	private setItems(items: ITEM[]): void {
		for (const item of items) {
			// just a safety
			if (item == null) continue;

			const itemId = this.itemId(item);
			// update map
			this.itemsById.set(itemId, item);
		}
	}
}
