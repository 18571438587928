import { FpApi } from "@tcs-rliess/fp-core";
import { when } from "mobx";

import { FleetplanApp } from "../../../../FleetplanApp";
import { handleError } from "../../../../handleError";
import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class UserLogTypeIdNameStore extends BaseStoreMultiSync<FpApi.Resource.UserLogType, string> {
	constructor(app: FleetplanApp) {
		super(app);

		setTimeout(() => {
			app.store.resource.userLogType.addListener("reload", () => {
				this.reload().catch(handleError);
			});
			this.reload().catch(handleError);
		}, 100);
	}

	protected async fetchAll(): Promise<FpApi.Resource.UserLogType[]> {
		await when(() => this.app.ctx.isAuthorized === true && this.app.customer != null);
		return Promise.resolve(this.app.store.resource.userLogType.getAll());
	}

	protected itemId(item: FpApi.Resource.UserLogType): string {
		return item.idName;
	}

	public getType(type: FpApi.Resource.UserLogTypeResourceType): FpApi.Resource.UserLogType[] {
		const items = this.getAll();
		return items.filter(i => i.resourceType === type);
	}
}
