export class FuelConverter {
    static getFactor(unitFrom, unitTo, fuelType) {
        if (unitFrom === unitTo) {
            return 1;
        }
        if (this.factors[unitFrom]?.[unitTo]?.[fuelType] != null) {
            return this.factors[unitFrom][unitTo][fuelType];
        }
        return fuelType === "avgas100" ? 0.755 : 0.8;
    }
    /**
     * Converts fuel amounts between different units. Takes the actual type of the fuel into account allowing to convert between measurements, for example between
     * weight and volume.
     *
     * 2020-04-29 [AP] Check website for fuel converter
     * https://mye6b.com/Fuel/
     */
    static convert(params) {
        const { fuelType, unitTo, tankCapacity } = params;
        let unitFrom = params.unitFrom;
        let convertedFuel = params.value;
        if (unitFrom === unitTo) {
            return convertedFuel;
        }
        // handle percentage
        // note: `tankCapacity` is always in "liter"
        if (unitFrom === "%") {
            unitFrom = "volume.metric.liter";
            convertedFuel = convertedFuel * (tankCapacity / 100);
        }
        if (unitTo === "%") {
            return convertedFuel = convertedFuel / (tankCapacity / 100);
        }
        // run conversion
        return convertedFuel * this.getFactor(unitFrom, unitTo, fuelType);
    }
}
// Folgende Mittelwerte (für 15°C) werden dabei zugrundegelegt
FuelConverter.factors = {
    "mass.metric.kilogram": {
        "us.liq.gal": {
            "jeta1": 1 / 3.04,
            "avgas100": 1 / 2.81,
            "avgas100ll": 1 / 2.81,
            "mogas": 0.34,
            "jetb": 1 / 2.95,
        },
        "volume.metric.liter": {
            "jeta1": 1.25,
            "avgas100": 1.35,
            "avgas100ll": 1.35,
            "mogas": 1.30,
            "jetb": 1.28,
        },
        "mass.imperial.pound": {
            "jeta1": 2.21,
            "avgas100": 2.21,
            "avgas100ll": 2.21,
            "mogas": 2.21,
            "jetb": 2.21,
        },
        "mass.uscs.pound": {
            "jeta1": 2.21,
            "avgas100": 2.21,
            "avgas100ll": 2.21,
            "mogas": 2.21,
            "jetb": 2.21,
        },
    },
    "us.liq.gal": {
        "mass.metric.kilogram": {
            "jeta1": 3.04,
            "avgas100": 2.81,
            "avgas100ll": 2.81,
            "mogas": 2.9,
            "jetb": 2.95,
        },
        "volume.metric.liter": {
            "jeta1": 3.79,
            "avgas100": 3.79,
            "avgas100ll": 3.79,
            "mogas": 3.79,
            "jetb": 3.79,
        },
        "mass.imperial.pound": {
            "jeta1": 6.70,
            "avgas100": 6.20,
            "avgas100ll": 6.20,
            "mogas": 6.40,
            "jetb": 6.50,
        },
        "mass.uscs.pound": {
            "jeta1": 6.70,
            "avgas100": 6.20,
            "avgas100ll": 6.20,
            "mogas": 6.40,
            "jetb": 6.50,
        },
    },
    "volume.metric.liter": {
        "mass.metric.kilogram": {
            "jeta1": 0.80,
            "avgas100": 0.74,
            "avgas100ll": 0.74,
            "mogas": 0.753,
            "jetb": 0.78,
        },
        "us.liq.gal": {
            "jeta1": 0.2638522427440633,
            "avgas100": 0.2638522427440633,
            "avgas100ll": 0.2638522427440633,
            "mogas": 0.2638522427440633,
            "jetb": 0.2638522427440633,
        },
        "mass.imperial.pound": {
            "jeta1": 1.76,
            "avgas100": 1.64,
            "avgas100ll": 1.64,
            "mogas": 1.69,
            "jetb": 1.72,
        },
        "mass.uscs.pound": {
            "jeta1": 1.76,
            "avgas100": 1.64,
            "avgas100ll": 1.64,
            "mogas": 1.69,
            "jetb": 1.72,
        },
    },
    "mass.imperial.pound": {
        "mass.metric.kilogram": {
            "jeta1": 0.45,
            "avgas100": 0.45,
            "avgas100ll": 0.45,
            "mogas": 0.45,
            "jetb": 0.45,
        },
        "us.liq.gal": {
            "jeta1": 0.15,
            "avgas100": 0.16,
            "avgas100ll": 0.16,
            "mogas": 0.13,
            "jetb": 0.13,
        },
        "volume.metric.liter": {
            "jeta1": 0.5681818181818182,
            "avgas100": 0.61,
            "avgas100ll": 0.61,
            "mogas": 0.59,
            "jetb": 0.58,
        },
        "mass.uscs.pound": {
            "jeta1": 1,
            "avgas100": 1,
            "avgas100ll": 1,
            "mogas": 1,
            "jetb": 1,
        }
    },
    "mass.uscs.pound": {
        "mass.metric.kilogram": {
            "jeta1": 0.45,
            "avgas100": 0.45,
            "avgas100ll": 0.45,
            "mogas": 0.45,
            "jetb": 0.45,
        },
        "us.liq.gal": {
            "jeta1": 0.15,
            "avgas100": 0.16,
            "avgas100ll": 0.16,
            "mogas": 0.13,
            "jetb": 0.13,
        },
        "volume.metric.liter": {
            "jeta1": 0.5681818181818182,
            "avgas100": 0.61,
            "avgas100ll": 0.61,
            "mogas": 0.59,
            "jetb": 0.58,
        },
        "mass.imperial.pound": {
            "jeta1": 1,
            "avgas100": 1,
            "avgas100ll": 1,
            "mogas": 1,
            "jetb": 1,
        }
    },
};
