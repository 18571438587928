import Color from "color";
import { buildColor } from "./ThemeColor";
const DEFAULTS = {
    borderColor: "#dddddd",
    hover: "#f5f5f5",
};
export const ThemeLight = {
    kind: "light",
    background: "white",
    font: "black",
    fontMuted: "#777",
    color: {
        // bootstrap colors
        primary: buildColor("light", new Color("#337ab7")),
        success: buildColor("light", new Color("#5cb85c"), "white"),
        danger: buildColor("light", new Color("#d9534f")),
        warning: buildColor("light", new Color("#f0ad4e"), "white"),
        info: buildColor("light", new Color("#5bc0de")),
        // custom colors
        deeporange: buildColor("light", new Color("#fe541e")),
        purple: buildColor("light", new Color("#9b6bcc"), "white"),
        confirmed: buildColor("light", new Color("#3498db")),
        cancelled: buildColor("light", new Color("#777")),
        yellow: buildColor("light", new Color("#ffdd33")),
    },
    defaults: DEFAULTS,
    content: {
        background: "#f5f7f7"
    },
    panel: {
        background: "#fff",
        titleBorder: "rgb(247, 247, 247)",
        border: "rgb(221, 221, 221)",
    },
    table: {
        padding: {
            md: "8px",
            sm: "4px",
        },
        borderColor: DEFAULTS.borderColor,
    },
    dockedManager: {
        borderColor: DEFAULTS.borderColor,
    },
    menu: {
        background: "#fbfbfb",
        hover: DEFAULTS.hover,
        borderColor: DEFAULTS.borderColor,
    },
    dropdown: {
        background: "#FFFFFF",
        hover: DEFAULTS.hover,
        borderColor: DEFAULTS.borderColor,
    },
    tree: {
        hover: DEFAULTS.hover,
        active: "#dbeeff",
        activeHover: "#C3E3FF",
    }
};
