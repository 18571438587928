import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class TagStore extends BaseStoreMulti<FpApi.Tag.Tag, number> {
	protected itemId(item: FpApi.Tag.Tag): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Tag.Tag[]> {
		return apiManager
			.getService(FpApi.Tag.TagService)
			.get(this.app.ctx);
	}
}
