import { faBug } from "@fortawesome/pro-light-svg-icons";
import { ApiError } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { CommandBar, lazyComponent } from "@tcs-rliess/fp-web-ui";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary, FpProvider } from "./common";
import { FP_APP } from "./main";

const ErrorReportDialog = lazyComponent(() => import("./ErrorReportDialog"), m => m.ErrorReportDialog);
const errorQueue: ApiError[] = observable.array([], { deep: false });

export const handleError = (error: Error): void => {
	fpLog.error("handleError", { error });

	let apiError: ApiError;
	if (error instanceof ApiError) {
		apiError = error;
	} else {
		apiError = new ApiError(0, "UNHANDLED_JS_ERROR", "An unexpected error happened.", error);
	}

	errorQueue.push(apiError);

	// todo: not final
	return;
	FP_APP.toastManager.add({
		icon: faBug,
		app: "Error",
		variant: "danger",
		autoDismiss: false,
		body: <>
			Something went wrong<br/>
			{apiError.name}
		</>,
		buttons: <>
			<CommandBar.Button onClick={() => errorQueue.push(apiError)}>
				Report
			</CommandBar.Button>
		</>
	});
};

const ErrorDisplayer: React.FC = observer(() => (
	<ErrorBoundary>
		<FpProvider app={FP_APP}>
			{errorQueue.length > 0 ? (
				<React.Suspense fallback={<div/>}>
					<ErrorReportDialog
						errors={errorQueue}
						onHide={() => errorQueue.splice(0)}
					/>
				</React.Suspense>
			): null}
		</FpProvider>
	</ErrorBoundary>
));

const errorRoot = document.createElement("div");
errorRoot.id = "REACT_ERROR_ROOT";

document.addEventListener("readystatechange", () => {
	if (document.readyState !== "loading") {
		document.body.appendChild(errorRoot);
		ReactDOM.render(<ErrorDisplayer/>, errorRoot);
	}
}, { once: true });
