import { omit } from "lodash";
import React from "react";
import styled, { css, useTheme } from "styled-components";
import { getSize, useThemeColor } from "../../Css";
import { Flex } from "../Flex";
import { Label } from "../Label";
const LabelPropsCtx = React.createContext({});
const ChildrenCount = React.createContext(null);
const COLOR_DEFAULT = "#777777";
const Tile = (props) => {
    const count = React.Children.toArray(props.children).length;
    const sizing = React.useMemo(() => {
        switch (props.size) {
            case "md": return { height: 16, fontSize: ".9rem", lineHeight: "6px" };
            case "sm": return { height: 8, fontSize: ".6rem", lineHeight: "3px" };
            default: return {};
        }
    }, [props.size]);
    return React.createElement(Flex, { column: true, style: { cursor: props.onClick ? "pointer" : undefined }, onClick: props.onClick },
        React.createElement(Label, { ...omit(props, "children", "onClick"), style: { textAlign: "center", ...sizing, letterSpacing: 1, ...(props.style ?? {}) } }, props.label),
        React.createElement(Flex, null,
            React.createElement(ChildrenCount.Provider, { value: count },
                React.createElement(LabelPropsCtx.Provider, { value: props }, props.children))));
};
const LeftTile = (props) => {
    const parentProps = React.useContext(LabelPropsCtx);
    const count = React.useContext(ChildrenCount);
    const theme = useTheme();
    const variant = props.variant ?? theme.color.primary[500].main;
    const clr = useThemeColor(variant);
    const width = React.useMemo(() => {
        let width = parentProps?.style?.width;
        if (!width)
            width = parentProps.fixedWidth ? getSize(parentProps.fixedWidth) : "100%";
        if (count === 1) {
            return width;
        }
        if (typeof width === "number")
            return `${width / 2 - 10}px`;
        else if (typeof width === "string")
            return `calc(${width} / 2 - 10px)`;
    }, [parentProps?.style?.width, count]);
    if (count === 1)
        return React.createElement(Label, { variant: variant, ...props, style: { ...(props.style ?? {}), width, textAlign: props.style?.textAlign ?? "center", border: 0 } });
    return React.createElement(Left, { variant: variant, fixedWidth: parentProps.fixedWidth, background: clr?.[500].main ?? COLOR_DEFAULT, ...props, style: { ...(props.style ?? {}), width, textAlign: props.style?.textAlign ?? "left", border: 0 } });
};
Tile.displayName = "TileSmall";
Tile.defaultProps = {
    size: "md",
};
const RightTile = (props) => {
    const parentProps = React.useContext(LabelPropsCtx);
    const theme = useTheme();
    const variant = props.variant ?? theme.color.info[100].main;
    const clr = useThemeColor(variant);
    const count = React.useContext(ChildrenCount);
    const width = React.useMemo(() => {
        let width = parentProps?.style?.width;
        if (!width)
            width = parentProps.fixedWidth ? getSize(parentProps.fixedWidth) : "100%";
        if (count === 1) {
            return width;
        }
        if (typeof width === "number")
            return `${width / 2 + 10}px`;
        else if (typeof width === "string")
            return `calc(${width} / 2 + 10px)`;
    }, [parentProps?.style?.width]);
    if (count === 1)
        return React.createElement(Label, { variant: variant, ...props, style: { ...(props.style ?? {}), width, textAlign: props.style?.textAlign ?? "center", border: 0 } });
    return React.createElement(Right, { variant: variant, fixedWidth: parentProps.fixedWidth, background: clr?.[500].main ?? COLOR_DEFAULT, ...props, style: { ...(props.style ?? {}), width, border: 0, textAlign: props.style?.textAlign ?? "right" } });
};
const Left = styled(Label) `
	${p => {
    if (p.fixedWidth) {
        const size = getSize(p.fixedWidth);
        if (typeof size === "number") {
            const actualSize = size / 2 - 10;
            return css `
					width: ${actualSize}px;
				`;
        }
    }
}};
	position: relative;
	::after {
		content: "";
		position: absolute;
		top: 0px;
		right: -20px;
		border-left: 20px solid ${b => b.background};
		border-bottom: 19px solid #ccc0;
	}
`;
const Right = styled(Label) `
	${p => {
    if (p.fixedWidth) {
        const size = getSize(p.fixedWidth);
        if (typeof size === "number") {
            const actualSize = size / 2 + 10;
            return css `
					width: ${actualSize}px;
				`;
        }
    }
}};
`;
export const TileSmall = Object.assign(Tile, { Left: LeftTile, Right: RightTile });
