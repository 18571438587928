var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from "mobx";
import React from "react";
export class SlidingPanelState {
    constructor(defaultOpen) {
        this.panels = [];
        this.history = [];
        this.portals = new Map();
        this.footer = (ref) => {
            this.footerOutlet = ref;
        };
        this.openPanel = defaultOpen;
    }
    open(key, direction = "next") {
        this.history.push(this.openPanel);
        this.oldOpen = this.openPanel;
        this.openPanel = key;
        this.direction = direction;
    }
    setPanels(keys) {
        this.panels = keys;
    }
    setFooterOutlet(ref) {
        this.footerOutlet = ref;
    }
    back() {
        if (this.history.length) {
            this.oldOpen = this.openPanel;
            this.openPanel = this.history.pop();
            this.direction = "back";
        }
    }
    registerPanel(identifier) {
        this.panels.push(identifier);
        /** if this is the first panel, open it as default */
        if (this.panels.length === 1)
            this.openPanel = identifier;
    }
    unregisterPanel(identifier) {
        this.panels = this.panels.filter(e => e !== identifier);
    }
    registerPortal(identifier, portal) {
        this.portals.set(identifier, portal);
        this.portals = new Map(this.portals);
    }
    unregisterPortal(identifier) {
        this.portals.delete(identifier);
        this.portals = new Map(this.portals);
    }
    getPortal(identifier) {
        return this.portals.get(identifier);
    }
}
__decorate([
    observable
], SlidingPanelState.prototype, "openPanel", void 0);
__decorate([
    observable
], SlidingPanelState.prototype, "oldOpen", void 0);
__decorate([
    observable
], SlidingPanelState.prototype, "direction", void 0);
__decorate([
    observable
], SlidingPanelState.prototype, "panels", void 0);
__decorate([
    observable.ref
], SlidingPanelState.prototype, "history", void 0);
__decorate([
    observable.ref
], SlidingPanelState.prototype, "footerOutlet", void 0);
__decorate([
    observable.ref
], SlidingPanelState.prototype, "portals", void 0);
__decorate([
    observable.ref
], SlidingPanelState.prototype, "currentElement", void 0);
__decorate([
    observable.ref
], SlidingPanelState.prototype, "nextElement", void 0);
__decorate([
    action
], SlidingPanelState.prototype, "open", null);
__decorate([
    action
], SlidingPanelState.prototype, "setPanels", null);
__decorate([
    action
], SlidingPanelState.prototype, "setFooterOutlet", null);
__decorate([
    action
], SlidingPanelState.prototype, "back", null);
__decorate([
    action
], SlidingPanelState.prototype, "registerPanel", null);
__decorate([
    action
], SlidingPanelState.prototype, "unregisterPanel", null);
__decorate([
    action
], SlidingPanelState.prototype, "registerPortal", null);
__decorate([
    action
], SlidingPanelState.prototype, "getPortal", null);
export const SlidingPanelCtx = React.createContext(null);
