import { FpApi } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FpEventModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class ResourceDutyBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {

		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.resourceDuty?.enabled) loads.push(this.resourceDuty(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, duty: FpApi.Resource.Duty.Duty): Promise<void> {
		if (this.store.configuration.events.resourceDuty?.enabled) {
			this.mapDuty(state, duty);
		}
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		this.store.projectModel.eventStore.remove(`dsrdid:${id}`) as FpEventModel<FpApi.Resource.Duty.Duty>[];

		return Promise.resolve();
	}

	private mapDuty(state: BuilderState, duty: FpApi.Resource.Duty.Duty): void {
		switch (duty.linkIdType) {
			case "dscaid":
			default: {
				this.duty2Event(state, duty);
				break;
			}
		}
	}

	private async resourceDuty(state: RangeBuilderState): Promise<void> {
		const resourceDuties = await this.app.store.resource.duty.getRange(state.from, state.to);
		resourceDuties.forEach(duty => {
			this.mapDuty(state, duty);
		});
	}

	private duty2Event(state: BuilderState, duty: FpApi.Resource.Duty.Duty): void {
		this.makeEvent(state, () => {
			const start = DateTime.fromISO(duty.dateStart);
			let end = DateTime.fromISO(duty.dateEnd);

			if (duty.linkIdType === "fpvid") {
			// noop, aircraft get the lenght
			} else {
				const days = end.diff(start, "days").days;
				if (days < 1) {
				// shorter then a day, likely a duty
				// only show duty on their start date, mostly relevant for night shifts
					end = start.startOf("day").plus({ days: 1 });
				}

				// other longer duties like vacation we need to display the full length
			}

			const event = new FpEventModel({
				id: `dsrdid:${duty.id}`,
				startDate: this.toTimeZone(start),
				endDate: this.toTimeZone(end),
				allDay: true, // temp
				name: duty.dscatidType.toString(),

				fpLinkType: "dsrdid",
				fpLinkId: duty.id,
				fpData: duty,
			});

			return event;
		}, function*() {
			// assignment to resource
			yield { linkType: duty.linkIdType, linkId: duty.linkId };

			// directly assigned to the setup
			// if (duty.dsrdsidSub != null && duty.dsrdsidPos == null) {
			// 	yield { linkType: "dsrdsidRes", linkId: `${duty.dsrdsidSub}:resource:${duty.dsrdsidRes}` };
			// }

			// assigned to a shift on a setup
			// setup sub id : pos : resource shift
			// if (duty.dsrdsidSub && duty.dsrdsidPos && duty.dsrsid) {
			// 	yield { linkType: "dsrsid", linkId: `${duty.dsrdsidSub}:${duty.dsrdsidPos}:${duty.dsrsid}` };
			// }
		});
	}
}
