import { library } from "@fortawesome/fontawesome-svg-core";

const farDelta = {
	iconName: "delta",
	prefix: "far",
	icon: [
		283,
		300,
		[],
		"f270",
		"M2.551 289.324C2.904 288.362 31.415 223.337 65.908 144.824L128.622 2.075 132.165 2.074 135.707 2.074 195.56 145.824C228.479 224.887 255.599 289.912 255.827 290.324 256.054 290.737 199.015 291.074 129.074 291.074 8.385 291.074 1.941 290.985 2.551 289.324zM204.955 272.133C202.718 265.813 120.514 67.075 120.138 67.078 119.744 67.08 46.649 232.539 31.914 266.782L29.206 273.074H117.248C171.872 273.074 205.162 272.717 204.955 272.133z",
	],
};

const facDelta = {
	iconName: "delta",
	prefix: "fac",
	icon: [
		283,
		300,
		[],
		"f270",
		"M2.551 289.324C2.904 288.362 31.415 223.337 65.908 144.824L128.622 2.075 132.165 2.074 135.707 2.074 195.56 145.824C228.479 224.887 255.599 289.912 255.827 290.324 256.054 290.737 199.015 291.074 129.074 291.074 8.385 291.074 1.941 290.985 2.551 289.324zM204.955 272.133C202.718 265.813 120.514 67.075 120.138 67.078 119.744 67.08 46.649 232.539 31.914 266.782L29.206 273.074H117.248C171.872 273.074 205.162 272.717 204.955 272.133z",
	],
};

const farWordWrap = {
	iconName: "word-wrap",
	prefix: "far",
	icon: [
		283,
		300,
		[],
		"f270",
		"M850 3840 l0 -210 1710 0 1710 0 0 210 0 210 -1710 0 -1710 0 0 -210z M850 2560 l0 -210 1424 0 c926 0 1442 -4 1477 -10 182 -35 320 -177 350 -359 28 -176 -73 -366 -235 -442 -90 -42 -149 -49 -423 -49 l-243 0 0 217 0 218 -322 -323 -323 -322 323 -322 322 -323 0 217 0 216 293 4 c333 5 368 11 527 94 207 108 356 289 426 519 24 80 27 106 27 235 0 129 -3 155 -27 235 -72 238 -231 426 -451 531 -178 86 -27 79 -1677 82 l-1468 2 0 -210z M850 1280 l0 -210 640 0 640 0 0 210 0 210 -640 0 -640 0 0 -210z",
	],
};

const falBookOpenText = {
	iconName: "book-open-text",
	prefix: "fal",
	icon: [
		283,
		300,
		[],
		"f270",
		"M3 5h4v1H3V5zm0 3h4V7H3v1zm0 2h4V9H3v1zm11-5h-4v1h4V5zm0 2h-4v1h4V7zm0 2h-4v1h4V9zm2-6v9c0 .55-.45 1-1 1H9.5l-1 1-1-1H2c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.5l1 1 1-1H15c.55 0 1 .45 1 1zm-8 .5L7.5 3H2v9h6V3.5zm7-.5H9.5l-.5.5V12h6V3z",
	],
};

// alias: https://github.com/FortAwesome/Font-Awesome/issues/13947
const falBookOpenAlt = Object.assign(falBookOpenText, { iconName: "book-open-alt" });

library.add(farDelta as any, facDelta as any, farWordWrap as any, falBookOpenText as any, falBookOpenAlt as any);
