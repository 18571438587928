import { faGripVertical } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { useTheme } from "styled-components";
const RowToolbar = styled.div `
	display: flex;
	> button {
		padding: 5px 10px;
	}
`;
const RowContent = styled.div `
	height: 100%;
	display: flex;
	align-items: center;
	line-height: ${p => p.lineHeight}px;
	
	&:hover {
		background: #f2f2f2;
	}
	&:hover button {
		box-shadow: inset 0px 3.1em 0 #f2f2f2;
	} 
`;
const RowLabel = styled.div `
	flex: 1;
	display: flex;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
// eslint-disable-next-line react/prefer-stateless-function
export const FleetplanAlwaysExpandContentRenderer = function FleetplanAlwaysExpandContentRenderer(props) {
    const { scaffoldBlockPxWidth, toggleChildrenVisibility, connectDragPreview, connectDragSource, isDragging, canDrop, canDrag, node, title, draggedNode, path, treeIndex, isSearchMatch, isSearchFocus, icons, buttons, className, style, didDrop, lowerSiblingCounts, listIndex, swapFrom, swapLength, swapDepth, treeId, // Not needed, but preserved for other renderers
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    disableDropFromOutside, rowHeight, ...otherProps } = props;
    const nodeTitle = title || node.title;
    const theme = useTheme();
    return React.createElement("div", { style: {
            height: "100%",
            marginLeft: scaffoldBlockPxWidth * Math.max(0, lowerSiblingCounts.length - 1),
            background: isDragging ? theme.color.primary[100].main : null,
        }, ...otherProps },
        React.createElement(RowContent, { lineHeight: scaffoldBlockPxWidth === 46 ? 46 : 34, ref: e => {
                // Set the row preview to be used during drag and drop
                connectDragPreview(e);
            } },
            canDrag ? React.createElement("div", { ref: e => {
                    connectDragSource(e, { dropEffect: "copy" });
                }, style: { display: "flex", position: "relative" } },
                React.createElement(FontAwesomeIcon, { className: "dnd-grab", color: theme.fontMuted, fixedWidth: true, icon: faGripVertical, style: { cursor: "move", fontSize: ".75em", } }),
                " ")
                : null,
            React.createElement(RowLabel, { draggable: false }, nodeTitle),
            buttons.length > 0 ? (React.createElement(RowToolbar, null, buttons.map((btn, index) => (React.createElement(React.Fragment, { key: index }, btn))))) : null));
};
FleetplanAlwaysExpandContentRenderer.defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: "",
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null,
    disableDropFromOutside: false,
};
