import { DateTime } from "luxon";
import React from "react";
import { useController } from "react-hook-form";
import styled from "styled-components";
import { CommandBar, Flex, FlexItem } from "../../../Components";
import { UiContext } from "../../../FpWebUiProvider";
import { Form } from "../../../index";
import { useOutsideAlerter } from "../../../lib";
import { Input } from "../../Input";
import { DateRange, Overlay, Wrapper } from "../util";
import { DateRangePicker } from "./DateRangePicker";
const InternalDateRangeInput = (props) => {
    const [show, setShow] = React.useState(false);
    const uiCtx = React.useContext(UiContext);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => { if (show) {
        setShow(false);
        props.onClose?.();
    } });
    const [from, to] = React.useMemo(() => {
        if (!props.dateRange)
            return [undefined, undefined];
        return [props.dateRange.from, props.dateRange.to];
    }, [props.dateRange]);
    return (React.createElement(Wrapper, { ref: wrapperRef, style: props.fullHeight ? { height: "100%" } : undefined },
        show && React.createElement(Overlay, { left: props.pullLeft },
            React.createElement(DateRangePicker, { minDate: props.minDate, maxDate: props.maxDate, dateRange: props.dateRange, onChange: (st) => {
                    props.onChange(st);
                }, maxNumberOfDays: props.maxNumberOfDays, showPresets: props.showPresets, presets: props.presets }),
            props.withFooter && React.createElement(Flex, { justifyContent: "flex-end", style: { marginRight: "1rem" } },
                React.createElement(FlexItem, null,
                    React.createElement(CommandBar.Right, null,
                        React.createElement(CommandBar.Button, { size: "xs", variant: "link", onClick: () => setShow(false) }, "Close"),
                        React.createElement(CommandBar.Button, { size: "xs", variant: "primary", onClick: () => setShow(false) }, "Apply"))))),
        React.createElement(FromToGrid, { ...(props.componentProps ?? {}) },
            React.createElement(Form.Field, { ...(props.fromFieldProps ?? {}) },
                React.createElement(Input, { onClick: () => {
                        if (props.disabled)
                            return;
                        if (show) {
                            props.onClose?.();
                        }
                        else {
                            props.onOpen?.();
                        }
                        setShow(!show);
                    }, style: { textAlign: "right" }, value: from ? uiCtx.formatter.date(from) : "", disabled: props.disabled })),
            React.createElement(Form.Field, { ...(props.toFieldProps ?? {}) },
                React.createElement(Input, { onClick: () => {
                        if (props.disabled)
                            return;
                        if (show) {
                            props.onClose?.();
                        }
                        else {
                            props.onOpen?.();
                        }
                        setShow(!show);
                    }, style: { textAlign: "right" }, value: to ? uiCtx.formatter.date(to) : "", disabled: props.disabled })))));
};
const FromToGrid = styled.div `
display: grid;
grid-template-columns: 1fr 1fr;
gap: 1rem;
`;
const InternalHookDateRangeInput = (props) => {
    const fromCtrl = useController({ name: props.name[0] });
    const toCtrl = useController({ name: props.name[1] });
    const range = React.useMemo(() => {
        if (!fromCtrl.field.value || !toCtrl.field.value)
            return undefined;
        return new DateRange(fromCtrl.field.value ? DateTime.fromISO(fromCtrl.field.value) : null, 
        // cheese to make the range exclusive from the end date
        toCtrl.field.value ? DateTime.fromISO(toCtrl.field.value) : null);
    }, [fromCtrl.field.value, toCtrl.field.value]);
    return React.createElement(InternalDateRangeInput, { ...props, dateRange: range, onChange: (range) => {
            fromCtrl.field.onChange(range.from?.toISODate() ?? null);
            // get the one minute back
            toCtrl.field.onChange(range.to.toISODate() ?? null);
        } });
};
export const DateRangeInput = Object.assign(InternalDateRangeInput, {
    Hook: InternalHookDateRangeInput
});
