import { Format, Table, UnitSize } from "@tcs-rliess/fp-web-ui";
import React from "react";
import styled from "styled-components";
import { DefaultFooter, DefaultHeader } from "../../lib";
import { MovementTable } from "./MovementTable";
export const LoadManifest = (props) => {
    return (React.createElement(PDF, null,
        props.data?.loadManifestData.flightDataArray.map((flightData, index) => {
            return React.createElement(React.Fragment, { key: `flightDataArray_${index}` },
                React.createElement(DefaultHeader, null),
                React.createElement(PrintTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement(TopHeader, { style: { textAlign: "left" } }, flightData.aircraftRegistration && React.createElement("h3", { style: { fontWeight: "normal" } }, flightData.aircraftRegistration)),
                            React.createElement(TopHeader, null,
                                React.createElement("h3", { style: { fontWeight: "normal", textAlign: "center" } },
                                    "Load Manifest",
                                    React.createElement("br", null),
                                    flightData.eventId)),
                            React.createElement(TopHeader, { style: { textAlign: "right" } }, flightData.flightNo && React.createElement("h3", { style: { fontWeight: "normal" } },
                                "#",
                                flightData.flightNo))))),
                React.createElement(SlimTable, null,
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement(UTh, null, "DOF"),
                            React.createElement("td", null, flightData.dateOfFlight ? React.createElement(Format.Date, null, flightData.dateOfFlight) : ""),
                            React.createElement(UTh, null, "Customer"),
                            React.createElement("td", null, flightData.customerName)),
                        React.createElement("tr", null,
                            flightData.leftSeat && React.createElement(React.Fragment, null,
                                React.createElement(UTh, null, flightData.leftSeat.role),
                                React.createElement("td", null, flightData.leftSeat.name)),
                            flightData.rightSeat && React.createElement(React.Fragment, null,
                                React.createElement(UTh, null, flightData.rightSeat.role),
                                React.createElement("td", null, flightData.rightSeat.name))),
                        React.createElement("tr", null,
                            React.createElement(UTh, { colSpan: 4 }, "Reference")),
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 4 },
                                flightData.reference,
                                "\u00A0")),
                        React.createElement("tr", null,
                            React.createElement(UTh, { colSpan: 4 }, "Remarks")),
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 4 },
                                flightData.remarks,
                                "\u00A0")))),
                React.createElement("br", null),
                flightData.connectionDataArray.map((connectionData, index) => {
                    return React.createElement(React.Fragment, { key: `connectionData_${index}` },
                        React.createElement(SlimTable, null,
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement(BlTh, { style: { width: "4cm" } }, "#"),
                                    React.createElement(BlTh, null, "DEP"),
                                    React.createElement(BlTh, null, "DES"),
                                    React.createElement(BlTh, null, "STD"),
                                    React.createElement(BlTh, null, "STA"),
                                    React.createElement(BlTh, null, "ETE "))),
                            React.createElement("tbody", null,
                                React.createElement("tr", null,
                                    React.createElement(BlTd, null,
                                        index + 1,
                                        " / ",
                                        flightData.connectionDataArray?.length),
                                    React.createElement(BlTd, null, connectionData.from),
                                    React.createElement(BlTd, null, connectionData.to),
                                    React.createElement(BlTd, null,
                                        React.createElement(Format.Time, null, connectionData.std)),
                                    React.createElement(BlTd, null,
                                        React.createElement(Format.Time, null, connectionData.sta)),
                                    React.createElement(BlTd, null, connectionData.ete)))),
                        React.createElement("br", null),
                        (props.data?.loadManifestData.paxEnabled && (connectionData.pax.length > 0)) && React.createElement(React.Fragment, null,
                            React.createElement(SlimTable, null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement(UTh, { style: { width: "0.5cm" } }, "#"),
                                        React.createElement(UTh, { style: { width: "4cm" } }, "Passenger"),
                                        React.createElement(UTh, { style: { width: "2cm" } }, "Dep"),
                                        React.createElement(UTh, { style: { width: "2cm" } }, "Des"),
                                        React.createElement(UTh, { style: { textAlign: "right", width: "1.5cm" } }, "Bag"),
                                        React.createElement(UTh, { style: { textAlign: "right", width: "1.5cm" } }, "Pax"),
                                        React.createElement(UTh, { style: { width: "1.5cm", textAlign: "right" } }, "Ttl"))),
                                React.createElement("tbody", null, connectionData.pax?.map((bookingItemPax, index) => {
                                    return React.createElement("tr", { key: bookingItemPax.id },
                                        React.createElement("td", null, index + 1),
                                        React.createElement("td", null,
                                            bookingItemPax.name?.length > 0 ? bookingItemPax.name : React.createElement("span", { style: { color: "GrayText" } }, "Reserved"),
                                            React.createElement("br", null),
                                            React.createElement("span", { style: { color: "gray", fontSize: 10 } }, bookingItemPax.flags)),
                                        React.createElement("td", null, bookingItemPax.from),
                                        React.createElement("td", null, bookingItemPax.to),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemPax.pax?.bagWeight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, bookingItemPax.pax.bagWeight) : ""),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemPax.pax?.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, bookingItemPax.pax.weight) : ""),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemPax.pax?.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, (bookingItemPax?.pax?.bagWeight ?? 0) + (bookingItemPax?.pax?.weight ?? 0)) : ""));
                                })),
                                React.createElement("tfoot", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", { style: { textAlign: "right" }, colSpan: 4 },
                                            "Total ",
                                            props.data?.loadManifestData.weightUnit),
                                        React.createElement("td", { style: { textAlign: "right" } }, connectionData.totalPaxBagWeight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, connectionData.totalPaxBagWeight) : null),
                                        React.createElement("td", { style: { textAlign: "right" } }, connectionData.totalPaxWeight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, connectionData.totalPaxWeight) : null),
                                        React.createElement("td", { style: { textAlign: "right" } }, React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, (connectionData.totalPaxBagWeight ?? 0) + (connectionData.totalPaxWeight ?? 0)))))),
                            React.createElement("br", null)),
                        (props.data?.loadManifestData.cargoEnabled && (connectionData.cargo.length > 0)) && React.createElement(React.Fragment, null,
                            React.createElement(SlimTable, null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement(UTh, { style: { width: "0.5cm" } }, "#"),
                                        React.createElement(UTh, { style: { width: "4cm" } }, "Cargo"),
                                        React.createElement(UTh, { style: { width: "2cm" } }, "Dep"),
                                        React.createElement(UTh, { style: { width: "2cm" } }, "Des"),
                                        React.createElement(UTh, { style: { width: "1.5cm", textAlign: "right" } }, "WT"),
                                        React.createElement(UTh, { style: { width: "1.5cm", textAlign: "right" } }, "QTY"),
                                        React.createElement(UTh, { style: { textAlign: "right", width: "1.5cm" } }, "TTL WT"))),
                                React.createElement("tbody", null, connectionData.cargo?.map((bookingItemCargo, index) => {
                                    return (React.createElement("tr", { key: bookingItemCargo.id },
                                        React.createElement("td", null, index + 1),
                                        React.createElement("td", null,
                                            bookingItemCargo.cargo?.description,
                                            React.createElement("br", null),
                                            React.createElement("span", { style: { color: "gray", fontSize: 10 } }, bookingItemCargo.flags)),
                                        React.createElement("td", null, bookingItemCargo.from),
                                        React.createElement("td", null, bookingItemCargo.to),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemCargo.cargo?.quantity ?? ""),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemCargo.cargo?.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, bookingItemCargo.cargo.weight) : ""),
                                        React.createElement("td", { style: { textAlign: "right" } }, bookingItemCargo.cargo?.weightTotal ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, bookingItemCargo.cargo.weightTotal) : "")));
                                })),
                                React.createElement("tfoot", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", { style: { textAlign: "right" }, colSpan: 6 },
                                            "Total ",
                                            props.data?.loadManifestData.weightUnit),
                                        React.createElement("td", { style: { textAlign: "right" } },
                                            React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, connectionData.totalCargoWeight))))),
                            React.createElement("br", null)),
                        React.createElement(SlimTable, { style: { width: "6cm", marginLeft: "auto" } },
                            React.createElement("tbody", null,
                                props.data?.loadManifestData.paxEnabled && React.createElement("tr", null,
                                    React.createElement(UTh, null, "Passenger"),
                                    React.createElement("td", { style: { textAlign: "right", width: "2cm" } },
                                        React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, (connectionData.totalPaxBagWeight ?? 0) + (connectionData.totalPaxWeight ?? 0)))),
                                props.data?.loadManifestData.cargoEnabled && React.createElement("tr", null,
                                    React.createElement(UTh, null, "Cargo"),
                                    React.createElement("td", { style: { textAlign: "right", width: "2cm" } },
                                        React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, connectionData.totalCargoWeight))),
                                React.createElement("tr", null,
                                    React.createElement(UTh, null, "Crew"),
                                    React.createElement("td", { style: { textAlign: "right", width: "2cm" } },
                                        React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, flightData.crewWeight ?? 0))),
                                React.createElement("tr", null,
                                    React.createElement(UTh, null, "Total"),
                                    React.createElement("td", { style: { textAlign: "right", width: "2cm" } },
                                        React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, (connectionData.totalCargoWeight ?? 0) + (connectionData.totalPaxBagWeight ?? 0) + (connectionData.totalPaxWeight ?? 0) + (flightData.crewWeight)))))),
                        React.createElement("br", null));
                }),
                React.createElement(DefaultFooter, null),
                React.createElement(PageBreak, null));
        }),
        React.createElement(MovementTablePage, null,
            React.createElement(DefaultHeader, null),
            React.createElement(PrintTable, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(TopHeader, { style: { textAlign: "left" } }),
                        React.createElement(TopHeader, null,
                            React.createElement("h3", { style: { fontWeight: "normal" } }, "Movements")),
                        React.createElement(TopHeader, { style: { textAlign: "right" } })))),
            React.createElement(MovementTable, { data: props.data?.movementTableData, hideHeader: true, movementTableParams: props.loadManifestParams?.movement }))));
};
const PageBreak = styled.div `
	break-after: page;
`;
const TopHeader = styled.th `
	width: 33%;
	font-weight: normal;
	font-size: 100;
	vertical-align: top;
`;
const SlimTable = styled(Table) `
	td {
		border-color: #e1e5e8 !important;
		padding: 0.06cm !important;
	}
	th {
		background-color: #e1e5e8 !important;
		border-color: #e1e5e8 !important;
		padding: 0.06cm !important;
	}
`;
const MovementTablePage = styled.div `
	page: MovementTable;
`;
const PDF = styled.div `
	@page MovementTable {
		size: A4 landscape;
	}
`;
// Borderless <th>
const BlTh = styled.th `
	text-transform: uppercase;
	border: none !important;
`;
// Borderless <td>
const BlTd = styled.td `
	border: none !important;
`;
// Capitalized <th>
const UTh = styled.th `
	text-transform: uppercase;
	background-color: #e1e5e8 !important;
`;
const PrintTable = styled.table `
	font-size: 13px;
	width: "100%";
`;
