import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { FlexItem } from "..";
export const SideMenu = function SideMenu(props) {
    const [open, setOpen] = React.useState(false);
    return (React.createElement(StyledSideMenu, { className: open ? "open" : "" },
        React.createElement(SimpleMenuItem, { onClick: () => setOpen(e => !e), style: { width: "100%" } },
            React.createElement("div", { style: { width: 40, textAlign: "center" } },
                React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: "bars" })),
            open && React.createElement(FlexItem, { grow: true }),
            open && React.createElement(FlexItem, { shrink: true }, props.customerImage && React.createElement("img", { src: props.customerImage, style: { height: 20 } }))),
        props.items.map((item) => {
            return (React.createElement(StyledMenuItem, { key: item.id },
                React.createElement("div", { style: { width: 40, textAlign: "center" } }, item.icon ? React.isValidElement(item.icon) ? item.icon : React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: item.icon }) : React.createElement("div", null)),
                open && React.createElement(FlexItem, { grow: true }, item.name),
                open && React.createElement("div", { style: { width: 40 } },
                    React.createElement(FontAwesomeIcon, { icon: "chevron-right" }),
                    (item.children && item.children.length > 0) ? React.createElement(FontAwesomeIcon, { icon: "chevron-right" }) : React.createElement("div", null))));
        })));
};
const SimpleMenuItem = styled.button `
padding: 0px;
border: none;
text-align: center;
background-color: ${p => p.theme.background};
gap: 1rem;
display: flex;
height: 40px;
align-items: center;
white-space: nowrap;
user-select: none;
`;
const StyledMenuItem = styled.button `
padding: 0px;
border: none;
background-color: ${p => p.theme.background};
display: flex;
gap: 1rem;
height: 40px;
align-items: center;
white-space: nowrap;
user-select: none;
transition: box-shadow 0.5s ease-in-out;
box-shadow: 0px 0px 0px 0px black;
position: relative;
color: ${p => p.theme.font};
text-align: left;
::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0px;
	height: 100%;
}

&:hover {
	background-color: ${p => p.theme.defaults.hover};
	box-shadow: -10px 0px 0px 0px ${p => p.theme.color.primary[500].main};
	color: ${p => p.theme.color.primary[500].main};
	::after {
		transition: width 0.2s ease-in-out;
		position: absolute;
		width: 2px;
		height: 100%;
		background-color: ${p => p.theme.color.primary[500].main};
	}
}

&:focus {
	outline: none;
}

&:active {
	background-color: ${p => p.theme.defaults.borderColor};
}
`;
const StyledSideMenu = styled.div `

${StyledMenuItem} {
	width: 250px;
}
transition: width 0.5s ease-in-out;
&.open {
	width: 250px;
}



background-color: ${p => p.theme.background};
border-right: 1px solid ${p => p.theme.defaults.borderColor};
overflow-x: hidden;
overflow-y: auto;
width: 40px;
`;
