import React from "react";
import { DayPicker as ReactDayPicker } from "react-day-picker";
import styled, { css } from "styled-components";
import { Button } from "../../Components";
const DayPickerStyled = styled(ReactDayPicker) `
	--rdp-cell-size: 23px;
	--rdp-caption-font-size: 12px;
	.rdp {
		margin: .3em;
	}
	height: 100%;
	${props => {
    if (props.className && props.className.split(" ").includes("lg")) {
        return css `font-size: 1.1em !important;`;
    }
}}

	& .rdp-nav_icon {
		transform: scale(.5);
	}

	& .rdp-day_selected {
		background-color: #9ac9ff !important;
		color: white;

	}
	& .rdp-day_selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
		background-color: #b8daf88f !important;
		color: #4a90e2;

	}
	& .rdp-day {
		border-radius: 0 !important;
		font-size: 0.8em;
	}
	
	& .rdp-weeknumber {
		border-radius: 0 !important;
		font-size: 0.8em;
		color: ${p => p.theme.fontMuted};
	}

	& .rdp-months {
		flex-wrap: nowrap;
	}
	& .rdp-day_today {
		border: 1px solid ${p => p.theme.color.danger[500].main} !important;
		//color: white;
	}
	& .DayPicker-Day--today:hover {
		// background: #4682b4b5 !important;
		// color: white;
	}
	& .rdp-nav_button {

	}
`;
export const DayPicker = function DayPicker(props, ref) {
    const footer = (React.createElement(Button, { variant: "link", size: "xs", onClick: props.onDayClick?.bind(this, new Date()) }, "Today"));
    // find dates from the props selected
    let fromYear = new Date().getFullYear();
    let toYear = new Date().getFullYear();
    // "selected" is out value, it can be a mix of "Date"s in arrays and objects
    if (props.selected instanceof Date) {
        fromYear = Math.min(fromYear, props.selected.getFullYear());
        toYear = Math.max(toYear, props.selected.getFullYear());
    }
    else if (Array.isArray(props.selected)) {
        props.selected.forEach((item) => {
            if (item instanceof Date) {
                fromYear = Math.min(fromYear, item.getFullYear());
                toYear = Math.max(toYear, item.getFullYear());
            }
            else {
                Object
                    .values(item)
                    .filter(d => d instanceof Date)
                    .forEach((d) => {
                    fromYear = Math.min(fromYear, d.getFullYear());
                    toYear = Math.max(toYear, d.getFullYear());
                });
            }
        });
    }
    const finalProps = {
        showWeekNumber: true,
        showOutsideDays: true,
        fixedWeeks: props.numberOfMonths > 1,
        weekStartsOn: 1,
        ISOWeek: true,
        disableNavigation: false,
        pagedNavigation: true,
        captionLayout: "dropdown-buttons",
        fromYear: fromYear - 10,
        toYear: toYear + 10,
        footer,
        ...props
    };
    return React.createElement(DayPickerStyled, { ...finalProps });
};
