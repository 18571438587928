import { ApiContext, FpApi, IContactsHR, WorkflowUtil as WorkflowUtilCore } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FpDirClientDataProcessor } from "./fp-query";

export class WorkflowUtil extends WorkflowUtilCore {
	public static getWorkflowRelation(relations: FpApi.InlineRelation[]): FpApi.InlineRelation {
		return relations?.find(e => e.category === FpApi.System.Workflow.TaskCategory.TaskWorkflowItemCategory) ?? null;
	}

	protected async getContract(ctx: ApiContext, dscaid: number): Promise<IContactsHR>  {
		const dirClient = new FpDirClientDataProcessor(ctx);
		const contract = await dirClient.contractHrGetByDate({
			params: {
				dscaid: dscaid,
				date: Number.parseInt(DateTime.utc().toFormat("yyyyMMdd")),
			},
			cache: {
				// 1 hours
				maxAge: 1 * 60 * 60 * 1000,
			},
		});

		return contract;
	}
}
