import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAddressBook, faAnchor, faAngleLeft,
	faAt,
	faBan, faBars, faBook, faBookmark, faBoxesAlt, faBuilding, faCalendar, faCalendarAlt, faCaretSquareDown,
	faCertificate,
	faChairOffice,
	faChartBar, faChartLine, faCheckSquare,
	faCircle,
	faCity,
	faClipboard, faClock, faCog, faCommentPlus, faComments, faCommentSlash, faCopy, faCreditCard,
	faCreditCardFront,
	faCrown,
	faDoorClosed,
	faDotCircle, faDownload,
	faDrone, faDroneAlt,
	faEllipsisV, faEnvelope,
	faExchange,
	faExternalLink,
	faExternalLinkSquare, faFile, faFileImage, faFilePdf,
	faFlag,
	faFont,
	faGlobe,
	faGlobeEurope,
	faGraduationCap, faHandPointRight,
	faHashtag,
	faHeadphonesAlt,
	faHelicopter,
	faHospital,
	faHospitalAlt,
	faICursor,
	faInboxIn, faInboxOut, faIndustry, faInfinity, faKey, faLanguage,
	faMagnifyingGlassMinus,
	faMapSigns, faMedkit,
	faMinus,
	faMobile, faMoneyBill, faPaperclip,
	faParagraph,
	faPencil,
	faPercent,
	faPlane,
	faPlus, faProjectDiagram, faQuestionCircle,
	faReply,
	faReplyAll,
	faSearch, faServer, faShieldCheck, faSigma, faSitemap, faSquare,
	faStar,
	faSuitcase,
	faSuitcaseRolling,
	faTag,
	faTicket,
	faTint,
	faTrash, faUpload, faUser,
	faUserCheck, faUserGraduate, faUsers,
	faUsersClass,
	faUserSecret,
	faUserShield,
	faUserTie,
	faWarehouse,
	faWarehouseAlt, faWindowRestore
} from "@fortawesome/pro-regular-svg-icons";

library.add(
	faAddressBook, faAnchor, faAngleLeft, faBan, faBars, faBook, faBookmark, faBoxesAlt, faBuilding, faCalendar, faCalendarAlt, faCaretSquareDown,
	faCertificate, faChartBar, faChartLine, faCheckSquare, faClipboard, faClock, faCog, faCommentPlus, faComments, faCommentSlash, faCopy, faCreditCard,
	faDotCircle, faDownload, faEllipsisV, faEnvelope, faExternalLinkSquare, faFile, faFileImage, faFilePdf, faGlobe, faGraduationCap, faHandPointRight,
	faHashtag, faInboxIn, faInboxOut, faIndustry, faInfinity, faKey, faLanguage, faMapSigns, faMedkit, faMobile, faMoneyBill, faPaperclip, faPencil, faPlane,
	faPlus, faProjectDiagram, faQuestionCircle, faSearch, faServer, faShieldCheck, faSigma, faSitemap, faSquare, faSuitcase, faTag, faTrash, faUpload, faUser,
	faUserCheck, faUserGraduate, faUsers, faUserTie, faWarehouseAlt, faWindowRestore, faFlag, faCrown, faCircle, faFont, faMinus, faICursor, faParagraph,
	faTint, faExchange, faAt, faHelicopter, faDrone, faDroneAlt, faUserSecret, faReply, faStar, faReplyAll, faPercent, faTicket, faGlobeEurope, faCity, faWarehouse,
	faHospital, faUsersClass, faHospitalAlt, faExternalLink, faSuitcaseRolling, faUserShield, faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed, faMagnifyingGlassMinus
);
