import { ApiContext, ApiSpecService, apiManager } from "@tcs-rliess/fp-core";
import { DataProcessorGetParams, FP_QUERY } from "@tcs-rliess/fp-query";

import { ApiUtil } from "../../api";

type ServiceConstructor = abstract new () => any & { rpcId: string; };

type MappedService<SERVICE extends ServiceConstructor> = {
	[PROP in keyof InstanceType<SERVICE>]: InstanceType<SERVICE>[PROP] extends (ctx: ApiContext, signal?: AbortSignal) => infer RETURN ? (options: DataProcessorGetParams<undefined>) => RETURN
		: InstanceType<SERVICE>[PROP] extends (ctx: ApiContext, params: infer PARAMS, signal?: AbortSignal) => infer RETURN ? (options: DataProcessorGetParams<PARAMS>) => RETURN
			: InstanceType<SERVICE>[PROP];
}

export class ServiceDataProcessor {
	private readonly spec: ApiSpecService;
	private readonly ctx: ApiContext;

	public readonly rpcId: string;

	private static CACHE = new Map<string, MappedService<any>>();

	private constructor(ctx: ApiContext, spec: ApiSpecService) {
		this.spec = spec;
		this.rpcId = apiManager.fullPath(this.spec);

		for (const method in spec.spec.methods) {
			const methodSpec = spec.spec.methods[method];
			const methodPath = `${this.rpcId}.${method}`;

			this[method] = (options: DataProcessorGetParams<unknown>) => {
				return ApiUtil.fetchRpc2(this.ctx, methodPath, options.params, options.signal);
			};

			if (methodSpec.kind === "query") {
				// method with params have signal as third argument, otherwise signal will be the second argument
				this[method] = (options: DataProcessorGetParams<unknown>) => {
					return FP_QUERY.query({
						key: methodPath,
						params: options.params,
						maxAge: options.cache?.maxAge,
						work: (params) => {
							return ApiUtil.fetchRpc2(this.ctx, methodPath, params, options.signal);
						},
					});
				};
			}
		}
	}

	public static getService<SERVICE extends ServiceConstructor>(ctx: ApiContext, service: SERVICE): MappedService<SERVICE> {
		const rpcId = (service as any).rpcId;

		// check cache
		const external = this.CACHE.get(rpcId);
		if (external) {
			return external;
		}

		// setup processor
		const spec = apiManager.getSpec(rpcId) as ApiSpecService;
		const processor = new ServiceDataProcessor(ctx, spec);

		this.CACHE.set(rpcId, processor);

		return processor as MappedService<SERVICE>;
	}
}
