import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

import { Bucket } from "./Bucket";

/**
 * @deprecated
 */
export class SchedulerBackendStore {
	private app: FleetplanApp;

	private buckets = new Map<string, Bucket>();

	constructor(app: FleetplanApp) {
		this.app = app;
	}

	public getBuckets(start: DateTime, end: DateTime): Bucket[] {
		const buckets: Bucket[] = [];

		for (let current = start.startOf("month"); current <= end; current = current.plus({ month: 1 })) {
			buckets.push(this.getBucket(current.year, current.month));
		}

		return buckets;
	}

	public getBucket(year: number, month: number): Bucket {
		const key = `${year}-${month}`;

		// check for existing bucket
		let bucket = this.buckets.get(key);
		if (bucket != null) {
			return bucket;
		}

		// create new bucket
		bucket = new Bucket(this.app, year, month);
		this.buckets.set(key, bucket);

		return bucket;
	}
}
