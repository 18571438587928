import { FpApi } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FpEventModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class ResourceDutyWishBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.resourceDuty?.enabled) loads.push(this.resourceDutyWish(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: FpApi.Resource.Duty.DutyWish): Promise<void> {
		if (this.store.configuration.events.resourceDuty?.enabled) {
			this.wish2Event(state, item);
		}

		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: number): Promise<void> {
		this.store.projectModel.eventStore.remove(`dsrschid:${id}`);
		return Promise.resolve();
	}

	private async resourceDutyWish(state: RangeBuilderState): Promise<void> {
		const resourceDutyWishes = await this.app.store.resource.dutyWish.getRange(state.from, state.to);

		resourceDutyWishes.forEach(wish => {
			this.wish2Event(state, wish);
		});
	}

	private wish2Event(state: BuilderState, wish: FpApi.Resource.Duty.DutyWish): void {
		this.makeEvent(state, () => {
			// don't display wishes that have a duty
			if (wish.dsrdid != null) return;

			const date = DateTime.fromISO(wish.date).startOf("day");
			const event = new FpEventModel({
				id: `dsrdwid:${wish.id}`,
				startDate: this.toTimeZone(date),
				endDate: this.toTimeZone(date.plus({ day: 1 })),
				allDay: true, // temp
				draggable: false,
				resizable: false,

				fpLinkType: "dsrdwid",
				fpLinkId: wish.id,
				fpData: wish,
			});

			return event;
		}, function*() {
			// assignment to resource
			yield { linkType: "dscaid", linkId: wish.dscaid };
		});
	}
}
