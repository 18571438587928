import Color from "color";
export const WHITE = new Color("white");
export const BLACK = new Color("black");
/**
 * Tint a color: mix a color with white
 * @param color input color
 * @param weight range 0 - 1
 */
export function tintColor(color, weight) {
    return WHITE.mix(color, weight);
}
/**
 * Shade a color: mix a color with black
 * @param color input color
 * @param weight range 0 - 1
 */
export function shadeColor(color, weight) {
    return BLACK.mix(color, weight);
}
