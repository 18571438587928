import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAnalytics,
	faAngleDoubleDown, faAngleDoubleUp, faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArchive,
	faArrowDownWideShort,
	faArrowFromLeft, faArrowFromRight,
	faArrowProgress,
	faArrowRight,
	faArrowToBottom,
	faArrowToRight,
	faArrowToTop,
	faArrowUpWideShort,
	faAt,
	faBalanceScale, faBan,
	faBars,
	faBook,
	faBookmark,
	faBox,
	faBriefcase,
	faBriefcaseMedical, faBuilding,
	faCalculator,
	faCalculatorSimple,
	faCalendar,
	faCalendarAlt,
	faCalendarCheck,
	faCalendarDay,
	faCalendarEdit, faCalendarExclamation,
	faCartArrowDown,
	faCertificate,
	faChairOffice,
	faChalkboardTeacher,
	faChartBar,
	faChartLine,
	faChartPie,
	faCheck,
	faCheckSquare, faChevronDoubleRight, faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCity,
	faClipboardList,
	faClipboardPrescription,
	faClock,
	faCodeBranch, faCog,
	faCogs,
	faColumns,
	faCommentPlus, faCommentSlash,
	faComments,
	faCompressAlt,
	faCopy,
	faCreditCard,
	faCreditCardFront,
	faCrown,
	faCube,
	faCubes,
	faDatabase,
	faDoorClosed,
	faDotCircle,
	faDownload,
	faDrone, faDroneAlt,
	faDrumstick,
	faEllipsisV,
	faEnvelope,
	faExchange,
	faExclamationTriangle,
	faExpandAlt,
	faExternalLink,
	faExternalLinkSquare,
	faEye,
	faEyeSlash,
	faFax,
	faFile, faFileAlt,
	faFileCertificate,
	faFileCheck,
	faFileExcel,
	faFileExport,
	faFilePdf,
	faFilter,
	faFlag,
	faFont,
	faGasPump,
	faGem, faGhost,
	faGlobeEurope,
	faGraduationCap,
	faHandHeart, faHandsHeart,
	faHdd,
	faHeadphones,
	faHeadphonesAlt,
	faHeartbeat,
	faHelicopter,
	faHistory, faHome,
	faHospital,
	faHospitalAlt,
	faICursor,
	faIdCard,
	faInfinity,
	faInfoCircle,
	faInventory,
	faIslandTropical,
	faKey,
	faKitMedical,
	faLayerGroup,
	faLightbulb,
	faLink,
	faList,
	faListAlt, faLock, faLockOpen, faMagnifyingGlassMinus, faMapMarker, faMapSigns,
	faMinus,
	faMinusCircle, faMobile,
	faMoon,
	faPaperPlane,
	faParagraph,
	faPaste,
	faPencil,
	faPercent,
	faPersonWalkingArrowLoopLeft,
	faPersonWalkingDashedLineArrowRight,
	faPhone,
	faPlane,
	faPlus,
	faPrint,
	faProjectDiagram, faQuestionCircle,
	faRepeat,
	faReply,
	faReplyAll,
	faScissors,
	faSearch,
	faSearchPlus, faShareSquare, faShield,
	faShieldCheck,
	faShip,
	faShoppingCart,
	faSigma,
	faSignature,
	faSitemap, faSkullCrossbones,
	faSlidersH,
	faSpinnerThird,
	faSquare,
	faStar,
	faStarShooting,
	faStickyNote,
	faSuitcase,
	faSun,
	faSync, faTablet, faTachometer, faTachometerAlt,
	faTasks,
	faTicket,
	faTimes,
	faTowerControl,
	faTrash,
	faUnlink, faUser,
	faUserChart,
	faUserCheck,
	faUserClock,
	faUserCrown,
	faUserHardHat,
	faUserLock, faUserPlus,
	faUserShield,
	faUserUnlock,
	faUsers,
	faUsersClass,
	faWarehouse,
	faWater,
	faWrench
} from "@fortawesome/pro-light-svg-icons";

library.add(
	faArchive, faArrowFromLeft, faArrowFromRight, faArrowRight, faArrowToRight, faBalanceScale, faBan, faBookmark, faBriefcaseMedical, faBuilding, faCalendar,
	faCalendarEdit, faCalendarExclamation, faCertificate, faCheckSquare, faChevronDoubleRight, faChevronDown, faChevronRight, faClipboardList,
	faClipboardPrescription, faCodeBranch, faCog, faCommentPlus, faCommentSlash, faCopy, faDownload, faEnvelope, faExclamationTriangle, faExternalLink, faEye,
	faFile, faFileAlt, faFileExcel, faFilePdf, faGem, faGhost, faHandHeart, faHandsHeart, faHeartbeat, faHistory, faHome, faIdCard, faLayerGroup, faLink,
	faListAlt, faLock, faLockOpen, faMapMarker, faMapSigns, faPencil, faPhone, faProjectDiagram, faQuestionCircle, faSearchPlus, faShareSquare, faShield,
	faShieldCheck, faSigma, faSitemap, faSkullCrossbones, faSquare, faStickyNote, faSync, faTablet, faTachometer, faTachometerAlt, faTimes, faUnlink, faUser,
	faUserLock, faUserPlus, faUsers, faUserShield, faUserChart, faSun, faMoon, faRepeat, faDotCircle, faFlag, faCrown, faFont, faMinus, faICursor, faParagraph,
	faUserUnlock, faAt, faCogs, faSlidersH, faUserHardHat, faHelicopter, faDrone, faDroneAlt, faInventory, faPlane, faSignature, faAnalytics, faArrowToTop,
	faArrowToBottom, faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp, faBook, faGasPump, faInfoCircle, faCalendarCheck, faChalkboardTeacher,
	faClock, faChartBar, faCubes, faPaperPlane, faHeadphones, faList, faLightbulb, faSearch, faInfinity, faGraduationCap, faChevronLeft, faBars, faCalculator,
	faCartArrowDown, faChartLine, faWater, faChartPie, faFileCertificate, faPlus, faExpandAlt, faCompressAlt, faUserCheck, faComments, faDatabase, faTrash,
	faExternalLinkSquare, faPrint, faCircle, faMinusCircle, faMobile, faFax, faFileCheck, faColumns, faCalendarAlt, faUserCrown, faEllipsisV, faTicket, faCheck,
	faSpinnerThird, faTasks, faCube, faShoppingCart, faSuitcase, faFileExport, faWrench, faCalendarDay, faExchange, faReply, faStar, faReplyAll, faHdd, faKey,
	faUserClock, faPercent, faShip, faGlobeEurope, faCity, faWarehouse, faHospital, faUsersClass, faHospitalAlt, faCreditCard, faDrumstick, faBox,
	faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed, faTowerControl, faFilter, faAngleRight, faAngleLeft, faArrowUpWideShort,
	faArrowDownWideShort, faEyeSlash, faScissors, faPaste, faKitMedical, faIslandTropical, faStarShooting, faArrowProgress, faCalculatorSimple, faPersonWalkingDashedLineArrowRight, faPersonWalkingArrowLoopLeft, faBriefcase, faMagnifyingGlassMinus
);
