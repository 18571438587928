import { apiManager, FpApi, TimeSpan } from "@tcs-rliess/fp-core";
import Aigle from "aigle";
import { DateTime } from "luxon";

import { BaseStoreRange } from "../../BaseStoreRange";

export class ResourceSchedulePublishStore extends BaseStoreRange<FpApi.Resource.Duty.SchedulePublish, number> {
	protected itemId(item: FpApi.Resource.Duty.SchedulePublish): number {
		return item.id;
	}
	protected itemDateModified(item: FpApi.Resource.Duty.SchedulePublish): DateTime {
		return DateTime.fromISO(item.dateModified);
	}

	protected itemRange(item: FpApi.Resource.Duty.SchedulePublish): TimeSpan {
		return new TimeSpan(
			DateTime.fromISO(item.dateFrom),
			DateTime.fromISO(item.dateTo),
		);
	}

	protected async fetchIdList(idList: number[]): Promise<FpApi.Resource.Duty.SchedulePublish[]> {
		return Aigle.map(idList, id => {
			return apiManager
				.getService(FpApi.Resource.Duty.SchedulePublishService)
				.getId(this.app.ctx, { id });
		});
	}

	protected async fetchRange(from: DateTime, to: DateTime): Promise<FpApi.Resource.Duty.SchedulePublish[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.SchedulePublishService)
			.get(this.app.ctx, {
				from: from.toISO(),
				to: to.toISO(),
			});
	}
}
