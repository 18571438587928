import { ApiContext, apiManager, ApiSpecService } from "@tcs-rliess/fp-core";
import { FP_QUERY } from "@tcs-rliess/fp-query";

import { ApiUtil } from "./api/ApiUtil";

function wrapService(spec: ApiSpecService): any {
	const rpcPath = apiManager.fullPath(spec);
	const impl: any = {
		rpcId: rpcPath,
	};

	for (const method in spec.spec.methods) {
		const methodSpec = spec.spec.methods[method];
		const methodPath = `${rpcPath}.${method}`;

		// method with params have signal as third argument, otherwise signal will be the second argument

		if (methodSpec.kind === "query") {
			if (methodSpec.params) {
				impl[method] = (ctx: ApiContext, params: any, signal?: AbortSignal) => {
					return FP_QUERY.query({
						key: methodPath,
						params: params,
						work: (params) => ApiUtil.fetchRpc2(ctx, methodPath, params, signal),
					});
				};
			} else {
				impl[method] = (ctx: ApiContext, signal?: AbortSignal) => {
					return FP_QUERY.query({
						key: methodPath,
						params: {},
						work: () => ApiUtil.fetchRpc2(ctx, methodPath, undefined, signal),
					});
				};
			}
		} else {
			if (methodSpec.params) {
				impl[method] = (ctx: ApiContext, params: any, signal?: AbortSignal) => {
					return ApiUtil.fetchRpc2(ctx, methodPath, params, signal);
				};
			} else {
				impl[method] = (ctx: ApiContext, signal?: AbortSignal) => {
					return ApiUtil.fetchRpc2(ctx, methodPath, undefined, signal);
				};
			}
		}
	}

	return impl;
}

// bit of a dummy
// we need to setup before we include lib/instances
// apiManager.initialize();
apiManager.validateParam = false;
apiManager.validateReturn = false;
apiManager.getSpecs().forEach(spec => {
	if (spec.kind !== "service") return;

	apiManager.implement(wrapService(spec));
});
