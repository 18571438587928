import { faChevronDown, faChevronRight, faChevronUp, faThumbtack } from "@fortawesome/pro-light-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { CommandBar } from "../../Components/CommandBar/CommandBar";
import { FlexItem } from "../../Components/Flex";
import { DockedManagerCtx } from "./DockedManagerState";
const DEFAULT_PANEL_SIZE = 400;
// const PanelHeader = styled.div`
// 	flex: 0 0 39px; // 31
// 	border-bottom: 1px solid ${p => p.theme.dockedManager.borderColor};
// 	display: flex;
// 	flex-direction: row;
// 	line-height: 39px; // 30
// 	@media (pointer: coarse) {
// 		line-height: 40px;
// 	}
// `;
const PanelBody = styled.div `
	flex: 1 0 0;
	overflow: auto;
	min-height: 0;
`;
const Panel = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	& > ${CommandBar.Button} {
		${p => p.position === "top" ? css `
			order: 1;
			border-bottom: none;
			border-top: 1px solid ${p => p.theme.dockedManager.borderColor};
		` : ""}
	}
`;
const PanelCtx = React.createContext(undefined);
export function usePanelKey() {
    return React.useContext(PanelCtx);
}
const DockedPanelComponent = observer(function DockedPanel(props) {
    const state = React.useContext(DockedManagerCtx);
    const [key] = React.useState(() => props.panelKey ?? Symbol());
    if (state == null) {
        throw new Error("DockedPanel appears to not be in an DockedManager!");
    }
    React.useEffect(() => {
        const size = props.initialSize ?? DEFAULT_PANEL_SIZE;
        return state.registerPanel({
            key: key,
            label: props.label,
            position: props.initialPosition,
            size: size,
            minSize: props.minSize ?? size,
            maxSize: props.maxSize ?? size + 200,
        });
    }, []);
    React.useEffect(() => { if (props.minSize)
        state.panels.get(key).minSize = props.minSize; }, [props.minSize]);
    React.useEffect(() => { if (props.maxSize)
        state.panels.get(key).maxSize = props.maxSize; }, [props.maxSize]);
    React.useEffect(() => { state.changeLabel(key, props.label); }, [props.label]);
    React.useEffect(() => {
        if (props.onClose == null)
            return;
        state.addEventListener(key, "close", props.onClose);
        return () => state.removeEventListener(key, "close", props.onClose);
    }, [props.onClose]);
    React.useEffect(() => {
        if (props.onOpen == null)
            return;
        state.addEventListener(key, "open", props.onOpen);
        return () => state.removeEventListener(key, "open", props.onOpen);
    }, [props.onOpen]);
    React.useEffect(() => {
        if (props.openOnMount === true)
            state.openPanel(key);
        else if (props.openOnMount === "sm")
            state.openPanel(key);
        else if (props.openOnMount === "md" && (state.isMd || state.isLg))
            state.openPanel(key);
        else if (props.openOnMount === "lg" && state.isLg)
            state.openPanel(key);
    }, []);
    const position = state.getPosition(key);
    const container = state.getElementForPanel(key);
    if (container == null)
        return null;
    return ReactDOM.createPortal(React.createElement(PanelCtx.Provider, { value: key },
        React.createElement(Panel, { position: position }, props.children)), container);
});
const ICON = {
    "top": faChevronUp,
    "right": faChevronRight,
    "bottom": faChevronDown,
    "left": faChevronLeft,
};
const DockedPanelHeaderComponent = observer(function DockedPanel(props) {
    const state = React.useContext(DockedManagerCtx);
    const key = React.useContext(PanelCtx);
    const position = state.getPosition(key);
    const floating = state.floating[position];
    return React.createElement(CommandBar, { style: { ...(props.style ?? {}), flexDirection: position === "right" ? "row-reverse" : undefined, background: position === "bottom" ? "#f5f7f7" : undefined } },
        props.children,
        React.createElement(FlexItem, { grow: true }),
        React.createElement(CommandBar.Right, null,
            ((state.isMd || state.isLg) && position !== "left") ? (React.createElement(CommandBar.Button, { active: floating, onClick: () => state.setFloating(position, !floating) },
                React.createElement(FontAwesomeIcon, { icon: faThumbtack, fixedWidth: true }))) : null,
            React.createElement(CommandBar.Button, { onClick: () => state.closePanel(key) },
                React.createElement(FontAwesomeIcon, { icon: ICON[position], fixedWidth: true }))));
});
const DockedPanelFooterComponent = observer(React.forwardRef(function DockedPanel(props, ref) {
    const state = React.useContext(DockedManagerCtx);
    const innerRef = React.useRef();
    React.useImperativeHandle(ref, () => innerRef.current);
    const key = React.useContext(PanelCtx);
    const position = state.getPosition(key);
    return React.createElement(CommandBar, { ref: innerRef, flipBorder: true, style: { ...(props.style ?? {}), flexDirection: position === "right" ? "row-reverse" : undefined, background: position === "bottom" ? "#f5f7f7" : undefined } }, props.children);
}));
const HeaderButtons = () => {
    const state = React.useContext(DockedManagerCtx);
    const key = React.useContext(PanelCtx);
    const position = state.getPosition(key);
    const floating = state.floating[position];
    return React.createElement(React.Fragment, null,
        ((state.isMd || state.isLg) && position !== "left") ? (React.createElement(CommandBar.Button, { active: floating, onClick: () => state.setFloating(position, !floating) },
            React.createElement(FontAwesomeIcon, { icon: faThumbtack, fixedWidth: true }))) : null,
        React.createElement(CommandBar.Button, { onClick: () => state.closePanel(key) },
            React.createElement(FontAwesomeIcon, { icon: ICON[position], fixedWidth: true })));
};
export const DockedPanel = Object.assign(DockedPanelComponent, {
    Header: DockedPanelHeaderComponent,
    Footer: DockedPanelFooterComponent,
    Title: CommandBar.Text,
    Body: PanelBody,
    Button: CommandBar.Button,
    HeaderButtons: HeaderButtons,
});
