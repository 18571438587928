import classNames from "classnames";
import { omit } from "lodash";
import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import styled, { css } from "styled-components";
import { isThemeColor } from "../../Css";
import { useFieldHook, useGroupBodyProps } from "../../index";
import { shareRef } from "../../lib/utils";
const InternalTextarea = React.forwardRef(function InternalTextarea(props, ref) {
    const [value, setValue] = React.useState(props.value ?? props.defaultValue ?? "");
    const textarea = React.useRef(null);
    const bodyProps = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? bodyProps?.bootstrap ?? true;
    React.useLayoutEffect(() => {
        if (!props.autoResize)
            return;
        textarea.current.style.height = "0px";
        textarea.current.style.height = `${textarea.current.scrollHeight}px`;
    }, []);
    const As = React.useMemo(() => {
        return bootstrap ? "textarea" : StyledTextarea;
    }, [bootstrap]);
    return React.createElement(TextareaClasses, { className: bootstrap ? undefined : "expand", variant: props.variant, style: props.containerStyle },
        React.createElement(As, { ...omit(props, "useBlur"), ref: shareRef(ref, textarea), className: `${bootstrap ? "form-control" : ""} ${props.className ?? ""}`, value: props.useBlur ? value : props.value, style: {
                resize: props.autoResize ? "none" : undefined,
                overflowY: props.autoResize ? "hidden" : undefined,
                ...(props.style ?? {}),
            }, onChange: e => {
                // auto size text area
                if (props.noLineBreak) {
                    e.target.value = e.target.value.replace(/\n/g, "");
                }
                if (props.autoResize) {
                    e.target.style.height = "0px";
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }
                props.useBlur ? setValue(e.target.value) : props.onChange?.(e);
            }, 
            /** used for formik to prevent too much rerenders */
            onBlur: (e) => {
                props.onBlur?.(e);
                if (!props.useBlur)
                    return;
                e.target.value = value || "";
                if (value)
                    props.onChange(e);
            } }),
        !bootstrap && React.createElement(InternalBorder, { variant: props.variant }),
        !bootstrap && React.createElement(Ticks, null));
});
const InternalHookTextarea = React.forwardRef(function HookInput(props, ref) {
    const { name } = useFieldHook() ?? {};
    const finalName = name ?? props.name;
    const { control, register } = useFormContext() ?? {};
    const stateProps = control ? { control: control } : null;
    const { errors } = useFormState(stateProps) ?? {};
    const variant = errors[finalName] ? "danger" : undefined;
    const formBodyProps = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? formBodyProps?.bootstrap;
    if (bootstrap) {
        return React.createElement("textarea", { className: classNames("form-control", props.className), ...props, ref: ref, ...register(finalName) });
    }
    return React.createElement(TextareaClasses, { className: "expand", variant: variant, style: props.containerStyle },
        React.createElement(StyledTextarea, { ...props, ref: ref, ...register(finalName) }),
        React.createElement(InternalBorder, { variant: variant }),
        React.createElement(Ticks, null));
});
const StyledTextarea = styled.textarea `
	display: block;

	border: none;
	border-bottom: 1px solid #dddddd;
	// background: rgb(180 180 180 / 8%);
	padding: 7px;
	width: 100%;
	resize: vertical;

	&:hover {
		outline: none;
		border-bottom-color: #cccccc;
		// background: rgb(180 180 180 / 10%);
	}

	&:focus {
		outline: none;
		// background: rgb(180 180 180 / 12%);
	}
`;
const Ticks = styled.div `
	width: 100%;

	::before {
		content: "";
		display: block;
		width: 1px;
		height: 8px;
		background: #dddddd;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	::after {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		border-top: 1px solid #dddddd;
		border-right: 1px solid #dddddd;
		position: absolute;
		top: 0;
		right: 0;
	}
`;
const InternalBorder = styled.div `
	display: block;
	position: absolute;
	height: 1px;
	width: 100%;
	bottom: 0;
	background: ${p => p.variant ? isThemeColor(p.variant) ? p.theme.color[p.variant][500].main : p.variant : p.theme.color["primary"][500].main};
	transform: scaleX(0);
	transition: transform 0.25s cubic-bezier(0.77, 0, 0.175, 1);
	transform-origin: 50% 0%;
	margin-top: -8px;
`;
const TextareaClasses = styled.div `
	position: relative;

	textarea:focus + ${InternalBorder}, .expand:focus ${InternalBorder} {
		transform: scaleX(1);
	}

	${p => p.variant ? css `
		textarea + ${InternalBorder}, .expand ${InternalBorder} {
			transform: scaleX(1);
		}
	` : null}
`;
export const Textarea = Object.assign(InternalTextarea, {
    Hook: InternalHookTextarea,
});
