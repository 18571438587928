import { FpApi, FpLegClientLeg } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../FleetplanApp";

//#region Helper for old API
export async function mapFpLegToFpApiLeg(l: FpLegClientLeg, app: FleetplanApp): Promise<FpApi.Resource.AircraftLeg.AircraftLeg> {
	let ac: FpApi.Resource.Aircraft;
	try {
		ac = await app.store.resource.aircraft.getId(l.fpvid);
	} catch (e) {
		ac = null;
	}
	return {
		id: l.id,
		mission: l.mission_id,
		blockOn: l.blockon,
		blockOff: l.blockoff,
		blockLocked: null,
		blockTime: l.blocktime,
		comDocIsInvoiced: l.data.customer.is_invoiced,
		comDoc: l.data.customer.comdoc,
		comDocSetAuto: null,
		crew: l.crew?.length ?? 0,
		flightFrom: `${(l.flight_from)}`,
		flightTo: `${(l.flight_to)}`,
		flightLocked: null,
		dscaidCustomerName: null,
		flightTime: l.flighttime,
		flightRegionFrom: l.flight_region_from,
		flightRegionTo: l.flight_region_to,
		blockTimeLocked: null,
		flightRule: l.data.flightrule,
		fpreslogid: l.fpreslogid,
		fpvid: l.fpvid,
		invoiceTimeType: null,
		landings: l.data.landings,
		mineralOilTaxRefund: null,
		n1CyclesEng1: null,
		n1CyclesEng2: null,
		n2CyclesEng1: null,
		n2CyclesEng2: null,
		opsEnd: null,
		opsStart: null,
		opsTime: null,
		passenger: l.data.passenger,
		pilots: l.crew.slice(0, 2),
		refuel: null,
		refuelLanding: l.data.fuel.refuel_l_after_landing,
		refuelTypeStart: l.data.fuel.unit as any,
		seriesBlockId: null,
		seriesBlockStep: null,
		type: null,
		$logbookPage: null,
		airHobbs: null,
		vehicle: { ...(ac ?? {}), model: l.aircraft_model },
	};
}
