import styled from "styled-components";
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
const MenuItemStyled = styled.div `
	padding: 8px 16px;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		background: ${p => p.theme.menu.hover};
	}
`;
MenuItemStyled.displayName = "MenuItem";
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
const MenuSeparatorStyled = styled.div `
	border-top: 1px solid ${p => p.theme.menu.borderColor};
`;
MenuSeparatorStyled.displayName = "MenuSeparator";
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
const MenuStyled = styled.div `
	border: 1px solid ${p => p.theme.menu.borderColor};
	background: ${p => p.theme.menu.background};
	min-width: 200px;
	max-width: 400px;
`;
MenuStyled.displayName = "Menu";
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
export const Menu = Object.assign(MenuStyled, {
    Item: MenuItemStyled,
    Separator: MenuSeparatorStyled,
});
