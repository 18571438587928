import React from "react";
import { UiContext } from "../../FpWebUiProvider";
import { useTimezone } from "./Context";
import { TzOffset } from "./TzOffset";
export const Date = function Date({ showTz = true, ...props }) {
    const { formatter } = React.useContext(UiContext);
    const tz = useTimezone(props.tz);
    let mask = props.mask;
    if (props.short) {
        mask = "short";
    }
    else if (props.medium) {
        mask = "medium";
    }
    if (showTz && formatter.options.tz !== (tz)) {
        return React.createElement(React.Fragment, null,
            formatter.date(props.children, {
                mask: mask,
                today: props.today,
                tz: tz,
            }),
            "\u00A0",
            tz && React.createElement(TzOffset, { date: props.children, tz: tz },
                React.createElement(Date, { ...props, tz: formatter.options.tz }, props.children)));
    }
    return React.createElement(React.Fragment, null, formatter.date(props.children, {
        mask: mask,
        today: props.today,
        tz: tz,
    }));
};
