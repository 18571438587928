import { observer } from "mobx-react";
import React from "react";

import { FleetplanApp, useApp } from "../FleetplanApp";

export function withCheck<P>(check: (app: FleetplanApp, props: P) => React.ReactElement, WrappedComponent: React.ComponentType<P>): React.FC<P> {
	const Component: React.FC<P> = function (props) {
		const app = useApp();
		const result = check(app, props);

		if (result == null) {
			return <WrappedComponent {...props}/>;
		} else {
			return result;
		}
	};

	Component.displayName = `WithCheck(${WrappedComponent.displayName || WrappedComponent.name || "?"})`;
	return observer(Component);
}
