import { apiManager, FpApi } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Lock from "async-lock";
import { openDB, IDBPDatabase, DBSchema } from "idb";
import { DateTime } from "luxon";
import { observable } from "mobx";

import { FleetplanApp } from "../../../../FleetplanApp";

interface MyDB extends DBSchema {
	articles: {
		key: number;
		value: {
			dssrid: number;
			dateCached: Date;
			articles: FpApi.ControlledDocument.Article[];
		};
		indexes: {
			"dateCached": Date;
		};
	};
}

export class ArticleStore {
	private app: FleetplanApp;
	private db: Promise<IDBPDatabase<MyDB>>;
	private lock = new Lock();
	private log = fpLog.child("ArticleStore")

	@observable
	public enabled = true;

	constructor(app: FleetplanApp) {
		this.app = app;
	}

	private async getDb(): Promise<IDBPDatabase<MyDB>> {
		if (this.db != null) {
			try {
				return await this.db;
			} catch (e) {
				this.log.warn("couldn't open indexed db", { e });
				this.enabled = false;
				return;
			}
		}

		try {
			let v3ClearData = false;

			this.db = openDB("ControlledDocumentStore", 3, {
				upgrade: (db, oldVersion, newVersion, transaction) => {
					this.log.info("upgrade", { oldVersion, newVersion });

					if (oldVersion < 1) {
						// no DB => 1
						db.createObjectStore("articles", {
							keyPath: "dssrid",
							autoIncrement: false,
						});
					}

					if (oldVersion < 2) {
						// 1 => 2
						// date cached index for clean up
						const articles = transaction.objectStore("articles");
						articles.createIndex("dateCached", "dateCached");
					}

					if (oldVersion < 3) {
						// 2 => 3
						// cached invalid data with wrong "valid to", so clear data once
						v3ClearData = true;
					}
				},
			});

			// note: indexed db does not work in firefox private mode, the await openDB wil return an error
			const db = await this.db;

			if (v3ClearData) {
				this.log.info("upgrade 2 -> 3", { articles: await db.count("articles") });
				void db.clear("articles");
				this.log.info("upgrade 2 -> 3", { articles: await db.count("articles") });
			}
		} catch (e) {
			this.log.warn("couldn't open indexed db", { e });
			this.enabled = false;
			return;
		}

		// run clean up once after the we opened the db
		await this.cleanUp();

		return this.db;
	}

	public async getArticles(dssrid: number): Promise<FpApi.ControlledDocument.Article[]> {
		return this.lock.acquire(`getArticles.${dssrid}`, async () => {
			const { ctx } = this.app;
			const db = await this.getDb();

			if (this.enabled == false) {
				this.log.warn("getArticles: store disabled, doing request");

				// load from api
				return await apiManager
					.getService(FpApi.ControlledDocument.RevisionService)
					.getArticles(ctx, { id: dssrid });
			} else {
				// check idb
				const entry = await db.get("articles", dssrid);
				if (entry != null) {
					return entry.articles;
				}

				// load from api
				const articles = await apiManager
					.getService(FpApi.ControlledDocument.RevisionService)
					.getArticles(ctx, { id: dssrid });

				await db.put("articles", {
					dssrid: dssrid,
					dateCached: new Date(),
					articles: articles,
				});

				return articles;
			}
		});
	}

	public async clear(): Promise<void> {
		const db = await this.getDb();
		await db.clear("articles");
	}

	public async cleanUp(): Promise<void> {
		const db = await this.getDb();

		// Articles contain image url's and links, these are valid for 7 days. but to the api using memcached the links we get may only be valid for 6 days.
		// We keep the cache for 5 days to have an extra buffer of 1 day.
		const before = DateTime.local().minus({ days: 5 }).toJSDate();

		const tx = db.transaction("articles", "readwrite");
		const keys = await tx
			.store
			.index("dateCached")
			.getAllKeys(IDBKeyRange.upperBound(before));

		for (const id of keys) {
			await tx.store.delete(id);
		}

		await tx.done;
	}
}
