import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React from "react";
import { usePanelKey } from "../../../Layout";
import { Dropdown } from "../../Dropdown/Dropdown";
import { Flex, FlexItem } from "../../Flex";
import { TabState, TabStateCtx, TabDiv, TabVariant } from "./lib";
import { Tab } from "./Tab";
import { TabBar } from "./TabBar";
import { TabBody } from "./TabBody";
import { DropdownParent } from "./TabDropdown";
export const TabContainerPropsCtx = React.createContext({});
export const TabContainer = observer(React.forwardRef(function Tabs(props, selfRef) {
    const [state] = React.useState(() => new TabState({ initial: props.mountOnEnter ? "mountOnEnter" : "mountAll", tabLeave: props.unmountOnLeave ? "unmount" : "keep", isControlled: !!props.handleTabClick }));
    const [moreKey] = React.useState(() => Symbol("more"));
    const ref = React.useRef(null);
    const dockedKey = usePanelKey();
    const hideOuterBorder = dockedKey == undefined ? props.hideFirstBorder ?? false : true;
    React.useImperativeHandle(selfRef, () => ({
        state,
    }));
    const [size, setSize] = React.useState(null);
    const updateDimensions = () => {
        if (ref.current && ref.current?.offsetParent && ref.current.offsetWidth) {
            setTimeout(() => {
                setSize(ref.current.offsetWidth);
            });
        }
    };
    React.useEffect(() => {
        if (props.activeTab !== undefined) {
            state.setActivePanel(props.activeTab);
        }
    }, [props.activeTab]);
    React.useEffect(() => {
        const obs = new ResizeObserver(updateDimensions);
        obs.observe(ref.current);
        window.addEventListener("resize", updateDimensions);
        setTimeout(() => {
            setSize((old) => {
                if (old === ref.current.clientWidth) {
                    return old;
                }
                return ref.current.clientWidth;
            });
        }, 10); // needs an slight initial offset to get the correct size
        return () => {
            window.removeEventListener("resize", updateDimensions);
            obs.disconnect();
        };
    }, []);
    React.useEffect(() => {
        if (!props.handleTabClick)
            state.setActivePanel(props.defaultActiveTab);
    }, [props.defaultActiveTab, state]);
    React.useEffect(() => {
        if (!props.handleTabClick) {
            if (state.activePanel !== props.defaultActiveTab)
                props.onChange?.(state.activePanel);
        }
    }, [state.activePanel, state, props.defaultActiveTab, props.onChange, props]);
    const visibilityKey = Array.from(state.knownPanels.values()).map(e => `${e.isHidden}`).join("-");
    const [more, knownPanels, tabSize] = React.useMemo(() => {
        let knownPanels = Array.from(state.knownPanels.entries()).filter(e => !e?.[1].hasDropdownParent).filter(e => !e?.[1]?.isHidden);
        // knownPanels.reverse(); // from behind
        // return [ [], knownPanels];
        let currentTabsize = Number(state.tabSize);
        const more = [];
        let iterations = 0;
        if (currentTabsize === size) {
            const p = knownPanels.pop();
            if (p)
                more.push(p);
            currentTabsize -= (p?.[1]?.size ?? 0);
        }
        else {
            while (currentTabsize > size) {
                const e = knownPanels.pop();
                if (!e)
                    continue;
                currentTabsize -= (e?.[1]?.size ?? 0);
                if (e)
                    more.push(e);
                iterations++;
                if (iterations > 1000) {
                    console.error("TabContainer second while loop, max iterations reached. Something went wrong...");
                    break;
                }
            }
        }
        knownPanels.forEach((e, index) => {
            if (e[1].hideInMore) {
                more.push(e);
                knownPanels[index] = null;
            }
        });
        knownPanels = knownPanels.filter(Boolean);
        if (more.length) {
            let iterations = 0;
            while ((currentTabsize + 55) > size) {
                const e = knownPanels.pop();
                currentTabsize -= (e?.[1]?.size ?? 0);
                if (e)
                    more.push(e);
                else
                    break;
                iterations++;
                if (iterations > 1000) {
                    console.error("TabContainer second while loop, max iterations reached. Something went wrong...");
                    break;
                }
            }
        }
        return [more.filter(Boolean), knownPanels, currentTabsize];
    }, [state.tabSize, state.knownPanels, size, state.knownPanels.size, visibilityKey]);
    return React.createElement(TabStateCtx.Provider, { value: state },
        React.createElement(DropdownParent.Provider, { value: null },
            React.createElement(TabVariant.Provider, { value: props.variant ?? "default" },
                React.createElement(TabContainerPropsCtx.Provider, { value: props },
                    React.createElement("div", { ref: ref, style: { height: "100%" } },
                        React.createElement(Flex, { column: true, style: { height: "100%", overflow: "visible" } },
                            React.createElement(TabBar, null,
                                knownPanels?.map((panel, index) => {
                                    if (panel[1].dropdown) {
                                        const dropdownItems = panel[1].items?.filter(e => !e.isHidden);
                                        if (!dropdownItems.length)
                                            return null;
                                        return React.createElement(Tab, { key: index, hideFirstBorder: hideOuterBorder, noPadding: true, preventDefault: true, tabKey: panel[0], title: React.createElement(React.Fragment, null,
                                                React.createElement(Dropdown, { component: (p) => React.createElement("div", { ...p }), style: { padding: "0px 16px" }, key: panel[1].key, title: React.createElement(React.Fragment, null,
                                                        panel[1].title,
                                                        "\u00A0\u00A0",
                                                        React.createElement(FontAwesomeIcon, { icon: faChevronDown })) }, dropdownItems.map((e, i) => {
                                                    return React.createElement(Dropdown.Item, { key: i, title: React.createElement(React.Fragment, null, e.title), onClick: (evt) => {
                                                            if (e.isDisabled)
                                                                return;
                                                            if (evt.metaKey || evt.ctrlKey) {
                                                                state.getState(e.tabKey)?.remount?.();
                                                            }
                                                            props.handleTabClick?.(e.tabKey);
                                                            e.onTabClick?.(e.tabKey);
                                                            if (e.isButton)
                                                                return;
                                                            if (props.handleTabClick)
                                                                return;
                                                            state.setActivePanel(e.tabKey);
                                                        } });
                                                }))) });
                                    }
                                    return React.createElement(Tab, { hideFirstBorder: hideOuterBorder, onClick: panel[1]?.onClick, tabKey: panel[0], key: `${panel[1].key}-${panel[1].forceRerender}`, title: React.createElement(React.Fragment, null, panel[1].props.title) });
                                }).filter(Boolean),
                                (props.rightAlignedItems || !!more?.length) && React.createElement(Flex, { grow: true, alignItems: "center" },
                                    React.createElement(FlexItem, { grow: true }),
                                    React.createElement(FlexItem, null,
                                        !!more.length && React.createElement(React.Fragment, null,
                                            React.createElement(Tab, { tabKey: moreKey, preventDefault: true, noPadding: true, hideFirstBorder: hideOuterBorder, title: React.createElement(Dropdown, { component: TabDiv, componentProps: { noPadding: true, hideFirstBorder: hideOuterBorder }, title: React.createElement("div", null,
                                                        React.createElement(FontAwesomeIcon, { icon: faEllipsisH })), openLeft: tabSize <= (ref.current?.offsetWidth ?? 400) ? true : false }, more.map(e => {
                                                    if (e[1].dropdown || e[1].items.length) {
                                                        return React.createElement(Dropdown.Item, { onClick: () => e[1]?.onClick(e[0]), key: e[1].key, title: React.createElement(React.Fragment, null,
                                                                e[1].title,
                                                                "\u00A0",
                                                                e[1].props?.innerTitle) }, e[1]?.items?.filter(e => !e.isHidden).map((e, i) => {
                                                            return React.createElement(Dropdown.Item, { key: i, title: React.createElement(React.Fragment, null,
                                                                    e.title,
                                                                    "\u00A0",
                                                                    e.innerTitle), onClick: (evt) => {
                                                                    if (e.isDisabled)
                                                                        return;
                                                                    if (evt.metaKey || evt.ctrlKey) {
                                                                        state.getState(e.tabKey)?.remount?.();
                                                                    }
                                                                    props.handleTabClick?.(e.tabKey);
                                                                    e.onTabClick?.(e.tabKey);
                                                                    if (e.isButton)
                                                                        return;
                                                                    if (props.handleTabClick)
                                                                        return;
                                                                    state.setActivePanel(e.tabKey);
                                                                } });
                                                        }));
                                                    }
                                                    else {
                                                        return React.createElement(Dropdown.Item, { onClick: (evt) => {
                                                                if (e.isDisabled)
                                                                    return;
                                                                if (evt.metaKey || evt.ctrlKey) {
                                                                    state.getState(e[1]?.props.tabKey)?.remount?.();
                                                                }
                                                                e[1].props?.onTabClick?.(e[1]?.props.tabKey);
                                                                props.handleTabClick?.(e[1]?.props.tabKey);
                                                                if (e[1].props.isButton)
                                                                    return;
                                                                if (props.handleTabClick)
                                                                    return;
                                                                state.setActivePanel(e[0]);
                                                            }, key: e[1].key, title: React.createElement(React.Fragment, null,
                                                                e[1].props.title,
                                                                "\u00A0",
                                                                e[1].props?.innerTitle) });
                                                    }
                                                })) })),
                                        props.rightAlignedItems))),
                            React.createElement(TabBody, null, props.children)))))));
}));
TabContainer.defaultProps = {
    mountOnEnter: true,
    unmountOnLeave: false,
};
