import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class WorkflowStore extends BaseStoreMultiSync<FpApi.System.Workflow.Workflow, string> {
	private currentVersionData: Map<string, FpApi.System.Workflow.Workflow> = new Map();

	protected itemId(item: FpApi.System.Workflow.Workflow): string {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.System.Workflow.Workflow[]> {
		return apiManager
			.getService(FpApi.System.Workflow.WorkflowService)
			.get(this.app.ctx);
	}

	public updateCurrentVersionId(id: string, data: FpApi.System.Workflow.Workflow): void {
		this.currentVersionData.set(id, data);
	}

	public getCurrentVersion(id: string): FpApi.System.Workflow.Workflow {
		if(this.currentVersionData.has(id)) return this.currentVersionData.get(id);
		return this.fetchCurrentVersion(id);
	}

	private fetchCurrentVersion(id: string): FpApi.System.Workflow.Workflow {
		if (this.currentVersionData.has(id)) {
			return this.currentVersionData.get(id);
		}
		const data = Array.from(this.getMap().values()).find((item) => {
			return item.groupId === id && item.isCurrent;
		});
		this.currentVersionData.set(id, data);
		return data;
	}
}
