import { apiManager, FpApi } from "@tcs-rliess/fp-core";
import React from "react";
import { usePrintContext } from "./lib";
import { getByProject } from "./PDFRegistry";
export const PDFExport = (props) => {
    const printContext = usePrintContext();
    const templateName = printContext.job.template;
    const projectName = printContext.customer.appCustomer;
    const Template = getByProject(projectName, templateName);
    const [data, setData] = React.useState();
    React.useEffect(() => {
        (async () => {
            const printDataService = apiManager.getService(FpApi.PrintData.PrintDataService);
            switch (templateName) {
                case "LoadManifest":
                    setData(await printDataService.getLoadManifestData(printContext.ctx, { ...props.loadManifestParams }));
                    break;
                case "LoadCustomerManifest":
                    setData(await printDataService.getLoadCustomerManifestData(printContext.ctx, { ...props.loadCustomerManifest }));
                    break;
                case "MovementTable":
                    setData(await printDataService.getMovementTableData(printContext.ctx, { ...props.movementTableParams }));
                    break;
                case "Voucher":
                    setData(await printDataService.getVoucherData(printContext.ctx, { ...props.voucherParams }));
                    break;
                case "FlightOrder":
                    setData(await printDataService.getFlightOrderData(printContext.ctx, { ...props.flightOrderParams }));
            }
        })().catch(console.error);
    }, [Template]);
    if (Template) {
        return React.createElement(Template, { ...props, data: data ? data : props.data });
    }
    return React.createElement(React.Fragment, null, "TEMPLATE_NOT_FOUND");
};
