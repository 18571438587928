import { FpDirClientState, TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export class ContactStateUtil {
	public static async getId(app: FleetplanApp, dscaid: number): Promise<FpDirClientState> {
		const state = await app.store.contactState.getId(dscaid);

		return this.cleanup(state);
	}

	public static async getIdList(app: FleetplanApp, dscaidList: number[]): Promise<Map<number, FpDirClientState>> {
		const stateList = await app.store.contactState.getIdList(dscaidList);
		const result = new Map<number, FpDirClientState>();

		for (const state of stateList) {
			result.set(state.id, this.cleanup(state));
		}

		return result;
	}

	public static cleanup(state: FpDirClientState): FpDirClientState {
		return {
			...state,
			resource_checkin: this.resourceCheckIn(state),
		};
	}

	/**
	 * Check which values are valid, and drops when if not valid anymore
	 * @param state contact state
	 * @returns cleaned up object
	 */
	public static resourceCheckIn(state: FpDirClientState): FpDirClientState["resource_checkin"] {
		const now = DateTime.local();
		const date = DateTime.fromISO(state.resource_checkin.date);
		const eow = DateTime.fromISO(state.resource_checkin.eow);

		return {
			date: state.resource_checkin.date,
			eow: state.resource_checkin.eow,

			y: date.hasSame(now, "year") ? state.resource_checkin.y : undefined,
			m: date.hasSame(now, "month") ? state.resource_checkin.m : undefined,
			d: date.hasSame(now, "day") ? state.resource_checkin.d : undefined,
			ds: date.hasSame(now, "day") ? state.resource_checkin.ds : undefined,
			w: now <= eow ? state.resource_checkin.w : undefined,
		};
	}

	public static asTimeManagement(state: FpDirClientState): TimeManagement {
		return TimeManagement.fromState(state);
	}
}


export class TimeManagement {
	private _isValid = false;
	public get isValid() {
		return this._isValid;
	}
	private validity: Array<FpDirClientState["time_management"]["tdt_contracts"][0] & { validity: TimeSpan }>;
	public static fromState(state: FpDirClientState): TimeManagement {
		return new TimeManagement(state.time_management, state.id);
	}

	private constructor(private timeManagement: FpDirClientState["time_management"], public readonly dscaid: number) {
		if (!timeManagement || Object.keys(timeManagement).length === 0) {
			return;
		}
		this.validity = timeManagement.tdt_contracts.map(e => {
			return {
				validity: new TimeSpan(
					DateTime.fromFormat(e.contract.valid_from.toString(), "yyyyMMdd"),
					e.contract.next_valid_from ? DateTime.fromFormat(e.contract.next_valid_from.toString(), "yyyyMMdd").minus({ days: 1 }) : null,
				),
				...e,
			};
		});
		this._isValid = true;
	}

	private isTerminated(point: DateTime) {
		const contract = this.getValidContract(point);
		if (!contract) return true;
		if (contract.contract.termination_date == null) return false;
		return point <= DateTime.fromFormat(contract.contract.termination_date.toString(), "yyyyMMdd");
	}

	private getValidContract(point: DateTime) {
		return this.validity?.find(e => e.validity.includes(point));
	}

	public getContractData(point: DateTime) {
		const contract = this.getValidContract(point);
		return contract?.tdt;
	}

	private getPointInTimeForYear(point: DateTime) {
		const tdt = this.getContractData(point);
		return tdt?.tdt_years[point.year];
	}

	public getDayHoursNet(point: DateTime): number {
		if (this.isValid === false) return null;
		if (this.isTerminated(point)) return 0;
		const tdt = this.getPointInTimeForYear(point);
		return tdt?.tdt_d_n;
	}

	public getWeekHoursNet(point: DateTime): number {
		if (this.isValid === false) return null;
		const tdt = this.getPointInTimeForYear(point);
		return tdt?.tdt_w_n;
	}

	public getYearHoursGross(point: DateTime): number {
		if (this.isValid === false) return null;
		return this.timeManagement.tdt_years[point.year]?.tdt_y_g;
	}

	public getYearHoursNet(point: DateTime): number {
		if (this.isValid === false) return null;
		const contractInfo = this.getContractData(point);
		return contractInfo?.tdt_y_n;
	}

	public getTdTMonth(point: DateTime): number {
		if (this.isValid === false) return null;
		return this.timeManagement.tdt_years[point.year]?.tdt_months?.[point.month]?.tdt_m_g;
	}

	public getTargetDutyTimeDayNet(point: DateTime): number {
		const contractInfo = this.getContractData(point);
		return contractInfo?.tdt_years[point.year]?.tdt_d_n;
	}

	public getHolidayCount(point: DateTime): number {
		const contractInfo = this.getContractData(point);
		return contractInfo?.tdt_years[point.year]?.holidayPerYearInDays_n;
	}

	public getVacPerYearInDays(point: DateTime): number {
		const contractInfo = this.getContractData(point);
		return contractInfo?.vacationPerYearInDays;
	}

	public getWorkdaysPerWeek(point: DateTime): number {
		const contractInfo = this.getContractData(point);
		return contractInfo?.workdaysPerWeek;
	}

}
