import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";


export class ScheduleWorkPatternStore extends BaseStoreMulti<FpApi.Resource.Duty.WorkPattern, number> {
	protected itemId(item: FpApi.Resource.Duty.WorkPattern): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Resource.Duty.WorkPattern[]> {
		return apiManager
			.getService(FpApi.Resource.Duty.WorkPatternService)
			.get(this.app.ctx);
	}
}
