import { apiManager, FpApi, ApiContext, LandingField, FpDirNodeKind, TreeUtil, SystemCategoryDutyType, FPEvent, FPEventWaypoint, ClientCategoryUtil, DirContact, formatContactName } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { ThemeVariantOrColor } from "@tcs-rliess/fp-web-ui";
import Aigle from "aigle";
import Color from "color";
import { castArray, first, flatten, last, sortBy } from "lodash-es";
import { DateTime } from "luxon";
import { action, observable, toJS, transaction } from "mobx";
import React from "react";

import { FleetplanApp } from "../FleetplanApp";
import { IFleetplanSchedulerEvent, IFleetplanSchedulerResource } from "../modules/Common/Scheduler/FleetplanScheduler";
import { StringNodeLabel } from "../modules/Directory/NodeLabel";

import { Queue } from "./Queue";

export const KindToKey = {
	[FpDirNodeKind.Group]: "fpdirgrp",
	[FpDirNodeKind.Position]: "fpdirpos",
	[FpDirNodeKind.Location]: "fpdirloc",
	[undefined as any]: "fpdirloc",
};
export const KindToKeyShort = {
	[FpDirNodeKind.Group]: "grp",
	[FpDirNodeKind.Position]: "pos",
	[FpDirNodeKind.Location]: "loc",
	[undefined as any]: "loc"
};

type getEventsOptions = {
	/** adding the calendar will cause to pull events, that  */
	dscsvid?: "me" | (string & {});
	dscaid?: number | number[];
	fpdirloc?: number | number[];
	fpdirpos?: number | number[];
	fpdirgrp?: number | number[];
	dsrsid?: string;
	forceFull?: boolean;
	wishOptions?: {
		type?: FpApi.Resource.Duty.SystemCategoryType,
	};
	dutyOptions?: {
		mode?: string;
		h24Mode?: boolean;
		types?: number[];
		colorPriority?: "category" | "shift";
	};
	aircraftOptions?: {
		fpvids: number[];
	}
	dataOptions?: ("duties" | "events" | "wishes" | "aircraft_events")[];
}

function getName(contact: DirContact, short?: boolean): string {
	if(!contact) return "";
	if(contact.isCompany) {
		if(!contact.$organization) fpLog.error("Contact is company but no organization", contact);
		return `${(contact.$organization?.nameOrganization ?? "UNKNOWN")}${(contact.$organization?.nameOrganizationShort ?? "UNKNOWN") ? ` (${(contact.$organization?.nameOrganizationShort ?? "UNKNOWN")})` : ""}`;
	}
	if(short) {
		return `${contact.$person?.abbreviation || getName(contact, false)}`;
	}
	return `${contact.$person?.lastName}, ${contact.$person?.givenName} ${contact.$person?.abbreviation ? `(${contact.$person?.abbreviation})` : ""}`;
}

export const CalendarEventStateCtx = React.createContext<CalendarEventState>(null);
export class CalendarEventState {

	private queue = new Queue();
	@observable.shallow public events: Map<string, IFleetplanSchedulerEvent> = new Map();
	@observable.shallow public eventsByDay: Map<string, Map<string, string>> = new Map();
	/**
	 * dateformat: `${day}-${month}-${year}` -> 1-1-2020 / d-M-y
	 */
	public getEventsByDay(day: string, resourceId?: string | Array<string>): IFleetplanSchedulerEvent[]{
		const arr = Array.from(this.eventsByDay.get(day)?.values() ?? []);
		const resId = castArray(resourceId);
		const ret: IFleetplanSchedulerEvent[] = [];
		for(const id of arr) {
			const event = this.events.get(id);
			if(event && (resourceId ? event.resourceId.find(e => resId.find(p => p === e)) : true)) {
				ret.push(event);
			}
		}
		return ret;
	}
	@observable.shallow public eventsByDayXResourceId: Map<string, Array<string>> = new Map();
	public getEventsByDayXResourceId(resourceId: string) {
		const evs =  this.eventsByDayXResourceId.get(resourceId) ?? [];
		const ret: IFleetplanSchedulerEvent[] = [];
		for(const id of evs) {
			const event = this.events.get(id);
			if(event) ret.push(event);
		}
		return ret;
	}
	@observable public pulledDataAt: number;
	// @observable public eventsByDayFull: Map<string, Map<string, IFleetplanSchedulerEvent>> = new Map();

	private loaded: Set<string> = new Set();
	public readonly dateBuffer = [ 3, 12 ];
	private dutyService = apiManager.getService(FpApi.Resource.Duty.DutyService);

	public static async fromDutyToScheduler(e: FpApi.Resource.Duty.Duty | Partial<FpApi.Resource.Duty.Duty>, app: FleetplanApp, mode?: string, h24Mode?: boolean, colorPriority: "shift" | "category" = "shift") {
		let color = "#000";
		const shift = e.dsrsid ? await app.store.resource.shift.getId(e.dsrsid) : null;
		if(e.linkIdType === "fpvid") {
			try {
				const cat = await app.store.systemCategory.getId(e.dscatidType);
				color = e.color ?? shift?.color ?? cat?.color ?? "#000";
			} catch (e) {
				console.error(e);
				const cat = await app.store.categoryUtil.getId(e.dscatidType);
				color = e.color ?? shift?.color ?? cat?.data?.category?.color ?? "#000";
			}
		} else {
			const cat = await app.store.categoryUtil.getId(e.dscatidType);
			if(colorPriority === "shift") {
				color = e.color ?? shift?.color ?? cat?.data?.category?.color ?? "#000";
			} else if (colorPriority === "category") {
				color = e.color ?? cat?.data?.category?.color ?? shift?.color ?? "#000";
			} else {
				color = e.color ?? shift?.color ?? cat?.data?.category?.color ?? "#000";
			}
		}

		let dtEnd = DateTime.fromISO(e.dateEnd);
		const dtStart = DateTime.fromISO(e.dateStart);
		if(h24Mode && dtEnd.day > dtStart.day &&  Math.abs(DateTime.fromISO(e.dateStart).diff(DateTime.fromISO(e.dateEnd), "hours").hours) <= 48) {
			dtEnd = dtStart.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
		}
		const ret =  {
			id: e.id,
			start: dtStart,
			end: dtEnd,
			label: await this.dutyLabel(e as any, app, mode),
			cleanLabel: await this.dutyLabel(e as any, app, mode, true),
			resourceId: [
				`${e.linkIdType}:${e.linkId}`,
				`position:${e.fpdirloc}:${e.fpdirgrp}:${e.fpdirpos}:${e.dsrsid ?? ""}`,
				`setup-sub:${e.dsrdsid}:${e.dsrdsidSub}`,
				`setup-sub:${e.linkIdType}:${e.dsrdsid}:${e.dsrdsidSub}`,
				// `setup-sub-c:${e.dsrdsid}:${e.dsrdsidSub}:${e.dsrdsidPos}`,
				`setup-sub-c-shift:${e.dsrdsid}:${e.dsrdsidSub}:${e.dsrdsidPos}:${e.dsrsid}`,
				`setup-sub-c-pos:${e.dsrdsid}:${e.dsrdsidSub}:${e.dsrdsidPos}`,
				`setup:${e.dsrdsid}`,
				// `${e.linkIdType}:${e.linkId}:loc:${e.fpdirloc}`,
			].filter(Boolean),
			extra: e,
			backgroundColor: color,
			source: "duty",
		};
		return ret;
	}

	public static async dutyLabel(duty: FpApi.Resource.Duty.Duty, app: FleetplanApp, mode?: string, clean?: boolean): Promise<string> {
		// map location name
		// let locName = duty.dscaidLocation ? duty.dscaidLocation.toString() : "";
		// let loc = this.locations.find(r => r.id === duty.dscaidLocation);
		// if (loc && loc.$organization) {
		//  locName = loc.$organization.nameOrganizationShort || loc.$organization.nameOrganization;
		// }

		// map type name
		let typeName = duty.dscatidType ? duty.dscatidType.toString() : "[ERROR]";
		const type = await app.store.categoryUtil.getId(duty.dscatidType);
		if (type) {
			typeName = type.data?.category?.abbr ? type.data?.category?.abbr.toUpperCase() : type.name;
		}

		let remarks = "";
		if (duty.remarks) {
			const bgColor = Color(duty.color || "#ffffff");
			const color = bgColor.isLight() ? "#000000" : "#ffffff";

			remarks = `<div style="width: 0;height: 0;border-style: solid;border-width: 0 10px 10px 0;
                            border-color: transparent ${color} transparent transparent;margin-top:-1px;">
                        </div>`;
		}

		const cat = await app.store.categoryUtil.getId(duty.dscatidType);
		const shift = duty.dsrsid ? await app.store.resource.shift.getId(duty.dsrsid) : null;

		if(clean) {
			return `${typeName} ${remarks}`;
		}
		if (mode === "compact") {
			const dutyColor = duty.color ?? duty.dsrsid ? shift?.color : null ?? cat?.data?.category?.color ?? "#ffffff";
			return `
                <div style="
                    white-space: nowrap;
                    font-size: 11px;
                    line-height: 12px;
                    padding-top: 1px;
                    display:flex;
                    justify-content:space-between;
                    height:100%;
                    ${[ FpApi.Resource.Duty.DutyStatus.Request, FpApi.Resource.Duty.DutyStatus.Rejected ].includes(duty.status) ?
		duty.status === FpApi.Resource.Duty.DutyStatus.Request ?
			`background: repeating-linear-gradient(-45deg, ${dutyColor}, ${dutyColor} 10px, ${app.theme.color.purple[500].main} 10px, ${app.theme.color.purple[500].main} 20px)` :
			`background: repeating-linear-gradient(-45deg, ${dutyColor}, ${dutyColor} 10px, ${app.theme.color.danger[500].main} 10px, ${app.theme.color.danger[500].main} 20px)` : ""
}
                ">
                    ${typeName}
                    ${remarks}
                </div>
            `;
		}

		// full day?
		const start = DateTime.fromISO(duty.dateStart);
		const end = DateTime.fromISO(duty.dateEnd);
		const fullDay = start.valueOf() === start.startOf("day").valueOf() && end.valueOf() === end.startOf("day").valueOf();
		const util = TreeUtil.fromDirectoryNodes(app.directory);
		// set location name, if type is location
		let _location = "";
		if (type && type.data?.category?.idName?.toLowerCase() === SystemCategoryDutyType.Location) {
			if (duty[SystemCategoryDutyType.Location]) _location = util.find(e => e.node.id === duty[SystemCategoryDutyType.Location])?.name;
		}

		// set days or hours in vacation & worktime
		let idName = "";
		let dayHours = "";
		if (type) {
			idName = type.data?.category?.idName?.toLowerCase();
			if (idName === SystemCategoryDutyType.Work) {
				dayHours = "<br/> " + end.diff(start).toFormat("hh:mm") + " h";
			} else if ([ SystemCategoryDutyType.Off, SystemCategoryDutyType.Vacation, SystemCategoryDutyType.MedicalLeave ].includes(idName as any)) {
				dayHours = duty.days + " d";
			}
		}
		return `
            <div style="white-space: nowrap; font-size: 11px; line-height: 12px;padding-top: 1px;display:flex;justify-content:space-between;">
                <div>
                    ${idName === SystemCategoryDutyType.Location ? _location : typeName.toUpperCase()}
                    ${fullDay ? "" :  " <br/> " + app.formatter.time(start) + " <br/> " + app.formatter.time(end)}
                    ${idName === SystemCategoryDutyType.Vacation ? "<br/>" + duty.status : ""}
                    ${dayHours && [ SystemCategoryDutyType.Vacation, SystemCategoryDutyType.Off, SystemCategoryDutyType.MedicalLeave ].includes(idName as any) ? "<br/>" : ""}
                    ${dayHours ? dayHours + "<br/>" : ""}
                </div>
                ${remarks}
            </div>
        `;
	}

	public static async fromWishToScheduler(e: FpApi.Resource.Duty.DutyWish, app: FleetplanApp) {
		return {
			id: e.id,
			start: DateTime.fromISO(e.date).startOf("day"),
			end: DateTime.fromISO(e.date).endOf("day"),
			label: `${StringNodeLabel({ id: e.fpdirloc, tree: app.directory })} / ${StringNodeLabel({ id: e.fpdirpos, tree: app.directory })}`,
			resourceId: [ `dscaid:${e.dscaid}` ],
			extra: {
				...e,
				$dscaid: await app.store.contact.getId(e.dscaid),
			},
			source: "wish",
		};
	}

	constructor(private app: FleetplanApp) {
	}

	public insert(event: IFleetplanSchedulerEvent): void {
		this.events.set(event.id, event);
		this.update(event);
	}


	public async getEvents(start: DateTime, end: DateTime, options: getEventsOptions = {}): Promise<IFleetplanSchedulerEvent[]> {
		return this.queue.push(this._getEvents.bind(this, start, end, options));
	}

	private async _getEvents(start: DateTime, end: DateTime, options: getEventsOptions = {}): Promise<IFleetplanSchedulerEvent[]> {
		[ start, end ] = [ start.startOf("day").minus({ days: this.dateBuffer[0] }), end.endOf("day").plus({ days: this.dateBuffer[1] }) ];
		let dates = this.singleDays(start, end);
		const ranges = [];
		if(!options.forceFull) {
			dates = dates.filter(e => !this.loaded.has(e.toISO()));
			dates.forEach(e => this.loaded.add(e.toISO()));
			// determine to loaded ranges
			let [ startPointer ] = dates;
			if(dates.length === 1) {
				ranges.push([ dates[0], dates[0].plus({ days: 1 }) ]);
			} else {
				for(let i = 1; i < dates.length; i++) {
					if(dates[i+1] == null) {
						ranges.push([ startPointer, dates[i] ]);
						break;
					}
					if(dates[i].diff(dates[i-1], "days").days > 1) {
						ranges.push([ startPointer, dates[i] ]);
						startPointer = dates[i];
					}
				}
			}
		} else {
			this.clear();
			ranges.push([ start, end ]);
		}

		// load data from this ranges that are not loaded
		for(const [ start, end ] of ranges) {
			const results = await Promise.allSettled([
				// loaded from new bookings
				((!options.dataOptions?.length) || options.dataOptions?.includes("duties")) && this.getDuties(start, end, options),
				(options.dataOptions?.includes("wishes")) && this.getWishes(start, end, options),
				(options.dataOptions?.includes("aircraft_events")) && this.getAircraftEvents(start, end, options.aircraftOptions),
				((!options.dataOptions?.length) || options.dataOptions?.includes("events")) && this.getModernEvents(start, end),
			]);

			const allSettled = results.filter(e => e.status === "fulfilled").map(e => (e as any).value);

			const flat = flatten(allSettled).filter(Boolean);
			transaction(() => {
				flat.forEach((e, i) => {
					this.update(e);
				});
			});
		}
		const evs = this.getData();
		this.pulledDataAt = DateTime.utc().valueOf();
		return evs;
	}

	public getData(): IFleetplanSchedulerEvent[] {
		return Array.from(this.events.values());
	}

	public getEventById(id) {
		return this.events.get(id);
	}

	@action
	public delete(id: string): boolean {
		const event = this.events.get(id);

		const resourceIds = event?.resourceId ?? [];

		if(resourceIds.length) {
			resourceIds.forEach(resourceId => {
				const events = this.eventsByDayXResourceId.get(resourceId) ?? [];
				if(events) {
					events.splice(events.findIndex(e => e === event.id), 1);
					if(events.length === 0) {
						this.eventsByDayXResourceId.delete(resourceId);
					}
				}
			});
		}

		const key = `${event.start.day}-${event.start.month}-${event.start.year}`;
		this.eventsByDay.get(key)?.delete(id);
		this.eventsByDay.set(key, new Map(this.eventsByDay.get(key)));

		const isUpdated = this.events.has(id);
		this.events.delete(id);
		this.eventsByDayXResourceId = new Map(this.eventsByDayXResourceId);
		return isUpdated;
	}

	@action
	public update(data: IFleetplanSchedulerEvent): boolean {
		const isUpdated = this.events.has(data.id);
		// update events day
		const old = this.events.get(data.id);
		if(old) {
			const key = `${old.start.day}-${old.start.month}-${old.start.year}`;
			this.eventsByDay.get(key)?.delete(data.id);
		}
		// this is logic for duties and repeats
		if(data.source === "duty") {
			const duty: FpApi.Resource.Duty.Duty = data.extra;
			// this event might exist

			if(this.events.has(duty.repeatParent)) {
				const key = `${data.start.day}-${data.start.month}-${data.start.year}`;
				const possibleClone = Array.from(this.getEventsByDay(key)).find(e => e.extra.repeatParent === duty.repeatParent);
				this.eventsByDay.get(key)?.delete(possibleClone.id);
			}
		}
		const newKey = `${data.start.day}-${data.start.month}-${data.start.year}`;
		const m = new Map(Array.from(this.eventsByDay.get(newKey)?.entries() ?? []).map(e  => [ e[0], toJS(e[1]) ]));
		m.set(data.id, data.id);
		// if(data.start.diff(data.end, "days").days > 1) {
		// 	const target = data.start.diff(data.end, "days").days;
		// 	let pointer = 0;
		// 	while(pointer < target) {
		// 		const key = `${data.start.plus({ days: pointer }).day}-${data.start.plus({ days: pointer }).month}-${data.start.plus({ days: pointer }).year}`;
		// 		this.eventsByDayFull.set(key, new Map(this.eventsByDayFull.get(key) ?? new Map()));
		// 		pointer++;
		// 	}
		// }
		// update events day end

		// update viaResourceId
		const copyOfEventsByDayXResourceId = new Map(this.eventsByDayXResourceId);
		for(const resId of data.resourceId) {
			const d = copyOfEventsByDayXResourceId.get(resId);
			const index = d ? d.findIndex(e => e === data.id) : -1;
			const events = [ ...(copyOfEventsByDayXResourceId.get(resId) ?? []) ];
			if(index !== -1) {
				events.splice(index, 1);
			}
			copyOfEventsByDayXResourceId.set(resId, [ ...(copyOfEventsByDayXResourceId.get(resId) ?? []), data.id ]);
		}

		this.eventsByDayXResourceId = copyOfEventsByDayXResourceId;
		this.eventsByDay.set(newKey, m);
		try {
			const e = new Map(Array.from(this.events.entries()).map(e => [ e[0], toJS(e[1]) ])).set(data.id, { ...data });
			this.events = e;
		} catch(err) {
			console.error(err);
		}
		this.pulledDataAt = DateTime.utc().valueOf();
		return isUpdated;
	}

	public clear(): void {
		this.loaded.clear();
		this.events.clear();
		this.eventsByDay.clear();
		this.eventsByDayXResourceId.clear();
	}

	private singleDays(start: DateTime, end: DateTime) {
		const size = Math.max(1, end.diff(start, "days").days);
		const dates: DateTime[] = [];
		for(let counter = 0; counter <= size; counter++) {
			dates.push(start.plus({ days: counter }));
		}
		return dates;
	}

	public async getModernEvents(s: DateTime, e: DateTime): Promise<IFleetplanSchedulerEvent[]> {
		const newEvents = await this.app.store.event.getRange(s, e);

		const outEvents: IFleetplanSchedulerEvent[] = [];
		// collect and load fplaids
		const fplaids = new Set<number>();
		newEvents.forEach(e =>
			e.waypoints?.forEach(e =>
				fplaids.add(e.fplaid)
			)
		);
		// load fplaids in to the store
		await this.app.store.landingField.getIds(Array.from(fplaids));
		for (const event of newEvents) {
			// turn into calendar events
			if (event.status === FpApi.Calendar.Event.EventStatus.Cancelled) continue;
			const mapped = await this.eventToCalendarEvent(event);
			outEvents.push(...mapped);
		}

		return outEvents;
	}

	public async getDuties(s: DateTime, e: DateTime, options: getEventsOptions): Promise<IFleetplanSchedulerEvent[]> {
		const newEvents = await this.dutyService.get(this.app.ctx, {
			from: s.toISO(),
			to: e.toISO(),
			fpdirgrp: (Array.isArray(options.fpdirgrp) ? !!options.fpdirgrp.length : Boolean(options.fpdirgrp)) ? castArray(options.fpdirgrp) : undefined,
			fpdirloc: (Array.isArray(options.fpdirloc) ? !!options.fpdirloc.length : Boolean(options.fpdirloc)) ? castArray(options.fpdirloc) : undefined,
			fpdirpos: (Array.isArray(options.fpdirpos) ? !!options.fpdirpos.length : Boolean(options.fpdirpos)) ? castArray(options.fpdirpos) : undefined,
			dscaid: (Array.isArray(options.dscaid) ? !!options.dscaid.length : Boolean(options.dscaid)) ? castArray(options.dscaid) : undefined,
			dutyTypes: options?.dutyOptions?.types ? options.dutyOptions.types : undefined,
		});

		const kinds: FpDirNodeKind[] = [];
		if(options.fpdirgrp) kinds.push(FpDirNodeKind.Group);
		if(options.fpdirloc) kinds.push(FpDirNodeKind.Location);
		if(options.fpdirpos) kinds.push(FpDirNodeKind.Position);

		const ret = await Aigle.map(newEvents, async duty => {
			this.app.store.resource.duty.update(duty);
			const keys = kinds.map(e => `${duty.linkIdType}:${duty.linkId}:${KindToKeyShort[e]}:${duty.showAlways ? null : duty[KindToKey[e]]}`);
			const ret = await CalendarEventState.fromDutyToScheduler(duty, this.app, options?.dutyOptions?.mode, options?.dutyOptions?.h24Mode, options?.dutyOptions?.colorPriority);
			const r =  {
				...ret,
				resourceId: [
					...ret.resourceId,
					...keys,
				],
			};
			return r;
		});
		return ret;
	}

	public async getWishes(s: DateTime, e: DateTime, options: getEventsOptions): Promise<IFleetplanSchedulerEvent[]> {

		const fpdirgrp = Array.isArray(options.fpdirgrp) ? options.fpdirgrp[0] : options.fpdirgrp;
		const fpdirloc = Array.isArray(options.fpdirloc) ? options.fpdirloc[0] : options.fpdirloc;
		const fpdirpos = Array.isArray(options.fpdirpos) ? options.fpdirpos[0] : options.fpdirpos;
		const newEvents = await apiManager.getService(FpApi.Resource.Duty.DutyWishService).get(this.app.ctx, {
			from: s.toISO(),
			to: e.toISO(),
			fpdirgrp: fpdirgrp,
			fpdirloc: fpdirloc,
			fpdirpos: fpdirpos,
			dscaid: options.dscaid ? options.dscaid[0] : undefined,
			dsrsid: options.dsrsid ? options.dsrsid : undefined,
			type: options.wishOptions?.type ? options.wishOptions?.type : undefined,
		});
		return Aigle.map(newEvents, e => {
			return CalendarEventState.fromWishToScheduler(e, this.app);
		});
	}

	public async getAircraftEvents(s: DateTime, e: DateTime, options: getEventsOptions["aircraftOptions"]): Promise<IFleetplanSchedulerEvent[]> {
		const events = await apiManager.getService(FpApi.Quality.Event.EventService)
			.getAircraftEvents(this.app.ctx, {
				start: s.toISO(),
				end: e.toISO(),
				fpvids: options?.fpvids,
			});
		return events.map(e => ({
			...e,
			source: "aircraft_events",
			start: DateTime.fromISO(e.start),
			end: DateTime.fromISO(e.end),
			resourceId: e.resourceId?.map(e => e.replace("aircraft", "fpvid")) ?? [],
		}));
	}

	/**
	 * Flights get mapped into one event per leg. So if the flight has 3 waypoints "a > b C" two events with "a > b" and "b > c" will and created and shown in
	 * the calendar.
	 * @param event input events
	 * @returns calendar events
	 */
	public async eventToCalendarEvent(event: FPEvent): Promise<Array<IFleetplanSchedulerEvent>> {
		// build route
		const wps = event.waypoints;
		const sorted = sortBy(wps, (e) => e.sta ? DateTime.fromISO(e.sta).toMillis() : 0);

		// get all fplaids
		const fplaids = new Set(sorted.map(e => e.fplaid).filter(Boolean));
		const lfs = await this.app.store.landingField.getIds(Array.from(fplaids));
		const mappedLfs: Array<[ number, LandingField ]> = lfs.map(e => [ e.id, e ]);
		const landingFields = new Map(mappedLfs);

		if (event.type === FpApi.Calendar.Event.EventType.Flight) {
			const result: Array<{
				start?: FPEventWaypoint
				end?: FPEventWaypoint
				waypoints?: FPEventWaypoint[];
			}> = [];
			let nextObj: Record<string, any> = {
				waypoints: [],
			};

			let flag = 0;
			for(const point of sorted) {
				if (true) { // point.type === FpApi.Calendar.Event.EventFlightWaypointType.LANDING_FIELD
					if(flag === 0) {
						nextObj["start"] = point;
						flag = 1;
					} else if(flag === 1) {
						nextObj["end"] = point;
						nextObj["id"] = `${nextObj.start.id}-${nextObj.end.id}`;
						result.push(nextObj);
						nextObj = {
							waypoints: [],
							start: point,
						};
						flag = 1;
					}
				}
				else {
					// nextObj.waypoints.push(point);
				}
			}
			const ret = result.filter(e => e.end);

			// resolve crew
			const crew = event.resources.filter(e => e.link_type === "dscaid");
			const crewMapping = new Map(await Aigle.map(crew, async member => {
				const c = await this.app.store.contact.getId(Number.parseInt(member.link_id));
				return [ member.link_id, c ];
			}));
			const resourceRoles = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole);

			const acResource = event.resources.find(e => e.link_type === "fpvid");
			const ac = (acResource && acResource.link_id) ? await this.app.store.resource.aircraft.getId(Number.parseInt(acResource.link_id)) : undefined;

			const customerId = event.data?.dscaid_customer;
			const customer = customerId ? formatContactName(await this.app.store.contact.getId(customerId)) : undefined;

			const events: Array<IFleetplanSchedulerEvent> = [];
			// general flight event
			events.push({
				id: event.id,
				start: DateTime.fromISO(first(ret).start.std),
				end: DateTime.fromISO(last(ret).end.sta),
				label: `${event.flight?.flight_no ? `<span style="line-height: 22px">#${event.flight?.flight_no}</span>` : ""}
				${event.reference ? `| <span style="line-height: 22px">${event.reference}</span>` : ""}
				${customer ? `| <span style="line-height: 22px">${customer}</span>` : ""}
				${crew.length ? `| <span style="line-height: 22px">${crew.map(e => `${resourceRoles.getOption(e.dserrid)?.abbr}: ${getName(crewMapping.get(e.link_id), true)}`).join("</br>")}</span>` : ""}
				${sorted.length ? `| <span style="line-height: 22px">${sorted.map(s => landingFields.get(s.fplaid)?.index3 ?? landingFields.get(s.fplaid)?.index2 ?? "Unset").join(" > ")}</span>` : ""}
				${event.remarks ? `| <span style="line-height: 22px">${event.remarks}</span>` : ""}
				| <span style="line-height: 22px">${event.id2}</span>
				`,
				resourceId: [ `ev:${event.id}`, ...flatten(event.resources.map(res => {
					const ret = [];
					if(mapping[res.link_type]) {
						ret.push(`${mapping[res.link_type] ?? res.link_type}:${res.link_id}`);
					}
					ret.push(`${res.link_type}:${res.link_id}`);
					return ret;
				})) ],
				backgroundColor: ac?.color,
				extra: {
					event: event,
					label_title: event.id2,
					type: "ev",
				},
				source: "event",
			});
			// for(const route of ret) {
			// 	events.push({
			// 		id: `${event.id}-${route.start.id}-${route.end.id}`,
			// 		start: DateTime.fromISO(route.start.std),
			// 		end: DateTime.fromISO(route.end.sta),
			// 		label: `${event.runningNo}<br/>${event.reference}<br/>${landingFields.get(route.start.fplaid)?.index1 ?? "Unset"} > ${landingFields.get(route.end.fplaid)?.index1 ?? "Unset"}`,
			// 		resourceId: [ `ev:${event.id}`, ...flatten(event.$dseidParent.resources.map(res => {
			// 			const ret = [];
			// 			if(mapping[res.linkType]) {
			// 				ret.push(`${mapping[res.linkType] ?? res.linkType}:${res.linkId}`);
			// 			}
			// 			ret.push(`${res.linkType}:${res.linkId}`);
			// 			return ret;
			// 		})) ],
			// 		backgroundColor: ac?.color,
			// 		extra: {
			// 			event: event,
			// 			label_title: event.runningNo,
			// 			type: "ev",
			// 		},
			// 		source: "event",
			// 	});
			// }
			return events;
		} else if(event.type === FpApi.Calendar.Event.EventType.Order) {
			const acResource = event.resources.find(e => e.link_type === "fpvid");
			const ac = (acResource && acResource.link_id) ? await this.app.store.resource.aircraft.getId(Number.parseInt(acResource.link_id)) : undefined;

			const work_no = event.data?.order?.work_no;
			const customerId = event.data?.dscaid_customer;
			const customer = customerId ? formatContactName(await this.app.store.contact.getId(customerId)) : undefined;

			return [{
				id: `${event.id}`,
				start: event.date_start ? DateTime.fromISO(event.date_start) : undefined,
				end: event.date_end ? DateTime.fromISO(event.date_end) : undefined,
				label: `${event.reference}<br/>${work_no ? `${work_no}<br/>` : ""}${customer ? `${customer}<br/>` : ""}${ac ? ac.registrationCode : event.id2}`,
				resourceId: [ `ev:${event.id}`, ...flatten(event.resources.map(res => {
					const ret = [];
					if(mapping[res.link_type]) {
						ret.push(`${mapping[res.link_type] ?? res.link_type}:${res.link_id}`);
					}
					ret.push(`${res.link_type}:${res.link_id}`);
					return ret;
				})) ],
				backgroundColor: ac?.color,
				extra: {
					event: event,
					label_title: event.id2,
					type: "ev",
				}
			}];
		} else if(event.type === FpApi.Calendar.Event.EventType.General) {
			return [{
				id: `${event.id}`,
				start: event.date_start ? DateTime.fromISO(event.date_start) : undefined,
				end: event.date_end ? DateTime.fromISO(event.date_end) : undefined,
				label: `${event.id2}<br/>${event.reference}<br/>`,
				resourceId: [ `ev:${event.id}`, ...flatten(event.resources.map(res => {
					const ret = [];
					if(mapping[res.link_type]) {
						ret.push(`${mapping[res.link_type] ?? res.link_type}:${res.link_id}`);
					}
					ret.push(`${res.link_type}:${res.link_id}`);
					return ret;
				})) ],
				extra: {
					event: event,
					label_title: event.id2,
					type: "ev",
				},
				source: "event",
			}];
		}
	}
}

/*
const ev = {
	id: event.id,
	resourceId: event.dscalid.split(","),
	start: DateTime.fromFormat(event.start_date, "yyyy-MM-dd hh:mm:ss"),
	end: DateTime.fromFormat(event.end_date, "yyyy-MM-dd hh:mm:ss"),
	backgroundColor: event.color,
	label: `
			<div>
				${event.text}
			</div>
			`,
	// is required
	// eslint-disable-next-line @typescript-eslint/camelcase
	extra: { ...event, label_title: event.text },
};
*/
const mapping = {
	"fpvid": "aircraft",
	"dscdid": "certsdocs",
};

// /**
//  * Remove unwanted resources
//  */
// function eventFilter(resource: FpApi.Calendar.Event.Resource) {
// 	return [ "fpvid", "dscaid", "aircraftbooking", "dscdid" ].includes(resource.linkType);
// }

export function fromLegacyCalEventToNew(event: any, ctx: ApiContext, res: IFleetplanSchedulerResource[]): IFleetplanSchedulerEvent {
	// will use res to check permissions here
	let canView = true;
	let canClick = true;
	let canChange = true;
	if(event.extra?.type === "quality_event_ressource") {
		if(event.extra.event.dsqmrepid) {
			// TODO check permissions
			// if(!ctx.hasPermission("dsqmrepid", event.extra.event.dsqmrepid, FpApi.Quality.Report.ReportPermission.EventReader)) {
			// 	canClick = false;
			// }
		}
	}

	if(event.dscalid?.startsWith("aircraftbooking")) {
		const [ , id ] = event.dscalid.split(":");

		if(!(ctx.hasPermission("dscalid.aircraft", id, FpApi.Security.PermissionCalendarLvl.EventEditor))) {
			canChange = false;
			if(event.isinvolved && (ctx.hasPermission("dscalid.aircraft", id, FpApi.Security.PermissionCalendarLvl.EventOwnEditor))) {
				canChange = true;
			}
		}
		if (!ctx.hasPermission("dscalid.aircraft", id, FpApi.Security.PermissionCalendarLvl.EventOwnReader)) {
			if(event.isinvolved && !(ctx.hasPermission("dscalid.aircraft", id, FpApi.Security.PermissionCalendarLvl.EventReader))) {
				canClick = false;
				canView = false;
				canChange = false;
			}
		}
	}

	if(event.dscalid?.includes("resource_duty")) {
		if(!ctx.hasRole("fpcrewing_manager")) {
			canChange = false;
		}
	}

	if(event.type === "human") {
		if(event.haseditright !== 1) {
			canChange = false;
		}
	}

	let start: DateTime = event.start_date ? DateTime.fromFormat(event.start_date, "yyyy-MM-dd hh:mm:ss") : event.start;
	let end: DateTime = event.end_date ?  DateTime.fromFormat(event.end_date, "yyyy-MM-dd hh:mm:ss") : event.end;

	if(!start.isValid) {
		start = DateTime.fromISO(event.start_date);
	}

	if(!end.isValid) {
		end = DateTime.fromISO(event.end_date);
	}

	const ev: IFleetplanSchedulerEvent = {
		id: event.id,
		resourceId: event.dscalid?.split(",") ?? event.resourceId,
		start,
		end,
		backgroundColor: event.color ?? event.backgroundColor,
		label: `
				<div>
					${event.text ?? event.label}
				</div>
				`,
		// is required
		// eslint-disable-next-line @typescript-eslint/camelcase
		extra: { ...event, label_title: event.text, canView: canView, canClick, canChange, ...event.extra ?? {}, },
	};

	if((event).type === "aircraft") {
		if(event.text === "unavailable") {
			ev.extra.canView = false;
			ev.extra.canClick = false;
			ev.extra.canChange = false;
			ev.label = `
				${event.registration_code}
			`;
		} else {
			ev.label = `
				<div>
					${event.text}
					<br/>
					${(event.registration_code && !event.text.includes(event.registration_code)) ? `
						<hr style="margin:2px;">
						<span>
							${event.registration_code}
						</span><br/>
					` : ""}
					${event.mission && !event.text.includes(event.mission) ? `
						<hr style="margin:2px;">
						<span>${event.mission}</span><br/>
					` : ""}
					${(event.pre_flight_from && event.pre_flight_to) ? `
						<hr style="margin:2px;">
						<span>${event.pre_flight_from}-${event.pre_flight_to}</span><br/>
					` : ""}
					${event.capacity_person_pax > 0 ? `
						<hr style="margin:2px;"><span>${event.passenger ?? 0} / ${event.capacity_person_pax}</span>
					`: ""}
					<br/>
				</div>
			`;
		}
	} else if (event.type === "human" && event.guests && (event.dscalid_creator === event.dscalid)) {
		ev.label = `
				<div>
					<i class="fal fa-crown"></i>
					${event.text}
				</div>
				`;
	} else if (event.type === "resource_duty") {
		ev.label = `
			<div>
				${event.dscatid_name} | ${event.calname}
			</div>
			`;
	}

	if(!canView) {
		ev.label = "<div></div>";
	}

	return ev;
}

export class CalendarGridHeatmap {
	// constants
	public static readonly MIN_MAX_HEAT = 5;
	public static readonly MAx_COLOR_SHADES = 9;

	private _maxHeat = CalendarGridHeatmap.MIN_MAX_HEAT;
	private _color: string;
	private _colorShadesAmount = CalendarGridHeatmap.MIN_MAX_HEAT;
	@observable
	public heatMap: FpApi.Resource.Duty.DutyWishMap = {};


	public get shades(): Array<string> {
		return [ "#f5f0fa", "#ebe1f5", "#e1d3f0", "#d7c4eb", "#c3a6e0", "#b997db", "#af89d6", "#a57ad1", "#9b6bcc" ];

		const ret = [ ];

		const colorShade = (col, amt) => {
			col = col.replace(/^#/, "");
			if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

			let [ r, g, b ] = col.match(/.{2}/g);
			([ r, g, b ] = [ parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt ]);

			r = Math.floor(Math.max(Math.min(255, r), 0)).toString(16);
			g = Math.floor(Math.max(Math.min(255, g), 0)).toString(16);
			b = Math.floor(Math.max(Math.min(255, b), 0)).toString(16);

			const rr = (r.length < 2 ? "0" : "") + r;
			const gg = (g.length < 2 ? "0" : "") + g;
			const bb = (b.length < 2 ? "0" : "") + b;

			return `#${rr}${gg}${bb}`;
		};

		for(let i = 1; i <= this._colorShadesAmount + 1; i++) {
			ret.unshift(colorShade(this._color, i * (100 / this._colorShadesAmount)));
		}
		return ret;

	}

	constructor(private app: FleetplanApp, private eventState: CalendarEventState, options: {
		color: ThemeVariantOrColor;
	}) {
		const color = (app.theme.color[options?.color] ? app.theme.color[options?.color].main : options.color) ?? "#00ffff";
		this._color = Color(color).hex();
	}

	@action
	public async loadHeat(from: DateTime, to: DateTime, options?: Omit<FpApi.Resource.Duty.DutyWishServiceGetMapParams, "from" | "to">): Promise<FpApi.Resource.Duty.DutyWishMap> {
		const heatmap = await apiManager.getService(FpApi.Resource.Duty.DutyWishService).getMap(this.app.ctx, {
			from: from.toISO(),
			to: to.toISO(),
			...(options ?? {}),
		});
		this.heatMap = heatmap;
		return heatmap;
	}

	public heatToShade(heat: number): string {
		if(heat === 0) return "#ffffff";
		if(heat < 0) {
			return this.shades[0];
		}
		if(heat >= this._maxHeat) {
			return this.shades[this.shades.length - 1];
		}

		const result = Math.floor(this.shades.length * (heat / this._maxHeat));

		return this.shades[result];
	}

	public calculateHeatFromEvents(): number {
		this._maxHeat = Math.max(Array.from(this.eventState.eventsByDay.values()).reduce((prev, next) => {
			return Math.max(prev, next.size);
		}, 0), CalendarGridHeatmap.MIN_MAX_HEAT);
		return this._maxHeat;
	}

	public setMaxHeat(maxHeat: number): void {
		this._maxHeat = maxHeat;
	}

	public calcluateHeatAmount(): number {
		// this._colorShadesAmount = Math.max(Math.floor(this._maxHeat / CalendarGridHeatmap.MIN_MAX_HEAT), 3);
		return this._colorShadesAmount;
	}
}
