import { useAsyncMemo } from "@tcs-rliess/fp-web-ui";
import { last, sortBy } from "lodash-es";
import React, { createContext, useEffect, useMemo } from "react";

import { useApp } from "../FleetplanApp";



const SettingsCtx = createContext<ReturnType<typeof useSettings>>(null);

export const SettingsProvider: React.FC<React.PropsWithChildren<{ settings: ReturnType<typeof useSettings> }>> = ({ settings, children }) => {
	return <SettingsCtx.Provider value={settings}>{children}</SettingsCtx.Provider>;
};

export const useSettingsCtx = () => {
	return React.useContext(SettingsCtx);
};

export function useSettings<T extends { default: number }>(props: { viewId: string }, fallbackDefaultSetting?: T) {
	const app = useApp();

	if(props.viewId && props.viewId.includes("undefined")) {
		throw new Error("viewId includes 'undefined'. This is not allowed.");
	}

	const { response: settings, hasResolved: hasResolvedSettings, reload } = useAsyncMemo(async () => {
		if(!props.viewId) return [];
		await app.fpSettings?.loadSection(props.viewId);
		return app.fpSettings.getAll<T>(props.viewId);
	}, [ props.viewId ]);

	useEffect(() => {
		if(!props.viewId) return;
		const off = app.fpSettings?.subscribeSection(props.viewId, (eventName, data) => {
			reload();
		});
		return off;
	}, [ props.viewId ]);

	const activeSetting = useMemo(() => {
		if(!props.viewId) return null;
		if(!hasResolvedSettings) return undefined;
		if(!settings?.length) return {
			section: props.viewId,
			_r: null,
			directory_id: null,
			dscaid: null,
			dscid: null,
			id: null,
			trees_item_id: null,
			data: fallbackDefaultSetting,
		};
		if(!settings) return undefined;
		if(localStorage.getItem(props.viewId)) {
			const id = localStorage.getItem(props.viewId);
			const def = settings.find(s => s.id === +id);
			if(def) return def;
		}
		const def = last(sortBy(settings, s => s.data?.default ?? 0));
		localStorage.setItem(props.viewId, def.id.toString());
		return def;
	}, [ settings, hasResolvedSettings ]);

	return {
		settings,
		hasResolvedSettings,
		defaultSetting: activeSetting
	};
}
