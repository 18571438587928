import { FpLandingFieldClient, LandingFieldGetCountriesResponse } from "@tcs-rliess/fp-core";
import { DataProcessorGetParams, FP_QUERY } from "@tcs-rliess/fp-query";

export class FpLandingFieldClientDataProcessor {
	private client: FpLandingFieldClient;

	constructor () {
		this.client = new FpLandingFieldClient({ use: "FpLandingFieldClientDataProcessor" });
	}

	public getCountries(options: DataProcessorGetParams): Promise<LandingFieldGetCountriesResponse> {
		return FP_QUERY.query({
			key: "FpLandingFieldClient.getCountries",
			maxAge: options.cache?.maxAge,
			params: {},
			work: () => this.client.getCountries(),
		});
	}
}
