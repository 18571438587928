import { ApiContext, IFleetplanApi } from "@tcs-rliess/fp-core";
import EventEmitter from "events";

import { FleetplanApp } from "../../FleetplanApp";

/**
 * Requires a pre load via `ensureLoad`, but then is fully sync.
 * Initial load generally happens in the `FleetplanApp`
 * 
 * @fires put
 * @fires delete
 * @fires reload
 */
export abstract class BaseStoreMultiSync<ITEM, ID> extends EventEmitter {
	protected readonly app: FleetplanApp;
	protected readonly fleetplanApi: IFleetplanApi;
	protected get ctx(): ApiContext { return this.app.ctx; }

	private fetchPromise: Promise<void>;
	private itemsById = new Map<ID, ITEM>();

	constructor(app: FleetplanApp) {
		super();

		this.app = app;
		this.fleetplanApi = app.fleetplanApi;
	}

	/** return id of item */
	protected abstract itemId(item: ITEM): ID;
	protected abstract fetchAll(): Promise<ITEM[]>;

	/**
	 * Updates the item in the store with new data
	 * @param item new or updated item
	 * @fires put
	 */
	public update(item: ITEM): void {
		this.itemsById.set(this.itemId(item), item);
		this.emit("put", item);
	}

	/**
	 * Removes the Item from the store
	 * @param id id of deleted item
	 * @fires delete
	 */
	public remove(id: ID): void {
		this.itemsById.delete(id);
		this.emit("delete", id);
	}

	public getId(id: ID): ITEM {
		this.requireLoad();
		return this.itemsById.get(id);
	}

	public getIds(idList: ID[]): ITEM[] {
		this.requireLoad();
		return idList
			.map(id => this.itemsById.get(id))
			.filter(i => i != null);
	}

	public getAll(): ITEM[] {
		this.requireLoad();
		return Array.from(this.itemsById.values());
	}

	public getFilter(filter: (item: ITEM) => boolean): ITEM[] {
		this.requireLoad();
		const items = Array.from(this.itemsById.values());
		return items.filter(filter);
	}

	public getMap(): Map<ID, ITEM> {
		this.requireLoad();
		return new Map(this.itemsById);
	}

	public async reload(): Promise<void> {
		this.fetchPromise = null;
		await this.ensureLoad();
		this.emit("reload");
	}

	public async ensureLoad(): Promise<void> {
		if (this.fetchPromise == null) {
			this.fetchPromise = this.fetchAll().then(data => {
				this.itemsById = new Map(
					data.map(i => [ this.itemId(i), i ])
				);
			});
		}

		await this.fetchPromise;
	}

	private requireLoad(): void {
		if (this.fetchPromise == null) {
			throw new Error("call ensureLoad first!");
		}
	}
}
