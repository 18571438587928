import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class ExternalGroupStore extends BaseStoreMulti<FpApi.Security.ExternalGroup, string> {
	protected itemId(item: FpApi.Security.ExternalGroup): string {
		return item.id;
	}

	protected async fetchAll(): Promise<FpApi.Security.ExternalGroup[]> {
		const provider = await this.app.store.security.identityProvider.getActive(FpApi.Security.IdentityProviderType.Azure);
		const syncEnabled = provider?.options.azure.syncEnabled ?? false;

		if (syncEnabled === false) return [];

		return apiManager
			.getService(FpApi.Security.ExternalSyncService)
			.getGroups(this.app.ctx);
	}
}
