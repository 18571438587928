import React from "react";
import { UiContext } from "../../FpWebUiProvider";
import { useTimezone } from "./Context";
export const DateTimeSpan = function DateTimeSpan(props) {
    const { formatter } = React.useContext(UiContext);
    const tz = useTimezone(props.tz);
    let mask = props.mask;
    if (props.short) {
        mask = "short";
    }
    else if (props.medium) {
        mask = "medium";
    }
    return React.createElement(React.Fragment, null, formatter.dateTimeSpan(props.start, props.end, {
        mask: mask,
        today: props.today,
        tz: tz,
        showDays: props.showDays,
    }));
};
