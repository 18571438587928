import { TreeUtil, LandingField, DirectoryNode, ResourceDutyUtil as ResourceDutyUtilCore } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../../FleetplanApp";

export class ResourceDutyUtil extends ResourceDutyUtilCore {
	private app: FleetplanApp;

	constructor(app: FleetplanApp) {
		super();
		this.app = app;
	}

	protected async getDirectoryTree(): Promise<TreeUtil<DirectoryNode, number>> {
		return this.app.store.fpDir.directory.getTree();
	}

	protected async getLandingfields(fplaidList: number[]): Promise<Map<number, LandingField>> {
		const items = this.app.store.landingField.getIds(fplaidList);
		return new Map(items.map(i => [ i.id, i ]));
	}
}
