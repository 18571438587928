//import { Format, Text } from "@tcs-rliess/fp-web-ui";
import React from "react";
export const FlightOrder = (props) => {
    const data = props.data;
    //const printContext = React.useContext(PrintContext);
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data!");
    //const paddedLegs = Array.from({ ...data.legs, length: data.legs.length < 5 ? 5 : data.legs.length }, (v) => v ?? {});
    return (React.createElement(React.Fragment, null, "FlightOrder HTM"));
};
