import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreSingleMulti } from "../BaseStoreSingleMulti";

export class ProductItemStore extends BaseStoreSingleMulti<FpApi.Product.ProductItem, number> {
	protected itemId(item: FpApi.Product.ProductItem): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Product.ProductItem[]> {
		return apiManager
			.getService(FpApi.Product.ProductItemService)
			.get(this.app.ctx);
	}

	protected fetchIdList(idList: number[]): Promise<FpApi.Product.ProductItem[]> {
		return apiManager
			.getService(FpApi.Product.ProductItemService)
			.getIdList(this.app.ctx, { idList });
	}

	public getVersions(parentId: number): Promise<FpApi.Product.ProductItem[]> {
		const service = apiManager.getService(FpApi.Product.ProductItemService);
		return service.getVersions(this.ctx, { parentId });
	}

	public getRoutes(dspiid: number): Promise<FpApi.Resource.RouteAircraft[]> {
		return this.app.store.routes.getFilter(r => r.$dspiid.some(p => p.id === dspiid));
	}
}
