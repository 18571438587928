import { ApiContext, FpApi, IContactAddressApi } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class ContactAddressApi implements IContactAddressApi {
	public async getId(ctx: ApiContext, params: any): Promise<FpApi.Contact.ContactAddress> {
		return ApiUtil.fetchRpc(ctx, "contact.contactAddress.getId", params);
	}

	public async get(ctx: ApiContext, params: any): Promise<FpApi.Contact.ContactAddress[]> {
		return ApiUtil.fetchRpc(ctx, "contact.contactAddress.get", params);
	}
}
