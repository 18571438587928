import styled, { css } from "styled-components";
export const Table = styled.table `
	width: 100%;
	& > thead > tr > th,
	& > thead > tr > td,
	& > tbody > tr > th,
	& > tbody > tr > td,
	& > tfoot > tr > th,
	& > tfoot > tr > td {
		padding: ${p => p.theme.table.padding[p.size ?? "md"]};
	${p => {
    switch (p.borderless) {
        case "all":
            return css `border: none;`;
        case "block":
            return css `border-inline: 1px solid ${p => p.theme.table.borderColor};`;
        case "inline":
            return css `border-block: 1px solid ${p => p.theme.table.borderColor};`;
        default:
            return css `border: 1px solid ${p => p.theme.table.borderColor};`;
    }
}}
		text-align: left;
		vertical-align: top;

		page-break-inside: avoid;
	}

	& > thead > tr:last-child > th,
	& > thead > tr:last-child > td {
		${p => p.borderless ? "" : css `
			border-bottom: 1px solid ${p => p.theme.table.borderColor};
		`}
		vertical-align: bottom;
	}

	& > tfoot > tr:first-child > th,
	& > tfoot > tr:first-child > td {
		${p => p.borderless ? "" : css `
			border-top: 1px solid ${p => p.theme.table.borderColor};
		`}
	}

	@media print {
		& > thead > tr > th,
		& > thead > tr > td,
		& > tbody > tr > th,
		& > tbody > tr > td,
		& > tfoot > tr > th,
		& > tfoot > tr > td {
			padding: ${p => p.size === "sm" ? .25 : .5}em;
			border: 1px solid #000000;
		}

		& > thead > tr:last-child > th,
		& > thead > tr:last-child > td {
			border-bottom: 1px solid #000000;
		}

		& > tfoot > tr:first-child > th,
		& > tfoot > tr:first-child > td {
			border-top: 1px solid #000000;
		}
	}
`;
