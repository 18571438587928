import { ApiContext, apiManager, FpApi, SettingsScopeUtil } from "@tcs-rliess/fp-core";
import { extendObservable, observable } from "mobx";

import SettingType = FpApi.Setting.SettingType;

export class SettingsStore extends SettingsScopeUtil {
	private ctx: ApiContext;
	private settingService = apiManager.getService(FpApi.Setting.SettingService)

	@observable
	public loaded = false;

	private level: number;
	private linkId: string;
	private linkIdType: string;

	constructor(ctx: ApiContext, params: {
		level: number;
		linkId: string;
		linkIdType: string;
	}) {
		super();

		// make the list observable
		// for user settings we listen to changes and update `FleetplanApp.userSettings`
		extendObservable(this, {
			items: this.items,
		}, {
			items: observable,
		});

		this.prefix = "";

		this.ctx = ctx;
		this.level = params.level;
		this.linkId = params.linkId;
		this.linkIdType = params.linkIdType;
	}

	public async reload(): Promise<void> {
		const list = await this.settingService.list(this.ctx, {
			level: this.level,
			linkId: this.linkId,
			linkIdType: this.linkIdType,
		});

		this.items = list.values;
		this.loaded = true;
	}

	public async set(key: string, type: SettingType, value: any): Promise<void> {
		let valueString = value;
		switch (type) {
			case SettingType.Number: valueString = value.toString(); break;
			case SettingType.Boolean: valueString = value ? "true" : "false"; break;
		}

		await this.settingService.set(this.ctx, {
			data: [{
				level: this.level,
				linkId: this.linkId,
				linkIdType: this.linkIdType,
				key: key,
				type: type,
				value: valueString,
			}]
		});

		// update locally
		const item = this.get(key);
		if (item == null) {
			this.items.push({
				key: key,
				type: type,
				isModified: true,
				levels: {
					[this.level]: valueString,
				},
				value: value,
				fromLevel: this.level,
			});
		} else {
			item.isModified = true;
			item.levels[this.level] = valueString;
			item.value = value;
			item.fromLevel = this.level;
		}
	}

	public async setNumber(key: string, value: number): Promise<void> {
		await this.set(key, SettingType.Number, value);
	}

	public async setString(key: string, value: string): Promise<void> {
		await this.set(key, SettingType.String, value);
	}

	public async setBoolean(key: string, value: boolean): Promise<void> {
		await this.set(key, SettingType.String, value);
	}
}

