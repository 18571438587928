import React from "react";
import styled from "styled-components";
import { Alert } from "../Alert/Alert";
import { Flex, FlexItem } from "../Flex";
import { Button } from "./Button";
const StyledFlex = styled(Flex) `
	padding-top: 14px;
	padding-right: 8px;
`;
export const CancelSave = (props) => {
    if (props.confirm)
        return React.createElement(CancelSaveConfirm, { ...props });
    return React.createElement(CancelSaveNoConfirm, { ...props });
};
const CancelSaveConfirm = (props) => {
    const [confirm, setConfirm] = React.useState(false);
    return React.createElement(StyledFlex, null,
        props.children && React.createElement(FlexItem, { shrink: true }, props.children),
        React.createElement(FlexItem, { grow: true }),
        React.createElement(Flex, null,
            (props.submitText && confirm) && React.createElement(Alert, { style: { marginRight: 4 }, color: "warning" }, props.submitText),
            confirm ? React.createElement(React.Fragment, null,
                React.createElement(Button, { disabled: props.saveDisabled, size: "sm", onClick: props.onSave, variant: "primary" }, props.saveLabel ?? "Save"),
                React.createElement(Button, { disabled: props.cancelDisabled, size: "sm", type: "button", onClick: () => setConfirm(false), variant: "link" }, props.cancelLabel ?? "Cancel")) : React.createElement(React.Fragment, null,
                React.createElement(Button, { disabled: props.cancelDisabled, size: "sm", onClick: props.onCancel, variant: "link" }, props.cancelLabel ?? "Cancel"),
                React.createElement(Button, { disabled: props.saveDisabled, onClick: () => setConfirm(true), size: "sm", type: "button", variant: "primary" }, props.saveLabel ?? "Save"))));
};
const CancelSaveNoConfirm = (props) => {
    return React.createElement(StyledFlex, null,
        props.children && React.createElement(FlexItem, { shrink: true }, props.children),
        React.createElement(FlexItem, { grow: true }),
        React.createElement(FlexItem, null,
            React.createElement(Button, { disabled: props.cancelDisabled, size: "sm", onClick: props.onCancel, variant: "link" }, props.cancelLabel ?? "Cancel"),
            React.createElement(Button, { disabled: props.saveDisabled, size: "sm", onClick: props.onSave, variant: "primary" }, props.saveLabel ?? "Save")));
};
