import { castArray } from "lodash";
import React from "react";
import { useController } from "react-hook-form";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
import { useFieldHook } from "../../index";
const InternalToggleInput = function ToggleInput(props) {
    const [firstColor, secondColor] = castArray(props.variant ?? props.toggleColor);
    const onColor = useThemeColor(firstColor, "#fff");
    const offColor = useThemeColor(secondColor ?? "#e0e0e0", "#fff");
    return (React.createElement(StyledToggleInput, { onKeyDown: e => {
            if (e.key === "Enter" || e.key === " ") {
                if (props.disabled)
                    return;
                props.onChange?.(!props.value);
            }
        }, onColor: onColor[500].main, off: offColor[500].main, size: props.size, style: props.style },
        React.createElement("input", { onKeyDown: e => {
                if (e.key === "Enter" || e.key === " ") {
                    if (props.disabled)
                        return;
                    props.onChange?.(!props.value);
                }
            }, disabled: props.disabled, "aria-disabled": props.disabled, checked: props.value, type: "checkbox", onChange: () => props.onChange(!props.value) })));
};
InternalToggleInput.defaultProps = {
    toggleColor: "success",
    size: "md",
};
const InternalToggleInputHook = function ToggleInputHook(props) {
    const { name } = useFieldHook() ?? {};
    const finalName = props.name || name;
    const controller = useController({ name: finalName });
    return React.createElement(InternalToggleInput, { ...props, value: controller.field.value, onChange: e => {
            controller.field.onChange(e);
            props.onChange?.(e);
        } });
};
export const ToggleInput = Object.assign(InternalToggleInput, {
    Hook: InternalToggleInputHook
});
export const StyledToggleInput = styled.div `
--animation-duration: .1s;
${p => {
    switch (p.size) {
        case "sm":
            return css `
				--component-width: 24px;
				--component-height: 12px;
				--knob-width: 7px;
				--knob-height: 7px;
				--knob-position: 2.5px;
			`;
        case "md":
            return css `
				--component-width: 40px;
				--component-height: 20px;
				--knob-width: 14px;
				--knob-height: 14px;
				--knob-position: 3px;
			`;
        case "lg":
            return css `
				--component-width: 80px;
				--component-height: 40px;
				--knob-width: 34px;
				--knob-height: 34px;
				--knob-position: 3px;
			`;
    }
}}

width: var(--component-width);
height: var(--component-height);
position: relative;
cursor: pointer;
input[type=checkbox] {
	width: var(--component-width);
	height: var(--component-height);
	cursor: pointer;
	appearance: none;
	background: ${p => p.off};
	// 	border-radius: 2px;
	position: relative;
	outline: 0;
	transition: all var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
	margin-top: 0;
	border-radius: 2rem;
}

input[type=checkbox]:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

// knob
input[type=checkbox]:after{
	position: absolute;
	content: "";
	top: var(--knob-position);
	left: var(--knob-position);
	width: var(--knob-width);
	height: var(--knob-height);
	background: #fff;
	// z-index: 2;
	border-radius: 2rem;
	transition: all var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
}

input[type=checkbox]:checked{
	background: ${p => p.onColor};
}

input[type=checkbox]:focus {
	outline: 1px solid ${p => p.onColor};
	outline-offset: 1px;
}

@keyframes toggleKnob {
	0% {
		width: var(--knob-width);
	}
	50% {
		width: calc(100% - var(--knob-width) - 3px);
	}
	100% {
		width: var(--knob-width);
	}
}

input[type=checkbox]:checked:after{
	animation: toggleKnob calc(var(--animation-duration) * 1.2) cubic-bezier(.42, 0, 0.8, 0.35) forwards;
	left: calc(100% - var(--knob-width) - 3px);
}

`;
