import { apiManager, FpApi } from "@tcs-rliess/fp-core";
import { reaction } from "mobx";

import { FleetplanApp } from "../../../../FleetplanApp";
import { handleError } from "../../../../handleError";
import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class UserLogTypeStore extends BaseStoreMultiSync<FpApi.Resource.UserLogType, number> {
	private cache: FpApi.Resource.UserLogType[];

	constructor(app: FleetplanApp) {
		super(app);

		reaction(
			() => app.flags.flightOpsNew,
			() => {
				this.reload().catch(handleError);
			},
		);
	}

	protected async fetchAll(): Promise<FpApi.Resource.UserLogType[]> {
		let items = this.cache;

		if (items == null) {
			items = await apiManager
				.getService(FpApi.Resource.UserLogTypeService)
				.get(this.app.ctx);

			this.cache = items;
		}

		if (this.app.flags.flightOpsNew) {
			// all log types (including `flightOpsNew`)
			return items;
		} else {
			// drop the new log types
			return items.filter(i => i.flightOpsNew !== true);
		}
	}

	protected itemId(item: FpApi.Resource.UserLogType): number {
		return item.id;
	}

	public getType(type: FpApi.Resource.UserLogTypeResourceType): FpApi.Resource.UserLogType[] {
		const items = this.getAll();
		return items.filter(i => i.resourceType === type);
	}

	public getIdName(idName: string): FpApi.Resource.UserLogType {
		const items = this.getAll();
		return items.find(i => i.idName === idName);
	}

	public getIdNames(idNames: string[]): FpApi.Resource.UserLogType[] {
		const items = this.getAll();
		return idNames.map((idName) => items.find(i => i.idName === idName));
	}
}
