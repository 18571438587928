import { ActivityGetResult, apiManager, FpApi, FpLegClient, FpLegClientCrewLog, FpLegClientLeg } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

export class Bucket {
	public readonly start: DateTime;
	public readonly end: DateTime;

	private readonly app: FleetplanApp;
	private readonly legClient: FpLegClient;

	private cacheActivity: ActivityGetResult[];
	private cacheAircraftLeg: FpLegClientLeg[];
	private cacheCrewLeg: FpLegClientCrewLog[];

	constructor(app: FleetplanApp, year: number, month: number) {
		this.app = app;
		this.start = DateTime.local(year, month);
		this.end = this.start.plus({ month: 1 });

		this.legClient = new FpLegClient({ ctx: this.app.ctx, use: "Scheduler/Bucket" });
	}

	public async aircraftLeg(fpvidList: number[]): Promise<FpLegClientLeg[]> {
		if (this.cacheAircraftLeg) {
			return this.cacheAircraftLeg;
		}

		const result = await this.legClient.getLegs(this.app.ctx.dscid, {
			fpvids: fpvidList,
			blockoff_start: this.start.toISO(),
			blockoff_end: this.end.toISO(),
		});
		this.cacheAircraftLeg = result.rows;

		return this.cacheAircraftLeg;
	}

	public async crewLeg(dscaidList: number[]): Promise<FpLegClientCrewLog[]> {
		if (this.cacheCrewLeg) {
			return this.cacheCrewLeg;
		}

		const result = await this.legClient.getCrewLogs(this.app.ctx.dscid, {
			dscaids: dscaidList,
			blockoff_start: this.start.toISO(),
			blockoff_end: this.end.toISO(),
		});
		this.cacheCrewLeg = result.rows;

		return this.cacheCrewLeg;
	}

	public async activity(objects: Array<{ linkType: string; linkId: string; }>): Promise<ActivityGetResult[]> {
		if (this.cacheActivity) {
			return this.cacheActivity;
		}

		const result = await apiManager
			.getService(FpApi.Activity.ActivityService)
			.getMany(this.app.ctx, {
				objects: objects,
				start: this.start.toString(),
				end: this.end.toString(),
				activities: true,
			});

		this.cacheActivity = result.map(r => ActivityGetResult.fromResult(r));

		return this.cacheActivity;
	}
}
