import { apiManager, FpApi, TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { BaseStoreRange } from "../../BaseStoreRange";

export class ResourceDutyWishStore extends BaseStoreRange<FpApi.Resource.Duty.DutyWish, string> {
	protected itemId(item: FpApi.Resource.Duty.DutyWish): string {
		return item.id;
	}
	protected itemDateModified(item: FpApi.Resource.Duty.DutyWish): DateTime {
		return DateTime.fromISO(item.dateModified);
	}

	protected itemRange(item: FpApi.Resource.Duty.DutyWish): TimeSpan {
		return new TimeSpan(
			DateTime.fromISO(item.date).startOf("day"),
			DateTime.fromISO(item.date).startOf("day").plus({ day: 1 }),
		);
	}

	protected async fetchIdList(idList: string[]): Promise<FpApi.Resource.Duty.DutyWish[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.DutyWishService)
			.getIdList(this.app.ctx, { idList });
	}

	protected async fetchRange(from: DateTime, to: DateTime): Promise<FpApi.Resource.Duty.DutyWish[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.DutyWishService)
			.get(this.app.ctx, {
				from: from.toISO(),
				to: to.toISO(),
			});
	}
}
