import Color from "color";
import { buildColor } from "./ThemeColor";
const DEFAULTS = {
    borderColor: "#222222",
    hover: "#0d0d0d",
};
export const ThemeDark = {
    kind: "dark",
    background: "black",
    font: "white",
    fontMuted: "#777",
    color: {
        // bootstrap colors
        primary: buildColor("dark", new Color("#337ab7")),
        success: buildColor("dark", new Color("#5cb85c"), "white"),
        danger: buildColor("dark", new Color("#d9534f")),
        warning: buildColor("dark", new Color("#f0ad4e"), "white"),
        info: buildColor("dark", new Color("#5bc0de")),
        // custom colors
        deeporange: buildColor("dark", new Color("#fe541e")),
        purple: buildColor("dark", new Color("#9b6bcc")),
        confirmed: buildColor("dark", new Color("#3498db")),
        cancelled: buildColor("dark", new Color("#777")),
        yellow: buildColor("dark", new Color("#ffdd33")),
    },
    defaults: DEFAULTS,
    content: {
        background: "#000",
    },
    panel: {
        background: "#292F33",
        titleBorder: "#66757F",
        border: "#66757F",
    },
    table: {
        padding: {
            md: "8px",
            sm: "4px",
        },
        borderColor: DEFAULTS.borderColor,
    },
    dockedManager: {
        borderColor: DEFAULTS.borderColor,
    },
    menu: {
        background: "#050505",
        hover: DEFAULTS.hover,
        borderColor: DEFAULTS.borderColor,
    },
    dropdown: {
        background: "#050505",
        hover: DEFAULTS.hover,
        borderColor: DEFAULTS.borderColor,
    },
    tree: {
        hover: DEFAULTS.hover,
        active: "#002c54",
        activeHover: "#003769",
    }
};
