import { TimeRangeModel } from "@bryntum/scheduler-thin";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class WeekendBuilder extends BaseBuilder {
	public buildRange(state: RangeBuilderState): Promise<void> {
		if (this.store.configuration.events.weekend?.enabled) this.weekends(state);

		return Promise.resolve();
	}

	public handlePut(state: BuilderState, item: unknown): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		return Promise.resolve();
	}

	private weekends(state: RangeBuilderState): void {
		const days = this.store.configuration.events.weekend.days;

		let date = state.from
			.setZone(this.store.tz)
			.startOf("day");

		for (; date < state.to; date = date.plus({ day: 1 })) {
			if (days.includes(date.weekday)) {
				const range = new TimeRangeModel({
					id: `weekend:${date.toISODate()}`,
					startDate: this.toTimeZone(date),
					endDate: this.toTimeZone(date.plus({ day: 1 })),
					name: "Weekend",
					cls: "fp-weekend",
				});
				state.timeRanges.push(range);
			}
		}
	}
}
