import { isString } from "lodash";
import React from "react";
import { UiContext } from "../../FpWebUiProvider";
export const Number = function Number(props) {
    const { formatter } = React.useContext(UiContext);
    let value = props.children;
    // TODO [03.12.18 - pr] is this ok?
    if (isString(value)) {
        value = parseFloat(value);
    }
    const out = formatter.number(value, {
        separatorGroup: props.separatorGroup,
        separatorDecimal: props.separatorDecimal,
        decimalDigits: props.decimalDigits,
        enforceSign: props.enforceSign,
    });
    if (value < 0 && props.redOnNegative) {
        return React.createElement("span", { style: { color: "red" } }, out);
    }
    else if (value > 0 && props.greenOnPositive) {
        return React.createElement("span", { style: { color: "green" } }, out);
    }
    else {
        return React.createElement(React.Fragment, null, out);
    }
};
