import { fpLog } from "@tcs-rliess/fp-log";
import { action, observable } from "mobx";

export interface IStatusPageResponse {
	page: {
		id: string;
		name: string;
		url: string;
		time_zone: string;
		updated_at: string;
	};
	components: IStatusPageComponent[];
	incidents: IIncident[];
	scheduled_maintenances: IScheduledMaintenance[];
	status: {
		indicator: string;
		description: string;
	};
}

export interface IStatusPageComponent {
	id: string;
	name: string;
	status: string;
	created_at: string;
	updated_at: string;
	position: number;
	description: any; // ???
	showcase: boolean;
	group_id: string;
	page_id: string;
	group: boolean;
	only_show_if_degraded: boolean;
}

export interface IScheduledMaintenance {
	created_at: string;
	id: string;
	impact: string;
	incident_updates: {
		affected_components: any;
		body: string;
		custom_tweet: any;
		deliver_notifications: boolean;
		display_at: string;
		id: string;
		incident_id: string;
		status: string;
		updated_at: string;
		created_at: string;
	}[];
	monitoring_at: any;
	name: string;
	page_id: string;
	resolved_at: any;
	scheduled_for: string;
	scheduled_until: string;
	shortlink: string;
	status: string;
	updated_at: string;
}

export interface IIncident {
	created_at: string;
	id: string;
	impact: string;
	incident_updates: {
		affected_components: any;
		body: string;
		custom_tweet: any;
		deliver_notifications: boolean;
		display_at: string;
		id: string;
		incident_id: string;
		status: string;
		updated_at: string;
		created_at: string;
	}[];
	monitoring_at: any;
	name: string;
	page_id: string;
	resolved_at: any;
	scheduled_for: string;
	scheduled_until: string;
	shortlink: string;
	status: string;
	updated_at: string;
}

export class StatusPageStore {
	// debug value
	private debug = false;
	// private i = 0;

	@observable public status: IStatusPageResponse;
	@observable public lastFetch: Date;

	constructor() {
		this.updateData();
		setInterval(this.updateData, this.debug ? 10000 : 300000); // 5min
	}

	@action.bound
	private async updateData(): Promise<void> {
		if (this.debug) {
			/*
			const dummy = require("./testdata.json");

			this.status = dummy[this.i];
			this.lastFetch = new Date();

			this.i++;
			this.i = this.i % dummy.length;
			*/
		} else {
			try {
				const data = await fetch("https://7183xkjgdkg1.statuspage.io/api/v2/summary.json");
				this.status = await data.json();
				this.lastFetch = new Date();
			} catch (err) {
				fpLog.error(err);
				this.status = null;
				this.lastFetch = null;
			}
		}
	}
}
