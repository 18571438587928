import { Format, UnitSize } from "@tcs-rliess/fp-web-ui";
import React from "react";
import { DefaultFooter, DefaultHeader } from "../../lib";
import { DataH3, DataTable, DataTd } from "../../lib/styledExportComponents";
export const MovementTable = (props) => {
    let rowSpan = 1;
    if (props.data?.paxEnabled && props.data?.cargoEnabled) {
        rowSpan = 3;
    }
    else if (props.data?.paxEnabled) {
        rowSpan = 2;
    }
    return (React.createElement(React.Fragment, null,
        !props.hideHeader && (React.createElement(DefaultHeader, null)),
        props.data?.flights.map((flight, index) => {
            return (React.createElement(React.Fragment, null,
                React.createElement("h3", null, flight.id),
                React.createElement(DataTable, { key: index },
                    React.createElement("thead", null,
                        React.createElement("tr", { className: "bgGrey" },
                            React.createElement(DataTd, { colSpan: 5 },
                                React.createElement(DataH3, null,
                                    React.createElement(Format.Date, null, props.firstDate))),
                            React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 2 },
                                React.createElement(DataH3, null, "CARRIED OVER")),
                            React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 2 },
                                React.createElement(DataH3, null, "BOARDED")),
                            React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 2 },
                                React.createElement(DataH3, null, "TOTAL")),
                            React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 2 },
                                React.createElement(DataH3, null, "REMOVED")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "AVAIL WT")),
                            React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 2 },
                                React.createElement(DataH3, null, "CARY OVER"))),
                        React.createElement("tr", { className: "bgGrey" },
                            React.createElement(DataTd, null,
                                React.createElement(DataH3, null, "FROM")),
                            React.createElement(DataTd, null,
                                React.createElement(DataH3, null, "TO")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "STD")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "STA")),
                            React.createElement(DataTd, null,
                                React.createElement(DataH3, null, "KIND")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "PCS")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "WT")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "PCS")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "WT")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "PCS")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "WT")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "PCS")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "WT")),
                            React.createElement(DataTd, { style: { textAlign: "right" } }),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "PCS")),
                            React.createElement(DataTd, { style: { textAlign: "right" } },
                                React.createElement(DataH3, null, "WT")))),
                    React.createElement("tbody", null, flight.waypoints.map((waypoint) => {
                        const PAX = () => {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(DataTd, { style: { textTransform: "uppercase" } }, "PAX"),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.carriedOver.quantity ? waypoint.movements.pax.carriedOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.carriedOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.pax.carriedOver.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.boarded.quantity ? waypoint.movements.pax.boarded.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.boarded.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.pax.boarded.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.total.quantity ? waypoint.movements.pax.total.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.total.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.pax.total.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.removed.quantity ? waypoint.movements.pax.removed.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.removed.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.pax.removed.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" }, rowSpan: rowSpan }, waypoint.movements.availableWeight > 0 ? React.createElement(Format.Mass, { inUnit: "mass.metric.kilogram", size: UnitSize.Medium, ignoreAbbr: true }, waypoint.movements.availableWeight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.caryOver.quantity ? waypoint.movements.pax.caryOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.pax.caryOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.pax.caryOver.weight) : null)));
                        };
                        const BAGGAGE = () => {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(DataTd, { style: { textTransform: "uppercase" } }, "BAGGAGE"),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.carriedOver.quantity ? waypoint.movements.paxBaggage.carriedOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.carriedOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.paxBaggage.carriedOver.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.boarded.quantity ? waypoint.movements.paxBaggage.boarded.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.boarded.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.paxBaggage.boarded.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.total.quantity ? waypoint.movements.paxBaggage.total.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.total.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.paxBaggage.total.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.removed.quantity ? waypoint.movements.paxBaggage.removed.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.removed.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.paxBaggage.removed.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.caryOver.quantity ? waypoint.movements.paxBaggage.caryOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.paxBaggage.caryOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.paxBaggage.caryOver.weight) : null)));
                        };
                        const CARGO = () => {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(DataTd, { style: { textTransform: "uppercase" } }, "CARGO"),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.carriedOver.quantity ? waypoint.movements.cargo.carriedOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.carriedOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.cargo.carriedOver.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.boarded.quantity ? waypoint.movements.cargo.boarded.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.boarded.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.cargo.boarded.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.total.quantity ? waypoint.movements.cargo.total.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.total.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.cargo.total.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.removed.quantity ? waypoint.movements.cargo.removed.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.removed.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.cargo.removed.weight) : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.caryOver.quantity ? waypoint.movements.cargo.caryOver.quantity : null),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.movements.cargo.caryOver.weight ? React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium }, waypoint.movements.cargo.caryOver.weight) : null)));
                        };
                        return (React.createElement(React.Fragment, { key: waypoint.id },
                            React.createElement("tr", null,
                                React.createElement(DataTd, { rowSpan: rowSpan }, waypoint.from),
                                React.createElement(DataTd, { rowSpan: rowSpan }, waypoint.to),
                                React.createElement(DataTd, { style: { textAlign: "right" }, rowSpan: rowSpan },
                                    React.createElement(Format.Time, null, waypoint.std)),
                                React.createElement(DataTd, { style: { textAlign: "right" }, rowSpan: rowSpan },
                                    React.createElement(Format.Time, null, waypoint.sta)),
                                (props.data?.paxEnabled) ? (React.createElement(PAX, null)) : ((props.data?.cargoEnabled) && (React.createElement(CARGO, null)))),
                            (props.data?.paxEnabled) && (React.createElement(React.Fragment, null,
                                React.createElement("tr", null,
                                    React.createElement(BAGGAGE, null)),
                                (props.data?.cargoEnabled) && (React.createElement("tr", null,
                                    React.createElement(CARGO, null)))))));
                    }))),
                React.createElement("br", null)));
        }),
        !props.hideFooter && (React.createElement(DefaultFooter, null))));
};
