import { DirContact, FpDirClient } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../../../FleetplanApp";
import { handleError } from "../../../handleError";
import { EventStreamImpact } from "../../mqtt";
import { BaseStoreMultiSync } from "../BaseStoreMultiSync";

/**
 * fields loaded initially on page load for all contacts
 */
// export const DEFAULT_DIR_CONTACT_FIELDS: DirContactFields[] = [
// 	"id",
// 	"dscid",
// 	"dspcaid",
// 	"isCompany",
// 	"isActive",
// 	"isSystem",
// 	"isHidden",
// 	"avatarTn",
// 	"dsttidList",
// 	"dsuid",
// 	"accountsReceivableNo",
// 	"$person.givenName",
// 	"$person.lastName",
// 	"$person.abbreviation",
// 	"$person.preferredEmail",
// 	"$organization.nameOrganization",
// 	"$organization.nameOrganizationShort",
// ];

type FilterFunction = (item: DirContact) => boolean;

interface ContactStoreGet {
	includeHidden?: boolean;
	includeSystem?: boolean;
	includeArchived?: boolean;
}

export class ContactStore extends BaseStoreMultiSync<DirContact, number> {
	private dirClient: FpDirClient;

	constructor(app: FleetplanApp) {
		super(app);
		this.dirClient = new FpDirClient({ use: "ContactStore" });

		// listen to event stream
		app.eventStream.subscribe({
			impact: EventStreamImpact.AllUsers,
			filter: { a: "fpdir", s: "contacts" },
			callback: items => {
				const dscaidList: number[] = [];

				for (const item of items) {
					const dscaid = parseInt(item.ca);
					switch (item.t) {
						case "insert":
						case "update": {
							dscaidList.push(dscaid);
							break;
						}
						case "delete": {
							this.remove(dscaid);
							break;
						}
					}
				}

				if (dscaidList.length) {
					this.dirClient
						.contactsFilter(app.dscid, { filters: [{ id: dscaidList }] })
						.then(result => {
							// update in store
							result.rows.forEach(item => this.update(item));
						})
						.catch(handleError);
				}
			},
		});
	}

	public getAll(options?: ContactStoreGet): DirContact[] {
		const items = super.getAll();
		return this.filterGet(items, options);
	}

	public getFilter(optionsOrFilter?: FilterFunction | ContactStoreGet & { filter: FilterFunction; }): DirContact[] {
		const filter = typeof optionsOrFilter === "function" ? optionsOrFilter : optionsOrFilter.filter;
		const options: ContactStoreGet = typeof optionsOrFilter === "function" ? {} : optionsOrFilter;

		const items = super.getFilter(filter);
		return this.filterGet(items, options);
	}

	protected itemId(item: DirContact): number {
		return item.id;
	}

	protected fetchAll(): Promise<DirContact[]> {
		return this.dirClient
			.contactsAll(this.app.ctx.dscid, {})
			.then(r => r.rows);
	}

	private filterGet(items: DirContact[], options: ContactStoreGet): DirContact[] {
		options = options ?? {};

		return items.filter(contact => {
			if (!options.includeHidden && contact.isHidden) return false;
			if (!options.includeSystem && contact.isSystem) return false;
			if (!options.includeArchived && !contact.isActive) return false;
			return true;
		});
	}
}
