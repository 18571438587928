import { LandingField, FpLandingFieldClient } from "@tcs-rliess/fp-core";
import { observable } from "mobx";

import { Queue } from "../../Queue";
import { BaseStoreMultiSync } from "../BaseStoreMultiSync";

export interface LandingFieldDistrict {
	"ZdbOrtId": number;
	"isDeleted": boolean;
	"Land": string;
	"BundeslandName": string;
	"IsoRegion": string;
	"RegierungsbezirkName"?: string;
	"KreisName": string;
	"GemeindeName": string;
	"OrtName": string;
	"OrtLatitude": number;
	"OrtLongitude": number;
	"Postleitzahlen": string;
}

const loadingQueue = new Queue({ maxRunning: 1 });

export type DistrictLabelValue = { label: string; value: string; $district: LandingFieldDistrict; }

export class LandingFieldStore extends BaseStoreMultiSync<LandingField, number> {
	private landingFieldClient = new FpLandingFieldClient({ use: "LandingFieldStore" });

	private districtLoadTrigger = false;
	@observable.shallow public districts: {
		DE?: LandingFieldDistrict[];
		CH?: LandingFieldDistrict[];
		AT?: LandingFieldDistrict[];
	} = {};
	@observable.ref public districtsMapped: {
		DE?: DistrictLabelValue[];
		CH?: DistrictLabelValue[];
		AT?:DistrictLabelValue[];
	} = {};
	@observable.ref public allDistrictsMap = new Map<number, LandingFieldDistrict>();

	protected itemId(item: LandingField): number {
		return item.id;
	}

	protected async fetchAll(): Promise<LandingField[]> {
		const result = await this.landingFieldClient.getAll(this.app.ctx.dscid);
		return result.rows;
	}

	public async ensureDistricts(): Promise<void> {
		return loadingQueue.push(async () => { // queue to prevent multiple loads
			if (!this.app.store.settingsProject.getString("landingfield.districts") || this.districtLoadTrigger) return;
			this.districtLoadTrigger = true;

			const districts = this.app.store.settingsProject.getString("landingfield.districts");
			if (!this.districts.DE && districts.includes("DE")) {
				const de_places = await fetch("https://cdn.fleetplan.net/orte-db/orte_DE_231113.json");
				const places = await de_places.json();
				this.districts.DE = places;
				this.districtsMapped.DE = places.map(district => ({ label: district.OrtName, value: "district:" + district.ZdbOrtId.toString(), $district: district }));
			}
			if (!this.districts.AT && districts.includes("AT")) {
				const at_places = await fetch("https://cdn.fleetplan.net/orte-db/orte_AT_231113.json");
				const places = await at_places.json();
				this.districts.AT = places;
				this.districtsMapped.AT = places.map(district => ({ label: district.OrtName, value: "district:" + district.ZdbOrtId.toString(), $district: district }));
			}
			if (!this.districts.CH && districts.includes("CH")) {
				const ch_places = await fetch("https://cdn.fleetplan.net/orte-db/orte_CH_231113.json");
				const places = await ch_places.json();
				this.districts.CH = places;
				this.districtsMapped.CH = places.map(district => ({ label: district.OrtName, value: "district:" + district.ZdbOrtId.toString(), $district: district }));
			}

			this.allDistrictsMap = new Map([
				...(this.districts.DE ?? []),
				...(this.districts.AT ?? []),
				...(this.districts.CH ?? [])
			].map((district) => [ district.ZdbOrtId, district ]));
		});
	}

/* 	protected async fetchIdList(idList: number[]): Promise<LandingField[]> {
		if (idList.length === 1) {
			const result = await this.landingFieldClient.get(this.app.ctx.dscid, idList[0]);
			return result.rows;
		} else {
			const result = await this.landingFieldClient.search(this.app.ctx.dscid, {
				filters: [{ id: idList }],
			});
			return result.rows;
		}
	} */
}
