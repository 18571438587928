import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class NotificationGroupStore extends BaseStoreMulti<FpApi.System.Notification.Group, number> {
	protected fetchAll(): Promise<FpApi.System.Notification.Group[]> {
		return apiManager
			.getService(FpApi.System.Notification.GroupService)
			.get(this.app.ctx, { resolve: true });
	}

	protected itemId(item: FpApi.System.Notification.Group): number {
		return item.id;
	}
}
