import { ACTIVITY_TYPE_DISPLAY_NAME, FpApi } from "@tcs-rliess/fp-core";

import { FpResourceTimeRangeModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

const ACTIVITY_TYPE = [
	FpApi.Activity.ActivityValueType.RestRequired,
	FpApi.Activity.ActivityValueType.Duty,
];

export class ActivityBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.activity?.enabled) loads.push(this.activity(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: FpApi.Activity.Activity): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		return Promise.resolve();
	}

	private async activity(state: RangeBuilderState): Promise<void> {
		const buckets = this.getBuckets(state);

		const dscaidList = state.getResourcesIdList("dscaid");
		const objects = dscaidList.map(id => ({ linkType: "dscaid", linkId: id }));

		if (objects.length === 0) {
			return;
		}

		for (const bucket of buckets) {
			const results = await bucket.activity(objects);

			for (const result of results) {
				state.getResources(result.linkType, result.linkId).forEach(resource => {
					result.activities.forEach(activity => {
						if (ACTIVITY_TYPE.includes(activity.type) === false) return;

						const range = new FpResourceTimeRangeModel({
							resourceId: resource.id,
							startDate: this.toTimeZone(activity.start),
							endDate: this.toTimeZone(activity.end),
							name: ACTIVITY_TYPE_DISPLAY_NAME[activity.type],

							fpLinkType: "_activity",
							fpLinkId: activity.id,
							fpData: activity,
						});

						switch (activity.type) {
							case FpApi.Activity.ActivityValueType.Duty: {
								range.timeRangeColor = "green";
								range.iconCls = "b-fa b-fa-briefcase"; // "fal fa-briefcase";
								break;
							}
							case FpApi.Activity.ActivityValueType.RestRequired: {
								range.timeRangeColor = "red";
								range.iconCls = "b-fa b-fa-bed"; // "fal fa-bed";
								break;
							}
						}

						state.resourceTimeRanges.push(range);
					});
				});
			}
		}
	}
}
