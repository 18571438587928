import autobind from "autobind-decorator";
import { defaults, toString } from "lodash-es";

export interface IMediaApiDropHandlerOptions {
	linkId: any;
	linkIdType: string;
	linkIdSubType: string;
	checkSingleFileFirst: 0 | 1;
	name: string;
	dsloader: any[];
}

export class MediaApiDropHandler {
	private currentOverlay: HTMLElement;
	private currentOptions: IMediaApiDropHandlerOptions;

	constructor() {
		document.addEventListener("DOMContentLoaded", () => {
			document.body.addEventListener("dragover", this.handleDragOver);
		});
	}

	@autobind
	private initEvents(): void {
		const targets = document.querySelectorAll("[data-mediaapi-drop-target]");
		// for of loop not possible, because we can't interate over NodeListOf
		for (let i = 0; i < targets.length; i++) {
			const target = targets[i] as HTMLElement;
			if (target.hasAttribute("data-mediaapi-drop-target-init")) continue;
			target.setAttribute("data-mediaapi-drop-target-init", "true");

			target.addEventListener("dragenter", this.showOverlay);
		}
	}

	@autobind
	private showOverlay(ev: DragEvent): void {
		ev.preventDefault();
		const target = (ev.currentTarget as HTMLElement);
		const pos = target.getBoundingClientRect();
		const options = JSON.parse(target.getAttribute("data-mediaapi-drop-target")) as IMediaApiDropHandlerOptions;

		const overlayEl = document.createElement("div");
		// style overlay
		overlayEl.style.position = "absolute";
		overlayEl.style.top = pos.top + "px";
		overlayEl.style.left = pos.left + "px";
		overlayEl.style.width = pos.width + "px";
		overlayEl.style.height = pos.height + "px";

		overlayEl.style.color = "#4a4a4a";
		overlayEl.style.border = "3px dashed hotpink";
		overlayEl.style.textAlign = "center";
		overlayEl.style.fontWeight = "bold";
		overlayEl.style.lineHeight = pos.height - 6 + "px";
		overlayEl.style.background = "rgba(255, 255, 255, 0.6)";
		overlayEl.style.borderRadius = "7px";

		// content
		overlayEl.innerHTML = `upload to ${options.name}`;

		// logic
		overlayEl.addEventListener("drop", this.handleDrop);
		overlayEl.addEventListener("dragleave", () => {
			if (this.currentOverlay === overlayEl) {
				this.currentOverlay = null;
			}
			overlayEl.remove();
		});
		this.currentOverlay = overlayEl;
		this.currentOptions = options;
		document.body.appendChild(overlayEl);
	}

	@autobind
	private handleDragOver(ev: DragEvent): void {
		ev.preventDefault();
		this.initEvents();

		/*
		if (this.currentOverlay) {
			ev.dataTransfer.dropEffect = "copy";
		} else {
			ev.dataTransfer.dropEffect = "none";
		}
		*/
	}

	@autobind
	private async handleDrop(ev: DragEvent): Promise<void> {
		ev.preventDefault();

		const overlay = this.currentOverlay;
		const options = this.currentOptions;
		overlay.innerHTML = "<i class=\"fal fa-spinner-third fa-spin\"></i> uploading...";

		// Same for this
		for (let i = 0; i < ev.dataTransfer.files.length; i++) {
			const file = ev.dataTransfer.files[i];
			await this.upload(options, file);
		}

		overlay.innerHTML = "<i class=\"fal fa-check\"></i> done";
		dsloader.apply(window, options.dsloader);
		setTimeout(() => {
			overlay.remove();
		}, 1000);
	}

	private async upload(options: IMediaApiDropHandlerOptions, file: File): Promise<void> {
		const form = new FormData();

		defaults(options, {
			linkIdSubType: "",
			checkSingleFileFirst: 0,
		});

		form.append("linkId", options.linkId);
		form.append("linkIdType", options.linkIdType);
		form.append("linkIdSubType", options.linkIdSubType);
		form.append("checkSingleFileFirst", toString(options.checkSingleFileFirst));
		form.append("thumbnail", "0");
		form.append("thumbnailCrop", "0");
		form.append("thumbnailSize", "0");
		form.append("maxwidth", "0");
		form.append("maxheight", "0");
		form.append("s3acl", "authenticated-read");
		form.append("appdata", JSON.stringify({}));
		form.append("acl", JSON.stringify({
			"new": "dsdefault",
			"view": "dsdefault",
			"overwrite": "dsdefault",
			"delete": "dsdefault",
		}));

		form.append("files[]", file);

		const res = await fetch("/api/v1/mediaApi/insert", {
			method: "post",
			body: form,
			credentials: "include",
		});
		await res.text();
	}
}
