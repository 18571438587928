import { apiManager, ServiceConstructor } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { GridApi } from "ag-grid-community";
import { DateTime } from "luxon";
import { action, autorun, computed, observable } from "mobx";

import { FleetplanApp } from "../FleetplanApp";
import { handleError } from "../handleError";

import { Queue } from "./Queue";

interface BaseGridStateOptions {
	continuity?: boolean;
	skip?: boolean;
}

export abstract class BaseGridState<DataType> {
	@observable.shallow public data: DataType[];
	@observable public isLoading = false;
	@observable public lastUpdated: DateTime;
	@observable public highlightedID;

	protected app: FleetplanApp;
	protected key: keyof DataType;
	@observable protected api: GridApi;

	@computed public get rowData(): any {
		return this.data;
	}

	constructor(protected options: { app: FleetplanApp, key?: keyof DataType, loadOnInit?: boolean, useImmutable?: boolean, noInitialRun?: boolean }) {
		this.app = options.app;
		if(!this.options.noInitialRun)
			this.run().catch(handleError);
		autorun(() => {
			this.lastUpdated;
			if(this.api) {
				(this.api as any).setLastUpdated(this.lastUpdated);
			}
		});
		autorun(() => {
			this.isLoading;
			if(this.api) {
				(this.api as any).setExperimentalLoading(this.isLoading);
			}
		});
	}

	@action
	public setLastUpdated(value: DateTime): void {
		this.lastUpdated = value;
	}

	private gridQueue = new Queue();

	public update(item: DataType, id: any): void {
		this.gridQueue.push(() => this.internalUpdate(item, id).catch(() => console.error("Error while updating grid")));
	}

	@action
	async internalUpdate(item: DataType, id: any): Promise<void> {
		if(!this.options.key) {
			fpLog.warn("You haven't set 'key'. Cannot update!");
			return;
		}
		item = (this.extendData(item) ?? item) as any;
		if(!id) {
			this.data.push(item);
			if(this.options.useImmutable)
				setTimeout(() => {
					this.api?.applyTransaction({
						add: [ item ]
					});
				});
		} else {
			const index = this.data?.findIndex(e => id === e[this.options.key]);
			if(index == null) return;
			if(index === -1) {
				this.data.push(item);
				if(this.options.useImmutable)
					setTimeout(() => {
						this.api?.applyTransaction({
							add: [ item ]
						});
					}, 1);
			} else {
				this.data[index] = item;
				if(this.options.useImmutable) {
					setTimeout(() => {
						const rowNode = this.api?.getRowNode(id);
						if(rowNode) {
							this.api?.applyTransaction({
								update: [ item ]
							});
							rowNode.setData(item);
							this.api?.refreshCells({
								rowNodes: [ rowNode ],
								force: true,
							});
							this.api?.redrawRows({
								rowNodes: [ rowNode ],
							});
						}
					}, 1);
				}
			}
		}
		return new Promise(() => undefined);
	}

	@action
	setHighlightID(id: number | string): void {
		if (!this.api) return;

		if (id) {
			this.api.getRowNode(id.toString())?.setSelected(true, true);
			this.highlightedID = id;
		} else {
			this.api.getRowNode(this.highlightedID)?.setSelected(false, true);
			this.highlightedID = null;
		}
	}

	protected abstract innerRun(cb?: (data: any[]) => void | Promise<void>);

	public async lazyLoader(data: any[]) {
		const mappedData = data;
		// eslint-disable-next-line @typescript-eslint/no-for-in-array
		for(const key in mappedData) {
			const newData = await this.extendData(mappedData[key]);
			if(newData == null) break;
			mappedData[key] = newData;
		}
		this.data = this.data ?  [ ...this.data, ...mappedData ] : [ mappedData ];
		setTimeout(() => {
			this.api?.applyTransaction({
				add: mappedData
			});
		});
	}

	protected extendData(ev: DataType & any): Promise<DataType & any>{
		return null;
	}



	@action protected async run(options?: BaseGridStateOptions): Promise<void> {
		if(this.isLoading && !options?.skip) return;
		this.setLoading(true);
		try {
			if(options?.continuity) {
				let firstRun = true;
				await this.innerRun((e) => this.lazyLoader(e));
				this.api?.refreshCells();
				autorun((autorun) => {
					this.data;
					this.api;
					if(this.api && this.data) {
						if(!firstRun) {
							setTimeout(() => {
								this.api?.setGridOption("rowData", this.rowData);
							});
						}
						if(this.options.useImmutable)
							autorun.dispose();
						firstRun = false;
					}
				});
			} else {
				const innerRunData: any[] = await this.innerRun();
				if(innerRunData) {
					const mappedData = innerRunData;
					// eslint-disable-next-line @typescript-eslint/no-for-in-array
					for(const key in mappedData) {
						const newData = await this.extendData(mappedData[key]);
						if(newData == null) break;
						mappedData[key] = newData;
					}
					this.data = mappedData;
				}
				autorun((autorun) => {
					this.data;
					this.api;
					if(this.api && this.data) {
						this.api?.setGridOption("rowData", this.rowData);
						if(this.options.useImmutable)
							autorun.dispose();
					}
				});
			}
		} catch (err) {
			handleError(err);
		} finally {
			this.setLoading(false);
			this.lastUpdated = DateTime.local();
		}
	}

	@action
	public remove(id: any): void {
		if(!this.options.key) {
			fpLog.warn("You haven't set 'key'. Cannot remove!");
			return;
		}
		const data = this.data.filter(e => id !== e[this.options.key]);
		if(this.options.useImmutable) {
			setTimeout(() => {
				this.api?.applyTransaction({ remove: [ this.api?.getRowNode(id)?.data ] });
			});
		}
		this.data = [ ...data ];
		// this.api?.sizeColumnsToFit();
	}

	@action
	public setApi(api: GridApi): void {
		this.api = api;
	}


	@action
	public setLoading(value: boolean): void {
		this.isLoading = value;
	}

	// @action
	// public refresh(): any | Promise<any> {
	// 	this.run().then(() => {
	// 		this.api?.setRowData(this.rowData);
	// 	}).catch(handleError);
	// }

}


/**
 * @description Important notice for usage:
 * In case you are using immutable data, please provide options.useImmutable
 * see usage in [EventGrid]{@see 'fp-web/src/modules/Quality/Event/Grid/EventGrid.tsx'}
 * @deprecated 2022-01-19 - [PR] use {@link BaseGridState} instead
 */
export class BaseServiceGridState<DataType, ServiceType, Params = any> extends BaseGridState<DataType>{
	protected serviceConstructor: ServiceConstructor<ServiceType>

	protected service: ServiceType;

	constructor(protected options: { method: keyof ServiceType, serviceConstructor: ServiceConstructor<ServiceType>, app: FleetplanApp, initialParams?: Params, key?: keyof DataType, loadOnInit?: boolean, useImmutable?: boolean }) {
		super(options);
		this.serviceConstructor = options.serviceConstructor;
		this.service = apiManager.getService(options.serviceConstructor);
	}


	@action
	public async innerRun() {
		if(this.options.loadOnInit) {
			return await this.init(this.options.initialParams);
		}
	}

	@computed public get rowData(): any {
		return this.data;
	}


	@action
	protected async init(initialParams?: Params): Promise<DataType[]> {
		return await this.loadData(initialParams);
	}

	protected async loadData(params: Params): Promise<DataType[]> {
		// this.setLoading(true);
		try {
			const service: any = apiManager.getService(this.options.serviceConstructor);
			return await service[this.options.method](this.app.ctx, params);
		} catch(err) {
			handleError(err);
		} finally {
			// this.setLoading(false);
		}
	}
}
