import { ApiContext, IFleetplanApi } from "@tcs-rliess/fp-core";
import EventEmitter from "events";

import { FleetplanApp } from "../../FleetplanApp";

/**
 * Loads data on demand, all methods are async.
 * 
 * @fires put
 * @fires delete
 * @fires reload
 */
export abstract class BaseStoreMulti<ITEM, ID> extends EventEmitter {
	protected readonly app: FleetplanApp;
	protected readonly fleetplanApi: IFleetplanApi;
	protected get ctx(): ApiContext { return this.app.ctx; }

	private load: Promise<void>;
	private itemsById = new Map<ID, ITEM>();

	constructor(app: FleetplanApp) {
		super();

		this.app = app;
		this.fleetplanApi = app.fleetplanApi;
	}

	/** return id of item */
	protected abstract itemId(item: ITEM): ID;
	protected abstract fetchAll(): Promise<ITEM[]>;

	public async getId(id: ID): Promise<ITEM> {
		await this.ensureLoad();
		return this.itemsById.get(id);
	}

	public async getIds(idList: ID[]): Promise<ITEM[]> {
		await this.ensureLoad();
		return idList
			.map(id => this.itemsById.get(id))
			.filter(i => i != null);
	}

	public async getAll(): Promise<ITEM[]> {
		await this.ensureLoad();
		return Array.from(this.itemsById.values());
	}

	public async getFilter(filter: (item: ITEM) => boolean): Promise<ITEM[]> {
		await this.ensureLoad();
		return Array.from(this.itemsById.values()).filter(filter);
	}

	public async getMap(): Promise<Map<ID, ITEM>> {
		await this.ensureLoad();
		return new Map(this.itemsById);
	}

	public update(item: ITEM): void {
		this.itemsById.set(this.itemId(item), item);
		this.emit("put", item);
	}

	public remove(id: ID): void {
		this.itemsById.delete(id);
		this.emit("delete", id);
	}

	public flush(): void {
		this.load = null;
		this.itemsById = null;
	}

	public async ensureLoad(): Promise<void> {
		if (this.load == null) {
			this.load = this.fetchAll()
				.then(data => {
					this.itemsById = new Map(
						data.map(i => [ this.itemId(i), i ])
					);
				})
				.then(() => {
					this.emit("reload");
				});
		}

		await this.load;
	}
}
