import { FpSetting, FpSettingsClient, SectionTree } from "@tcs-rliess/fp-core";
import EventEmitter from "events";

import { FleetplanApp } from "../FleetplanApp";

export class FpSettingsClientWeb extends FpSettingsClient {
	protected app: FleetplanApp;
	constructor(params: (ConstructorParameters<typeof FpSettingsClient>[0] & { app: FleetplanApp })) {
		super(params);
		this.app = params.app;
	}
	public emitter = new EventEmitter();
	public async delete(id: number, _r: string): Promise<void> {
		const node = this.sectionTree.findKey(id);
		await super.delete(id, _r);
		this.emitter.emit("delete", id);
		this.emitter.emit(`${node.data.section}:delete`, id);
	}

	public async insert<T>(section: string, data: T, options?: Parameters<FpSettingsClient["insert"]>[2]): Promise<FpSetting<T>[]> {
		const inserted = await super.insert(section, data, options);
		this.emitter.emit("insert", inserted);
		this.emitter.emit(`${section}:insert`, inserted);
		return inserted;
	}

	public async fetchSection(section: string): Promise<SectionTree> {
		const response = await super.fetchSection(section);
		const userTree = new Set(this.app.store.fpDir.directory.getUserTree().toFlatArray().map(e => e.id));
		response.settings = response.settings.filter(e => {
			if(e.data.directory_id && e.data.trees_item_id) {
				// check if included
				return userTree.has(e.data.trees_item_id);
			}
			return true;
		});
		return response;
	}

	public async update<T>(data: FpSetting<T>): Promise<FpSetting<T>> {
		const resp = await super.update(data);
		this.emitter.emit("update", resp);
		this.emitter.emit(`${data.section}:update`, resp);
		return resp;
	}

	public subscribeSection<T>(section: string, cb: (eventName: string, data: any) => void) {
		function onDelete(id: number) {
			cb("delete", id);
		}
		function onInsert(data: FpSetting<T>[]) {
			cb("insert", data);
		}
		function onUpdate(data: FpSetting<T>) {
			cb("update", data);
		}
		this.emitter.addListener(`${section}:delete`, onDelete);
		this.emitter.addListener(`${section}:insert`, onInsert);
		this.emitter.addListener(`${section}:update`, onUpdate);

		return () => {
			this.emitter.removeListener(`${section}:delete`, onDelete);
			this.emitter.removeListener(`${section}:insert`, onInsert);
			this.emitter.removeListener(`${section}:update`, onUpdate);
		};
	}
}
