import React from "react";
export function useMatchMedia(query) {
    const [matches, setMatches] = React.useState(() => window.matchMedia(query).matches);
    React.useEffect(() => {
        const mediaListener = window.matchMedia(query);
        const handler = () => setMatches(mediaListener.matches);
        if (mediaListener.addEventListener) {
            mediaListener.addEventListener("change", handler);
            return () => mediaListener.removeEventListener("change", handler);
        }
        else {
            // older browsers (like old safari) don't support addEventListener/removeEventListener
            // https://tcs.myjetbrains.com/youtrack/issue/FP-10162
            // https://tcs.myjetbrains.com/youtrack/issue/FP-10197
            mediaListener.addListener(handler);
            return () => mediaListener.removeListener(handler);
        }
    }, [query]);
    return matches;
}
