var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { defaults } from "lodash";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import styled, { css } from "styled-components";
import { CommandBar } from "../CommandBar/CommandBar";
import { ContentRowStateCtx } from "../Content/Content";
import { Flex, FlexItem } from "../Flex";
export class PanelState {
    constructor(collapseable, open, panelKey) {
        this.collapseable = collapseable;
        this.isOpen = true;
        this.key = panelKey || Symbol();
        this.isOpen = open;
    }
    setOpen(state) {
        this.isOpen = state;
    }
}
__decorate([
    observable
], PanelState.prototype, "isOpen", void 0);
__decorate([
    action
], PanelState.prototype, "setOpen", null);
export const PanelStateCtx = React.createContext(null);
export const BasePanel = observer(React.forwardRef(function BasePanel(props, ref) {
    const [state] = React.useState(() => new PanelState(props.collapseable, props.open, props.panelKey));
    const groupCtx = React.useContext(AccordionStateCtx);
    const contentCtx = React.useContext(ContentRowStateCtx);
    React.useEffect(() => {
        if (!groupCtx)
            return;
        if (groupCtx.accordion) {
            if (groupCtx.open === state.key)
                state.setOpen(true);
            else
                state.setOpen(false);
        }
    }, [groupCtx?.open]);
    React.useEffect(() => {
        if (!groupCtx?.accordion || !groupCtx)
            state.setOpen(props.open);
    }, [props.open, state]);
    React.useImperativeHandle(ref, () => ({
        state: state,
    }));
    const styles = defaults(props.style, { height: contentCtx?.options?.fill ? "100%" : undefined });
    return React.createElement(PanelStateCtx.Provider, { value: state },
        React.createElement(PanelWrapper, { column: true, style: styles, noBorder: props.noBorder || !!contentCtx, className: classNames(props.className, "panel-wrapper") }, props.children));
}));
BasePanel.defaultProps = {
    open: true,
};
export const PanelBody = observer((props) => {
    const ctx = React.useContext(PanelStateCtx);
    const contentCtx = React.useContext(ContentRowStateCtx);
    const open = ctx?.isOpen ?? props.open;
    return React.createElement(StyledPanelBody, { grow: contentCtx?.options?.fill, className: props.className, style: props.style, clearPadding: props.clearPadding, ...props, open: open }, open && React.createElement(React.Fragment, null, props.children));
});
/**
 * icon can be string.
 * Reason for this is simply, because it may happen, that a className is required as an icon
 * @returns
 */
export const PanelTitle = (props) => {
    return React.createElement(PanelToggle, null,
        React.createElement(StyledPanelTitle, { className: props.className, style: props.style ?? {} },
            props.icon && React.createElement(React.Fragment, null,
                typeof props.icon === "string" ? React.createElement("i", { className: props.icon }) : React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.icon }),
                "\u00A0"),
            props.children));
};
export const PanelToggle = (props) => {
    const ctx = React.useContext(PanelStateCtx);
    const groupCtx = React.useContext(AccordionStateCtx);
    return React.createElement("div", { onClick: (e) => {
            if (groupCtx?.accordion) {
                if (ctx.key === groupCtx.open)
                    groupCtx.setOpen(null);
                else
                    groupCtx.setOpen(ctx.key);
            }
            else {
                if (ctx.collapseable)
                    ctx.setOpen(!ctx.isOpen);
            }
            props.onClick?.(e);
        }, className: props.className, style: {
            cursor: "pointer",
            ...(props.style ?? {}),
        } }, typeof props.children === "object" ? props.children : typeof props.children === "function" ? props.children(groupCtx, ctx) : null);
};
export const PanelHeading = observer((props) => {
    const ctx = React.useContext(PanelStateCtx);
    return React.createElement(StyledPanelHeading, { open: ctx.isOpen, className: props.className, style: props.style ?? {} }, props.children);
});
export const StyledPanelBody = styled(FlexItem) `
background-color: ${props => props.theme.panel.background};
padding: ${props => props.clearPadding ? "0px" : "15px"};
transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, height 2s ease-in-out;
opacity: ${props => props.open ? "1" : "0"};
max-height: ${props => props.open ? "100%" : "0"};
height: ${props => props.open ? "100%" : "0"};
${props => props.open ? "" : "padding: 0;"};
`;
export const StyledPanelHeading = styled(CommandBar) `
background-color:  ${props => props.theme.panel.background};
`;
export const StyledPanelTitle = styled.h3 `
font-weight: 500;
font-size: 16px;
color: #585f69;
margin: 0;
`;
export const PanelWrapper = styled(Flex) `

${props => props.noBorder ? "" : css `border: 1px solid ${props.theme.panel.border};`}
`;
export class AccordionState {
    constructor(accordion, defaultOpen) {
        this.accordion = accordion;
        this.open = defaultOpen ?? null;
    }
    setOpen(open) {
        this.open = open;
    }
}
__decorate([
    observable
], AccordionState.prototype, "open", void 0);
__decorate([
    observable
], AccordionState.prototype, "accordion", void 0);
__decorate([
    action
], AccordionState.prototype, "setOpen", null);
export const AccordionStateCtx = React.createContext(null);
export const AccordionGroup = (props) => {
    const [state] = React.useState(() => new AccordionState(props.accordion, props.defaultOpen));
    return React.createElement(AccordionStateCtx.Provider, { value: state },
        React.createElement(StyledGroup, { hideSideBorder: props.hideSideBorder, style: props.style }, props.children));
};
export const StyledGroup = styled.div `
	display: flex;
	flex-direction: column;
	.panel-wrapper:not(:last-child) {
		border-bottom: none;
	}

	.panel-wrapper {
		${props => {
    if (props.hideSideBorder) {
        return css `
					border-left: none;
					border-right: none;
				`;
    }
    return "";
}}
	}

`;
const Elevation = styled.div `
box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
border: 1px solid #e4e4e4;
${e => e.defaultPadding ? css `padding: .5rem;` : ""}
`;
const Heading = Object.assign(PanelHeading, {
    Button: CommandBar.Button,
    Input: CommandBar.Input,
    Action: CommandBar.Action,
    Add: CommandBar.Add,
    Dropdown: CommandBar.Dropdown,
    Left: CommandBar.Left,
    Right: CommandBar.Right,
    Title: CommandBar.Title
});
export const Panel = Object.assign(BasePanel, {
    Elevation: Elevation,
    Body: PanelBody,
    Heading: Heading,
    /** @deprecated Use Heading.Title instead! */
    Title: CommandBar.Title,
    Group: AccordionGroup,
    Toggle: PanelToggle,
});
