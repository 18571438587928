import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreSingle } from "../BaseStoreSingle";

export interface PartialQualityEvent {
	id: number;
	runningNo: string;
}

export class PartialQualityEventStore extends BaseStoreSingle<PartialQualityEvent, number> {
	protected itemId(item: PartialQualityEvent): number {
		return item.id;
	}

	protected fetchIdList(idList: number[]): Promise<PartialQualityEvent[]> {
		return Promise.all(
			idList.map(id => {
				return apiManager
					.getService(FpApi.Quality.Event.EventService)
					.getId(this.app.ctx, { id })
					.then(response => {
						return {
							id: response.id,
							runningNo: response.runningNo,
						};
					});
			})
		);
	}
}
