import { ApiContext, AuthenticationApi as IAuthenticationApi } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class AuthenticationApi implements IAuthenticationApi {
	public async resetTotp(ctx: ApiContext, params: { dsuid: number }): Promise<void> {
		return ApiUtil.fetchRpc(ctx, "security.authentication.resetTotp", params);
	}

	public async getTotpToken(ctx: ApiContext, params: { dsuid: number }): Promise<string> {
		return ApiUtil.fetchRpc(ctx, "security.authentication.getTotpToken", params);
	}
}
