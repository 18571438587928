import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../BaseStoreMultiSync";
import { flatten } from "lodash-es";


export class TaskTemplateStore extends BaseStoreMultiSync<FpApi.Task.TaskTemplate, number> {
	protected lookup: Map<string, Map<string, FpApi.Task.TaskTemplate[]>> = new Map<string, Map<string, FpApi.Task.TaskTemplate[]>>();
	protected itemId(item: FpApi.Task.TaskTemplate): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Task.TaskTemplate[]> {
		return apiManager
			.getService(FpApi.Task.TaskTemplateService)
			.get(this.app.ctx, {});
	}

	public update(item: FpApi.Task.TaskTemplate): void {
		super.update(item);
		this.buildLookup();
		this.emit(`put:${item.linkType}:${item.linkId}`, item);
	}

	public remove(id: number): void {
		const item = this.getId(id);
		super.remove(id);
		this.emit(`delete:${item.linkType}:${item.linkId}`);
		this.buildLookup();
	}

	public async ensureLoad(): Promise<void> {
		await super.ensureLoad();
		this.buildLookup();
	}

	protected buildLookup(): void {
		const templates = this.getAll();
		// create a lookup map for each type
		const lookup = new Map<string, Map<string, FpApi.Task.TaskTemplate[]>>();
		for(const template of templates) {

			const links = [{
				linkType: template.linkType,
				linkId: template.linkId,
			}];

			for(const relation of template.relations ?? []) {
				if(relation.category === "MULTI_LINK")  {
					links.push({
						linkType: relation.type,
						linkId: relation[relation.type],
					});
				}
			}
			for(const link of links) {
				let map = lookup.get(link.linkType);
				if(map == null) {
					map = new Map<string, FpApi.Task.TaskTemplate[]>();
					lookup.set(link.linkType, map);
				}
				let list = map.get(link.linkId);
				if(list == null) {
					list = [];
					map.set(link.linkId ?? "", list);
				}
				list.push(template);
			}
		}
		this.lookup = lookup;
	}

	public getTemplates(linkType?: string, linkId?: string): FpApi.Task.TaskTemplate[] {
		if(!linkType) return this.getAll();
		const templates: FpApi.Task.TaskTemplate[] = [];
		const lookup = this.lookup.get(linkType);
		if(linkId) {
			templates.push(...(lookup?.get(linkId) ?? []));
			templates.push(...(lookup?.get("") ?? []));
		}
		else
			templates.push(...(flatten(lookup ? Array.from(lookup?.values()) : []) ?? []));

		return templates;
	}
}
