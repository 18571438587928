import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { TabStateCtx } from "./lib";
import { DropdownParent } from "./TabDropdown";
export const TabPanel = observer(function TabPanel(props) {
    const ctx = React.useContext(TabStateCtx);
    const [mounted, setMounted] = React.useState(false);
    const [remount, setRemount] = React.useState(false);
    const initial = React.useMemo(() => props.mountOnEnter != null ? (props.mountOnEnter) ? "mountOnEnter" : "mountAll" : ctx.initial, []);
    const tabLeave = React.useMemo(() => props.unmountOnLeave != null ? (props.unmountOnLeave) ? "unmount" : "keep" : ctx.tabLeave, []);
    const dropDownCtx = React.useContext(DropdownParent);
    const triggerMount = React.useCallback(() => {
        props.onMount?.(props.tabKey);
    }, []);
    React.useEffect(() => {
        if (remount) {
            setRemount(false);
            triggerMount();
        }
    }, [remount]);
    React.useEffect(() => {
        if (mounted) {
            triggerMount();
        }
    }, [mounted]);
    React.useEffect(() => {
        if (ctx.activePanel === props.tabKey) {
            setMounted(true);
        }
        ctx.registerPanel(props.tabKey, props.title, { hasDropdownParent: dropDownCtx ? true : false, dropdown: false, props: { color: props.color } });
        return () => ctx.unregisterPanel(props.tabKey);
    }, []);
    React.useEffect(() => {
        if (dropDownCtx) {
            ctx.getState(dropDownCtx)?.updateChild(props);
        }
    }, [props, dropDownCtx, ctx.getState(dropDownCtx)]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setColor(props.color);
    }, [props.color]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setDisabled(props.isDisabled);
    }, [props.isDisabled]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setHidden(props.isHidden ?? false);
    }, [props.isHidden]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setHighlight(props.highlight);
    }, [props.highlight]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setOnTabClick(props.onTabClick);
    }, [props.onTabClick]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setButton(props.isButton ?? false);
    }, [props.isButton]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setHideInMore(props.hideInMore ?? false);
    }, [props.hideInMore]);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setUnmount(() => setMounted(false));
    }, []);
    React.useEffect(() => {
        ctx.getState(props.tabKey).setRemount(() => setRemount(true));
    }, []);
    React.useEffect(() => {
        ctx.getState(props.tabKey)?.setProps(props);
    }, [props]);
    React.useEffect(() => {
        if (initial === "mountOnEnter") {
            if (ctx.activePanel === props.tabKey) {
                setMounted(true);
            }
            if (tabLeave === "unmount" && ctx.activePanel !== props.tabKey) {
                setMounted(false);
            }
        }
    }, [ctx.activePanel]);
    React.useEffect(() => {
        if (ctx.getState(props.tabKey)) {
            ctx.getState(props.tabKey).setTitle(props.title);
        }
    }, [props.title]);
    React.useEffect(() => {
        return () => {
            ctx.unregisterPanel(props.tabKey);
            if (dropDownCtx) {
                ctx.getState(dropDownCtx)?.forgetChild(props.tabKey);
            }
        };
    }, []);
    if (props.isHidden)
        return null;
    if (remount)
        return null;
    if (initial === "mountAll") {
        // alert(1);
        return React.createElement("div", { className: "internal-tab", style: { display: ctx.activePanel !== props.tabKey ? "none" : undefined, ...(props.style ?? {}) } },
            props.innerTitle && React.createElement(InnerTitle, null, props.innerTitle),
            props.children);
    }
    if (initial === "mountOnEnter") {
        if (mounted) {
            // alert(2);
            return React.createElement("div", { className: "internal-tab", style: { display: ctx.activePanel !== props.tabKey ? "none" : undefined, ...(props.style ?? {}) } },
                props.innerTitle && React.createElement(InnerTitle, null, props.innerTitle),
                props.children);
        }
    }
    // alert(4);
    return null;
});
const InnerTitle = styled.h5 `
    border-bottom: 1px solid ${e => e.theme.panel.border};
    margin: 0px;
    padding: 8px;
	text-transform: capitalize;
	font-variant: petite-caps;
`;
