import { defer } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { Menu } from "../Menu";
// see https://stackoverflow.com/a/4770179 for scroll disable
const WHEEL_EVENT = (global.document != null && "onwheel" in global.document.createElement("div")) ? "wheel" : "mousewheel";
const WHEEL_LISTENER = function WHEEL_LISTENER(e) {
    e.preventDefault();
};
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
export function useContextMenu() {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const [position, setPosition] = React.useState([0, 0]);
    const CLICK_LISTENER = React.useCallback((e) => {
        e.preventDefault();
        setOpen(false);
        window.removeEventListener(WHEEL_EVENT, WHEEL_LISTENER);
    }, []);
    const handler = function (event) {
        document.removeEventListener("click", CLICK_LISTENER);
        // disable browser context menu, unless user holds "ctrl"
        if (event.ctrlKey)
            return;
        event.preventDefault();
        // disable scrolling
        window.addEventListener(WHEEL_EVENT, WHEEL_LISTENER, { passive: false });
        // hock up close listener
        defer(() => {
            document.addEventListener("click", CLICK_LISTENER, { once: true });
        });
        setPosition([event.pageY, event.pageX]);
        setOpen(true);
    };
    return {
        open: open,
        position: position,
        data: data,
        handlerWithData: function (newData) {
            return (event) => {
                setData(newData);
                handler(event);
            };
        },
        handler: handler,
    };
}
/**
 * @deprecated use normal dropdowns or ag grid context menu
 */
export const ContextMenu = function ContextMenu(props) {
    const { hook, children } = props;
    const ref = React.useRef();
    const [[x, y], setXY] = React.useState([hook.position[0], hook.position[1]]);
    React.useEffect(() => {
        if (ref.current) {
            // check if the menu is out of bounds
            const totalHeight = hook.position[0] + ref.current.clientHeight;
            // if it is, move it back to the top
            if (totalHeight > window.innerHeight) {
                setXY([hook.position[0] - (totalHeight - window.innerHeight) - 5, hook.position[1]]);
                return;
            }
        }
        setXY([hook.position[0], hook.position[1]]);
    }, [ref.current, hook.position]);
    if (hook.open === false)
        return null;
    const content = React.createElement(Menu, { style: {
            position: "absolute",
            top: x,
            left: y,
            zIndex: 10000000,
        }, ref: ref }, typeof children === "function" ? children(hook.data) : children);
    return ReactDOM.createPortal(content, document.body);
};
