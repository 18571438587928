import { noop } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useMatchMedia } from "../../lib";
import { DockedManagerCtx, DockedManagerState } from "./DockedManagerState";
import { PanelButton } from "./PanelButton";
const Grid = styled.div `
	height: 100%;
	width: 100%;
	position: relative;
`;
const DragHandle = styled.div `
	position: absolute;
	border: 3px solid transparent;
	z-index: 1000;
	transition: border-color .2s 0s;
	
	&:hover {
		transition: border-color .2s .5s;
		border-color: ${p => p.theme.color.primary[500].main};
	}
`;
const Bar = styled.div `
	background: ${p => p.theme.background};
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	user-select: none;
`;
const TopBar = styled(Bar) `
	border-bottom: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const RightBar = styled(Bar) `
	border-left: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const BottomBar = styled(Bar) `
	border-top: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const LeftBar = styled(Bar) `
	border-right: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const Rotate = styled.div `
	transform-origin: top left;
	transform: rotate(90deg) translateY(-30px);
	white-space: nowrap;

	@media (pointer: coarse) {
		transform: rotate(90deg) translateY(-40px);
	}
`;
const Panel = styled.div `
	background: ${p => p.theme.background};
	position: absolute;
	z-index: 3;
`;
const TopPanel = styled(Panel) `
	border-bottom: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const RightPanel = styled(Panel) `
	border-left: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const BottomPanel = styled(Panel) `
	border-top: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const LeftPanel = styled(Panel) `
	border-right: 1px solid ${p => p.theme.dockedManager.borderColor};
`;
const Content = styled.div `
	overflow: auto;
	position: absolute;
`;
// function withDockedManager<T>(Component: React.FC<T>): React.FC<T> {
// 	// eslint-disable-next-line react/display-name
// 	return (props) => {
// 		return <DockedManager>
// 			<Component {...props}/>
// 		</DockedManager>;
// 	};
// }
export const DockedManager = observer(function DockedManager(props) {
    const parentState = React.useContext(DockedManagerCtx);
    // this defines the one breakpoint the docked manager has
    const isMd = useMatchMedia("(min-width: 750px)");
    const isLg = useMatchMedia("(min-width: 1200px)");
    const size = isLg ? "lg" : isMd ? "md" : "sm";
    React.useEffect(() => { state.size = size; }, [size]);
    const [state] = React.useState(() => new DockedManagerState(size));
    const theme = useTheme();
    const barSize = useMatchMedia("(pointer: coarse)") ? 41 : 31;
    // drag resize logic
    const gridRef = state.gridRef = React.useRef();
    const onMouseMove = React.useCallback((ev) => {
        if (state.resize == null)
            return;
        state.updateMousePosition(ev);
    }, []);
    // already inside a docked manager
    if (parentState)
        return React.createElement(React.Fragment, null, props.children);
    const allPanels = state.panelsByPosition();
    const openPanels = {
        "top": state.panels.get(state.open.top),
        "right": state.panels.get(state.open.right),
        "bottom": state.panels.get(state.open.bottom),
        "left": state.panels.get(state.open.left),
    };
    // determine which bars to show
    const bars = [];
    if (allPanels.top.length === 1 && allPanels.top[0].key === openPanels.top?.key)
        noop();
    else if (allPanels.top.length > 0)
        bars.push("top");
    if (allPanels.right.length === 1 && allPanels.right[0].key === openPanels.right?.key)
        noop();
    else if (allPanels.right.length > 0)
        bars.push("right");
    if (allPanels.bottom.length === 1 && allPanels.bottom[0].key === openPanels.bottom?.key)
        noop();
    else if (allPanels.bottom.length > 0)
        bars.push("bottom");
    if (allPanels.left.length === 1 && allPanels.left[0].key === openPanels.left?.key)
        noop();
    else if (allPanels.left.length > 0)
        bars.push("left");
    const columns = [
        /* left panel   */ ((state.isMd || state.isLg) && openPanels.left != null && state.floating.left === false) ? openPanels.left.size : 0,
        /* left bar     */ bars.includes("left") ? barSize : 0,
        /* right bar    */ bars.includes("right") ? barSize : 0,
        /* right panel  */ ((state.isMd || state.isLg) && openPanels.right != null && state.floating.right === false) ? openPanels.right.size : 0,
    ];
    const rows = [
        /* top panel    */ ((state.isMd || state.isLg) && openPanels.top != null && state.floating.top === false) ? openPanels.top.size : 0,
        /* top bar      */ bars.includes("top") ? barSize : 0,
        /* bottom bar   */ bars.includes("bottom") ? barSize : 0,
        /* bottom panel */ ((state.isMd || state.isLg) && openPanels.bottom != null && state.floating.bottom === false) ? openPanels.bottom.size : 0,
    ];
    return React.createElement(DockedManagerCtx.Provider, { value: state },
        React.createElement(Grid, { ref: gridRef, onMouseMove: onMouseMove, onMouseUp: state.stopResize, onMouseLeave: state.cancelResize, style: {
                gridTemplateColumns: columns.join(" "),
                gridTemplateRows: rows.join(" "),
            } },
            bars.includes("top") ? (React.createElement(TopBar, { style: {
                    top: rows[0],
                    right: columns[2] + columns[3],
                    left: columns[0] + columns[1],
                    height: rows[1],
                } }, allPanels.top.map(info => (React.createElement(PanelButton, { key: info.uid, style: state.open["top"] === info.key ? { background: theme.color.primary[500].main, color: "#fff" } : {}, onClick: () => state.toggleOpen("top", info.key) }, info.label))))) : null,
            bars.includes("right") && React.createElement(RightBar, { style: {
                    top: 0,
                    right: columns[3],
                    bottom: 0,
                    width: columns[2],
                    transition: "all 0.2s ease",
                } },
                React.createElement(Rotate, null, allPanels.right.map(info => (React.createElement(PanelButton, { key: info.uid, style: state.open["right"] === info.key ? { background: theme.color.primary[500].main, color: "#fff" } : {}, onClick: () => state.toggleOpen("right", info.key) }, info.label))))),
            bars.includes("bottom") ? (React.createElement(BottomBar, { style: {
                    right: columns[2] + columns[3],
                    bottom: rows[3],
                    left: columns[0] + columns[1],
                    height: rows[2],
                } }, allPanels.bottom.map(info => (React.createElement(PanelButton, { key: info.uid, style: state.open["bottom"] === info.key ? { background: theme.color.primary[500].main, color: "#fff" } : {}, onClick: () => state.toggleOpen("bottom", info.key) }, info.label))))) : null,
            bars.includes("left") ? (React.createElement(LeftBar, { style: {
                    top: 0,
                    bottom: 0,
                    left: columns[0],
                    width: columns[1],
                } },
                React.createElement(Rotate, null, allPanels.left.map(info => (React.createElement(PanelButton, { key: info.uid, style: state.open["left"] === info.key ? { background: theme.color.primary[500].main, color: "#fff", transform: "rotate(180deg)" } : { transform: "rotate(180deg)" }, onClick: () => state.toggleOpen("left", info.key) }, info.label)))))) : null,
            React.createElement(TopPanel, { ref: ref => state.setElement("top", ref), style: {
                    display: openPanels.top == null ? "none" : undefined,
                    top: 0,
                    right: columns[2] + columns[3],
                    left: columns[0] + columns[1],
                    height: state.isSm ? `calc(100% - ${rows[1] + rows[2] - 1}px)` : openPanels?.top?.size ?? rows[0],
                } }),
            React.createElement(RightPanel, { style: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    transition: "width .2s ease",
                    zIndex: 4,
                    width: openPanels.right == null ? 0 : state.isSm ? `calc(100% - ${columns[0] + columns[3] - 1}px)` : openPanels?.right?.size ?? columns[3],
                } },
                React.createElement("div", { style: {
                        display: openPanels.right == null ? "none" : undefined,
                        height: "100%",
                    }, ref: ref => {
                        state.setElement("right", ref);
                    } })),
            React.createElement(BottomPanel, { ref: ref => state.setElement("bottom", ref), style: {
                    display: openPanels.bottom == null ? "none" : undefined,
                    right: columns[2] + columns[3],
                    bottom: 0,
                    left: columns[0] + columns[1],
                    height: state.isSm ? `calc(100% - ${rows[0] + rows[3] - 1}px)` : openPanels?.bottom?.size ?? rows[3],
                } }),
            React.createElement(LeftPanel, { ref: ref => state.setElement("left", ref), style: {
                    display: openPanels.left == null ? "none" : undefined,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 4,
                    width: state.isSm ? `calc(100% - ${columns[0] + columns[3] - 1}px)` : openPanels?.left?.size ?? columns[0],
                } }),
            React.createElement(DragHandle, { onMouseDown: state.startResizeTop, style: {
                    display: (state.isSm || openPanels.top == null) ? "none" : undefined,
                    cursor: "ns-resize",
                    top: (state.resize === "top" ? state.mousePosition.y : (rows[0] + (openPanels?.top?.size ?? rows[0]))) - 3,
                    right: columns[2] + columns[3],
                    left: columns[0] + columns[1],
                    borderColor: state.resize === "top" ? theme.color.primary[500].main : undefined,
                } }),
            React.createElement(DragHandle, { onMouseDown: state.startResizeRight, style: {
                    display: (state.isSm || openPanels.right == null) ? "none" : undefined,
                    cursor: "ew-resize",
                    top: 0,
                    right: (state.resize === "right" ? (gridRef.current?.offsetWidth - state.mousePosition.x) : (columns[2] + (openPanels?.right?.size ?? columns[3]))) - 3,
                    bottom: 0,
                    borderColor: state.resize === "right" ? theme.color.primary[500].main : undefined,
                } }),
            React.createElement(DragHandle, { onMouseDown: state.startResizeBottom, style: {
                    display: (state.isSm || openPanels.bottom == null) ? "none" : undefined,
                    cursor: "ns-resize",
                    right: columns[2] + columns[3],
                    bottom: (state.resize === "bottom" ? (gridRef.current?.offsetHeight - state.mousePosition.y) : (rows[2] + (openPanels?.bottom?.size ?? rows[3]))) - 3,
                    left: columns[0] + columns[1],
                    borderColor: state.resize === "bottom" ? theme.color.primary[500].main : undefined,
                } }),
            React.createElement(DragHandle, { onMouseDown: state.startResizeLeft, style: {
                    display: (state.isSm || openPanels.left == null) ? "none" : undefined,
                    cursor: "ew-resize",
                    top: 0,
                    bottom: 0,
                    left: (state.resize === "left" ? state.mousePosition.x : ((openPanels?.left?.size ?? columns[0]) + columns[1])) - 3,
                    borderColor: state.resize === "left" ? theme.color.primary[500].main : undefined,
                } }),
            React.createElement(Content, { style: {
                    top: rows[0] + rows[1],
                    right: columns[2] + columns[3],
                    bottom: rows[2] + rows[3],
                    left: columns[0] + columns[1],
                } }, props.children)));
});
