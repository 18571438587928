import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class AircraftModelStore extends BaseStoreMultiSync<FpApi.Resource.AircraftModel, string> {
	protected itemId(item: FpApi.Resource.AircraftModel): string {
		return item.fpdbvmid;
	}

	protected fetchAll(): Promise<FpApi.Resource.AircraftModel[]> {
		return apiManager
			.getService(FpApi.Resource.AircraftModelService)
			.get(this.ctx, { getAll: true });
	}
}
