import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
/** @deprecated use ag-grid */
export const Cell = styled.div `
	padding: ${p => p.theme.table.padding.md};
	vertical-align: top;
	border-right: 1px solid ${p => p.theme.table.borderColor};
	border-bottom: 1px solid ${p => p.theme.table.borderColor};
	background: ${p => p.theme.background};
	flex: 1 1 100px;
	overflow-x: hidden;
	text-overflow: ellipsis;
`;
/** @deprecated use ag-grid */
export const HeaderCell = styled(Cell) `
	font-weight: bold;
`;
/** @deprecated use ag-grid */
const Row = styled.div `
	width: 100%;
	display: flex;
	flex-direction: row;

	${Cell}:last-child {
		border-right: none;
	}
`;
/** @deprecated use ag-grid */
const Table = styled.div `
	width: 100%;
	${p => p.noBorder ? "" : css `border: 1px solid ${p => p.theme.table.borderColor};`}
	background: ${p => p.theme.background};
	overflow-y: auto;
	max-height: 100vh;

	${Row}:last-child > ${Cell} {
		border-bottom: none;
	}
`;
/** @deprecated use ag-grid */
const TableHeader = styled.div `
	position: sticky;
	top: 0;
	z-index: 1;
	background: ${p => p.theme.background};
	
	& > ${Row} > ${Cell} {
		vertical-align: bottom;
	}

	& > ${Row}:last-child > ${Cell} {
		border-bottom: 2px solid ${p => p.theme.table.borderColor};
	}
`;
/** @deprecated use ag-grid */
const TableBody = styled.div ``;
/** @deprecated use ag-grid */
export const DataTable = function DataTable(props) {
    const activeColumns = props.view.columns.map(key => ({ key, ...props.columns[key] }));
    // check props
    if (props.rowData == null && props.getRow == null)
        throw new Error("DataTable: requires either rowData or getRow!");
    if (props.rowData != null && props.getRow != null)
        throw new Error("DataTable: only set rowData or getRow!");
    if (props.getRow !== undefined && props.totalRows == null)
        throw new Error("DataTable: getRow requires totalsRows!");
    const totalRows = props.totalRows ?? props.rowData.length;
    const getRow = props.getRow ?? ((i) => props.rowData[i]);
    const checkboxes = false;
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { noBorder: props.noBorder, style: props.style, className: props.className },
            React.createElement(TableHeader, null,
                React.createElement(Row, { style: { position: "sticky", top: 0 } },
                    checkboxes && (React.createElement(HeaderCell, { style: {
                            flex: "0 0 30px",
                            position: "sticky",
                            left: 0,
                        } },
                        React.createElement("input", { type: "checkbox" }))),
                    props.rowMenu != null && (React.createElement(HeaderCell, { style: {
                            flex: "0 0 35px",
                            position: "sticky",
                            left: checkboxes ? 30 : 0,
                        } })),
                    activeColumns.map(column => (React.createElement(HeaderCell, { key: column.key, style: {
                            flex: column.width != null ? `0 0 ${column.width}px` : undefined,
                            minWidth: column.minWidth ?? column.width ?? 100,
                        } }, column.heading))))),
            React.createElement(TableBody, null, new Array(totalRows).fill(0).map((_, index) => {
                const rowItem = getRow(index);
                return React.createElement(Row, { key: index, onContextMenu: props.rowMenu?.handlerWithData(rowItem), onClick: event => props.onRowClick?.(rowItem, index, event) },
                    checkboxes && (React.createElement(Cell, { style: {
                            flex: "0 0 30px",
                            position: "sticky",
                            left: 0,
                        } },
                        React.createElement("input", { type: "checkbox" }))),
                    props.rowMenu != null && (React.createElement(Cell, { onClick: props.rowMenu.handlerWithData(rowItem), style: {
                            flex: "0 0 35px",
                            position: "sticky",
                            left: checkboxes ? 30 : 0,
                            cursor: "pointer",
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faEllipsisV, fixedWidth: true }))),
                    activeColumns.map(column => {
                        const Component = column.content;
                        return React.createElement(Component, { key: `${index}-${column.key}`, row: index, isLoading: false, item: rowItem, cellProps: {
                                style: {
                                    flex: column.width != null ? `0 0 ${column.width}px` : undefined,
                                    minWidth: column.minWidth ?? column.width ?? 100,
                                },
                            } });
                    }));
            }))));
};
/** @deprecated use ag-grid */
export const ColumnSimple = function DataTable(props) {
    const value = get(props.item, props.path);
    return React.createElement(Cell, { ...props.cellProps }, value);
};
