import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

export class CalendarStore extends BaseStoreMulti<FpApi.Calendar.Calendar, number> {
	protected itemId(item: FpApi.Calendar.Calendar): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Calendar.Calendar[]> {
		return apiManager
			.getService(FpApi.Calendar.CalendarService)
			.get(this.ctx);
	}
}
