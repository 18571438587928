import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class IdentityProviderStore extends BaseStoreMulti<FpApi.Security.IdentityProvider, string> {
	protected itemId(item: FpApi.Security.IdentityProvider): string {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Security.IdentityProvider[]> {
		return apiManager
			.getService(FpApi.Security.IdentityProviderService)
			.get(this.app.ctx);
	}

	public async getActive(type: FpApi.Security.IdentityProviderType): Promise<FpApi.Security.IdentityProvider> {
		const items = await this.getAll();
		return items.find(p => p.active && p.type === type);
	}
}
