import Lock from "async-lock";
import EventEmitter from "events";
import { uniq } from "lodash-es";

import { FleetplanApp } from "../../FleetplanApp";

/**
 * Async store, loads items on demand
 */
export abstract class BaseStoreSingle<ITEM, ID> extends EventEmitter {
	protected readonly app: FleetplanApp;
	private lock = new Lock();

	private readonly itemsById = new Map<ID, ITEM>();

	constructor(app: FleetplanApp) {
		super();
		this.app = app;
	}

	/** return id of item */
	protected abstract itemId(item: ITEM): ID;
	/** fetches the given id list */
	protected abstract fetchIdList(idList: ID[]): Promise<ITEM[]>;

	/**
	 * Updates the item in the store with new data
	 * @param item new or updated item
	 * @fires put
	 */
	public update(item: ITEM): void {
		this.setItems([ item ]);
	}

	/**
	 * Removes the Item from the store
	 * @param id id of deleted item
	 * @fires delete
	 */
	public remove(id: ID): void {
		this.itemsById.delete(id);
		this.emit("delete", id);
	}

	public async getId(id: ID): Promise<ITEM> {
		const item = this.itemsById.get(id);
		if (item) return item;

		await this.loadIdList([ id ]);
		return this.itemsById.get(id);
	}

	public async getIdList(idList: ID[]): Promise<ITEM[]> {
		// make sure no id is duplicated
		idList = uniq(idList);

		// fetch data
		await this.loadIdList(idList);

		return idList
			.map(id => this.itemsById.get(id))
			.filter(i => i != null);
	}

	private async loadIdList(idList: ID[]): Promise<ITEM[]> {
		if (idList.length === 0) {
			return [];
		}

		return await this.lock.acquire("loadIdList", async () => {
			const missing = idList.filter(id => this.itemsById.has(id) === false);
			if (missing.length === 0) return [];

			const items = await this.fetchIdList(missing);
			if (items == null) return;

			this.setItems(items);

			return items;
		});
	}

	private setItems(items: ITEM[]): void {
		for (const item of items) {
			// just a safety
			if (item == null) continue;

			const itemId = this.itemId(item);

			// update map
			this.itemsById.set(itemId, item);
			this.emit("put", item);
		}
	}
}
