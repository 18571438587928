import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class ReportStore extends BaseStoreMultiSync<FpApi.Quality.Report.Report, number> {
	protected itemId(item: FpApi.Quality.Report.Report): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Quality.Report.Report[]> {
		return apiManager
			.getService(FpApi.Quality.Report.ReportService)
			.get(this.app.ctx);
	}
}
