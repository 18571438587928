import { observer } from "mobx-react";
import React from "react";
import styled, { css } from "styled-components";
import { TabVariant } from "./lib";
const UL = styled.ul `
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
`;
export const TabBar = observer((props) => {
    const version = React.useContext(TabVariant);
    const Bar = React.useMemo(() => {
        return function F(props) {
            return React.createElement(UL, null, props.children);
        };
    }, []);
    return React.createElement(TabBarContainer, { version: version, ref: props.forwardedRef },
        React.createElement(Bar, null, props.children));
});
const TabBarContainer = styled.div `
	height: 40px; // was not here
	${p => {
    switch (p.version) {
        case "default":
            return css `
					border-bottom: 2px solid ${e => e.theme.color.primary[500].main};
				`;
        case "light":
            return css `
					border-bottom: 1px solid #ccc;
				`;
    }
}}
	
`;
