import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../Css";
import { CommandBar } from "../CommandBar/CommandBar";
const ToastTimer = styled.div `
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	background: ${props => props.bg};
	animation: toast-timer ${e => e.duration}s linear;

	@keyframes toast-timer {
		from {
			width: 0%;
		}
		to {
			width: 100%;
		}
	}
`;
const ToastContent = styled.div `
	position: relative;
	width: 270px;
	background: white;

	&:hover ${ToastTimer} {
		animation-play-state: paused;
	}
`;
const InternalToast = (props) => {
    const color = useThemeColor(props.variant);
    return React.createElement(React.Fragment, null,
        React.createElement(ToastContent, { style: { background: color[100].main, border: `1px solid ${color[500].main}` } },
            React.createElement(CommandBar, { noBorder: true, style: { background: color[100].main } },
                props.icon ? (React.createElement(CommandBar.Text, null,
                    React.createElement(FontAwesomeIcon, { icon: props.icon, fixedWidth: true }))) : null,
                React.createElement(CommandBar.Text, null, props.title),
                React.createElement(CommandBar.Right, null,
                    props.buttons,
                    props.dismissible ? (React.createElement(CommandBar.Button, { variant: "danger", onClick: props.onDismiss },
                        React.createElement(FontAwesomeIcon, { icon: faTimes, fixedWidth: true }))) : null)),
            props.body ? (React.createElement("div", { style: { padding: "0 7px 7px 7px" } }, props.body)) : null,
            props.duration ? (React.createElement(ToastTimer, { bg: color[500].main, duration: props.duration, onAnimationEnd: props.onDismiss })) : null));
};
InternalToast.defaultProps = {
    variant: "cancelled",
};
export const Toast = Object.assign(InternalToast, {
    Content: ToastContent,
    Timer: ToastTimer,
});
