import React from "react";

import { useApp } from "../../../FleetplanApp";
import { handleError } from "../../../handleError";

import { ControlledDocumentState, ControlledDocumentStateContext } from "./ControlledDocumentState";

let _state: ControlledDocumentState;

export function useDocumentState(canLoad = true): ControlledDocumentState {
	const app = useApp();
	const state = React.useContext(ControlledDocumentStateContext);

	return React.useMemo(() => {
		if (state) _state = state;
		else if (!_state) {
			_state = app.store.controlleddocument;
		}
		if (!_state.initialLoadComplete && canLoad) _state.init().catch(handleError);

		return _state;
	}, [ app, state ]);
}
