import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React from "react";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../../Css";
import { usePanelKey } from "../../../Layout";
import { Text } from "../../Text";
import { TabDiv, TabStateCtx, TabVariant } from "./lib";
import { TabContainerPropsCtx } from "./TabContainer";
export const Tab = observer(function Tabs(props) {
    const ref = React.useRef(null);
    const containerProps = React.useContext(TabContainerPropsCtx);
    const ctx = React.useContext(TabStateCtx);
    const state = ctx.getState(props.tabKey);
    let variant = React.useContext(TabVariant);
    const dockedKey = usePanelKey();
    const hideOuterBorder = dockedKey == undefined ? props.hideFirstBorder ?? false : true;
    variant = props.variant || variant;
    const colors = useThemeColor(state?.highlight, "#FFF");
    const updateDimensions = () => {
        if (ref.current && ref.current?.offsetParent) {
            setTimeout(() => {
                ctx.getState(props.tabKey)?.setSize(ref.current?.clientWidth);
            });
        }
    };
    React.useEffect(() => {
        if (!state)
            return;
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);
    return React.createElement(TabTitleContainer, { hideOuterBorder: hideOuterBorder, onClick: (e) => {
            if (e.metaKey || e.ctrlKey) {
                state?.remount?.();
            }
            else {
                props.onClick?.(props.tabKey);
            }
        }, active: props.tabKey === ctx.activePanel, ref: ref },
        React.createElement(TabDiv, { title: state?.props?.tooltip ? state?.props?.tooltip : typeof state?.props?.innerTitle === "string" ? state?.props?.innerTitle : undefined, hideFirstBorder: hideOuterBorder, disabled: state?.isDisabled, style: { padding: props.noPadding ? 0 : undefined, position: "relative", ...(props.style ?? {}) }, active: props.tabKey === ctx.activePanel, variant: props.variant, color: state?.color, onClick: () => {
                if (props.preventDefault)
                    return;
                if (state?.isDisabled)
                    return;
                ctx.getState(props.tabKey)?.onClick?.(props.tabKey);
                containerProps.handleTabClick?.(props.tabKey);
                if (ctx.getState(props.tabKey).isButton)
                    return;
                if (containerProps.handleTabClick)
                    return;
                ctx.setActivePanel(props.tabKey);
            } },
            React.createElement(Text, { muted: state?.isDisabled },
                React.createElement("div", { style: { display: "flex", alignItems: "center", height: "100%", width: "100%", } },
                    props?.title,
                    " ",
                    state?.highlight && React.createElement(FontAwesomeIcon, { icon: faCircle, color: colors[500].main, style: { position: "absolute", top: "2px", left: "2px", transform: "scale(0.6)" } })))));
});
const TabTitleContainer = styled.li `
	float: left;
	${(props) => {
    if (props.active) {
        return props.hideOuterBorder ? css `
			:not(:first-child) > div {
				border-left: 1px solid #ccc;
			}
			` : css `
			> div {
				border-left: 1px solid #ccc;
			}`;
    }
    else {
        return css `
				:hover {
					// background-color: #e2e2e2;
					// height: 40px;
				}
			`;
    }
}}
	

`;
