import { ClientCategoryUtil, FpApi } from "@tcs-rliess/fp-core";
import { Text, Flex, Format } from "@tcs-rliess/fp-web-ui";
import * as React from "react";
import styled from "styled-components";
import { DefaultFooter, usePrintContext } from "../../lib";
const PilotsLogbookTable = styled.table `
	border-collapse: collapse;
	width: 100%;
	empty-cells: show;
	font-size: ${props => props.$fontSize ?? "11px"};
	color:  ${props => props.$fontColor ?? "#000000"};
	tr th,
	tr td { 
		padding: ${props => props.$tdPadding ?? "5px"}; 
		border:  ${props => props.$borderWidth ?? "1px"} solid ${props => props.$borderColor ?? "#dddddd"};
	}
	&.stripedColors tbody tr:nth-child(odd){
		background-color: ${props => props.$stripedColor ?? "#f9f9f9"};
	}
`;
export const PilotsLogbook = (props) => {
    const printContext = usePrintContext();
    const data = props.data;
    if (!data)
        return React.createElement(Flex, { justifyContent: "center" }, "Loading...");
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultFooter, null),
        React.createElement("div", { style: { backgroundColor: "white", padding: "0px" } },
            React.createElement(Flex, { justifyContent: "space-between", alignItems: "flex-end", style: { borderBottom: "1px solid black", marginBottom: "20px", paddingBottom: "10px" } },
                React.createElement("div", { style: { flex: "1 1 0" } },
                    React.createElement(Text, { style: { fontSize: "22px" } }, "Crew Experience")),
                React.createElement("div", { style: { flex: "1 1 0", textAlign: "center" } },
                    data.contactName,
                    React.createElement("br", null),
                    React.createElement(Format.Date, null, data.dateStart),
                    " - ",
                    React.createElement(Format.Date, null, data.dateEnd)),
                React.createElement("div", { style: { flex: "1 1 0", textAlign: "right" } },
                    React.createElement("img", { src: printContext?.customer.$dscaid?.avatarTn, style: { maxWidth: "200px" } }))),
            React.createElement(PilotsLogbookTable, { "$tdPadding": "4px", "$fontSize": "inherit", "$borderColor": "#C4CACC", style: { marginBottom: "20px", tableLayout: "fixed" } },
                React.createElement("thead", null,
                    React.createElement("tr", { style: { backgroundColor: "#EEEEEE", textAlign: "center" } },
                        React.createElement("td", { rowSpan: 2 },
                            React.createElement(Text, { bold: true },
                                "TOTAL FLIGHT",
                                React.createElement("br", null),
                                "HOURS")),
                        React.createElement("td", { colSpan: 3 },
                            React.createElement(Text, { bold: true }, "TYPE OF FLIGHTS")),
                        React.createElement("td", { colSpan: 3 },
                            React.createElement(Text, { bold: true }, data.localNdays.dateStart && data.localNdays.dateEnd
                                ? React.createElement(React.Fragment, null,
                                    React.createElement(Format.Date, null, data.localNdays.dateStart),
                                    " - ",
                                    React.createElement(Format.Date, null, data.localNdays.dateEnd))
                                : data.localNdays.dateEnd
                                    ? React.createElement(React.Fragment, null,
                                        "LAST 90 DAYS FROM ",
                                        React.createElement(Format.Date, null, data.localNdays.dateEnd))
                                    : null))),
                    React.createElement("tr", { style: { backgroundColor: "#EEEEEE", textAlign: "center" } },
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "VFR")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "IFR")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "NIGHT")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "LANDINGS")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "FLIGHT TIME")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "NIGHT TIME")))),
                React.createElement("tbody", null,
                    React.createElement("tr", { style: { textAlign: "center" } },
                        React.createElement("td", null,
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.sumTotalFlightTime)),
                        React.createElement("td", null,
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.sumByFlightRule[FpApi.Resource.FlightRule.VFR] ?? 0)),
                        React.createElement("td", null,
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.sumByFlightRule[FpApi.Resource.FlightRule.IFR] ?? 0)),
                        React.createElement("td", null,
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.sumByFlightRule["night"] ?? 0)),
                        React.createElement("td", null, data.localNdays.landings),
                        " ",
                        React.createElement("td", null, data.localNdays.flightTime ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.localNdays.flightTime ?? "") : null),
                        " ",
                        React.createElement("td", null, data.localNdays.nightTime ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.localNdays.nightTime ?? "") : null),
                        " "))),
            Object.entries(data.sumByLicenseEndorsementAndRole).map(([model, records]) => (React.createElement(PilotsLogbookTable, { key: model, className: "stripedColors", "$tdPadding": "4px", "$fontSize": "inherit", "$borderColor": "#C4CACC", "$stripedColor": "#E1E5E8", style: { marginBottom: "20px", width: "57.14%", tableLayout: "fixed" } },
                React.createElement("thead", null,
                    React.createElement("tr", { style: { textAlign: "center", backgroundColor: "#EEEEEE" } },
                        React.createElement("td", { style: { textAlign: "left", textTransform: "uppercase" } },
                            React.createElement(Text, { bold: true }, ClientCategoryUtil.byEnum(FpApi.Resource.AircraftType).getLabel(model))),
                        " ",
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "TOTAL")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "PIC/PICUS")),
                        React.createElement("td", null,
                            React.createElement(Text, { bold: true }, "SIC")))),
                React.createElement("tbody", null,
                    React.createElement("tr", { style: { textAlign: "center" } },
                        React.createElement("td", { style: { textAlign: "left" } },
                            React.createElement(Text, { bold: true }, "ALL")),
                        React.createElement("td", null, records["total"]["total"] ? React.createElement(Text, { bold: true },
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["total"]["total"])) : null),
                        React.createElement("td", null, records["total"]["PIC"] ? React.createElement(Text, { bold: true },
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["total"]["PIC"])) : null),
                        React.createElement("td", null, records["total"]["SIC"] ? React.createElement(Text, { bold: true },
                            React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["total"]["SIC"])) : null)),
                    Object.entries(records).sort(([modelA], [modelB]) => modelA.localeCompare(modelB)).map(([licenseEndorsement, records]) => {
                        if (licenseEndorsement === "total")
                            return null; // handled above
                        return (React.createElement("tr", { key: licenseEndorsement, style: { textAlign: "center" } },
                            React.createElement("td", { style: { textAlign: "left" } }, licenseEndorsement),
                            React.createElement("td", null, records["total"] ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["total"]) : null),
                            React.createElement("td", null, records["PIC"] ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["PIC"]) : null),
                            React.createElement("td", null, records["SIC"] ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, records["SIC"]) : null)));
                    }))))))));
};
