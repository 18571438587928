import { createGlobalStyle } from "styled-components";
// see
// https://github.com/twbs/bootstrap/blob/main/dist/css/bootstrap-reboot.css
export const GlobalCss = createGlobalStyle `
	:root {
		font-size: 11pt;
		line-height: 1.9;
		font-family: 'Roboto', sans-serif;
		color: ${p => p.theme.font};
	}
	
	body {
		margin: 0;
		background: ${p => p.theme.background};
		font-size: 1rem;
		font-weight: 300;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
	}
	td, th {
		padding: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		margin-top: 1rem;
		margin-bottom: .6rem;
		font-weight: bold;
		line-height: 1.2;
	}
	h1 { font-size: 1.80em; }
	h2 { font-size: 1.64em; }
	h3 { font-size: 1.48em; }
	h4 { font-size: 1.32em; }
	h5 { font-size: 1.16em; }
	h6 { font-size: 1.00em; }

	p {
		margin-top: 0;
		margin-bottom: 1rem;

		orphans: 5;
		widows: 5;
	}

	hr {
		margin: 1rem 0;
		color: inherit;
		background-color: currentColor;
		height: 1px;
		border: 0;
	}

	a {
		color: ${p => p.theme.kind === "light" ? "#0d6efd" : "#0d6efd"};
		text-decoration: underline;
	}
	a:hover {
		color: ${p => p.theme.kind === "light" ? "#0a58ca" : "#0a58ca"};
	}
	
	button {
		color: ${p => p.theme.font};
		font-size: 1em;
	}

	@media (pointer: coarse) {
		:root {
			font-size: 13pt;
			line-height: 1.7;
		}
	}

	@media print {
		:root {
			font-size: 10pt;
			line-height: 1.25;
		}
	}
`;
