import React from "react";
import { Button, Format } from "../../../Components";
import { useOutsideAlerter } from "../../../lib";
import { Overlay, Wrapper } from "../util";
import { DatePicker } from "./DatePicker";
export const DatePickerButton = function DatePickerButton(props) {
    const [show, setShow] = React.useState(false);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => { if (show) {
        setShow(false);
        props.onClose?.();
    } });
    return (React.createElement(Wrapper, { ref: wrapperRef, style: props.fullHeight ? { height: "100%" } : undefined },
        show && React.createElement(Overlay, { style: { [props.openLeft ? "right" : "left"]: 0, width: "fit-content" } },
            React.createElement(DatePicker, { value: props.value, onChange: (st) => {
                    props.onChange(st, setShow);
                } })),
        React.createElement(props.component, { ...(props.componentProps ?? {}), [props.clickHandlerPath ?? "onClick"]: () => {
                if (show) {
                    props.onClose?.();
                }
                else {
                    props.onOpen?.();
                }
                setShow(!show);
            } }, props.label ?? React.createElement(Format.Date, null, props.value))));
};
DatePickerButton.defaultProps = {
    component: Button,
};
