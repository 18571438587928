import { IFleetplanApi } from "@tcs-rliess/fp-core";

import { SavedViewApi } from "./calender";
import { ContactAddressApi } from "./contact";
import { DashboardApi } from "./dashboard";
import { GannetApi } from "./external";
import { FlightLogbookTechnicalReportApi } from "./resource";
import { AircraftBookingApi } from "./resource/BookingAircraftApi";
import { AuthenticationApi } from "./security";
import { ExportApi } from "./system";

/**
 * @deprecated
 */
export class FleetplanApi implements IFleetplanApi {
	public calendar = {
		savedView: new SavedViewApi(),
	};
	public contact = {
		contactAddress: new ContactAddressApi()
	};
	public dashboard = {
		dashboard: new DashboardApi(),
	};
	public external = {
		gannet: new GannetApi(),
	};
	public resource = {
		aircraftBooking: new AircraftBookingApi(),
		flightLogbookTechnicalReport: new FlightLogbookTechnicalReportApi(),
	};
	public security = {
		authentication: new AuthenticationApi(),
	};
	public system = {
		export: new ExportApi(),
	};
}
