import { createGlobalStyle } from "styled-components";

export const FleetplanGridTheme = createGlobalStyle`
	.ag-theme-fleetplan {
		--ag-icon-font-color: rgba(0, 0, 0, 0.54);
		.ag-root-wrapper {
			border: none !important;
		}

		.ag-button.ag-standard-button {
			display: inline-block;
			margin-bottom: 0;
			font-weight: 400;
			text-align: center;
			white-space: nowrap;
			vertical-align: middle;
			touch-action: manipulation;
			cursor: pointer;
			user-select: none;
			background-image: none;
			border: 1px solid transparent;
			border-radius: 0px;
			padding: 6px 12px;
		}
		
		.ag-advanced-filter-builder-button {
			padding: 6px 12px;
		}

		.ag-advanced-filter-apply-button, .ag-advanced-filter-builder-apply-button {
			background-color: ${p => p.theme.color.primary[500].main};
			color: ${p => p.theme.color.primary[500].font};
			:hover {
				background-color: ${p => p.theme.color.primary[600].main};
				color: ${p => p.theme.color.primary[500].font};
			}
			:focus {
				background-color: ${p => p.theme.color.primary[600].main};
				color: ${p => p.theme.color.primary[500].font};
			}
			:active {
				background-color: ${p => p.theme.color.primary[700].main};
				color: ${p => p.theme.color.primary[500].font};
			}
		}

		.ag-advanced-filter-builder-cancel-button {
			color: #337ab7;
			background-color: transparent;
			border-color: transparent;
			&:hover {
				color: #23527c;
				background-color: transparent;
				border-color: transparent;
			}
			&:focus {
				color: #23527c;
				background-color: transparent;
				border-color: transparent;
			}
			&:active {
				color: #23527c;
				background-color: transparent;
				border-color: transparent;
			}
		}

		.ag-advanced-filter-builder-button-panel {
			flex-direction: row-reverse;
			justify-content: end;
		}

		.ag-header-row-column-group[aria-rowindex="1"]  .ag-header-group-cell-label  {
			font-weight:normal;
			text-transform:uppercase;
			background-color:#337ab7;
			padding-left:3px;
			color:#ffffff;
			margin: 7px 1px 7px 1px;
		}

		.ag-cell {
			line-height: 24px !important;
		}

		.ag-status-bar-center {
			padding-top: 7px;
			color: #acacac;
			font-weight: normal;
		}

		.ag-status-bar {
			height: 40px !important;
			border-top-color: ${props => props.theme.defaults.borderColor}
		}

		.ag-multi-filter-group-title-bar {
			font-weight: 500 !important;
		}

		.ag-header-row-column-group[aria-rowindex="1"]  .ag-header-group-cell-label .ag-icon {color:#ffffff !important}
		.ag-header-group-text  {padding-right:5px !important;}
		.ag-react-container {
			height: 100% !important;
			width: 100%;
			display: inline;
		}

		.ag-header-cell-label {
			text-transform: uppercase;
		}

		.fp-ag-rowspan-cell {
			background: white;
			border-left: 1px solid lightgrey !important;
			border-right: 1px solid lightgrey !important;
			border-bottom: 1px solid lightgrey !important;
		}

		.fp-ag-rowspan-cell {
			background: white;
			border-left: 1px solid lightgrey !important;
			border-right: 1px solid lightgrey !important;
			border-bottom: 1px solid lightgrey !important;
		}
	}

	.ag-theme-fleetplan-condensed {
		--ag-header-height: 23px !important;
		--ag-font-size: 12px !important;
		--ag-input-border-color: transparent !important;
		--ag-input-disabled-border-color: transparent !important;
		--ag-widget-container-horizontal-padding: 0px !important;
		--ag-widget-container-vertical-padding: 0px !important;
		--ag-widget-horizontal-spacing: 0px !important;
		--ag-widget-vertical-spacing: 0px !important;
		--ag-list-item-height: 20px !important;
		.ag-header-row-column-group[aria-rowindex="1"]  .ag-header-group-cell-label  {
			font-weight:normal !important;
			text-transform:uppercase !important;
			background-color:#337ab7 !important;
			padding-left:3px !important;
			color:#ffffff !important;
			margin: 4px 1px 4px 1px !important;
		}

		.ag-text-field-input:focus {
			outline: transparent !important; 
		}
		.ag-status-bar-left {
			line-height: 0px !important;
		}
		.ag-status-bar {
			height: 19px !important;
		}

		.ag-theme-fleetplan-dark {
			.ag-status-bar-center {
				padding-top: 7px !important;
				color: #4a4a4a !important;
				font-weight: normal !important;
			}
		}
	}
`;
