import { DirectoryNodeData, FpDirNode } from "@tcs-rliess/fp-core";
import { useAsyncMemo } from "@tcs-rliess/fp-web-ui";

import { FleetplanApp, useApp } from "../FleetplanApp";

export async function getUserTz(dscaid: number, app: FleetplanApp): Promise<string> {
	let locationFromHr: FpDirNode<DirectoryNodeData> = null;
	let timezone: string = null;
	const contactHr = await app.store.hrContractStore.getCurrentVersion(dscaid);
	// take first location (positions are sorted in priority, this is why we can do this like that)
	const foundLocation = contactHr?.positions?.find(e => e.loc)?.loc;
	if (foundLocation) {
		locationFromHr = app.store.fpDir.directory.getTree().findKey(foundLocation);
		timezone = locationFromHr?.data?.location?.tz;
	}
	// if we have no timezone from location, take it from user wich views the schedule
	if (!timezone) timezone = app.store.settingsUser.getString("tz");
	return timezone;
}

export function useContactTz(dscaid: number): ReturnType<typeof useAsyncMemo<string>> {
	const app = useApp();
	return useAsyncMemo(async () => {
		return await getUserTz(dscaid, app);
	}, [ dscaid ]);
}
