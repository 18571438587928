import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../Css";
import { Label } from "../Label";
export const TileTitle = styled.div `
	margin: 5px 5px -7px;
`;
export const BaseTile = styled.div `
	background-color: #eee;
	height: 94px;
	margin: 5px;
	display: inline-block;
	width: 160px;
	vertical-align: middle;
`;
export const TileBody = styled.div `
	margin: 0 10px 5px;
	text-align: center;
	font-size: 1.4em;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100px - 22px);
`;
const Title = (props) => {
    if (props.noLabel) {
        return React.createElement(TileTitle, null, props.children);
    }
    return React.createElement(TileTitle, null,
        React.createElement(Label, { style: { textAlign: "center" }, fullWidth: true, ...props }, props.children));
};
const Body = (props) => {
    const color = useThemeColor(props.variant, "black")[500].main;
    return React.createElement(TileBody, null,
        React.createElement("div", { style: { color: color, width: "100%" } }, props.children));
};
export const Tile = Object.assign(BaseTile, {
    Title: Title,
    Body: Body,
});
