import { TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export class ScheduleUtil {
	public static async getCurrentStation({ app, fpvid, timeSpan } : {
		app: FleetplanApp;
		fpvid: number;
		timeSpan: TimeSpan | DateTime
	}): Promise<number> {
		let location: number;

		if (DateTime.isDateTime(timeSpan)) {
			timeSpan = new TimeSpan(timeSpan.startOf("minute"), timeSpan.endOf("minute"));
		}

		const schedules = (await app.store.resource.schedule.getRange(timeSpan.start, timeSpan.end))
			.filter(e => {
				return e.linkType === "fpvid"
					&& e.dscid > 0
					&& e.isCurrent;
			}).map(e => {
				return {
					...e,
					range: new TimeSpan(DateTime.fromISO(e.dateFrom), DateTime.fromISO(e.dateTo)),
				};
			});

		location = schedules.find(e => e.linkId === fpvid.toString() && e.range.intersects(timeSpan))?.data.fpdirloc;
		if (!location) location = app.store.resource.aircraft.getId(fpvid)?.fpdirloc;
		return location;
	}
}
