import React from "react";
import styled from "styled-components";
const Node = styled.div `
	position: relative;
	min-width: 100%;

	background: ${p => p.active ? p.theme.tree.active : "transparent"};
	&:hover {
		background: ${p => p.active ? p.theme.tree.activeHover : p.theme.tree.hover};
		.dnd-grab {
			visibility: visible;
		}
	}
	.dnd-grab {
		visibility: hidden;
	}
`;
export const FleetplanAlwaysExpandNodeRenderer = function FleetplanAlwaysExpandNodeRenderer(props) {
    const { children, listIndex, swapFrom, swapLength, swapDepth, scaffoldBlockPxWidth, lowerSiblingCounts, connectDropTarget, connectDragSource, isOver, draggedNode, canDrop, treeIndex, treeId, // Delete from otherProps
    getPrevRow, // Delete from otherProps
    node, // Delete from otherProps
    path, // Delete from otherProps
    rowHeight, disableDropFromOutside, canDrag, ...otherProps } = props;
    return React.createElement(Node, { ...otherProps, active: node.active, ref: connectDropTarget }, React.Children.map(children, (child) => React.cloneElement(child, {
        isOver,
        canDrop,
        draggedNode,
        lowerSiblingCounts,
        listIndex,
        swapFrom,
        swapLength,
        swapDepth,
        rowHeight,
    })));
};
FleetplanAlwaysExpandNodeRenderer.defaultProps = {
    swapFrom: null,
    swapDepth: null,
    swapLength: null,
    canDrop: false,
    rowHeight: 25,
    draggedNode: null,
    disableDropFromOutside: false,
};
