import { ApiContext, IFlightLogbookTechnicalReport, IFlightLogbookTechnicalReportApi } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class FlightLogbookTechnicalReportApi implements IFlightLogbookTechnicalReportApi {
	public async get(ctx: ApiContext, params: any): Promise<IFlightLogbookTechnicalReport[]> {
		return ApiUtil.fetchRpc(ctx, "resource.flightLogbookTechnicalReport.get", params);
	}

	public async getId(ctx: ApiContext, params: any): Promise<IFlightLogbookTechnicalReport> {
		return ApiUtil.fetchRpc(ctx, "resource.flightLogbookTechnicalReport.getId", params);
	}

	public async post(ctx: ApiContext, params: { data: Partial<IFlightLogbookTechnicalReport> }): Promise<IFlightLogbookTechnicalReport> {
		return ApiUtil.fetchRpc(ctx, "resource.flightLogbookTechnicalReport.post", params);
	}

	public async put(ctx: ApiContext, params: { data: Partial<IFlightLogbookTechnicalReport> }): Promise<IFlightLogbookTechnicalReport> {
		return ApiUtil.fetchRpc(ctx, "resource.flightLogbookTechnicalReport.put", params);
	}

	public async delete(ctx: ApiContext, params: { id: string }): Promise<void> {
		return ApiUtil.fetchRpc(ctx, "resource.flightLogbookTechnicalReport.delete", params);
	}
}

