import styled from "styled-components";
export const DataTable = styled.table `
	width: 100%;
	border-collapse: collapse;
	&.my-2 {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	/*default (we cannot set this styled.td, because this would have more weight */
	td { 
		border: ${props => props.$borderWidth ?? "1px"} solid ${props => props.$borderColor ?? "#ddd"};
		padding: ${props => props.$tdPadding ?? "2px 4px"};
	}

	/* this table-class is used from drf / ara because of different td-paddings */
	&.borderColorBlack td  {
		border: 1px solid black;
		padding: 1px 0;
	}
`;
export const DataTd = styled.td `
	font-size:9px;
	text-align: center;	
	white-space: nowrap;

	&.alignLeft { 
		text-align: left;
	}
	&.alignRight { 
		text-align: right;
	}


	.borderless & {
		border: none;
	}
	&.border-right-0 {
		border-right: none !important;
	}
	&.border-left-0 {
		border-left: none !important;
	}
	&.rightDashed {
		border-right-style: dashed!important;
	}
	&.leftDashed {
		border-left-style: dashed!important;
	}
	.narrow & {
		line-height: 12px;
	}

	/* following syntax is for TD and for cascading from TR to TD  */
	.textBold &, &.textBold {
		font-weight: bold;
	}
	.bgGrey &, &.bgGrey {
		background-color: #f6f7f7;
	}
	/* bgGrey-dark is used for drf and ara */
	.bgGrey-dark &, &.bgGrey-dark { 
		background-color: #d3d3d3;
	}
	.textUppercase &, &.textUppercase {
		text-transform: uppercase;
	}
	.fontWeightNormal &, &.fontWeightNormal {
		font-weight: normal;
	}
`;
export const DataHr = styled.hr `
	border-bottom: 1px dashed #ddd;
	border-top: none;
	margin: 0;
	background-color: white;   
`;
export const DataHrSolid = styled.hr `
	border-bottom : 1px solid black;
	border-top: 0;
	margin: 1px 0;
`;
export const DataH5 = styled.h5 `
	margin: 0;
	color: black;
`;
export const DataH4 = styled.h4 `
	margin: 0;
`;
export const DataH3 = styled.h3 `
	margin: 0;
	color: black;
`;
export const SpanAbsolute = styled.span `
	position: absolute;
	right: 1px;
	top: -2px;
	font-size:85%;
`;
export const DivRelative = styled.div `
	position: relative;
	padding: 0 2px;
`;
export const DataSmall = styled.span `
	font-size: 80%; 
`;
export const FlightOrderTable = styled.table `
	border-collapse: collapse;
	width: 100%;
	empty-cells: show;
	font-size: ${props => props.$fontSize ?? "11px"};
	color:  ${props => props.$fontColor ?? "#000000"};
	tr th,
	tr td { 
		padding: ${props => props.$tdPadding ?? "5px"}; 
		border:  ${props => props.$borderWidth ?? "1px"} solid ${props => props.$borderColor ?? "#dddddd"};
	}
	&.stripedColors tbody tr:nth-child(odd){
		background-color: ${props => props.$stripedColor ?? "#f9f9f9"};
	}
`;
