import { faAngleRight, faChairOffice, faLock } from "@fortawesome/pro-light-svg-icons";
import { faMapMarker, faUsers, faUsersClass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DirectoryNode, FpDirNodeKind, TreeUtil } from "@tcs-rliess/fp-core";
import { Flex, FlexItem, Label, Text } from "@tcs-rliess/fp-web-ui";
import { capitalize, castArray, last } from "lodash-es";
import React from "react";
import { useNavigate } from "react-router";

import { FleetplanIcon } from "../../common";
import { useApp } from "../../FleetplanApp";
import { SystemCategoryLabel } from "../Common/Util/SystemCategoryLabel";

export interface NodeLabelProps {
	id: number | number[];
	/** @deprecated directory from app context is used here**/
	tree?: DirectoryNode[];
	hidePath?: boolean;
	onlyPath?: boolean;
	hideIcon?: boolean;
	pathTextNotMuted?: boolean;
}

const icons = {
	[FpDirNodeKind.Group]: faUsers,
	[FpDirNodeKind.Location]: faMapMarker,
	[FpDirNodeKind.Position]: faUsersClass,
	[FpDirNodeKind.Security]: faLock,
};

export const DirBreadCrumb: React.FC<NodeLabelProps> = function DirBreadCrumb(props) {
	const id = castArray(props.id);
	const app = useApp();
	const util = React.useMemo(() => TreeUtil.fromDirectoryNodes(app.directory), [ app.directory ]);
	const parents = React.useMemo(() => util.findParents(id[0]), [ props.id, util ]);
	const node = util.findKey(id[0]);
	const navigate = useNavigate();

	const buildParents = React.useMemo(() => {
		const crumb = [];
		const encounteredTypes = [];

		for (const node of parents) {
			if (!encounteredTypes.includes(node.kind)) {
				crumb.push(<React.Fragment key={`${node.id}-1`}><FontAwesomeIcon fixedWidth icon={icons[node.kind]} /></React.Fragment>);
				crumb.push(<React.Fragment key={`${node.id}-2`}><FontAwesomeIcon fixedWidth icon={faAngleRight} /></React.Fragment>);
				encounteredTypes.push(node.kind);
			}
			if(props.onlyPath) continue;
			crumb.push(<React.Fragment key={`${node.id}-3`}>
				{node?.data?.shortName || node.name}
				{<>&nbsp;<FontAwesomeIcon fixedWidth icon={faAngleRight} />&nbsp;</>}
			</React.Fragment>);
		}
		return crumb;
	}, [ parents ]);




	return <Flex row style={{ alignItems: "center" }}>
		<FlexItem style={{ alignItems: "center" }}>
			<small className="text-muted">
				<><FleetplanIcon onClick={() => navigate("/directory")} style={{ fontSize: "1.4em" }} />&nbsp;&nbsp;</>
				<><FontAwesomeIcon icon={faAngleRight} fixedWidth />&nbsp;&nbsp;</>
				{buildParents}
			</small>
		</FlexItem>
		<FlexItem>
			{node && <SystemCategoryLabel dscatid={Number.parseInt(node.type.split(":")[1])} iconPosition="left" hideText style={{ background: "transparent", color: "black", border: "none" }} />}
			{node?.data?.shortName || node?.name || ""}
		</FlexItem>
	</Flex>;
};

export const NodeLabel: React.FC<NodeLabelProps> = function NodeLabel(props) {
	const id = castArray(props.id).filter(Boolean);
	const app = useApp();
	const util = app.store.fpDir.directory.getTree();
	// we remove root elements from the path
	const node = [];
	let i = 0;
	util.walk(info => {
		if (id.includes(info.node.id)) {
			node[id.indexOf(info.node.id)] = info.node;
			i++;
		}
		if(i === id.length) return false;
	});
	const parents = id.map(id => util.findParents(id).filter(e => e.parentid !== 0));
	const name = node.map(node => node ? props.onlyPath ? null : node.type === "SYSTEM" ? capitalize(node.kind) : node.name || node.data?.shortName || "" : null);

	if(props.id === -1) return <Label style={{ background: "transparent", color: "black", border: "none" }}>Context sensitive</Label>;
	const ret = <Flex row>
		{node.map((node, i) => {
			return <React.Fragment key={node.id}>
				{props.hidePath ? null : <Flex justifyContent="center" alignItems="center">
					<small className={!props.pathTextNotMuted && "text-muted"}>
						{(parents[i].length === 0) && props.onlyPath ? <Text><SystemCategoryLabel iconPosition={null} isText dscatid={+node.type.split(":")[1]}/></Text> :
							parents[i].map((node, index) => <React.Fragment key={node.id}>
								{node?.data?.shortName || node.name}
								{props.onlyPath ? last(parents[i]) === node ? null : <>&nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;</>  : <>&nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;</>}
							</React.Fragment>)}
					</small>
				</Flex>}
				{props.onlyPath ? null : <FlexItem>
					{(!props.hideIcon && node) && <SystemCategoryLabel dscatid={Number.parseInt(node.type.split(":")[1])} iconPosition="left" hideText variant="transparent" style={{ color: "black", fontSize: ".8em" }} />}
					{(!props.hideIcon && node?.kind === FpDirNodeKind.Position) && <><Label style={{ background: "transparent", color: "black", border: "none", padding: "0px 10px", fontSize: ".8em" }}><FontAwesomeIcon icon={faChairOffice}/></Label>&nbsp;&nbsp;</>}
					{(!props.hideIcon && node?.kind === FpDirNodeKind.Security) && <><Label style={{ background: "transparent", color: "black", border: "none", padding: "0px 10px", fontSize: ".8em" }}><FontAwesomeIcon icon={faLock}/></Label>&nbsp;&nbsp;</>}
					{name[i]} {/* changed for ap -> prefer name before short name */}
				</FlexItem>}
			</React.Fragment>;
		})}
	</Flex>;
	return ret;
};

const internalMap = new Map<number, DirectoryNode>();
export const StringNodeLabel: (props: NodeLabelProps & { noSpan?: boolean, id: number }) => string = function StringNodeLabel(props) {
	let node: DirectoryNode;
	if(internalMap.has(props.id)) {
		node = internalMap.get(props.id);
	} else {
		const util = TreeUtil.fromDirectoryNodes(props.tree);
		node = util.findKey(props.id);
	}

	return `${props.noSpan ? "" : "<span>"}${node?.data?.shortName || node?.name || ""}${props.noSpan ? "" : "</span>"}`;
};
