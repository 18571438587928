import { observable } from "mobx";
import { handleError } from "../handleError";

export type QueueTask<T> = () => Promise<T>;

interface Options {
	maxRunning: number;
}
export class Queue {
	constructor(options?: Options) {
		this.maxRunning = options?.maxRunning ?? 2;
	}

	public get openTasks(): number {
		return this.tasks.length;
	}

	public get runningTasks(): number {
		return this.running;
	}

	@observable public finished = false;

	private tasks: QueueTask<unknown>[] = [];
	private running = 0;
	private maxRunning = 2;

	public push<T>(task: QueueTask<T>): Promise<T> {
		const taskPromise = new Promise<T>((resolve, reject) => {
			this.tasks.push(() => {
				return task().then(resolve).catch(reject);
			});
		});

		this.startNext();
		return taskPromise;
	}

	private startNext(): void {
		if (this.running >= this.maxRunning) return;

		const task = this.tasks.shift();
		if (task == null) {
			this.finished = true;
			return;
		} else {
			if(this.finished)
				this.finished = false;
		}
		this.running++;

		task().finally(() => {
			this.running--;
			this.startNext();
		}).catch(handleError);
	}
}
