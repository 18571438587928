import { FPEvent, FPEventResource, FpApi } from "@tcs-rliess/fp-core";
import { isEqualWith } from "lodash-es";

import { FleetplanApp } from "../../../FleetplanApp";
import { FpEventModel } from "../models";
import { SchedulerStore } from "../SchedulerStore";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

type Configuration = {
	enabled: boolean;
	types: Partial<Record<"DEFAULT" | FpApi.Calendar.Event.EventType, {
		enabled: boolean;
		creatable: boolean;
	}>>;
}

export class CalendarEventsBuilder extends BaseBuilder {
	private configuration: Configuration;

	constructor(store: SchedulerStore, app: FleetplanApp) {
		super(store, app);
		this.resolveConfiguration();
	}

	public updateConfiguration(): void {
		this.resolveConfiguration();
	}

	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.configuration.enabled) loads.push(this.calendarEvents(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: FPEvent): Promise<void> {
		if (item.type === FpApi.Calendar.Event.EventType.Container) {
			// currently ignore container events, as they don't have a date
			return;
		}

		if (this.configuration.enabled) {
			if (item.status === FpApi.Calendar.Event.EventStatus.Repeat) {
				// repeat events don't have a parent or rlog, they are always single events
				this.mapEvent(state, null, [ item ]);
				return;
			}

			const event = this.store.projectModel.eventStore.getById(`dseid:${item.id}`);
			if (event != null) {
				const oldItem = (event as FpEventModel).fpData as FPEvent;

				if (this.eventsDifferent(oldItem, item) === false) {
					// events are the same, return and avoid unnecessary updates, renders, etc.
					return;
				}
			}

			const calParent = this.app.store.event.getParent(item.id);
			const calChildren = this.app.store.event.getChildren(calParent.id);

			// delete all current events
			this.store.projectModel.eventStore.remove(`dseid:${calParent.id}`);
			calChildren.forEach(event => {
				this.store.projectModel.eventStore.remove(`dseid:${event.id}`);
			});

			// map and add event again
			this.mapEvent(state, calParent, calChildren);
		}

		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		this.store.projectModel.eventStore.remove(`dseid:${id}`);

		return Promise.resolve();
	}

	private resolveConfiguration(): void {
		const inputConfiguration = this.store.configuration.events.calendarEvents;
		const configuration: Configuration = {
			enabled: false,
			types: {
				DEFAULT: { enabled: false, creatable: true },
			},
		};

		if (typeof inputConfiguration?.types?.DEFAULT === "boolean") {
			configuration.types.DEFAULT.enabled = inputConfiguration.types.DEFAULT;
		} else {
			configuration.types.DEFAULT = {
				...configuration.types.DEFAULT,
				...(inputConfiguration?.types?.DEFAULT ?? {}),
			};
		}

		for (const name in FpApi.Calendar.Event.EventType) {
			const type = FpApi.Calendar.Event.EventType[name];

			if (typeof inputConfiguration?.types?.[type] === "boolean") {
				configuration.types[type].enabled = inputConfiguration.types[type];
			} else {
				configuration.types[type] = {
					...configuration.types.DEFAULT,
					...(inputConfiguration?.types?.[type] ?? {}),
				};
			}

			if (configuration.types[type].enabled) {
				configuration.enabled = true;
			}
		}

		this.configuration = configuration;
	}

	private async calendarEvents(state: RangeBuilderState): Promise<void> {
		const calendarEvents = await this.app.store.event.getRange(state.from, state.to);

		// gather all parents, and groups children
		const parents = new Set<FPEvent>();
		const childrenMap = new Map<string, FPEvent[]>;

		calendarEvents.forEach(calEvent => {
			if (calEvent.status === FpApi.Calendar.Event.EventStatus.Repeat) {
				// repeat events don't have a parent, they are always single events
				this.mapEvent(state, null, [ calEvent ]);
				return;
			}

			// get parent
			const parent = this.app.store.event.getParent(calEvent.id);
			parents.add(parent);

			if (childrenMap.has(parent.id)) {
				childrenMap.get(parent.id).push(calEvent);
			} else {
				childrenMap.set(parent.id, [ calEvent ]);
			}
		});

		// generate events
		for (const parent of parents) {
			const children = childrenMap.get(parent.id);
			// drop parents with no children
			if (children == null || children.length === 0) continue;

			this.mapEvent(state, parent, children);
		}
	}

	private mapEvent(state: BuilderState, calParent: FPEvent | null, calChildren: FPEvent[]): void {
		calChildren = calChildren.filter(calEvent => {
			// check configuration
			if (this.configuration.types[calEvent.type].enabled === false) return false;
			// 2024-03-22 - [PR] AP :"Calendar events from status Cancelled should not be displayed in the scheduler"
			if (calEvent.status === FpApi.Calendar.Event.EventStatus.Cancelled) return false;

			return true;
		});

		if (calChildren.length === 0) {
			return;
		}

		// 2024-04-16 [ET] FP-15497 nested events case problems with calendar views, disabled until resolution found
		//                 https://forum.bryntum.com/viewtopic.php?t=28741
		calChildren.forEach(calEvent => {
			this.makeEvent(state, () => {
				let startDate: string | Date = calEvent.date_start;
				let endDate: string | Date = calEvent.date_end;

				// if it's a flight, grab the earliest/latest time from the waypoint
				// the event layout template will take care of the rendering of scheduled vs. other times
				if (calEvent.type === FpApi.Calendar.Event.EventType.Flight) {
					const firstWaypoint = calEvent.waypoints[0];
					const lastWaypoint = calEvent.waypoints[calEvent.waypoints.length - 1];
					const dates = [
						firstWaypoint.atd, firstWaypoint.etd, firstWaypoint.std,
						lastWaypoint.ata, lastWaypoint.eta, lastWaypoint.sta
					]
						// drop nulls
						.filter(d => d != null)
						// turn into time stamp
						.map(d => +new Date(d));

					// grab min/max, and turn into a date
					startDate = new Date(new Date(Math.min(...dates)).getTime() - (firstWaypoint.ground_ops_time ?? 0) * 60 * 1000);
					endDate = new Date(new Date(Math.max(...dates)).getTime() + (lastWaypoint.ground_ops_time ?? 0) * 60 * 1000);
				}

				return new FpEventModel({
					id: `dseid:${calEvent.id}`,
					startDate: this.toTimeZone(startDate),
					endDate: this.toTimeZone(endDate),
					resizable: false,
					name: calEvent.id,

					fpLinkType: "dseid",
					fpLinkId: calEvent.id,
					fpData: calEvent,
				});
			}, function*() {
				// const seen = new Set<string>();
				const resources = calChildren.flatMap(e => e.resources);

				for (const resource of resources ?? []) {
					// yield to create assignment
					yield { linkType: resource.link_type, linkId: resource.link_id };
				}
			});
		});

		/*
		this.makeEvent(state, () => {
			const eventChildren = calChildren.map(calEvent => {
				let startDate: string | Date = calEvent.date_start;
				let endDate: string | Date = calEvent.date_end;

				// if it's a flight, grab the earliest/latest time from the waypoint
				// the event layout template will take care of the rendering of scheduled vs. other times
				if (calEvent.type === FpApi.Calendar.Event.EventType.Flight) {
					const firstWaypoint = calEvent.waypoints[0];
					const lastWaypoint = calEvent.waypoints[calEvent.waypoints.length - 1];
					const dates = [
						firstWaypoint.atd, firstWaypoint.etd, firstWaypoint.std,
						lastWaypoint.ata, lastWaypoint.eta, lastWaypoint.sta
					]
						// drop nulls
						.filter(d => d != null)
						// turn into time stamp
						.map(d => +new Date(d));

					// grab min/max, and turn into a date
					startDate = new Date(Math.min(...dates));
					endDate = new Date(Math.max(...dates));
				}

				return new FpEventModel({
					id: `dseid:${calEvent.id}`,
					startDate: this.toTimeZone(startDate),
					endDate: this.toTimeZone(endDate),
					resizable: false,
					name: calEvent.id,

					fpLinkType: "dseid",
					fpLinkId: calEvent.id,
					fpData: calEvent,
				});
			});

			if (calParent == null || eventChildren.length === 1) {
				// only one child, don't create the container
				return eventChildren[0];
			} else {
				const eventParent = new FpEventModel({
					id: `dseid:${calParent.id}`,
					resizable: false,
					name: calParent.id,

					children: eventChildren,

					fpLinkType: "dseid",
					fpLinkId: calParent.id,
					fpData: calParent,
				});

				return eventParent;
			}
		}, function*() {
			// const seen = new Set<string>();
			const resources = calChildren.flatMap(e => e.resources);

			for (const resource of resources ?? []) {
				// yield to create assignment
				yield { linkType: resource.link_type, linkId: resource.link_id };
			}
		});
		*/
	}

	private eventsDifferent(oldItem: FPEvent, newItem: FPEvent): boolean {
		// event changed
		if (oldItem.rlog.m !== newItem.rlog.m) return true;
		// resource count changed
		if (oldItem.resources.length !== newItem.resources.length) return true;
		// resource different/changed
		const resourcesEqual = isEqualWith(oldItem.resources, newItem.resources, (oldResource: FPEventResource, newResource: FPEventResource) => {
			return oldResource.id === newResource.id && oldResource.rlog.m === newResource.rlog.m;
		});
		if (resourcesEqual === false) return true;

		return false;
	}
}
