import { ApiContext, IDashboard, IDashboardApi, IGetParams } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class DashboardApi implements IDashboardApi {
	public async get(ctx: ApiContext, params: IGetParams): Promise<IDashboard[]> {
		return ApiUtil.fetchRpc(ctx, "dashboard.dashboard.get", params);
	}

	public async getId(ctx: ApiContext, params: { id: string }): Promise<IDashboard> {
		return ApiUtil.fetchRpc(ctx, "dashboard.dashboard.getId", params);
	}

	public async put(ctx: ApiContext, params: { data: Partial<IDashboard> }): Promise<IDashboard> {
		return ApiUtil.fetchRpc(ctx, "dashboard.dashboard.put", params);
	}

	public async post(ctx: ApiContext, params: { data: Partial<IDashboard> }): Promise<IDashboard> {
		return ApiUtil.fetchRpc(ctx, "dashboard.dashboard.post", params);
	}

	public async delete(ctx: ApiContext, params: { id: string }): Promise<void> {
		return ApiUtil.fetchRpc(ctx, "dashboard.dashboard.delete", params);
	}
}
