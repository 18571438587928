import { Format } from "@tcs-rliess/fp-web-ui";
import React from "react";
export const Voucher = (props) => {
    const data = props.data;
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data for Voucher found!");
    const voucherType = data?.booking?.voucherType || "R44";
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                ...(props.previewTab
                    ? { width: "21cm", height: "29.7cm" } // print preview tab - preview
                    : { width: "100%", height: "100vh" }), // pdf print preview
                //TODO: change background URL to: _{process.env.FP_LUCEE_FRONTEND_HOST}/project/heliaustria/image/gutschein_R44.jpg?v=1
                backgroundImage: `url('https://heli-austria.fleetplan.net/project/heliaustria/image/gutschein_${voucherType}-1.jpg?v=1')`,
                backgroundRepeat: "no-repeat center",
                backgroundSize: props.previewTab
                    ? "21cm 29.7cm"
                    : "100% 100%",
                position: "relative",
                color: "white",
                fontFamily: "Arial, Helvetica, sans-serif",
                fontWeight: "bold",
                lineHeight: "normal"
            } },
            React.createElement("div", { style: {
                    position: "absolute",
                    bottom: "12.5cm",
                    marginLeft: "1cm",
                    marginRight: "1cm",
                } },
                React.createElement("span", { style: { fontSize: "26pt" } }, data.formatted.product),
                data?.booking.voucherText != null && data?.booking.voucherText !== "" &&
                    React.createElement("span", { style: { fontSize: "19pt" } },
                        React.createElement("br", null),
                        data?.booking.voucherText,
                        React.createElement("br", null))),
            React.createElement("div", { style: {
                    position: "absolute",
                    width: "100%",
                    bottom: "10.4cm",
                    fontSize: "16pt"
                } },
                React.createElement("table", { style: {
                        marginRight: "auto",
                        marginLeft: "auto",
                    } },
                    React.createElement("tr", null,
                        React.createElement("td", { style: { width: "6cm" } }, "Gutschein-Nummer:"),
                        React.createElement("td", null, data?.booking.runningNo)),
                    React.createElement("tr", null,
                        React.createElement("td", null, "Ausgestellt.:"),
                        React.createElement("td", null,
                            React.createElement(Format.Date, null, data?.booking?.rlog.c ?? ""))))))));
};
