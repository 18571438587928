import { lazyComponent, Skeleton } from "@tcs-rliess/fp-web-ui";
import React from "react";
import ReactDOM from "react-dom";

import { FpProvider } from "./common";
import { FP_APP } from "./main";

const ObjectLabel = lazyComponent(() => import("./modules/Common/Util/chunk"), m => m.ObjectLabel);

document.addEventListener("DOMContentLoaded", () => {
	const observer = new MutationObserver((mutations, observer) => {
		const queueAdded: Node[] = [];
		// const queueRemoved: Node[] = [];

		for (const mutation of mutations) {
			queueAdded.push(...Array.from(mutation.addedNodes));
			// queueRemoved.push(...Array.from(mutation.removedNodes));
		}

		while (queueAdded.length > 0) {
			const node = queueAdded.shift();
			if (!(node instanceof Element)) continue;

			queueAdded.push(...Array.from(node.children));

			if (node.hasAttribute("data-object-label")) {
				const props = JSON.parse(node.getAttribute("data-object-label"));
				node.removeAttribute("data-object-label");

				node.setAttribute("fp-web-root", "");
				ReactDOM.render(<FpProvider app={FP_APP}>
					<React.Suspense fallback={<Skeleton.Line width={80}/>}>
						<ObjectLabel {...props}/>
					</React.Suspense>
				</FpProvider>, node);
			}
		}
	});

	observer.observe(document.body, {
		childList: true,
		subtree: true,
		attributes: true,
	});
}, { once: true });
