import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FpId } from "@tcs-rliess/fp-core";
import { ThemeVariantOrColor } from "@tcs-rliess/fp-web-ui";
import { computed, observable } from "mobx";
import React from "react";

/**
 * after what time to clean up toasts
 * 30 minutes
 */
const MAX_TOAST_AGE = 30 * 60 * 1000;

export interface ToastMessage {
	id: string;
	status: ToastStatus;

	variant: ThemeVariantOrColor;
	icon: IconProp;
	app: React.ReactNode;
	body?: React.ReactNode;
	buttons?: React.ReactNode;

	autoDismiss?: boolean;
	onDismiss?: () => void;
}
interface ToastStatus {
	time: Date;
	dismissed: boolean;
}

export type ToastMessageParams = Omit<ToastMessage, "id" | "status">;

export class ToastManager {
	@observable
	private toasts = new Map<string, ToastMessage>();

	@computed get displayQueue(): readonly ToastMessage[] {
		const result: ToastMessage[] = [];
		for (const toast of this.toasts.values()) {
			if (toast.status.dismissed === true) continue;
			result.push(toast);
		}

		// sort by time
		return result.sort((a, b) => {
			return +b.status.time - +a.status.time;
		});
	}
	@computed get dismissed(): readonly ToastMessage[] {
		const result: ToastMessage[] = [];
		for (const toast of this.toasts.values()) {
			if (toast.status.dismissed === false) continue;
			result.push(toast);
		}

		// sort by time
		return result.sort((a, b) => {
			return +b.status.time - +a.status.time;
		});
	}

	constructor() {
		// every minute, check the age of all toast, and clean up old toasts
		setInterval(() => {
			this.toasts.forEach((toast, id) => {
				const age = +new Date() - +toast.status.time;
				if (age >= MAX_TOAST_AGE) {
					this.toasts.delete(id);
				}
			});
		}, 60_000);
	}

	public add(message: ToastMessageParams): string {
		const final: ToastMessage = {
			autoDismiss: true,
			...message,
			id: FpId.new(),
			status: this.defaultState(),
		};
		this.toasts.set(final.id, final);

		return final.id;
	}

	public update(id: string, message: ToastMessageParams): string {
		const toast = this.toasts.get(id);

		const final: ToastMessage = {
			autoDismiss: true,
			...message,
			id: toast?.id ?? FpId.new(),
			status: {
				...this.defaultState(),
				...(toast?.status ?? {}),
				time: new Date(),
				dismissed: false,
			},
		};
		this.toasts.set(final.id, final);

		return final.id;
	}

	public dismiss(id: string): string {
		const toast = this.toasts.get(id);
		toast.status.dismissed = true;

		return toast.id;
	}

	private defaultState(): ToastStatus {
		return {
			time: new Date(),
			dismissed: false,
		};
	}
}
