export function shareRef(...refs) {
    return instance => {
        for (const ref of refs) {
            if (typeof ref === "function")
                ref(instance);
            else if (ref)
                ref.current = instance;
        }
    };
}
