import { formatContactName, formatCoordianteToString, formatLandingField } from "@tcs-rliess/fp-core";
import { Format, Text } from "@tcs-rliess/fp-web-ui";
import React from "react";
import { PrintContext } from "../../lib";
import { FlightOrderTable } from "../../lib/styledExportComponents";
export const FlightOrder = (props) => {
    const data = props.data;
    const printContext = React.useContext(PrintContext);
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data!");
    const doclist = ["Operational Flight Plan",
        "WX & NOTAM Briefing",
        "Weight and Balance",
        "Performance Calculation",
        "Permits (mission specific)",
        "Landingfield-Documents",
        "Documents regarding instruction(s) and briefing(s)"];
    const paddedLegs = Array.from({ ...data.legs, length: data.legs.length < 5 ? 5 : data.legs.length }, (v) => v ?? {});
    return (React.createElement(React.Fragment, null,
        React.createElement(FlightOrderTable, { "$tdPadding": "10px", "$fontSize": "10px", "$borderColor": "#000000", style: { marginBottom: "10px" } },
            React.createElement("tr", { style: { textAlign: "center" } },
                React.createElement("td", { rowSpan: 2 },
                    React.createElement("img", { src: printContext?.customer?.$dscaid?.avatarTn, style: { width: "180px" } })),
                React.createElement("td", null,
                    React.createElement(Text, { style: { fontSize: "22px", fontWeight: "bold" } }, "Commercial Flight Order CAT & SPO"))),
            React.createElement("tr", null,
                React.createElement("td", { style: { fontSize: "9px" } }, "This flight order must be carried during the flight. It also serves as a cover sheet for filing flight preparation. The existence of the documents to be carried and stored must be confirmed by checking off the relevant fields."))),
        React.createElement(FlightOrderTable, { "$tdPadding": "0px 6px", "$fontSize": "10px", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", null,
                    React.createElement(Text, { size: "lg", bold: true }, "FLIGHT - AIRCRAFT BOOKING #")),
                React.createElement("td", null,
                    React.createElement(Text, { size: "lg", bold: true }, "REFERENCE"))),
            React.createElement("tr", null,
                React.createElement("td", { style: { textAlign: "center", width: "30%" } },
                    React.createElement(Text, { bold: true, style: { fontSize: "18px", fontFamily: "Courier New" } }, data.event.id2)),
                React.createElement("td", null,
                    React.createElement(Text, { bold: true }, data.event.reference)))),
        React.createElement(FlightOrderTable, { "$tdPadding": "0px 6px", "$fontSize": "10px", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 9, style: { borderTop: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "FLIGHTCREW"))),
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 2, style: { textAlign: "center" } },
                    React.createElement(Text, { size: "sm", bold: true }, "ROLE"),
                    React.createElement("br", null),
                    data.crew.flightCrew[0]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true }, "3LC"),
                    React.createElement("br", null),
                    data.crew.flightCrew[0]?.$contact.$person?.abbreviation === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[0]?.$contact.$person?.abbreviation ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 5 },
                    React.createElement(Text, { size: "sm", bold: true }, "PHONE"),
                    React.createElement("br", null),
                    data.crew.flightCrew[0]?.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[0]?.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0")))),
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 2, style: { textAlign: "center" } },
                    React.createElement(Text, { size: "sm", bold: true }, "ROLE"),
                    React.createElement("br", null),
                    data.crew.flightCrew[1]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true }, "3LC"),
                    React.createElement("br", null),
                    data.crew.flightCrew[1]?.$contact.$person?.abbreviation === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[1]?.$contact.$person?.abbreviation ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 5 },
                    React.createElement(Text, { size: "sm", bold: true }, "PHONE"),
                    React.createElement("br", null),
                    data.crew.flightCrew[1]?.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[1]?.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0")))),
            data.crew.otherCrew.length
                ? React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                    React.createElement("td", { colSpan: 9 },
                        React.createElement(Text, { size: "lg", bold: true }, "ADDITIONAL CREW")))
                : null,
            data.crew.otherCrew.map((crewMember, i) => (React.createElement("tr", { key: "otherCrew_" + i },
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm", bold: true }, "ROLE"),
                    React.createElement("br", null),
                    crewMember.dserrid ?? React.createElement(React.Fragment, null, "\u00A0")),
                React.createElement("td", { colSpan: 5 },
                    React.createElement(Text, { size: "sm", bold: true }, "NAME"),
                    React.createElement("br", null),
                    crewMember.$contact.$person?.lastName,
                    ", ",
                    crewMember.$contact.$person?.givenName,
                    " ",
                    (crewMember.$contact.$person?.abbreviation ? `(${crewMember.$contact.$person?.abbreviation})` : "")),
                React.createElement("td", { colSpan: 3 },
                    React.createElement(Text, { size: "sm", bold: true }, "PHONE"),
                    React.createElement("br", null),
                    crewMember.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (crewMember.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0")))))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 9 },
                    React.createElement(Text, { size: "lg", bold: true }, "AIRCRAFT"))),
            React.createElement("tr", null,
                React.createElement("td", { rowSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true }, "REG"),
                    React.createElement("div", { style: { marginTop: "8px" } }, data.vehicle.aircraft.registrationCode ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { rowSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true }, "TYP"),
                    React.createElement("div", { style: { marginTop: "8px" } }, data.vehicle.aircraft.$fpdbvmid.vehicleModel ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 7 },
                    React.createElement(Text, { size: "sm", bold: true }, "NEXT MAINTENANCE"))),
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 3 },
                    React.createElement(Text, { size: "sm", bold: true }, "ITEM"),
                    React.createElement("br", null),
                    data.vehicle.state.maintenance.next_maintenance ?? React.createElement(React.Fragment, null, "\u00A0")),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm", bold: true }, "@TIME"),
                    React.createElement("div", { style: { textAlign: "center" } }, data.vehicle.state.maintenance.next_maintenance_at ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.vehicle.state.maintenance.next_maintenance_at) : "")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true }, "@DATE"),
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Format.Date, { tz: printContext?.userSettings?.leg?.tz }, data.vehicle.state.maintenance.next_maintenance_at_date ?? ""))))),
        React.createElement(FlightOrderTable, { "$tdPadding": "0px 6px", "$fontSize": "10px", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 10, style: { borderTop: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "OPERATING AREA / ROUTING"))),
            React.createElement("tr", { style: { textAlign: "center" } },
                React.createElement("td", { style: { width: "6%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "LEG")),
                React.createElement("td", { style: { width: "7%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "MISSION")),
                React.createElement("td", { style: { width: "7%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "DATE")),
                React.createElement("td", { style: { width: "6%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "STD (lt)")),
                React.createElement("td", { style: { width: "24%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "FROM")),
                React.createElement("td", { style: { width: "20%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "TO")),
                React.createElement("td", { style: { width: "6%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "STA (lt)")),
                React.createElement("td", { style: { width: "11%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "CUSTOMER")),
                React.createElement("td", { style: { width: "5%" } },
                    React.createElement(Text, { size: "sm", bold: true }, "RULE")),
                React.createElement("td", { style: { width: "8%" } },
                    React.createElement(Text, { size: "sm", bold: true },
                        "MIN REQ",
                        React.createElement("br", null),
                        "FUEL"))),
            paddedLegs.map((leg, i) => (React.createElement("tr", { key: "leglist_" + i, style: { textAlign: "center" } },
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" }, (i + 1))),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" }, leg.mission)),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" },
                        React.createElement(Format.Date, { showTz: false, tz: printContext?.userSettings?.tz }, leg.std))),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" },
                        React.createElement(Format.Time, { showTz: false, tz: printContext?.userSettings?.tz }, leg.std))),
                React.createElement("td", { style: { textAlign: "left" } },
                    React.createElement(Text, { size: "sm" }, typeof leg.from === "string" ? leg.from : formatLandingField(leg.from)),
                    React.createElement(Text, { muted: true, size: "sm" },
                        React.createElement("br", null),
                        React.createElement("span", null,
                            typeof leg.from === "string" ? "" : formatCoordianteToString(leg.from?.latitude_deg, "lat"),
                            " ",
                            typeof leg.from === "string" ? "" : formatCoordianteToString(leg.from?.longitude_deg, "lon")))),
                React.createElement("td", { style: { textAlign: "left" } },
                    React.createElement(Text, { size: "sm" }, typeof leg.to === "string" ? leg.to : formatLandingField(leg.to)),
                    React.createElement(Text, { muted: true, size: "sm" },
                        React.createElement("br", null),
                        React.createElement("span", null,
                            typeof leg.to === "string" ? "" : formatCoordianteToString(leg.to?.latitude_deg, "lat"),
                            " ",
                            typeof leg.to === "string" ? "" : formatCoordianteToString(leg.to?.longitude_deg, "lon")))),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" },
                        React.createElement(Format.Time, { showTz: false, tz: printContext?.userSettings?.tz }, leg.sta))),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" },
                        formatContactName(leg.customer),
                        React.createElement("br", null),
                        React.createElement("span", null, leg.comdoc?.length ? `SO-${leg.comdoc[0].id}` : ""))),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" }, leg.rule)),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" })))))),
        React.createElement(FlightOrderTable, { "$tdPadding": "0px 6px", "$fontSize": "10px", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 3, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "xs" }, "TO BE COMPLETED BY HAND")),
                React.createElement("td", { colSpan: 2, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "xs" }, "TO BE COMPLETED BY HAND"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 3, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "ENVELOPE"),
                    React.createElement("br", null),
                    React.createElement(Text, { size: "sm", bold: true }, "The following documents must be presented when the PIC issues the flight order")),
                React.createElement("td", { colSpan: 2, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "OPS RETURN"),
                    React.createElement("br", null),
                    React.createElement(Text, { size: "sm", bold: true }, "The following documents must be retained for a period of 3 months"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center" } },
                React.createElement("td", { style: { width: "20%", borderTop: "none", borderRight: "none" } },
                    React.createElement(Text, { size: "sm", bold: true }, "DOCUMENT")),
                React.createElement("td", { style: { width: "15%", borderTop: "none", borderLeft: "none", borderRight: "none" } },
                    React.createElement(Text, { size: "sm", bold: true },
                        "APPLICABLE",
                        React.createElement("br", null),
                        "(3LC)")),
                React.createElement("td", { style: { width: "15%", borderTop: "none", borderLeft: "none" } },
                    React.createElement(Text, { size: "sm", bold: true },
                        "NOT APPLICABLE",
                        React.createElement("br", null),
                        "(3LC)")),
                React.createElement("td", { style: { width: "25%", borderTop: "none", borderRight: "none" } },
                    React.createElement(Text, { size: "sm", bold: true },
                        "PAPERS CHECKED & STORED",
                        React.createElement("br", null),
                        "(3LC)")),
                React.createElement("td", { style: { width: "25%", borderTop: "none", borderLeft: "none" } },
                    React.createElement(Text, { size: "sm", bold: true },
                        "UPLOAD TO FLEETPLAN",
                        React.createElement("br", null),
                        "(3LC)"))),
            doclist.map((value, i) => (React.createElement("tr", { key: "doclist_" + i, style: { height: "30px" } },
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm" }, (value))),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null)))),
            React.createElement("tr", { style: { height: "30px" } },
                React.createElement("td", { colSpan: 3 },
                    React.createElement(Text, { size: "sm" }, "Incident documentation")),
                React.createElement("td", null),
                React.createElement("td", null))),
        React.createElement(FlightOrderTable, { "$tdPadding": "0px 6px", "$fontSize": "10px", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 2, style: { paddingTop: "8px", paddingBottom: "8px", borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "FLIGHTOPERATION DEPARTMENT")),
                React.createElement("td", { colSpan: 2, style: { paddingTop: "8px", paddingBottom: "8px", borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "lg", bold: true }, "FLIGHTCREW"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { style: { width: "25%", textAlign: "center", borderTop: "none", borderRight: "none", } },
                    React.createElement(Text, { size: "sm", bold: true }, "POSITION")),
                React.createElement("td", { style: { width: "25%", textAlign: "center", borderTop: "none", borderLeft: "none", } },
                    React.createElement(Text, { size: "sm", bold: true }, "SIGNATURE")),
                React.createElement("td", { colSpan: 2, style: { width: "50%", borderTop: "none" } })),
            React.createElement("tr", null,
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", { colSpan: 2, style: { fontSize: "9px" } },
                    "The pilot insures, neither through the consumption of alcoholic or other intoxicating substances, nor as a result of mental or physical deficiencies in the awarness to be disabled in carrying out his duties as the aircraft pilot in command.",
                    React.createElement("br", null),
                    "He assures that he carried out the flight preparation of the aircraft is ready for flight and properly fueled and loaded according to the flight order, and all documents according to CAT.GEN.MPA.180 are carried.",
                    React.createElement("br", null),
                    "With his signature, the pilot assures that he can demonstrate the ongoing flight experience required in accordance with ORO.FC.105.")),
            React.createElement("tr", null,
                React.createElement("td", { rowSpan: 2, colSpan: 2, style: { fontSize: "9px", lineHeight: "9px" } },
                    "Intended changes to the flight order must be communicated to the person issuing the flight order as soon as they become known. The following changes require the consent of the person issuing the flight order:",
                    React.createElement("ul", { style: { marginBottom: "0", marginTop: "0" } },
                        React.createElement("li", null, "Change of type of operation / change of SOP,"),
                        React.createElement("li", null, "Change of flight crew and flight crew composition,"),
                        React.createElement("li", null, "Change of deployment date of more than 48 hours (postponements due to weather are not affected here).Consent to the change and the re-issuance of a flight order can be made both in writing and orally."))),
                React.createElement("td", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center", padding: "5px" } },
                    React.createElement(Text, { size: "sm", bold: true }, "SIGNATURE PIC")),
                React.createElement("td", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center", padding: "5px" } },
                    React.createElement(Text, { size: "sm", bold: true }, "SIGNATURE SIC / TS / ACM"))),
            React.createElement("tr", null,
                React.createElement("td", null),
                React.createElement("td", null)))));
};
