import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreSingle } from "../../BaseStoreSingle";

export class DocumentStore extends BaseStoreSingle<FpApi.ControlledDocument.Document, number> {
	private gotAll: FpApi.ControlledDocument.Document[];

	protected itemId(item: FpApi.ControlledDocument.Document): number {
		return item.dssid;
	}

	protected fetchIdList(idList: number[]): Promise<FpApi.ControlledDocument.Document[]> {
		return apiManager
			.getService(FpApi.ControlledDocument.DocumentService)
			.getIds(this.app.ctx, { dssids: idList });
	}

	public async getAll(): Promise<FpApi.ControlledDocument.Document[]> {
		if (this.gotAll) {
			return this.gotAll;
		}

		this.gotAll = await apiManager
			.getService(FpApi.ControlledDocument.DocumentService)
			.get(this.app.ctx);

		for (const item of this.gotAll) {
			this.update(item);
		}

		return this.gotAll;
	}
}
