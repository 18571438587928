import React from "react";
import { UiContext } from "../../FpWebUiProvider";
import { OverlayTrigger } from "../OverlayTrigger/OverlayTrigger";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTimezone } from "./Context";
export const TzOffset = function TzOffset(props) {
    const { formatter } = React.useContext(UiContext);
    const tz = useTimezone(props.tz);
    if (props.children) {
        return React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, null,
                React.createElement(Text, { muted: true },
                    formatter.options.tz,
                    React.createElement("br", null),
                    props.children)) },
            React.createElement(Text, { muted: true, underline: true, style: { cursor: "help" } }, formatter.tz(props.date, { tz })));
    }
    return (React.createElement(Text, { muted: true }, formatter.tz(props.date, { tz })));
};
