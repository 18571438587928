import { DirectoryNode, FpDirNodeKind, TreeUtil } from "@tcs-rliess/fp-core";
import { cloneDeep } from "lodash-es";

import { DirectoryStore } from "./DirectoryStore";

export class DirectoryReadStore extends DirectoryStore<"directory"> {

	private userTree: DirectoryReadStore["tree"];
	private viewableNodes: Set<number> = new Set();

	public canViewNode(id: number): boolean {
		return this.viewableNodes.has(id);
	}

	public async reload(): Promise<void> {
		await super.reload();
		this.buildUserTree();
	}

	public async reloadMembers(): Promise<void> {
		await super.reloadMembers();
		this.buildUserTree();
	}

	public getUserTree() {
		return this.userTree;
	}

	protected buildUserTree(): void {
		if(this.app.ctx.hasRole("dsdirectory_viewer")) {
			this.userTree = this.getTree();
			return;
		}
		if(this.app.ctx.hasRole("dsdirectory_viewer_parents_childs")) {
			const tree = cloneDeep(this.getTree().tree);
			const clonedTree = TreeUtil.fromDirectoryNodes(tree);
			const userMembers = this.getMembersByResource("dscaid", this.app.dscaid);
			const ids: Set<number> = new Set();
			// collect all ids of the current user
			for(const member of userMembers) {
				if(member.loc) ids.add(member.loc);
				if(member.grp) ids.add(member.grp);
			}
			const keepIds: Set<number> = new Set(ids);
			const directParents: Array<DirectoryNode> = [];
			clonedTree.walk((info) => {
				// keep all security nodes
				if(info.node.kind === FpDirNodeKind.Security) {
					keepIds.add(info.node.id);
				}
				if(ids.has(info.node.id)) {
					// keep the parents
					info.parents.forEach((parent) => {
						keepIds.add(parent.id);
						if(parent.type !== "SYSTEM")
							directParents.push(parent);
					});
					// we're going to need the direct parents so we can show the user every node around their nodes
				}
			});
			for(const parent of directParents) {
				const children = clonedTree.findAllChildren(parent.id);
				for(const child of children) {
					keepIds.add(child.id);
				}
			}
			const resTree = clonedTree.filterBottomUp((info) => {
				// drop all positions
				if(info.kind === FpDirNodeKind.Position) return false;
				return keepIds.has(info.id);
			}, "children");
			this.viewableNodes = keepIds;
			this.userTree = resTree;
		} else {
			const tree = cloneDeep(this.getTree().tree);
			const clonedTree = TreeUtil.fromDirectoryNodes(tree);
			const userMembers = this.getMembersByResource("dscaid", this.app.dscaid);
			const ids: Set<number> = new Set();
			// collect all ids of the current user
			for(const member of userMembers) {
				if(member.loc) ids.add(member.loc);
				if(member.grp) ids.add(member.grp);
			}
			const keepIds: Set<number> = new Set(ids);
			const directParents: Array<DirectoryNode> = [];
			clonedTree.walk((info) => {
				// keep all security nodes
				if(info.node.kind === FpDirNodeKind.Security) {
					keepIds.add(info.node.id);
				}
				if(ids.has(info.node.id)) {
					// keep the parents
					info.parents.forEach((parent) => {
						keepIds.add(parent.id);
						if(parent.type !== "SYSTEM")
							directParents.push(parent);
					});
					// we're going to need the direct parents so we can show the user every node around their nodes
				}
			});
			const resTree = clonedTree.filterBottomUp((info) => {
				// drop all positions
				if(info.kind === FpDirNodeKind.Position) return false;
				return keepIds.has(info.id);
			}, "children");
			this.viewableNodes = keepIds;
			this.userTree = resTree;
		}
	}
}
