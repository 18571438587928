import { defaults, omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
export class ContentState {
    constructor(options) {
        this.options = options;
    }
}
export const ContentStateCtx = React.createContext(null);
export class ContentRowState {
    constructor(options) {
        this.options = options;
    }
}
export const ContentRowStateCtx = React.createContext(null);
export const UnstyledOuterContent = (props) => {
    const [state] = React.useState(() => new ContentState({ fill: props.fill, gap: props.gap }));
    return React.createElement(ContentStateCtx.Provider, { value: state },
        React.createElement(OuterContent, { ...props }, props.children));
};
export const ContentBody = (props) => {
    const ctx = React.useContext(ContentStateCtx);
    const [state] = React.useState(() => new ContentRowState({ fill: props.fill ?? ctx?.options.fill, gap: props.gap ?? ctx?.options.gap }));
    return React.createElement(ContentRowStateCtx.Provider, { value: state },
        React.createElement(BaseContent, { ...omit(props, "fill"), gap: state?.options?.gap }, props.children));
};
export const ContentHeader = (props) => {
    const style = defaults(props.style, { width: "100%" });
    return React.createElement(StyledContentHeader, { ...props, style: style }, React.Children.map(props.children, (child) => {
        return child;
    }));
};
const OuterContent = styled.div `
	background: ${props => props.theme.content};
	padding-top: ${props => props.gapTop ? props.gap ?? "2rem" : undefined};
	padding-left: ${props => props.gap ?? "2rem"};
	padding-right: ${props => props.gap ?? "2rem"};
	@media (max-width: 720px) {
		padding-left: 0px;
		padding-right: 0px;
	}
	// padding-bottom: ${props => props.gap ?? "2rem"};
`;
const BaseContent = styled.div `
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: ${props => {
    //return `${(100 / props.columns)}fr `.repeat(props.columns);
    return `repeat(${props.columns ?? 1}, minmax(0, 1fr))`;
}};
	grid-gap: ${props => props.gap ?? "2rem"};
	margin-bottom: ${props => props.gap ?? "2rem"};;
	// grid-auto-rows: min-content;
`;
export const ContentColumn = styled.div `
	grid-column: span ${props => props.lg ?? props.md ?? props.sm ?? 1};
	/* resize: vertical;
	overflow: auto; */
	@media (max-width: 960px) {
		grid-column: span ${props => props.md ?? props.lg ?? props.sm ?? 1};
	}
	@media (max-width: 720px) {
		grid-column: span ${props => props.sm ?? props.lg ?? props.sm ?? 1};
	}
`;
BaseContent.defaultProps = {
    columns: 2,
};
const StyledContentHeader = styled(Flex) `
	padding: 8px 6px;
	margin-bottom: 12px;
`;
export const Content = Object.assign(UnstyledOuterContent, {
    Header: ContentHeader,
    Row: ContentBody,
    Col: ContentColumn
});
