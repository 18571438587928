export var UnitType;
(function (UnitType) {
    UnitType["Byte"] = "byte";
    UnitType["Distance"] = "distance";
    UnitType["Volume"] = "volume";
    UnitType["Speed"] = "speed";
    UnitType["Mass"] = "weight";
    UnitType["Temperature"] = "temperature";
})(UnitType || (UnitType = {}));
export var UnitSystem;
(function (UnitSystem) {
    UnitSystem["Metric"] = "metric";
    UnitSystem["USCS"] = "uscs";
    UnitSystem["Imperial"] = "imperial";
    UnitSystem["Binary"] = "binary";
    UnitSystem["SI"] = "si";
})(UnitSystem || (UnitSystem = {}));
/**
 * Identifies the "commonly used small/medium/large" unit
 * While we have factor for and support varies lesser used units, we want to be able to identify die commonly used ones to prefer these in display and
 * selections for example.
 */
export var UnitSize;
(function (UnitSize) {
    UnitSize[UnitSize["Small"] = 0] = "Small";
    UnitSize[UnitSize["Medium"] = 1] = "Medium";
    UnitSize[UnitSize["Large"] = 2] = "Large";
})(UnitSize || (UnitSize = {}));
export const Units = [
    // Distance - Metric
    {
        id: "distance.metric.nanometer",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        display: "nanometer",
        abbr: "nm",
    }, {
        id: "distance.metric.mikrometer",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        display: "mikrometer",
        abbr: "μm",
    }, {
        id: "distance.metric.millimeter",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        abbr: "mm",
        display: "millimeter",
    }, {
        id: "distance.metric.centimeter",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        size: UnitSize.Small,
        display: "centimeter",
        abbr: "cm",
    }, {
        id: "distance.metric.decimeter",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        abbr: "dm",
        display: "decimeter",
    }, {
        id: "distance.metric.meter",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        size: UnitSize.Medium,
        abbr: "m",
        display: "meter",
    }, {
        id: "distance.metric.dekameter",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        display: "dekameter",
        abbr: "dm",
    }, {
        id: "distance.metric.hektometer",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        display: "hektometer",
        abbr: "hm",
    }, {
        id: "distance.metric.kilometer",
        type: UnitType.Distance,
        system: UnitSystem.Metric,
        size: UnitSize.Large,
        abbr: "km",
        display: "kilometer",
    },
    // Distance - USCS
    {
        id: "distance.uscs.inch",
        type: UnitType.Distance,
        system: UnitSystem.USCS,
        abbr: "in",
        display: "inch",
        size: UnitSize.Small,
    }, {
        id: "distance.uscs.feet",
        type: UnitType.Distance,
        system: UnitSystem.USCS,
        abbr: "ft",
        display: "feet",
        size: UnitSize.Medium,
    }, {
        id: "distance.uscs.yard",
        type: UnitType.Distance,
        system: UnitSystem.USCS,
        abbr: "yd",
        display: "yard",
    }, {
        id: "distance.uscs.mile",
        type: UnitType.Distance,
        system: UnitSystem.USCS,
        abbr: "m",
        display: "mile",
        size: UnitSize.Large,
    }, {
        id: "distance.uscs.nauticmile",
        type: UnitType.Distance,
        system: UnitSystem.USCS,
        abbr: "nm",
        display: "Nautical miles",
    },
    // Volume - Metric
    {
        id: "volume.metric.cubiccentimeter",
        type: UnitType.Volume,
        system: UnitSystem.Metric,
        abbr: "cm³",
        display: "cubic centimeter",
    }, {
        id: "volume.metric.cubicmeter",
        type: UnitType.Volume,
        system: UnitSystem.Metric,
        abbr: "m³",
        display: "cubic meter",
    }, {
        id: "volume.metric.cubicdecimeter",
        type: UnitType.Volume,
        system: UnitSystem.Metric,
        abbr: "dm³",
        display: "cubic decimeter",
    }, {
        id: "volume.metric.liter",
        type: UnitType.Volume,
        system: UnitSystem.Metric,
        display: "liter",
        abbr: "l",
        size: UnitSize.Medium,
    },
    // Volume - USCS
    {
        id: "volume.uscs.barrel",
        type: UnitType.Volume,
        system: UnitSystem.USCS,
        display: "Barrel",
        abbr: "bar",
    }, {
        id: "volume.uscs.cubicfoot",
        type: UnitType.Volume,
        system: UnitSystem.USCS,
        abbr: "ft³",
        display: "cubic foot",
    }, {
        id: "volume.uscs.cubicinch",
        type: UnitType.Volume,
        system: UnitSystem.USCS,
        abbr: "in³",
        display: "cubic inch",
    }, {
        id: "volume.uscs.gallon",
        type: UnitType.Volume,
        system: UnitSystem.USCS,
        display: "gallon",
        abbr: "gal",
        size: UnitSize.Medium,
    },
    // Volume - Imperial
    {
        id: "volume.imperial.barrel",
        type: UnitType.Volume,
        system: UnitSystem.Imperial,
        size: UnitSize.Medium,
        display: "barrel",
        abbr: "bar",
    }, {
        id: "volume.imperial.gallon",
        type: UnitType.Volume,
        system: UnitSystem.Imperial,
        abbr: "gal",
        display: "gallon",
    },
    // Byte - SI
    {
        id: "byte.si.byte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        display: "byte",
        abbr: "byte",
    }, {
        id: "byte.si.kilobyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        abbr: "KB",
        display: "kilobyte",
    }, {
        id: "byte.si.megabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        abbr: "MB",
        display: "megabyte",
        size: UnitSize.Medium,
    }, {
        id: "byte.si.gigabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        abbr: "GB",
        display: "gigabyte",
    }, {
        id: "byte.si.terabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        display: "terabyte",
        abbr: "TB",
    }, {
        id: "byte.si.petabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        display: "petabyte",
        abbr: "PB",
    }, {
        id: "byte.si.exabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        abbr: "EB",
        display: "exabyte",
    }, {
        id: "byte.si.zettabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        display: "zettabyte",
        abbr: "ZB",
    }, {
        id: "byte.si.yottabyte",
        type: UnitType.Byte,
        system: UnitSystem.SI,
        display: "yottabyte",
        abbr: "YB",
    },
    // Byte - Binary
    {
        id: "byte.binary.kibibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "kibibyte",
        abbr: "KiB",
    }, {
        id: "byte.binary.mebibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "mebibyte",
        abbr: "MiB",
        size: UnitSize.Medium,
    }, {
        id: "byte.binary.gibibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "gibibyte",
        abbr: "GiB",
    }, {
        id: "byte.binary.tebibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "tebibyte",
        abbr: "TiB",
    }, {
        id: "byte.binary.pebibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "pebibyte",
        abbr: "PiB",
    }, {
        id: "byte.binary.exbibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "exibyte",
        abbr: "EiB",
    }, {
        id: "byte.binary.zebibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "zebibyte",
        abbr: "ZiB",
    }, {
        id: "byte.binary.yobibyte",
        type: UnitType.Byte,
        system: UnitSystem.Binary,
        display: "yobibyte",
        abbr: "YiB",
    },
    // Speed - Metric
    {
        id: "speed.metric.ms",
        type: UnitType.Speed,
        system: UnitSystem.Metric,
        abbr: "m/s",
        display: "meters per second",
    }, {
        id: "speed.metric.kmh",
        type: UnitType.Speed,
        system: UnitSystem.Metric,
        abbr: "km/h",
        display: "kilometers per hour",
        size: UnitSize.Medium,
    },
    // Speed uscs
    {
        id: "speed.uscs.mph",
        type: UnitType.Speed,
        system: UnitSystem.USCS,
        abbr: "mph",
        display: "miles per hour",
        size: UnitSize.Medium,
    }, {
        id: "speed.uscs.knot",
        type: UnitType.Speed,
        system: UnitSystem.USCS,
        abbr: "knots",
        display: "knots",
    }, {
        id: "speed.uscs.fts",
        type: UnitType.Speed,
        system: UnitSystem.USCS,
        abbr: "ft/s",
        display: "feet per second",
    },
    // mass - metric
    {
        id: "mass.metric.milligram",
        type: UnitType.Mass,
        system: UnitSystem.Metric,
        abbr: "mg",
        display: "milligram",
    }, {
        id: "mass.metric.gram",
        type: UnitType.Mass,
        system: UnitSystem.Metric,
        abbr: "g",
        display: "gram",
    }, {
        id: "mass.metric.kilogram",
        type: UnitType.Mass,
        system: UnitSystem.Metric,
        abbr: "kg",
        display: "kilogram",
        size: UnitSize.Medium,
    }, {
        id: "mass.metric.ton",
        type: UnitType.Mass,
        system: UnitSystem.Metric,
        abbr: "t",
        display: "ton",
        size: UnitSize.Large,
    },
    // mass - imperial - https://en.wikipedia.org/wiki/Imperial_units
    {
        id: "mass.imperial.ounce",
        type: UnitType.Mass,
        system: UnitSystem.Imperial,
        abbr: "oz",
        display: "ounce",
    }, {
        id: "mass.imperial.pound",
        type: UnitType.Mass,
        system: UnitSystem.Imperial,
        abbr: "lb",
        display: "pound",
        size: UnitSize.Small,
    }, {
        id: "mass.imperial.stone",
        type: UnitType.Mass,
        system: UnitSystem.Imperial,
        abbr: "st",
        display: "stone",
        size: UnitSize.Medium,
    }, {
        id: "mass.imperial.hundredweight",
        type: UnitType.Mass,
        system: UnitSystem.Imperial,
        abbr: "cwt",
        display: "hundredweight",
    }, {
        id: "mass.imperial.ton",
        type: UnitType.Mass,
        system: UnitSystem.Imperial,
        abbr: "t",
        display: "ton",
        size: UnitSize.Large,
    },
    // mass - uscs
    {
        id: "mass.uscs.dram",
        type: UnitType.Mass,
        system: UnitSystem.USCS,
        abbr: "dr",
        display: "dram",
        size: UnitSize.Small,
    }, {
        id: "mass.uscs.pound",
        type: UnitType.Mass,
        system: UnitSystem.USCS,
        abbr: "lb",
        display: "pound",
        size: UnitSize.Medium,
    }, {
        id: "mass.uscs.hundredweight",
        type: UnitType.Mass,
        system: UnitSystem.USCS,
        abbr: "cwt",
        display: "hundredweight",
    }, {
        id: "mass.uscs.ton",
        type: UnitType.Mass,
        system: UnitSystem.USCS,
        abbr: "t",
        display: "ton",
        size: UnitSize.Large,
    },
    // temperature
    {
        id: "temperature.uscs.fahrenheit",
        type: UnitType.Temperature,
        system: UnitSystem.USCS,
        abbr: "°F",
        display: "fahrenheit",
        size: UnitSize.Medium,
    }, {
        id: "temperature.metric.celsius",
        type: UnitType.Temperature,
        system: UnitSystem.Metric,
        abbr: "°C",
        display: "celsius",
        size: UnitSize.Medium,
    },
];
/**
 * auto select a unit, we aim for the first which ends up above zero
 * @param value input value
 * @param inUnit unit of input value
 * @param outUnits array of possible out units
 */
export function findUnit(value, inUnit, outUnits) {
    let outValue;
    let unit;
    if (value === 0) {
        const out = Units.filter(e => outUnits.includes(e.id));
        unit = out.find(e => e.size === UnitSize.Medium)?.id;
        // when whe have an medium unit, we'll take this, if not, continue function
        if (unit) {
            return [unit, 0];
        }
    }
    for (unit of outUnits.reverse()) {
        outValue = UnitConverter.convert(value, inUnit, unit);
        if (Math.abs(outValue) >= 1) {
            return [unit, outValue];
        }
    }
    return [unit, outValue];
}
export function getUnit(id) {
    return Units.find((unit) => unit.id === id);
}
/**
 * Return all matching units.
 * @param type kind of unit, volume, distance, ...
 * @param system unit system, metric, USCS, ...
 * @param onlyCommon only return "common" unit which have an `size` set (small, medium, large)
 */
export function getUnits(type, system, onlyCommon = false) {
    let units = Units.filter((e) => {
        if (system != null)
            return (e.system === system) && (e.type === type);
        return e.type === type;
    });
    if (onlyCommon) {
        units = units.filter(unit => unit.size != null);
    }
    return units;
}
/**
 * Return the id of all matching units.
 * @param type kind of unit, volume, distance, ...
 * @param system unit system, metric, USCS, ...
 * @param onlyCommon only return "common" unit which have an `size` set (small, medium, large)
 */
export function getUnitsIds(type, system, onlyCommon = false) {
    return getUnits(type, system, onlyCommon).map(e => e.id);
}
/**
 * Convert values between different Units.
 */
export class UnitConverter {
    static convert(value, from, to) {
        if (from === to) {
            // sure, no convert
            return value;
        }
        else if (from === "temperature.uscs.fahrenheit") {
            // °C = (°F - 32) * 5/9
            return (value - 32) * 5 / 9;
        }
        else if (from === "temperature.metric.celsius") {
            // °F = °C * 1,8 + 32
            return value * 1.8 + 32;
        }
        else if (this.factors[from] && this.factors[from][to]) {
            // direct factor
            return value * this.factors[from][to];
        }
        else if (this.factors[to] && this.factors[to][from]) {
            // direct factor, but the other way around
            return value / this.factors[to][from];
        }
        else {
            // no direct conversion factor, look for a unit which has factors for both
            for (const unitName in this.factors) {
                const unit = this.factors[unitName];
                if (unit[from] && unit[to]) {
                    return value / unit[from] * unit[to];
                }
            }
        }
        throw new Error(`Found no option to convert from "${from}" to "${to}".`);
    }
}
/**
 * Factor objects
 * These object define the factor for conversion. One "base unit" should be chosen which has factor into all other units. This will be
 * used if no additional direct factor between the two units is defined.
 */
UnitConverter.factors = {
    "volume.metric.cubicmeter": {
        "volume.metric.cubiccentimeter": 1000000,
        "volume.metric.liter": 1000,
        "volume.metric.cubicdecimeter": 1000,
        // uscs = american imperial units
        "volume.uscs.barrel": 8.3864143606,
        "volume.uscs.cubicfoot": 35.314666721,
        "volume.uscs.cubicinch": 61023.744095,
        "volume.uscs.gallon": 264.17205236,
        // 'imperial' = british imperial
        "volume.imperial.barrel": 6.1102568972,
        "volume.imperial.gallon": 219.9692483,
        "volume.imperial.pint": 1759.7539864,
    },
    // read
    // - 1 meter is 3.28084 feet
    // - 1 meter is 100 centimeters
    "distance.metric.meter": {
        "distance.metric.nanometer": 100000,
        "distance.metric.mikrometer": 10000,
        "distance.metric.millimeter": 1000,
        "distance.metric.centimeter": 100,
        "distance.metric.decimeter": 10,
        // "distance.metric.meter": 1,
        "distance.metric.dekameter": 0.1,
        "distance.metric.hektometer": 0.01,
        "distance.metric.kilometer": 0.001,
        "distance.uscs.inch": 39.3701,
        "distance.uscs.feet": 3.28084,
        "distance.uscs.yard": 1.0936,
        "distance.uscs.mile": 0.00062,
        "distance.uscs.nauticmile": 0.000539957,
    },
    "distance.uscs.inch": {
        "distance.metric.centimeter": 2.54,
    },
    "distance.uscs.feet": {
        "distance.metric.centimeter": 30.48,
        "distance.metric.meter": 0.3048,
    },
    "distance.uscs.yard": {
        "distance.uscs.feet": 3,
        "distance.metric.centimeter": 91.44,
        "distance.metric.meter": 0.9144,
    },
    // international mile
    "distance.uscs.mile": {
        "distance.metric.meter": 1609.344,
        "distance.uscs.yard": 1760,
    },
    "byte.si.byte": {
        "byte.si.kilobyte": 1 / Math.pow(10, 3),
        "byte.si.megabyte": 1 / Math.pow(10, 6),
        "byte.si.gigabyte": 1 / Math.pow(10, 9),
        "byte.si.terabyte": 1 / Math.pow(10, 12),
        "byte.si.petabyte": 1 / Math.pow(10, 15),
        "byte.si.exabyte": 1 / Math.pow(10, 18),
        "byte.si.zettabyte": 1 / Math.pow(10, 21),
        "byte.si.yottabyte": 1 / Math.pow(10, 24),
        "byte.binary.kibibyte": 1 / Math.pow(2, 10),
        "byte.binary.mebibyte": 1 / Math.pow(2, 20),
        "byte.binary.gibibyte": 1 / Math.pow(2, 30),
        "byte.binary.tebibyte": 1 / Math.pow(2, 40),
        "byte.binary.pebibyte": 1 / Math.pow(2, 50),
        "byte.binary.exbibyte": 1 / Math.pow(2, 60),
        "byte.binary.zebibyte": 1 / Math.pow(2, 70),
        "byte.binary.yobibyte": 1 / Math.pow(2, 80),
    },
    "speed.metric.ms": {
        "speed.metric.kmh": 3.6,
        "speed.uscs.mph": 2.23694,
        "speed.uscs.knot": 1.9438477170141,
        "speed.uscs.fts": 3.2808453346456296629,
    },
    "mass.metric.kilogram": {
        "mass.metric.milligram": 1000000,
        "mass.metric.gram": 1000,
        "mass.metric.ton": 0.001,
        // imperial = british imperial
        "mass.imperial.ounce": 0.0283495,
        "mass.imperial.pound": 2.20462,
        "mass.imperial.stone": 0.157473,
        "mass.imperial.hundredweight": 0.01968413055,
        "mass.imperial.ton": 0.00098420652,
        // uscs = american imperial units
        "mass.uscs.dram": 0.00177184519,
        "mass.uscs.pound": 2.20462,
        "mass.uscs.hundredweight": 0.02204622621,
        "mass.uscs.ton": 0.00110231131,
    },
};
