import { FpEventModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class CrewLegBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.crewLeg?.enabled) loads.push(this.crewLeg(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, item: unknown): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, id: string): Promise<void> {
		return Promise.resolve();
	}

	private async crewLeg(state: RangeBuilderState): Promise<void> {
		const dscaidList = state.getResourcesIdList("dscaid").map(i => parseInt(i));
		const buckets = this.getBuckets(state);

		for (const bucket of buckets) {
			const crewLegs = await bucket.crewLeg(dscaidList);

			crewLegs.forEach(leg => {
				this.makeEvent(state, () => {
					const event = new FpEventModel({
						id: `_crew_leg:${leg.id}`,
						startDate: this.toTimeZone(leg.blockoff),
						endDate: this.toTimeZone(leg.blockon),
						draggable: false,
						resizable: false,

						fpLinkType: "_crew_leg",
						fpLinkId: leg.id.toString(),
						fpData: leg,
					});

					return event;
				}, function*() {
					yield { linkType: "fpvid", linkId: leg.fpvid };
				});
			});
		}
	}
}
