import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { SlidingPanelCtx, SlidingPanelState } from "./SlidingPanelState";
export const useSlidingPanelContext = () => useContext(SlidingPanelCtx);
export const useSlidingPanel = () => {
    /** ensure there is only one */
    const prevState = useSlidingPanelContext();
    const [state] = useState(() => prevState ?? new SlidingPanelState());
    return state;
};
export const PathCtx = createContext(undefined);
export const usePathContext = () => {
    return useContext(PathCtx);
};
export const usePath = (name, element, deps = []) => {
    const id = useMemo(() => Symbol(name), []);
    const p = usePathContext();
    useEffect(() => {
        if (!p)
            return null;
        const { setPath, removeFromPath } = p;
        setPath(path => {
            if (path.find(e => e.id === id))
                return path;
            return [...path, { name, id, element }];
        });
        return () => {
            removeFromPath(id);
        };
    }, [...deps]);
};
export const SlidingPanelKeyCtx = createContext(undefined);
export const useSlidingPanelKey = () => useContext(SlidingPanelKeyCtx);
