import { Format, UnitSize } from "@tcs-rliess/fp-web-ui";
import React from "react";
import styled from "styled-components";
import { DefaultFooter, DefaultHeader } from "../../lib";
import { DataH4, DataTable, DataTd } from "../../lib/styledExportComponents";
export const LoadCustomerManifest = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultHeader, null),
        React.createElement(PrintTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(TopHeader, { style: { textAlign: "left" } }, props.data?.vehicle?.registrationCode && (React.createElement("h3", { style: { fontWeight: "normal" } }, props.data?.vehicle?.registrationCode))),
                    React.createElement(TopHeader, null,
                        React.createElement("h3", { style: { fontWeight: "normal" } }, "Load Customer Manifest")),
                    React.createElement(TopHeader, { style: { textAlign: "right" } }, props.data?.mainEvent.flight?.flight_no && (React.createElement("h3", { style: { fontWeight: "normal" } },
                        "#",
                        props.data?.mainEvent.flight?.flight_no)))))),
        props.data?.waypointData.map((waypoint, index) => {
            return (React.createElement("div", { key: waypoint.id },
                React.createElement(DataTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", { className: "bgGrey" },
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "#")),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "DEP")),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "DES")),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "STD")),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "STA")),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(DataH4, null, "ETE ")))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                waypoint.id,
                                " ",
                                index + 1,
                                " / ",
                                props.data?.waypointData?.length),
                            React.createElement(DataTd, { style: { textAlign: "left" } }, waypoint.from?.index2),
                            React.createElement(DataTd, { style: { textAlign: "left" } }, waypoint.to?.index2),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(Format.Time, null, waypoint.std)),
                            React.createElement(DataTd, { style: { textAlign: "left" } },
                                React.createElement(Format.Time, null, waypoint.sta)),
                            React.createElement(DataTd, { style: { textAlign: "left" } }, waypoint.ete)))),
                React.createElement("br", null),
                waypoint.cargo.items.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(DataTable, null,
                        React.createElement("thead", null,
                            React.createElement("tr", { className: "bgGrey" },
                                React.createElement(DataTd, { style: { textAlign: "left" } },
                                    React.createElement(DataH4, null, "#")),
                                React.createElement(DataTd, { style: { textAlign: "left" } },
                                    React.createElement(DataH4, null, "CUSTOMER")),
                                React.createElement(DataTd, { style: { textAlign: "left" } },
                                    React.createElement(DataH4, null, "PHONE")),
                                React.createElement(DataTd, { style: { textAlign: "left" } },
                                    React.createElement(DataH4, null, "CARGO")),
                                React.createElement(DataTd, { style: { textAlign: "right" } },
                                    React.createElement(DataH4, null, "WT")),
                                React.createElement(DataTd, { style: { textAlign: "right" } },
                                    React.createElement(DataH4, null, "QTY")),
                                React.createElement(DataTd, { style: { textAlign: "right" } },
                                    React.createElement(DataH4, null, "TTL WT")),
                                React.createElement(DataTd, { style: { textAlign: "left" } },
                                    React.createElement(DataH4, null, "SENDER")))),
                        React.createElement("tbody", null, waypoint.cargo.items.map((cargoItem, index) => {
                            return (React.createElement("tr", { key: cargoItem.id },
                                React.createElement(DataTd, { style: { textAlign: "left" } }, index),
                                React.createElement(DataTd, { style: { textAlign: "left" } }, cargoItem.customerName),
                                React.createElement(DataTd, { style: { textAlign: "left" } }, cargoItem.customerPhone),
                                React.createElement(DataTd, { style: { textAlign: "left", whiteSpace: "normal" } }, cargoItem.description),
                                React.createElement(DataTd, { style: { textAlign: "right" } },
                                    React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium, decimalDigits: 2 }, cargoItem.weight)),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, cargoItem.quantity),
                                React.createElement(DataTd, { style: { textAlign: "right" } },
                                    React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium, decimalDigits: 2 }, cargoItem.weightTotal)),
                                React.createElement(DataTd, { style: { textAlign: "left" } }, cargoItem.senderName)));
                        })),
                        React.createElement("tfoot", null,
                            React.createElement("tr", null,
                                React.createElement(DataTd, { style: { textAlign: "right" }, colSpan: 5 }, "Total"),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, waypoint.cargo.quantityTotal),
                                React.createElement(DataTd, { style: { textAlign: "right" } }, React.createElement(Format.Mass, { ignoreAbbr: true, size: UnitSize.Medium, decimalDigits: 2 }, waypoint.cargo.weightTotal))))),
                    React.createElement("br", null)))));
        }),
        React.createElement(DefaultFooter, null)));
};
const TopHeader = styled.th `
	width: 33%;
	font-weight: normal;
	font-size: 100;
	vertical-align: top;
`;
const PrintTable = styled.table `
	font-size: 13px;
	width: "100%";
`;
