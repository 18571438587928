import { ApiContext, ApiError } from "@tcs-rliess/fp-core";
import { DateTime, Duration } from "luxon";
import { observable } from "mobx";

export class ApiUtil {
	public static apiHost = ""; // http://localhost:3080
	@observable public static serverTimeOffset: Duration;

	public static async fetchRpc(ctx: ApiContext, method: string, params: unknown = {}, signal?: AbortSignal): Promise<any> {
		return this.internalFetchRetry(ctx, this.apiHost + "/api/v0/rpc/" + method, params, signal);
	}

	public static async fetchRpc2(ctx: ApiContext, rpcPath: string, params: unknown, signal?: AbortSignal): Promise<any> {
		return this.internalFetchRetry(ctx, this.apiHost + "/api/v1/rpc/" + rpcPath, params, signal);
	}

	private static async internalFetchRetry(ctx: ApiContext, url: string, params: unknown, signal?: AbortSignal): Promise<unknown> {
		let res: Response;
		const start = new Date();

		try {
			res = await fetch(url, {
				method: "POST",
				signal: signal,
				credentials: "same-origin",
				headers: new Headers({
					"Accept": "application/json",
					"Content-Type": "application/json",
				}),
				body: JSON.stringify({
					params: params,
				}),
			});

			const date = res.headers.get("date");
			this.serverTimeOffset = DateTime.fromHTTP(date).diffNow();

			const json = await res.json();
			if (json.error != null) {
				throw this.buildApiError(json.error);
			} else {
				return json.result;
			}
		} catch (error) {
			const end = new Date();

			const debug = {
				start: start.toISOString(),
				end: end.toISOString(),
				time: +end - +start,
				url: url,
				params: params,
				res: {
					url: res?.url,
					status: res?.status,
					statusText: res?.statusText,
					headers: {},
				},
			};

			if (error.code === error.ABORT_ERR && signal?.aborted) {
				error.debug = debug;
				throw new ApiError(0, "CLIENT_REQUEST_ABORTED", "Request was aborted.", error);
			} else if (error instanceof ApiError) {
				error.internal.debug = debug;
				throw error;
			} else {
				error.debug = debug;
				throw new ApiError(res?.status ?? 0, "NETWORK_REQUEST_ERROR", "Unexpected error while making an API request.", error);
			}
		}
	}

	private static buildApiError(error: any): ApiError {
		const err = new ApiError(error.status, error.code, error.message, error.internal);
		// keep error id
		err.id = error.id ?? err.id;
		err.time = error.time ?? err.time;

		return err;
	}
}
