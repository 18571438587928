import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class ControlledDocumentWorkflowStore extends BaseStoreMulti<FpApi.ControlledDocument.Workflow, number> {
	protected itemId(item: FpApi.ControlledDocument.Workflow): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.ControlledDocument.Workflow[]> {
		return apiManager
			.getService(FpApi.ControlledDocument.WorkflowService)
			.get(this.app.ctx);
	}
}
