import { DateTime } from "luxon";
import React from "react";
import { useTimezone } from "../../../Components";
import { DayPicker } from "../DayPicker";
import { toDateTime } from "./util";
function toJs(input) {
    if (input == null)
        return undefined;
    return new Date(input.year, input.month - 1, input.day);
}
function applyDate(base, input, timezone) {
    if (input == null)
        return base;
    if (base == null) {
        const dt = DateTime.fromObject({
            day: input.getDate(),
            month: input.getMonth() + 1,
            year: input.getFullYear(),
        }, { zone: timezone });
        return dt;
    }
    return base.set({
        day: input.getDate(),
        month: input.getMonth() + 1,
        year: input.getFullYear(),
    });
}
export const DatePicker = function DatePicker(props) {
    const value = toDateTime(props.value, props.tz);
    const min = toDateTime(props.min, props.tz);
    const max = toDateTime(props.max, props.tz);
    const tz = useTimezone(props.tz);
    function onChange(date) {
        if (props.disabled)
            return;
        if (min != null && date.hasSame(min, "day"))
            date = DateTime.max(min, date);
        else if (min != null && date < min)
            return;
        if (max != null && date.hasSame(max, "day"))
            date = DateTime.min(max, date);
        else if (max != null && date > max)
            return;
        date = date.startOf("minute");
        props.onChange(date);
    }
    return React.createElement(DayPicker, { selected: toJs(value), fromMonth: toJs(min), toMonth: toJs(max), className: props.className, defaultMonth: toJs(value) ?? toJs(min), disabled: date => {
            if (props.disabled)
                return true;
            const luxon = applyDate(value, date, tz);
            if (min != null && luxon <= min.startOf("day"))
                return true;
            if (max != null && luxon >= max.endOf("day"))
                return true;
            return false;
        }, onDayClick: input => {
            onChange(applyDate(value, input, tz));
        } });
};
