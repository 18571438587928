import { last } from "lodash";
import React from "react";
import { WorkflowWidget } from "../../../Components";
const FormPathContext = React.createContext(undefined);
export const FormPath = (props) => {
    const [path, setPath] = React.useState([]);
    const removeFromPath = React.useCallback((id) => {
        setPath(path => path.filter(e => e.id !== id));
    }, [path]);
    return React.createElement(FormPathContext.Provider, { value: {
            path,
            setPath,
            removeFromPath,
        } }, props.children);
};
export const FormPathDisplay = () => {
    const { path } = useFormPathContext();
    return React.createElement(WorkflowWidget, null, path.map((e, i) => React.createElement(WorkflowWidget.Step, { isActive: last(path) === e, variant: last(path) === e ? "primary" : "cancelled", key: e.name }, e.element)));
};
export const useFormPath = (name, element, deps = []) => {
    const id = React.useMemo(() => Symbol(name), []);
    const p = useFormPathContext();
    React.useEffect(() => {
        if (!p)
            return null;
        const { setPath, removeFromPath } = p;
        setPath(path => {
            if (path.find(e => e.id === id))
                return path;
            return [...path, { name, id, element }];
        });
        return () => {
            removeFromPath(id);
        };
    }, [...deps]);
};
export const useFormPathContext = () => {
    return React.useContext(FormPathContext);
};
