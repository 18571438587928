import { DirContact } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { observable, action } from "mobx";

import { FleetplanApp } from "../../FleetplanApp";

export interface UserPresenceItem {
	dscaid: number;
	presence: "Offline" | "Online";
	contact: DirContact;
}

export class PresenceStore {
	private log = fpLog.child("PresenceStore")
	private appContext: FleetplanApp;

	constructor(appContext: FleetplanApp) {
		this.appContext = appContext;

		this.appContext.mqtt.subscribe("/presence/#", this.handlePresenceMessage);
		this.appContext.mqtt.addListener("reconnect", this.initialize);
	}

	@observable users: Map<number, UserPresenceItem> = new Map();

	@action.bound public initialize(): void {
		this.sendPresenceMessage();
	}

	@action.bound public sendPresenceMessage(): void {
		this.appContext.mqtt.publish(`/presence/${this.appContext.ctx.dscaid}`, JSON.stringify({ presence: "Online" }), {
			retain: true,
			qos: 0
		}, console.log);
	}

	@action.bound private handlePresenceMessage(topic: string, message): void {
		const messageSplit = topic.split("/");

		if (messageSplit.length !== 3) {
			return this.log.error(`[MQTT] Received invalid MQTT presence message: ${topic}`, { message });
		}

		const dscaid = parseInt(messageSplit[messageSplit.length - 1], 10);

		if (isNaN(dscaid)) {
			return this.log.error(`[MQTT] Received invalid MQTT presence message dscaid: ${topic}`, { message });
		}

		const presence = message != null && Object.prototype.hasOwnProperty.call(message, "presence") ? message.presence : "Offline";

		this.upsertUser(dscaid, presence).catch(this.log.error);
		if (presence === "Offline" && dscaid === this.appContext.ctx.dscaid) { // we do this to get back the online status when we disconnect on another device
			this.sendPresenceMessage();
		}
	}

	@action.bound async upsertUser(dscaid: number, presence: "Online" | "Offline"): Promise<void> {
		const user = this.users.get(dscaid);

		if (user) {
			this.users.set(dscaid, { ...user, presence });
		} else {
			const contact = await this.appContext.store.contact.getId(dscaid);
			this.users.set(dscaid, {
				dscaid,
				presence,
				contact
			});
		}
	}
}
