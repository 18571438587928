import React from "react";
import styled from "styled-components";
import { usePrintContext } from "./PrintContext";
const Header = styled.footer `
	font-size: .8rem;
	display: flex;
	flex-direction: row;
	height: 50px;

	& > div {
		flex: 1;
		padding: .5em;
	}
`;
export const DefaultHeader = function DefaultFooter() {
    const printContext = usePrintContext();
    return React.createElement(Header, null,
        React.createElement("div", null,
            React.createElement("img", { style: { float: "right", height: "100%" }, src: printContext?.customer.$dscaid?.avatarTn })));
};
