import Color from "color";
import { useTheme } from "styled-components";
import { WHITE, BLACK } from "../lib";
export function isThemeColor(color) {
    return [
        // bootstrap colors
        "primary", "success", "danger", "warning", "info",
        // custom colors
        "deeporange", "purple", "confirmed", "cancelled", "yellow"
    ].includes(color);
}
export function useThemeColor(colorString, defaultColor) {
    const theme = useTheme();
    if (isThemeColor(colorString)) {
        return theme.color[colorString];
    }
    else {
        if (colorString == null && defaultColor == null) {
            return null;
        }
        let color;
        try {
            color = Color(colorString);
        }
        catch (e) {
            if (defaultColor == null)
                return null;
            color = Color(defaultColor);
        }
        if (color == null)
            return null;
        return buildColor(theme.kind, color);
    }
}
export function buildColor(themeKind, main, fontColor) {
    const shades = getColorShades(themeKind, main.hex());
    function getColorInfo(color) {
        const cl = Color(color);
        return {
            main: cl.hex(),
            font: cl.isLight() ? BLACK.hex() : WHITE.hex(),
        };
    }
    return {
        "100": getColorInfo(shades[0]),
        "200": getColorInfo(shades[1]),
        "300": getColorInfo(shades[2]),
        "400": getColorInfo(shades[3]),
        "500": { ...getColorInfo(main.hex()), font: fontColor || getColorInfo(main.hex()).font },
        "600": getColorInfo(shades[5]),
        "700": getColorInfo(shades[6]),
        "800": getColorInfo(shades[7]),
        "900": getColorInfo(shades[8]),
    };
}
function getColorShades(themeKind, clr) {
    const ret = [];
    const color = Color(clr);
    for (let i = 4; i > 0; i--) {
        ret.unshift(color.mix(Color("#000"), i * .15));
    }
    ret.unshift(clr);
    for (let i = 1; i < 5; i++) {
        ret.unshift(color.mix(Color("#fff"), i * .2));
    }
    if (themeKind === "dark") {
        ret.reverse();
    }
    return ret;
}
