import { apiManager, FpApi, TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { handleError } from "../../../../handleError";
import { ResourceDutyUtil, ShiftUtil } from "../../../Resource";
import { BaseStoreRange } from "../../BaseStoreRange";

export class ResourceDutyStore extends BaseStoreRange<FpApi.Resource.Duty.Duty, string> {
	private resourceDutyUtil = new ResourceDutyUtil(this.app);

	public update(item: FpApi.Resource.Duty.Duty): void {
		if (item.rRule == null || item.id.split("_").length > 3) {
			// not a repeat, or already resolved
			super.update(item);
		} else {
			// unresolved repeat
			// resolve and then add to store

			this.removeBy(i => i.id.startsWith(item.id));

			const maxRange = this.getMaxLoadedRange();
			if (maxRange == null) return;

			const shifts = this.app.store.resource.shift.getAll();

			this.resourceDutyUtil.resolveRepeats({
				duties: [ item ],
				shifts: shifts,
				shiftUtil: new ShiftUtil(this.app),
				from: maxRange.start,
				to: maxRange.end,
			}).then(duties => {
				for (const duty of duties) {
					super.update(duty);
				}
			}).catch(handleError);
		}
	}

	protected itemId(item: FpApi.Resource.Duty.Duty): string {
		return item.id;
	}

	protected itemDateModified(item: FpApi.Resource.Duty.Duty): DateTime {
		return DateTime.fromISO(item.dateModified);
	}

	protected itemRange(item: FpApi.Resource.Duty.Duty): TimeSpan {
		return new TimeSpan(
			DateTime.fromISO(item.dateStart),
			DateTime.fromISO(item.dateEnd),
		);
	}

	protected async fetchIdList(idList: string[]): Promise<FpApi.Resource.Duty.Duty[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.DutyService)
			.getIdList(this.app.ctx, { idList });
	}

	protected async fetchRange(from: DateTime, to: DateTime): Promise<FpApi.Resource.Duty.Duty[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.DutyService)
			.get(this.app.ctx, {
				from: from.toISO(),
				to: to.toISO(),
			});
	}
}
