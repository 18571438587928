import { FpApi } from "@tcs-rliess/fp-core";
import { useEffect, useState } from "react";

import { useApp } from "../FleetplanApp";

export function useTaskTemplates(params?: { linkType: string, linkId?: string }[]): FpApi.Task.TaskTemplate[] {
	const app = useApp();
	const [ templates, setTemplates ] = useState<FpApi.Task.TaskTemplate[]>([]);

	const lkey = params?.map(({ linkType, linkId }) => `${linkType}:${linkId}`).join(",");

	useEffect(() => {
		function storeTemplates(): void {
			let templates: FpApi.Task.TaskTemplate[] = [];
			if(params?.length) {
				for(const { linkType, linkId } of params) {
					templates = [ ...templates, ...app.store.taskTemplate.getTemplates(linkType, linkId) ];
				}
			} else {
				templates = [ ...app.store.taskTemplate.getTemplates() ];
			}
			setTemplates([ ...templates ]);
		}
		storeTemplates();
		if(params?.length) {
			for(const { linkType, linkId } of params) {
				app.store.taskTemplate.addListener(`put:${linkType}:${linkId}`, storeTemplates);
				app.store.taskTemplate.addListener(`delete:${linkType}:${linkId}`, storeTemplates);
			}
		} else {
			app.store.taskTemplate.addListener("put", storeTemplates);
			app.store.taskTemplate.addListener("delete", storeTemplates);
			return () => {
				app.store.taskTemplate.removeListener("put", storeTemplates);
				app.store.taskTemplate.removeListener("delete", storeTemplates);
			};
		}
	}, [ lkey ]);

	return templates;
}
