import { Format } from "@tcs-rliess/fp-web-ui";
import React from "react";
export const Voucher = (props) => {
    const data = props.data;
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data for Voucher found!");
    //const voucherType = data?.booking?.voucherType;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                ...(props.previewTab
                    ? { width: "21cm", height: "29.7cm" } // print preview tab - preview
                    : { width: "100%", height: "100vh" }), // pdf print preview
                position: "relative",
                color: "#666",
                fontFamily: "Arial, Helvetica, sans-serif",
                lineHeight: "normal"
            } },
            React.createElement("div", { style: {
                    height: "660px",
                    //TODO: change background URL to: _{process.env.FP_LUCEE_FRONTEND_HOST}/project/heliaustria/image/gutschein_R44.jpg?v=1
                    // use image in X/fleetplan/fleetplan/project/_global/heli-1500x1000.jpg
                    backgroundImage: "url('https://www.fleetplan.net/assets/img/photos/heli-2000x1333.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                } },
                React.createElement("div", { style: {
                        position: "absolute",
                        top: "45px",
                        color: "white",
                        backgroundColor: "#00000030",
                        width: "100%",
                        padding: "10px",
                        textAlign: "center",
                        fontSize: "60px",
                        letterSpacing: "4px"
                    } }, "GUTSCHEIN ")),
            React.createElement("div", { style: {
                    marginTop: "15px",
                    marginRight: "15px",
                    marginLeft: "15px",
                    color: "#666"
                } },
                React.createElement("p", { style: { fontSize: "30px", color: "#666" } },
                    data.formatted.product,
                    " "),
                data?.booking.voucherText != null && data?.booking.voucherText !== "" &&
                    React.createElement("p", { style: { fontSize: "25px", color: "#666" } }, data?.booking.voucherText)),
            React.createElement("div", { style: {
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                } },
                React.createElement("table", { style: {
                        marginRight: "auto",
                        marginLeft: "auto",
                        fontSize: "20px",
                        marginBottom: "15px",
                        lineHeight: "26px"
                    } },
                    React.createElement("tr", null,
                        React.createElement("td", { style: { paddingRight: "20px" } }, "Booking-No:"),
                        React.createElement("td", null, data?.booking.runningNo)),
                    React.createElement("tr", null,
                        React.createElement("td", null, "Ausgestellt.:"),
                        React.createElement("td", null,
                            React.createElement(Format.Date, null, data?.booking?.rlog.c ?? "")))),
                React.createElement("div", { style: {
                        backgroundColor: "#f6f6f6",
                        padding: "15px",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    } },
                    React.createElement("div", null,
                        React.createElement("b", null, data?.formatted.client.$organization?.nameOrganization),
                        React.createElement("br", null),
                        data?.formatted.client.$organization?.address1Organization,
                        React.createElement("br", null),
                        data?.formatted.client.$organization?.zipCodeOrganization,
                        " ",
                        data?.formatted.client.$organization?.cityOrganization,
                        React.createElement("br", null),
                        data?.formatted.client.$organization?.countryOrganization,
                        React.createElement("br", null),
                        "Tel. ",
                        data?.formatted.client.$organization?.telephoneOrganization),
                    React.createElement("div", null,
                        React.createElement("img", { src: data?.formatted.client.avatarTn, style: { maxWidth: "300px" } })))))));
};
