import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { useTheme } from "styled-components";
import { CommandBar } from "../CommandBar/CommandBar";
import { useOverlay } from "../OverlayTrigger/OverlayTrigger";
const InternalPopoverComponent = function Popover({ placement: position = "top", ...props }) {
    const overlay = useOverlay();
    position = overlay?.placement || position;
    const popoverRef = React.useRef(null);
    const popoverRect = popoverRef.current?.getBoundingClientRect();
    return (React.createElement(InternalPopover, { className: props.className, style: props.style, ref: (ref) => {
            overlay?.register(ref);
            popoverRef.current = ref;
        } },
        props.title && React.createElement(Header, null,
            React.createElement(CommandBar.Text, null, props.title)),
        props.children,
        position === "top" && React.createElement(TopTick, null),
        position === "left" && React.createElement(LeftTick, { style: { top: overlay ? popoverRect?.y - overlay?.rect?.y : undefined } }),
        position === "bottom" && React.createElement(BottomTick, null),
        position === "right" && React.createElement(RightTick, { style: { top: overlay ? popoverRect?.y - overlay?.rect?.y : undefined } })));
};
const CloseButton = () => {
    const overlay = useOverlay();
    const theme = useTheme();
    if (!overlay)
        return null;
    return React.createElement(Popover.Header.Right, null,
        React.createElement(Popover.Header.Button, { variant: "link", onClick: overlay.close },
            React.createElement(FontAwesomeIcon, { color: theme.color.danger[500].main, icon: faTimes })));
};
const Header = styled(CommandBar) `
height: 30px;
background: ${p => p.theme.content.background};
${CommandBar.Button} {
	line-height: 0px;
	font-size: .8em;
}
${CommandBar.Text} {
	line-height: 19px;
}
`;
const Body = styled.div `
padding: 3px 1em;
`;
const LeftTick = styled.div `
	position: absolute;
    left: -6px;
    width: 0;
    height: 0;
    top: 50%;
    right: 100%;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
`;
const InternalPopover = styled.div `
border: 1px solid ${p => p.theme.defaults.borderColor};
background-color: ${p => p.theme.background};
box-shadow: 0 0 1em rgba(0, 0, 0, .25);
font-size: 12px;
position: relative;
z-index: 10;
isolation: isolate;
min-height: 60px;
max-width: 340px;
`;
const RightTick = styled.div `
	position: absolute;
    right: -6px;
    width: 0;
    height: 0;
    top: 50%;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid white;
`;
const TopTick = styled.div `
    position: absolute;
    top: -6px;
    left: calc(50% - 2px - 6px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${p => p.theme.background};
`;
const BottomTick = styled.div `
    position: absolute;
    bottom: -6px;
    left: calc(50% - 2px - 6px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${p => p.theme.background};
`;
export const Popover = Object.assign(InternalPopoverComponent, {
    Header: Object.assign(Header, {
        Close: CloseButton,
    }),
    Body
});
