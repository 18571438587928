import React from "react";
import { UiContext } from "../../FpWebUiProvider";
const TimezoneContext = React.createContext(undefined);
export const TimezoneProvider = function TimezoneProvider({ timezone, children }) {
    return React.createElement(TimezoneContext.Provider, { value: timezone }, children);
};
export const useTimezone = function useTimezone(providedTz) {
    const tzCtx = React.useContext(TimezoneContext);
    const { formatter } = React.useContext(UiContext);
    if (providedTz)
        return providedTz;
    if (!tzCtx)
        return formatter.options.tz;
    return tzCtx;
};
