import React from "react";
import { Flex, FlexItem } from "../../Components";
import { StyledInfoTableBody } from "./InfoTableBody";
import { InfoTableFooter, InfoTableHeader, StyledField } from "./InfoTableField";
export const InfoTable = function InfoTable(props) {
    const body = [];
    const footer = [];
    React.Children.forEach(props.children, (child) => {
        if (child?.type === StyledInfoTableBody) {
            body.push(child);
        }
        else if (child?.type === InfoTableFooter) {
            footer.push(child);
        }
    });
    return React.createElement(Flex, { column: true, style: { height: "100%", position: "relative" } },
        React.createElement(FlexItem, { grow: true, basis: 0, style: { height: "100%", overflow: "auto" } }, body),
        !!footer.length && React.createElement(FlexItem, { shrink: true, style: { borderTop: "1px solid #ddd", width: "100%" } }, footer));
};
InfoTable.Body = StyledInfoTableBody;
InfoTable.Field = StyledField;
InfoTable.Header = InfoTableHeader;
InfoTable.Footer = InfoTableFooter;
