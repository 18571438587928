import React from "react";
import { useThemeColor } from "../../Css/ThemeColor";
import { Flex } from "../Flex";
import { OverlayTrigger } from "../OverlayTrigger/OverlayTrigger";
import { Tooltip } from "../Tooltip/Tooltip";
const COLOR_DEFAULT = "#777777";
function getBarSize(size) {
    if (typeof size === "string") {
        switch (size) {
            case "xs":
                return 10.4;
            case "sm":
                return 12.8;
            case "md":
                return 16;
            case "lg":
                return 20;
        }
    }
    else {
        return size;
    }
}
const ProgressBarChild = (props) => {
    const ctx = React.useContext(ProgressBarCtx);
    const val = ctx.value ?? props.value;
    const max = (ctx.max ?? props.max) ?? 100;
    let childValue = 0;
    // if the value is bigger than our max, we set the value to 100%
    if (val > max) {
        childValue = 100;
    }
    else {
        childValue = (val / max) * 100;
    }
    const background = useThemeColor(props.variant, COLOR_DEFAULT)[props.shade ?? 500];
    const backgroundColor = background.main;
    const size = getBarSize(ctx.size ?? "md");
    const childStyle = {
        height: size,
        width: `${childValue}%`,
        backgroundColor: backgroundColor,
    };
    return props.popoverText
        ? (React.createElement(OverlayTrigger, { placement: props.popoverPlacement ?? "bottom", overlay: React.createElement(Tooltip, null, props.popoverText) },
            React.createElement("div", { style: { ...childStyle } })))
        : React.createElement("div", { style: { ...childStyle } });
};
const ProgressBarCtx = React.createContext(null);
export const ProgressBar = (props) => {
    const background = useThemeColor(props.variant, COLOR_DEFAULT)[props.shade ?? 500];
    const fontThemeColor = useThemeColor(props.fontColor, COLOR_DEFAULT)[props.fontShade ?? 500];
    const fontColor = props.fontColor
        ? fontThemeColor?.main
            ? fontThemeColor.main
            : props.fontColor
        : background.font;
    const backgroundColor = background.main;
    const size = getBarSize(props.size ?? "md");
    const parentStyle = {
        height: size,
        backgroundColor: "whitesmoke", // empty color (default background)
        width: "100%",
        position: "relative",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
    };
    const labelStyle = {
        fontSize: (size - 5),
        paddingBottom: 2,
        position: "absolute",
        left: "50%",
        top: "-8%",
        color: fontColor,
        textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
    };
    return React.createElement("div", { style: { ...parentStyle } },
        React.createElement(ProgressBarCtx.Provider, { value: props },
            (props.label && (size >= 16)) && React.createElement("span", { style: { ...labelStyle } }, props.label),
            props.children
                ? (React.createElement(Flex, null, props.children))
                : (props.popoverText
                    ? (React.createElement(OverlayTrigger, { placement: props.popoverPlacement ?? "bottom", overlay: React.createElement(Tooltip, null, props.popoverText) },
                        React.createElement(ProgressBarChild, { variant: backgroundColor, max: props.max, value: props.value })))
                    : (React.createElement(ProgressBarChild, { variant: backgroundColor, max: props.max, value: props.value })))));
};
ProgressBar.defaultProps = {
    max: 100,
    variant: "primary"
};
ProgressBar.Child = ProgressBarChild;
