import { faChevronLeft, faChevronRight, faThumbtack } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Flex, FlexItem } from "../../Components";
import { DockerLayoutContext } from "./context";
import { DockedLayoutSide } from "./util";
/** @deprecated use docked manager */
export const DockedLayoutPanel = (props) => {
    const layout = React.useContext(DockerLayoutContext);
    let bodyStyle = { overflowY: "auto" };
    if (props.bodyStyle) {
        bodyStyle = props.bodyStyle;
    }
    return React.createElement(Flex, { style: { height: "100%" }, column: true, className: props.className },
        React.createElement(Flex, { column: true, style: { borderBottom: "1px solid #dddddd" } },
            React.createElement(Flex, { row: true }, layout.side === DockedLayoutSide.Left ? (React.createElement(React.Fragment, null,
                props.renderHeader && React.createElement(props.renderHeader, null),
                React.createElement(FlexItem, { grow: true }),
                (layout.pinMode !== "hidden") &&
                    React.createElement(Button, { size: "sm", style: { border: "none" }, onClick: layout.onPinnedToggle, active: layout.isPinned },
                        React.createElement(FontAwesomeIcon, { icon: faThumbtack })),
                (layout.collapseMode !== "hidden") &&
                    React.createElement(Button, { size: "sm", style: { border: "none" }, onClick: layout.onCollapse },
                        React.createElement(FontAwesomeIcon, { icon: faChevronLeft })))) : (React.createElement(React.Fragment, null,
                (layout.collapseMode !== "hidden") &&
                    React.createElement(Button, { disabled: layout.collapseMode === "disabled", size: "sm", style: { border: "none" }, onClick: layout.onCollapse },
                        React.createElement(FontAwesomeIcon, { icon: faChevronRight })),
                (layout.pinMode !== "hidden") &&
                    React.createElement(Button, { disabled: layout.pinMode === "disabled", size: "sm", style: { border: "none" }, onClick: layout.onPinnedToggle, active: layout.isPinned },
                        React.createElement(FontAwesomeIcon, { icon: faThumbtack })),
                React.createElement(FlexItem, { grow: true }),
                props.renderHeader && React.createElement(props.renderHeader, null))))),
        React.createElement(FlexItem, { grow: true, style: bodyStyle }, props.children));
};
