import { faBullseye } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "color";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { useWidthHeight } from "../../lib/hooks";
import { Flex, FlexItem } from "../Flex";
import { Label } from "../Label";
export var MatrixAxis;
(function (MatrixAxis) {
    MatrixAxis[MatrixAxis["Y"] = 0] = "Y";
    MatrixAxis[MatrixAxis["X"] = 1] = "X";
})(MatrixAxis || (MatrixAxis = {}));
export const RiskMatrixLabel = (props) => {
    const [x, y] = props.value ?? [];
    const [cells, setCells] = React.useState(new Map());
    const getSchema = (id) => {
        return props.template.schema.find(e => e.id === id);
    };
    /** Make cells accessable */
    React.useEffect(() => {
        const mapping = new Map();
        props.template.matrix.forEach(e => {
            if (mapping.has(e.x)) {
                mapping.get(e.x).set(e.y, e);
            }
            else {
                mapping.set(e.x, new Map());
                mapping.get(e.x).set(e.y, e);
            }
        });
        setCells(mapping);
    }, [props.template.matrix]);
    const [xAxis, setXAxis] = React.useState();
    const [yAxis, setYAxis] = React.useState();
    const cell = props.value ? cells.get(x)?.get(y) : null;
    const yAxisValue = yAxis?.level[y];
    const xAxisValue = xAxis?.level[x];
    const schema = cell ? getSchema(cell.schemaId) : null;
    React.useEffect(() => {
        if (props.template.impact.pos === MatrixAxis.Y) {
            setYAxis(props.template.impact);
        }
        else {
            setXAxis(props.template.impact);
        }
        if (props.template.likelihood.pos === MatrixAxis.Y) {
            setYAxis(props.template.likelihood);
        }
        else {
            setXAxis(props.template.likelihood);
        }
    }, []);
    const score = props.template.outputTemplate
        .replace("$yLabel", `${yAxisValue?.name ?? y ?? ""}`)
        .replace("$xLabel", `${xAxisValue?.name ?? x ?? ""}`)
        .replace("$name", schema?.name)
        .replace("$value", (() => {
        switch (props.template.calculation) {
            case "computed": return `${((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || "0"}`;
            case "custom": return `${cell?.value}` || "-1";
        }
    })())
        .replace("$customValue", `${cell?.value || ((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || ""}` ?? "")
        .replace("$x", `${x}` ?? "")
        .replace("$y", `${y}` ?? "");
    return React.createElement(Label, { variant: schema?.color, ...props, style: { ...props.style } }, score);
};
export const RiskMatrix = observer(function RiskMatrix(props) {
    const container = React.useRef();
    const corner = React.useRef();
    let { width, } = useWidthHeight(container);
    width = width || 400;
    /** Make cells accessable */
    const cells = React.useMemo(() => {
        const mapping = new Map();
        props.template.matrix.forEach(e => {
            if (mapping.has(e.x)) {
                mapping.get(e.x).set(e.y, e);
            }
            else {
                mapping.set(e.x, new Map());
                mapping.get(e.x).set(e.y, e);
            }
        });
        return mapping;
    }, [props.template.matrix]);
    React.useEffect(() => {
        if (!props.element && props.onChangeElement) {
            props.onChangeElement(props.template.defaultElement);
        }
    }, []);
    React.useEffect(() => {
        if (props.onDataCallback) {
            props.onDataCallback(onDataChange());
        }
    }, [props.value]);
    function onDataChange(x, y) {
        if (x == null && y == null)
            [x, y] = props.value ?? [];
        const cell = x != null && y != null ? cells.get(x)?.get(y) : null;
        const yAxisValue = yAxis?.level[y];
        const xAxisValue = xAxis?.level[x];
        const schema = cell ? getSchema(cell.schemaId) : null;
        let value;
        switch (props.template.calculation) {
            case "computed":
                value = (xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0);
                break;
            case "custom":
                value = cell?.value || -1;
                break;
        }
        const score = props.template.outputTemplate
            .replace("$yLabel", `${yAxisValue?.name ?? y ?? ""}`)
            .replace("$xLabel", `${xAxisValue?.name ?? x ?? ""}`)
            .replace("$name", schema?.name ?? "")
            .replace("$value", (() => {
            switch (props.template.calculation) {
                case "computed": return `${((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || ""}`;
                case "custom": return `${cell?.value}` || "-1";
            }
        })())
            .replace("$customValue", `${cell?.value || ((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || ""}` ?? "")
            .replace("$x", `${x ?? ""}`)
            .replace("$y", `${y ?? ""}`);
        return {
            [yAxis?.identifier]: yAxis?.level[y]?.value,
            [xAxis?.identifier]: xAxis?.level[x]?.value,
            scoreText: score,
            scoreValue: value,
            scoreColor: new Color(schema?.color).hex(),
        };
    }
    const matrix = React.useMemo(() => {
        const matrix = [];
        for (let y = 0; y < props.template.ySize; y++) { // add extra cell for our outer layouting
            for (let x = 0; x < props.template.xSize; x++) {
                matrix.push({ y, x });
            }
        }
        return matrix;
    }, [props.template.ySize, props.template.xSize]);
    const [yAxis, xAxis] = React.useMemo(() => {
        let yAxis;
        let xAxis;
        if (props.template.impact.pos === MatrixAxis.Y) {
            yAxis = props.template.impact;
        }
        else {
            xAxis = props.template.impact;
        }
        if (props.template.likelihood.pos === MatrixAxis.Y) {
            yAxis = props.template.likelihood;
        }
        else {
            xAxis = props.template.likelihood;
        }
        return [yAxis, xAxis];
    }, []);
    const getSchema = (id) => {
        return props.template.schema.find(e => e.id === id);
    };
    if (props.template.likelihood.pos === props.template.impact.pos)
        return React.createElement("div", null, "Likelihood and Impact cannot have the same position!");
    const activeElement = props.element ? props.template.elements.find(e => e.name === props.element) : null;
    return React.createElement("div", { className: props.className ?? "", style: { fontSize: "0.85em", ...props.style, } },
        React.createElement(MatrixContainer, { template: props.template },
            React.createElement(MatrixCorner, { ref: corner },
                React.createElement(MatrixCell, null, yAxis?.label)),
            React.createElement(MatrixSelect, { ref: corner },
                React.createElement(MatrixCell, { style: { padding: 0, height: "100%" } },
                    React.createElement("select", { value: props.element, onChange: e => props.onChangeElement ? props.onChangeElement(e.target.value) : console.warn("'onChangeElement' not set!"), style: { width: "100%", height: "100%", border: "none", padding: "0.3rem" } }, props.template.elements.map(element => React.createElement("option", { key: element.name, value: element.name }, element.name))))),
            React.createElement(MatrixXAxisDescription, { ref: corner },
                React.createElement(MatrixCell, null, xAxis?.label)),
            React.createElement(MatrixYAxisContainer, { template: props.template }, Array.from(new Array(props.template.ySize).keys()).map(e => React.createElement(MatrixCell, { title: activeElement?.values[e] && activeElement?.values[e][yAxis?.identifier], key: e },
                React.createElement(Flex, null, props.template.colTemplate ? React.createElement(FlexItem, { style: { width: "100%" } }, (() => {
                    return props.template.colTemplate.replace("$value", yAxis?.level[e]?.value?.toString())
                        .replace("$description", yAxis?.level[e]?.description)
                        .replace("$name", yAxis?.level[e]?.name);
                })()) : React.createElement(React.Fragment, null,
                    React.createElement(FlexItem, { grow: true }, yAxis?.level[e]?.name),
                    yAxis?.showValue && React.createElement(FlexItem, { shrink: true },
                        "(",
                        yAxis?.level[e]?.value,
                        ")")))))),
            React.createElement(MatrixXAxisContainer, { template: props.template }, Array.from(new Array(props.template.xSize).keys()).map(e => React.createElement(MatrixCell, { title: activeElement?.values[e] && activeElement?.values[e][xAxis?.identifier], key: e },
                React.createElement(Flex, null, props.template.colTemplate ? React.createElement(FlexItem, { style: { width: "100%" } }, (() => {
                    return props.template.colTemplate.replace("$value", xAxis?.level[e]?.value?.toString())
                        .replace("$description", xAxis?.level[e]?.description)
                        .replace("$name", xAxis?.level[e]?.name);
                })()) : React.createElement(React.Fragment, null,
                    React.createElement(FlexItem, { grow: true }, xAxis?.level[e]?.name),
                    xAxis?.showValue && React.createElement(FlexItem, { shrink: true },
                        "(",
                        xAxis?.level[e]?.value,
                        ")")))))),
            React.createElement(MatrixCellContainer, { template: props.template, ref: container }, matrix.map(e => {
                const cell = cells.get(e.x)?.get(e.y);
                const schema = cell ? getSchema(cell.schemaId) : null;
                const [x, y] = props.value ?? [];
                const color = new Color(schema?.color ?? "rgb(255,255,255)");
                return React.createElement(MatrixCellContent, { style: { background: color.toString(), color: color.isLight() ? "black" : "white", opacity: x === e.x && y === e.y ? 1 : 0.5 }, onClick: () => {
                        props.onChange?.([e.x, e.y]);
                        props.onDataChange?.(onDataChange(e.x, e.y));
                    }, key: `${e.x}-${e.y}` },
                    React.createElement(Flex, null,
                        React.createElement(FlexItem, { grow: true },
                            React.createElement(RiskMatrixCell, { ...props, x: e.x, y: e.y, cell: cell, schema: schema, yAxis: yAxis?.level[e.y], xAxis: xAxis?.level[e.x] })),
                        x === e.x && y === e.y && React.createElement(FlexItem, { shrink: true },
                            React.createElement(FontAwesomeIcon, { icon: faBullseye }))));
            }))),
        (() => {
            // lower 
            const [x, y] = props.value ?? [];
            const cell = props.value ? cells.get(x)?.get(y) : null;
            const yAxisValue = yAxis?.level[y];
            const xAxisValue = xAxis?.level[x];
            const schema = cell ? getSchema(cell.schemaId) : null;
            const score = props.template.outputTemplate
                .replace("$yLabel", `${yAxisValue?.name ?? y ?? ""}`)
                .replace("$xLabel", `${xAxisValue?.name ?? x ?? ""}`)
                .replace("$name", schema?.name)
                .replace("$value", (() => {
                switch (props.template.calculation) {
                    case "computed": return `${((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || "0"}`;
                    case "custom": return `${cell?.value}` || "-1";
                }
            })())
                .replace("$customValue", `${cell?.value || ((xAxisValue?.value ?? x ?? 0) * (yAxisValue?.value ?? y ?? 0)) || "0"}` ?? "")
                .replace("$x", `${x}` ?? "")
                .replace("$y", `${y}` ?? "");
            const color = new Color(schema?.color ?? "rgb(255,255,255)");
            return React.createElement(Flex, { row: true, style: { width: "100%", paddingTop: 12, height: "100%", border: "1px solid #ddd", borderTop: "none" } },
                React.createElement(Flex, { grow: true, row: true, style: { fontSize: "0.8em", padding: "0.8rem" } },
                    React.createElement(Flex, { column: true, style: { display: "none" } }, props.template.schema.map(schema => React.createElement("p", { key: schema.id },
                        React.createElement(FontAwesomeIcon, { icon: faCircle, color: schema.color }),
                        "\u00A0",
                        schema?.name,
                        "\u00A0",
                        schema.description)))),
                React.createElement(Flex, { shrink: true, column: true },
                    React.createElement(Flex, { style: { width: "100%" } },
                        React.createElement(FlexItem, { grow: true },
                            React.createElement(MatrixCell, { style: { border: "none", textAlign: "end", width: `calc(${width ?? 0}px/${props.template.xSize})` } }, xAxis?.label)),
                        React.createElement(FlexItem, { shrink: true, style: { border: "none", background: schema?.color, color: color.isLight() ? "black" : "white", width: `calc(${width ?? 0}px/${props.template.xSize})` } },
                            React.createElement(MatrixCell, { style: { border: "none", width: "100%" } }, xAxis?.level[x]?.name))),
                    React.createElement(Flex, { style: { width: "100%" } },
                        React.createElement(FlexItem, { grow: true },
                            React.createElement(MatrixCell, { style: { border: "none", textAlign: "end", width: `calc(${width ?? 0}px/${props.template.xSize})` } }, yAxis?.label)),
                        React.createElement(FlexItem, { shrink: true, style: { border: "none", background: schema?.color, color: color.isLight() ? "black" : "white", width: `calc(${width ?? 0}px/${props.template.xSize})` } },
                            React.createElement(MatrixCell, { style: { border: "none", width: "100%" } }, yAxis?.level[y]?.name))),
                    React.createElement(Flex, { style: { width: "100%" } },
                        React.createElement(FlexItem, { grow: true },
                            React.createElement(MatrixCell, { style: { border: "none", textAlign: "end", width: `calc(${width ?? 0}px/${props.template.xSize})` } }, "Score:")),
                        React.createElement(FlexItem, { shrink: true, style: { border: "none", background: schema?.color, color: color.isLight() ? "black" : "white", width: `calc(${width ?? 0}px/${props.template.xSize})` } },
                            React.createElement(MatrixCell, { style: { border: "none", width: "100%" } }, score)))));
        })());
});
/**
     * $name -> name of the Schema of the Cell (High/Low)
     * $value -> Computed or custom value
     * $x -> x Position
     * $y -> y Position
     * $xLabel -> xLabel of the Position (A/B/C)
     * $yLabel -> yLabel of the Position (A/B/C)
     */
export const RiskMatrixCell = (props) => {
    let cellName = props.template.matrixContentTemplate
        .replace("$name", props.schema?.name)
        .replace("$value", (() => {
        switch (props.template.calculation) {
            case "computed": return `${(props.xAxis?.value ?? props.x ?? 0) * (props.yAxis?.value ?? props.y ?? 0) || "0"}`;
            case "custom": return `${props.cell?.value}` || "-1";
        }
    })())
        .replace("$customValue", `${props.cell?.value || (props.xAxis?.value ?? props.x ?? 0) * (props.yAxis?.value ?? props.y ?? 0) || "0"}` ?? "")
        .replace("$yLabel", `${props.yAxis?.name ?? props.y}`)
        .replace("$xLabel", `${props.xAxis?.name ?? props.x}`)
        .replace("$x", `${props.x}`)
        .replace("$y", `${props.y}`);
    if (props.schema?.forceLabel) {
        cellName = props.schema.name;
    }
    return React.createElement("div", null, cellName);
};
export const MatrixContainer = styled.div `
display: grid;
grid-template-areas: ${props => {
    switch (props.template.position) {
        case "left-bottom": return `${`'yAxis ${"cell ".repeat(props.template.xSize)}'`.repeat(props.template.ySize)} 'corner ${"xAxis ".repeat(props.template.xSize)}' `; // '${"score ".repeat(props.template.xSize + 1)}'
        case "left-top": return `'select ${"xAxisdescr ".repeat(props.template.xSize)}' 'corner ${"xAxis ".repeat(props.template.xSize)}' ${`'yAxis ${"cell ".repeat(props.template.xSize)}'`.repeat(props.template.ySize)} `; // '${"score ".repeat(props.template.xSize + 1)}'
        case "right-bottom": return `${`'${"cell ".repeat(props.template.xSize)} yAxis'`.repeat(props.template.ySize)} '${"xAxis ".repeat(props.template.xSize)}corner' `; // '${"score ".repeat(props.template.xSize + 1)}'
        case "right-top": return `'${"xAxis ".repeat(props.template.xSize)}corner' ${`'${"cell ".repeat(props.template.xSize)} yAxis'`.repeat(props.template.ySize)}`; // '${"score ".repeat(props.template.xSize + 1)}'
    }
}};
border-right: 1px solid #ddd;
border-bottom: 1px solid #ddd;
`;
export const MatrixCellContainer = styled.div `
grid-area: cell;
display: grid;
grid-template-columns: repeat(${props => props.template.xSize}, minmax(0, 1fr));
grid-template-rows: repeat(${props => props.template.ySize}, minmax(0, 1fr));
background: white;
`;
export const MatrixCell = styled.div `
padding: 0.6rem;
border: 1px solid #ddd;
border-bottom: none;
border-right: none;
`;
export const MatrixCellContent = styled.div `
padding: 0.6rem;
border: 1px solid #ddd;
border-bottom: none;
border-right: none;
cursor: pointer;
:hover {
	outline: 3px solid #ddd;
}
`;
export const MatrixCorner = styled.div `
grid-area: corner;
`;
export const MatrixSelect = styled.div `
grid-area: select;
`;
export const MatrixXAxisDescription = styled.div `
grid-area: xAxisdescr;
`;
export const MatrixXAxisContainer = styled.div `
grid-area: xAxis;
display: grid;
grid-template-columns: repeat(${props => props.template.xSize}, minmax(0, 1fr));
`;
export const MatrixYAxisContainer = styled.div `
grid-area: yAxis;
display: grid;
grid-template-rows: repeat(${props => props.template.ySize}, minmax(0, 1fr));
`;
// export const MatrixScoreContainer = styled.div<{template: RiskMatrixTemplate}>`
// grid-area: score;
// display: grid;
// grid-template-columns: repeat(${props => props.template.xSize + 1}, 1fr);
// `;
export const matrix = {
    drf: {
        template: {
            name: "Example Template",
            xSize: 5,
            ySize: 5,
            position: "left-top",
            calculation: "computed",
            defaultElement: "People",
            elements: [{
                    name: "People",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight injury or health effect	"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor injury or health effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Major injury or health effect	"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "PTD or up to 3 fatalities"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "More than 3 fatalities	"
                        }]
                }, {
                    name: "Assets",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight damage"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor damage"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate damage"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major damage"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive damage"
                        }]
                }, {
                    name: "Environment",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight effect"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major effect"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive effect"
                        }]
                }, {
                    name: "Reputation",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight impact"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor impact"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major impact"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive impact"
                        }]
                }],
            impact: {
                identifier: "likelihood",
                label: "Likelihood",
                showValue: true,
                pos: 0,
                level: [{
                        description: "Lowest possible",
                        name: "oft",
                        value: 50
                    }, {
                        description: "Lowest possible",
                        name: "gelegentlich",
                        value: 10
                    }, {
                        description: "Lowest possible",
                        name: "entfernt möglich",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "unwahrscheinlich",
                        value: 1
                    }, {
                        description: "Lowest possible",
                        name: "extrem unwahrscheinlich",
                        value: 0
                    }],
            },
            likelihood: {
                identifier: "impact",
                label: "Impact",
                pos: 1,
                showValue: true,
                level: [{
                        description: "Lowest possible",
                        name: "gefährlich",
                        value: 50
                    }, {
                        description: "Lowest possible",
                        name: "groß",
                        value: 10
                    }, {
                        description: "Lowest possible",
                        name: "mäßig",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "gering",
                        value: 1
                    }, {
                        description: "Lowest possible",
                        name: "nicht relevant",
                        value: 0
                    }],
            },
            matrixContentTemplate: "$value",
            outputTemplate: "$value",
            schema: [{
                    id: 0,
                    color: "rgb(0,176,80)",
                    description: "This level indicates: Risk mitigation is not required",
                    name: "Low"
                }, {
                    id: 1,
                    color: "rgb(255,255,4)",
                    description: "Risk should be mitigated",
                    name: "Medium"
                }, {
                    id: 2,
                    color: "rgb(255,192,2)",
                    description: "Risk should be mitigated right now",
                    name: "High"
                }, {
                    id: 3,
                    color: "rgb(255,1,0)",
                    description: "Risk should be mitigated right now",
                    name: "High"
                }, {
                    id: 4,
                    color: "rgb(255,255,255)",
                    description: "Not applicable",
                    name: "N/A",
                    forceLabel: true,
                }],
            matrix: [{
                    x: 0,
                    y: 0,
                    schemaId: 3,
                }, {
                    x: 1,
                    y: 0,
                    schemaId: 3,
                }, {
                    x: 2,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 0,
                    schemaId: 1,
                }, {
                    x: 4,
                    y: 0,
                    schemaId: 4,
                },
                {
                    x: 0,
                    y: 1,
                    schemaId: 3,
                }, {
                    x: 1,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 2,
                    y: 1,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 1,
                    schemaId: 4,
                },
                {
                    x: 0,
                    y: 2,
                    schemaId: 2,
                }, {
                    x: 1,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 2,
                    schemaId: 4,
                },
                {
                    x: 0,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 1,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 3,
                    schemaId: 4,
                },
                {
                    x: 0,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 4,
                    schemaId: 4,
                },
            ]
        }
    },
    ryan: {
        template: {
            name: "Example Template",
            xSize: 4,
            ySize: 4,
            position: "left-top",
            calculation: "computed",
            defaultElement: "People",
            elements: [{
                    name: "People",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No injury"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Minor injury"
                        }, {
                            likelihood: "More typical risk",
                            impact: "Major injury"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Fatality"
                        }]
                }, {
                    name: "Assets",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No equipment damage"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Cosmetic damage"
                        }, {
                            likelihood: "More typical risk",
                            impact: "Minor equipment or aircraft damage"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Significant damage"
                        }]
                }, {
                    name: "Environment",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No Measurable Effect"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Less than minor environmental impact"
                        }, {
                            likelihood: "More typical risk",
                            impact: "Minor Environmental Impact"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Major impact on environment"
                        }]
                }, {
                    name: "System or Process",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No effect"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Little or no effect on system, subsystem or process"
                        }, {
                            likelihood: "More typical risk",
                            impact: "System deficiencies leading to poor dependability or disruption"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Loss or breakdown of entire system, subsystem or process"
                        }]
                }, {
                    name: "Reputation",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No negative effect"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Slight effect on company reputation"
                        }, {
                            likelihood: "More typical risk",
                            impact: "Negative impact on company reputation"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Major impact on reputation"
                        }]
                }, {
                    name: "Regulatory",
                    values: [{
                            likelihood: "Rare risk with almost no probability of occurring",
                            impact: "No deviation, continue monitoring"
                        }, {
                            likelihood: "Relatively uncommon risk",
                            impact: "Company policy/procedure deviation"
                        }, {
                            likelihood: "More typical risk",
                            impact: "Minor regulatory deviation"
                        }, {
                            likelihood: "Highy likely to occur",
                            impact: "Major regulatory deviation"
                        }]
                }],
            impact: {
                identifier: "impact",
                label: "Severity",
                pos: 1,
                level: [{
                        description: "Lowest possible",
                        name: "A",
                        value: 4
                    }, {
                        description: "Lowest possible",
                        name: "B",
                        value: 3
                    }, {
                        description: "Lowest possible",
                        name: "C",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "D",
                        value: 1
                    }],
            },
            likelihood: {
                identifier: "likelihood",
                label: "Likelihood",
                pos: 0,
                level: [{
                        description: "Lowest possible",
                        name: "1",
                        value: 4
                    }, {
                        description: "Lowest possible",
                        name: "2",
                        value: 3
                    }, {
                        description: "Lowest possible",
                        name: "3",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "4",
                        value: 1
                    }],
            },
            matrixContentTemplate: "$name",
            outputTemplate: "$value",
            schema: [{
                    id: 0,
                    color: "rgb(146,208,80)",
                    description: "Little to no effect on event, system or aircraft",
                    name: "Low"
                }, {
                    id: 1,
                    color: "rgb(0,176,240)",
                    description: "Effects are felt, but not critical to the outcome",
                    name: "Minor"
                }, {
                    id: 2,
                    color: "rgb(255,255,0)",
                    description: "Is or could lead to a serious impact to the course of action and/or outcome",
                    name: "Moderate"
                }, {
                    id: 3,
                    color: "rgb(255,0,0)",
                    description: "Has or could result in disaster",
                    name: "High"
                }],
            matrix: [{
                    x: 0,
                    y: 0,
                    schemaId: 3,
                }, {
                    x: 1,
                    y: 0,
                    schemaId: 3,
                }, {
                    x: 2,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 0,
                    schemaId: 1,
                },
                {
                    x: 0,
                    y: 1,
                    schemaId: 3,
                }, {
                    x: 1,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 2,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 1,
                    schemaId: 1,
                },
                {
                    x: 0,
                    y: 2,
                    schemaId: 2,
                }, {
                    x: 1,
                    y: 2,
                    schemaId: 2,
                }, {
                    x: 2,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 2,
                    schemaId: 0,
                },
                {
                    x: 0,
                    y: 3,
                    schemaId: 2,
                }, {
                    x: 1,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 3,
                    schemaId: 0,
                }]
        }
    },
    nhc: {
        template: {
            name: "NHC Matrix",
            xSize: 5,
            ySize: 5,
            position: "left-top",
            calculation: "computed",
            defaultElement: "Personnel",
            elements: [{
                    name: "Personnel",
                    values: [{
                            likelihood: "Almost Inconcelvable that the event will occur (it hast never occurred in the history of the aviation industry)",
                            impact: "Superficial or not injuries"
                        }, {
                            likelihood: "Very unlikly to occur (not known to have occurred in the company but has already occurred at least once in the history of the aviation industry)",
                            impact: "Slight injuries"
                        }, {
                            likelihood: "Unlikely to occur, but possible (has maybe occured in the company at least once. Has regularly occured in the history of the aviation industry)",
                            impact: "Serious Injuries"
                        }, {
                            likelihood: "likely to occur sometimes (has already occurred in the company (Freq. < 3 x year). Has occurred infrequently in the history of the aviation industry)",
                            impact: "Fatality"
                        }, {
                            likelihood: "Likely to occur many times (has already occurred in the company (Freq. > 3 x year). Has occurred frequently in the history of the aviation industry)",
                            impact: "Multiple fatalities"
                        }]
                }, {
                    name: "Environment",
                    values: [{
                            likelihood: "Almost Inconcelvable that the event will occur (it hast never occurred in the history of the aviation industry)",
                            impact: "Negligible or no effects"
                        }, {
                            likelihood: "Very unlikly to occur (not known to have occurred in the company but has already occurred at least once in the history of the aviation industry)",
                            impact: "Little impact"
                        }, {
                            likelihood: "Unlikely to occur, but possible (has maybe occured in the company at least once. Has regularly occured in the history of the aviation industry)",
                            impact: "Noteworthly local effects"
                        }, {
                            likelihood: "likely to occur sometimes (has already occurred in the company (Freq. < 3 x year). Has occurred infrequently in the history of the aviation industry)",
                            impact: "Effects difficult to repair"
                        }, {
                            likelihood: "Likely to occur many times (has already occurred in the company (Freq. > 3 x year). Has occurred frequently in the history of the aviation industry)",
                            impact: "Massive effects (pollution, destruction, etc.)"
                        }]
                }, {
                    name: "Material",
                    values: [{
                            likelihood: "Almost Inconcelvable that the event will occur (it hast never occurred in the history of the aviation industry)",
                            impact: "Damage < 10k€"
                        }, {
                            likelihood: "Very unlikly to occur (not known to have occurred in the company but has already occurred at least once in the history of the aviation industry)",
                            impact: "Damage < 50K€"
                        }, {
                            likelihood: "Unlikely to occur, but possible (has maybe occured in the company at least once. Has regularly occured in the history of the aviation industry)",
                            impact: "Damage < 250k€"
                        }, {
                            likelihood: "likely to occur sometimes (has already occurred in the company (Freq. < 3 x year). Has occurred infrequently in the history of the aviation industry)",
                            impact: "Damage < 1M€"
                        }, {
                            likelihood: "Likely to occur many times (has already occurred in the company (Freq. > 3 x year). Has occurred frequently in the history of the aviation industry)",
                            impact: "Damage > 1M€"
                        }]
                }, {
                    name: "Image",
                    values: [{
                            likelihood: "Almost Inconcelvable that the event will occur (it hast never occurred in the history of the aviation industry)",
                            impact: "Light or no Impact"
                        }, {
                            likelihood: "Very unlikly to occur (not known to have occurred in the company but has already occurred at least once in the history of the aviation industry)",
                            impact: "Limited Impact"
                        }, {
                            likelihood: "Unlikely to occur, but possible (has maybe occured in the company at least once. Has regularly occured in the history of the aviation industry)",
                            impact: "Considerable Impact"
                        }, {
                            likelihood: "likely to occur sometimes (has already occurred in the company (Freq. < 3 x year). Has occurred infrequently in the history of the aviation industry)",
                            impact: "National Impact"
                        }, {
                            likelihood: "Likely to occur many times (has already occurred in the company (Freq. > 3 x year). Has occurred frequently in the history of the aviation industry)",
                            impact: "International Impact"
                        }]
                }],
            impact: {
                identifier: "impact",
                label: "RISK SEVERITY",
                pos: 1,
                level: [{
                        description: "NEGLIGBLE",
                        name: "1",
                        value: 1
                    }, {
                        description: "MINOR",
                        name: "2",
                        value: 2
                    }, {
                        description: "MAJOR",
                        name: "3",
                        value: 3
                    }, {
                        description: "HAZARDOUS",
                        name: "4",
                        value: 4
                    }, {
                        description: "CATASTROPHIC",
                        name: "5",
                        value: 5
                    }],
            },
            likelihood: {
                identifier: "likelihood",
                label: "RISK PROBABILITY",
                pos: 0,
                level: [{
                        description: "FREQUENT",
                        name: "E",
                        value: 5
                    }, {
                        description: "OCCASIONAL",
                        name: "D",
                        value: 4
                    }, {
                        description: "REMOTE",
                        name: "C",
                        value: 3
                    }, {
                        description: "IMPROBABLE",
                        name: "B",
                        value: 2
                    }, {
                        description: "EXTREMLY IMPROBABLE",
                        name: "A",
                        value: 1
                    }],
            },
            matrixContentTemplate: "$yLabel / $xLabel",
            outputTemplate: "$yLabel / $xLabel",
            schema: [{
                    id: 0,
                    color: "#00b050",
                    description: "This level indicates: Risk mitigation is not required",
                    name: "Low"
                }, {
                    id: 1,
                    color: "#ffff00",
                    description: "Risk should be mitigated",
                    name: "Medium"
                }, {
                    id: 2,
                    color: "#ff0000",
                    description: "Risk should be mitigated right now",
                    name: "High"
                }],
            matrix: [{
                    x: 0,
                    y: 0,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 0,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 0,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 1,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 1,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 2,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 2,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 4,
                    y: 3,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 4,
                    schemaId: 1,
                },
            ],
            colTemplate: "$description ($name)"
        },
    },
    default: {
        template: {
            name: "Example Template",
            xSize: 5,
            ySize: 5,
            position: "left-top",
            calculation: "computed",
            defaultElement: "People",
            elements: [{
                    name: "People",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight injury or health effect	"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor injury or health effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Major injury or health effect	"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "PTD or up to 3 fatalities"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "More than 3 fatalities	"
                        }]
                }, {
                    name: "Assets",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight damage"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor damage"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate damage"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major damage"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive damage"
                        }]
                }, {
                    name: "Environment",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight effect"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major effect"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive effect"
                        }]
                }, {
                    name: "Reputation",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight impact"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor impact"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major impact"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive impact"
                        }]
                }],
            impact: {
                identifier: "impact",
                label: "Impact",
                pos: 0,
                level: [{
                        description: "Lowest possible",
                        name: "1",
                        value: 1
                    }, {
                        description: "Lowest possible",
                        name: "2",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "3",
                        value: 3
                    }, {
                        description: "Lowest possible",
                        name: "4",
                        value: 4
                    }, {
                        description: "Lowest possible",
                        name: "5",
                        value: 5
                    }],
            },
            likelihood: {
                identifier: "likelihood",
                label: "Likelihood",
                pos: 1,
                level: [{
                        description: "Lowest possible",
                        name: "A",
                        value: 1
                    }, {
                        description: "Lowest possible",
                        name: "B",
                        value: 2
                    }, {
                        description: "Lowest possible",
                        name: "C",
                        value: 3
                    }, {
                        description: "Lowest possible",
                        name: "D",
                        value: 4
                    }, {
                        description: "Lowest possible",
                        name: "E",
                        value: 5
                    }],
            },
            matrixContentTemplate: "$name",
            outputTemplate: "$xLabel$yLabel",
            schema: [{
                    id: 0,
                    color: "#4CAF50",
                    description: "This level indicates: Risk mitigation is not required",
                    name: "Low"
                }, {
                    id: 1,
                    color: "#f1c40f",
                    description: "Risk should be mitigated",
                    name: "Medium"
                }, {
                    id: 2,
                    color: "#e74c3c",
                    description: "Risk should be mitigated right now",
                    name: "High"
                }],
            matrix: [{
                    x: 0,
                    y: 0,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 0,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 0,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 0,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 0,
                    schemaId: 0,
                },
                {
                    x: 0,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 1,
                    schemaId: 1,
                }, {
                    x: 4,
                    y: 1,
                    schemaId: 1,
                },
                {
                    x: 0,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 4,
                    y: 2,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 3,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 3,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 4,
                    schemaId: 1,
                }, {
                    x: 1,
                    y: 4,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 4,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 4,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 4,
                    schemaId: 2,
                },
            ]
        }
    },
    hta: {
        template: {
            name: "HTA",
            xSize: 5,
            ySize: 5,
            colTemplate: "$description ($name)",
            position: "left-top",
            calculation: "computed",
            defaultElement: "People",
            elements: [{
                    name: "People",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight injury or health effect	"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor injury or health effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Major injury or health effect	"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "PTD or up to 3 fatalities"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "More than 3 fatalities	"
                        }]
                }, {
                    name: "Assets",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight damage"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor damage"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate damage"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major damage"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive damage"
                        }]
                }, {
                    name: "Environment",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight effect"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor effect"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major effect"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive effect"
                        }]
                }, {
                    name: "Reputation",
                    values: [{
                            likelihood: "Never heard of before in the industry",
                            impact: "Slight impact"
                        }, {
                            likelihood: "Heard of in the industry",
                            impact: "Minor impact"
                        }, {
                            likelihood: "Has happened in the Organisation or more than once in the industry",
                            impact: "Moderate effect"
                        }, {
                            likelihood: "Has happened at the location or more than once per year in the organisation",
                            impact: "Major impact"
                        }, {
                            likelihood: "Has happened more than once per year at the location",
                            impact: "Massive impact"
                        }]
                }],
            impact: {
                identifier: "impact",
                label: "RISK SEVERITY",
                pos: 1,
                level: [{
                        description: "NEGLIGIBLE",
                        name: "A",
                        value: 5
                    }, {
                        description: "MINOR",
                        name: "B",
                        value: 4
                    }, {
                        description: "MAJOR",
                        name: "C",
                        value: 3
                    }, {
                        description: "HAZARDOUS",
                        name: "D",
                        value: 2
                    }, {
                        description: "CATASTROPHIC",
                        name: "E",
                        value: 1
                    }],
            },
            likelihood: {
                identifier: "likelihood",
                label: "RISK LIKELIHOOD",
                pos: 0,
                level: [{
                        description: "FREQUENT",
                        name: "5",
                        value: 5
                    }, {
                        description: "OCCASIONAL",
                        name: "4",
                        value: 4
                    }, {
                        description: "REMOTE",
                        name: "3",
                        value: 3
                    }, {
                        description: "IMPROBABLE",
                        name: "2",
                        value: 2
                    }, {
                        description: "EXTREMELY IMPROBABLE",
                        name: "1",
                        value: 1
                    }],
            },
            matrixContentTemplate: "$yLabel $xLabel",
            outputTemplate: "$xLabel$yLabel",
            schema: [{
                    id: 0,
                    color: "#4CAF50",
                    description: "This level indicates: Risk mitigation is not required",
                    name: "Low"
                }, {
                    id: 1,
                    color: "#f1c40f",
                    description: "Risk should be mitigated",
                    name: "Medium"
                }, {
                    id: 2,
                    color: "#e74c3c",
                    description: "Risk should be mitigated right now",
                    name: "High"
                }],
            matrix: [{
                    x: 0,
                    y: 0,
                    schemaId: 1,
                }, {
                    x: 1,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 2,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 0,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 0,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 1,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 1,
                    schemaId: 1,
                }, {
                    x: 2,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 3,
                    y: 1,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 1,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 2,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 2,
                    schemaId: 1,
                }, {
                    x: 3,
                    y: 2,
                    schemaId: 2,
                }, {
                    x: 4,
                    y: 2,
                    schemaId: 2,
                },
                {
                    x: 0,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 3,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 3,
                    schemaId: 1,
                }, {
                    x: 4,
                    y: 3,
                    schemaId: 1,
                },
                {
                    x: 0,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 1,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 2,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 3,
                    y: 4,
                    schemaId: 0,
                }, {
                    x: 4,
                    y: 4,
                    schemaId: 0,
                },
            ]
        }
    }
};
RiskMatrix.defaultProps = matrix.hta;
