import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

export class HolidaySetStore extends BaseStoreMulti<FpApi.Calendar.Holiday.HolidaySet, number> {
	protected itemId(item: FpApi.Calendar.Holiday.HolidaySet): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Calendar.Holiday.HolidaySet[]> {
		return apiManager
			.getService(FpApi.Calendar.Holiday.HolidayService)
			.get(this.ctx);
	}
}
