import React from "react";
const FlexContext = React.createContext({
    flexDirection: "row",
    itemSpacing: 0,
});
export const FlexItem = React.forwardRef(function FlexItem(props, r) {
    React.useImperativeHandle(r, () => ref.current);
    const ref = React.useRef();
    const style = props.style || {};
    let grow = props.grow || 0;
    if (typeof grow === "boolean")
        grow = grow ? 1 : 0;
    let shrink = props.shrink || 0;
    if (typeof shrink === "boolean")
        shrink = shrink ? 1 : 0;
    let basis = props.basis;
    if (grow > 0) {
        basis = basis || 0;
    }
    else {
        basis = basis || "auto";
    }
    if (typeof basis === "number")
        basis = `${basis}px`;
    style.flex = `${grow} ${shrink} ${basis}`;
    switch (props.flexDirection) {
        case "row":
            style.width = basis;
            break;
        case "column":
            style.height = basis;
            break;
    }
    style.alignSelf = props.alignSelf;
    return (React.createElement(FlexContext.Consumer, null, (value) => React.createElement("div", { style: { ...style, ...props.style ?? {}, ...value }, className: props.className, ref: ref, onClick: props.onClick, title: props.title }, props.children)));
});
export const Flex = React.forwardRef(function Flex(props, r) {
    const state = React.useContext(FlexContext);
    const style = props.style || {};
    const ref = React.useRef();
    React.useImperativeHandle(r, () => ref.current);
    style.display = "flex";
    if (props.displayNone) {
        style.display = "none";
    }
    // flexDirection: style.flexDirection as "row" | "column",
    if (state.itemSpacing) {
        style.margin = `calc(${state.itemSpacing} / 2)`;
    }
    if (props.gap) {
        if (typeof props.gap === "number") {
            style.gap = `${props.gap}px`;
        }
        else {
            style.gap = props.gap;
        }
    }
    if (props.column) {
        style.flexDirection = "column";
    }
    else {
        style.flexDirection = "row";
    }
    style.alignItems = props.alignItems;
    style.alignContent = props.alignContent;
    style.justifyContent = props.justifyContent;
    if (props.wrap === true) {
        style.flexWrap = "wrap";
    }
    else if (props.wrap === "reverse") {
        style.flexWrap = "wrap-reverse";
    }
    else {
        style.flexWrap = "nowrap";
    }
    const finalProps = Object.assign({}, props);
    finalProps.style = { ...style };
    let itemSpacing = props.itemSpacing;
    if (typeof itemSpacing === "number") {
        itemSpacing = `${itemSpacing}px`;
    }
    if (itemSpacing) {
        finalProps.style.margin = `calc(${itemSpacing} / -2)`;
    }
    finalProps.style = { ...finalProps.style, ...props.style ?? {} };
    // React.cloneElement(value.props.child, cloneProps);
    return React.createElement(FlexContext.Provider, { value: {
            flexDirection: props.column ? "column" : "row",
            itemSpacing: props.itemSpacing,
        } },
        React.createElement(FlexItem, { ...finalProps, ref: ref }, props.children));
});
