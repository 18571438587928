import { FpReadsClient, GetReadsParams, GetReadsResponse } from "@tcs-rliess/fp-core";
import { DataProcessorGetParams, FP_QUERY } from "@tcs-rliess/fp-query";

import { FleetplanApp } from "../../FleetplanApp";

export class FpReadsClientDataProcessor {
	private app: FleetplanApp;
	private client: FpReadsClient;

	constructor (app: FleetplanApp, params: { use: string; }) {
		this.app = app;
		this.client = new FpReadsClient(params);
	}

	public getReads(options: DataProcessorGetParams<GetReadsParams>): Promise<GetReadsResponse> {
		return FP_QUERY.query({
			key: "FpReadsClient.getReads",
			params: options.params,
			maxAge: options.cache?.maxAge,
			work: (params) => {
				return this.client.getReads(this.app.readTrackingRegion, this.app.dscid, params);
			},
		});
	}

	public markAsRead(params: { documentid: number; document_r: number; articleid: number; article_r: number; }): Promise<void> {
		return FP_QUERY.mutation({
			params: params,
			work: (params) => {
				return this.client.markAsRead(this.app.readTrackingRegion, this.app.dscid, params);
			},
			keyPrefix: [
				"FpReadsClient.getReads",
			],
		});
	}
}
