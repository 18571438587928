import { observer } from "mobx-react-lite";
import React from "react";
import { TabStateCtx } from "./lib";
export const DropdownParent = React.createContext(null);
// behaves like a tab, but isnt
export const TabDropdown = observer(function Tabs(props) {
    const ctx = React.useContext(TabStateCtx);
    // generated tabKey
    const [tabKey] = React.useState(() => Symbol());
    React.useEffect(() => {
        ctx.registerPanel(tabKey, props.title, { dropdown: true, hasDropdownParent: false, props: { color: props.color, } });
        return () => ctx.unregisterPanel(tabKey);
    }, []);
    React.useEffect(() => {
        const state = ctx.getState(tabKey);
        if (state) {
            state.setColor(props.color);
        }
    }, [props.color]);
    React.useEffect(() => {
        ctx.getState(tabKey)?.setDisabled(props.isDisabled);
    }, [props.isDisabled]);
    React.useEffect(() => {
        ctx.getState(tabKey)?.setHidden(props.isHidden ?? false);
    }, [props.isHidden]);
    React.useEffect(() => {
        ctx.getState(tabKey)?.setTitle(props.title);
    }, [props.title]);
    return React.createElement(DropdownParent.Provider, { value: tabKey }, props.children);
});
