import { FpApi } from "@tcs-rliess/fp-core";
import { castArray, get, set, toPath } from "lodash-es";

import { FleetplanApp } from "../../FleetplanApp";

import { DataLoader } from "./DataLoader";

export const RESOLVED = Symbol("resolved");
export const RESOLVE_RELATION = Symbol("resolveRelation");
const RESOLVE_DONE = Symbol("resolveDone");

export type LoadRelation = Omit<FpApi.InlineRelation, "category"> & {
	dscid?: number;
	dscatid?: number;
	dsstocklid?: string;
	"dscatid:old"?: number;
	"dscaid:state"?: number;
};

export interface ResolveConfig {
	/**
	 * Path to a property to determine the link type if each item in the array.
	 */
	pathLinkType?: string;
	/**
	 * Optional path to prefix all `paths` with.
	 * The `resolved` object will only use the `path` and ignore the `basePath`
	 */
	pathBase?: string;

	types: Record<string, {
		paths: Array<ResolvePathConfig>;
	}>;
}

export interface ResolvePathConfig<T = any> {
	when?: (item: T) => boolean;

	resolvedPath?: string;
	linkType: string;
	linkId: string | ((item: T) => number | number[] | string | string[] | LoadRelation | LoadRelation[]);
}

interface ResolverParams<ITEM extends object> {
	app: FleetplanApp;
	config: ResolveConfig;
	force?: boolean;
	items: ITEM[];
}

export class Resolver<ITEM extends object> {
	// private app: FleetplanApp;
	private dataLoader: DataLoader;

	private config: ResolveConfig;
	// private state: ResolveState<ITEM>;
	private items: ITEM[];
	private loadList: Map<string, Map<string | number, LoadRelation>>;
	/** linkType / linkId / object */
	private resolvedMap: Map<string, Map<unknown, unknown>>;

	private matches: Array<{
		item: ITEM;
		sourceObject: any;
		resolvedObject: any;
		isArray: boolean;
		relations: LoadRelation[];
		pathConfig: ResolvePathConfig;
	}>;

	public static async resolve<ITEM extends object>(params: ResolverParams<ITEM>): Promise<void> {
		const resolver = new Resolver(params);

		// resolving works in three steps

		// 1. loop through all items, and collect id's we need to load
		//    they will be collected in state.loadList, in Set's
		resolver.collectItems(params.force);
		// 2. load all items
		await resolver.loadItems();
		// 3. apply loaded data to the original item
		resolver.resolveItems();
	}

	private constructor(params: ResolverParams<ITEM>) {
		// this.app = params.app;
		this.dataLoader = new DataLoader(params.app);

		this.config = params.config;
		this.items = params.items;
		this.loadList = new Map();
		this.resolvedMap = new Map();
		this.matches = [];
	}

	private collectItems(force = false): void {
		const addLoad = (relation: LoadRelation): void => {
			const linkType = relation.type;
			const linkId = relation[linkType];

			// directory links need some special handling
			// - we unify everything as "fpdirid"
			// - "fpdirlink" are link multiple resource and be break them down for the load list
			switch (linkType) {
				case "fpdirloc":
				case "fpdirgrp":
				case "fpdirpos":
				case "fpdirsec": {
					addLoad({ type: "fpdirid", fpdirid: relation[linkType] });
					return;
				}
				case "fpdirlink": {
					addLoad({ type: "fpdirid", fpdirid: relation.fpdirloc });
					addLoad({ type: "fpdirid", fpdirid: relation.fpdirgrp });
					addLoad({ type: "fpdirid", fpdirid: relation.fpdirpos });
					addLoad({ type: "fpdirid", fpdirid: relation.fpdirsec });
					return;
				}
			}

			// ignore items with no id
			if (linkId == null || linkId === 0 || linkId === "") {
				return;
			}

			// put into load map
			const linkTypeMap = this.loadList.get(linkType);
			if (linkTypeMap == null) {
				this.loadList.set(linkType, new Map([[ linkId, relation ]]));
			} else {
				linkTypeMap.set(linkId, relation);
			}
		};

		// loop trough all items and collect ids
		for (const item of this.items) {
			if (force === false) {
				if (item[RESOLVE_DONE]) continue;
				item[RESOLVE_DONE] = true;
			}

			// figure out link type and type config
			const linkType = get(item, this.config.pathLinkType);
			const typeConfig = this.config.types[linkType] ?? this.config.types["__DEFAULT"];

			// ignore if unknown type
			if (typeConfig == null) continue;

			for (const pathConfig of typeConfig.paths) {
				let sourceObject: any;

				if (this.config.pathBase) {
					sourceObject = get(item, this.config.pathBase);
				} else {
					sourceObject = item;
				}

				// find "resolved" object
				const resolvedObject = sourceObject[RESOLVED] ?? (sourceObject[RESOLVED] = {});

				// check if path applies
				let applies = true;
				if (pathConfig.when) applies = pathConfig.when(sourceObject);
				if (applies !== true) continue;

				// store if we are an array or not
				let isArray = false;
				let relations: LoadRelation[];

				// resolve
				let result;
				if (typeof pathConfig.linkId === "string") {
					const path = toPath(pathConfig.linkId);
					result = [ sourceObject ];

					for (const pathElement of path) {
						result = result
							.flatMap(current => {
								isArray = isArray || Array.isArray(current[pathElement]);
								return current[pathElement];
							})
							.filter(o => o != null);
					}
				} else {
					// call function
					result = pathConfig.linkId(sourceObject);
					isArray = Array.isArray(result);
					result = castArray(result);
				}

				// resolve relation object
				const linkType = pathConfig.linkType;
				if (linkType === "!relation") {
					relations = result;
				} else {
					relations = result.map(id => ({ type: linkType, [linkType]: id }));
				}

				// add to load list
				relations.forEach(relation => addLoad(relation));

				this.matches.push({ item, sourceObject, resolvedObject, pathConfig, isArray, relations });
			}
		}
	}

	private async loadItems(): Promise<void> {
		for (const [ linkType, relations ] of this.loadList) {
			const resolvedItems = await this.dataLoader.load(linkType, Array.from(relations.values()));

			this.resolvedMap.set(linkType, resolvedItems);

			resolvedItems.forEach((value, linkId) => {
				// set relation into resolved item
				value[RESOLVE_RELATION] = this.loadList.get(linkType).get(linkId);
			});
		}
	}

	private resolveItems(): void {
		const resolve = (relation: LoadRelation): unknown => {
			switch (relation.type) {
				case "fpdirloc":
				case "fpdirgrp":
				case "fpdirpos":
				case "fpdirsec": {
					return this.resolvedMap
						.get("fpdirid")
						?.get(relation[relation.type]);
				}
				case "fpdirlink": {
					return {
						fpdirloc: this.resolvedMap.get("fpdirid")?.get(relation.fpdirloc),
						fpdirgrp: this.resolvedMap.get("fpdirid")?.get(relation.fpdirgrp),
						fpdirpos: this.resolvedMap.get("fpdirid")?.get(relation.fpdirpos),
						fpdirsec: this.resolvedMap.get("fpdirid")?.get(relation.fpdirsec),
						[RESOLVE_RELATION]: relation,
					};
				}
				default: {
					return this.resolvedMap
						.get(relation.type)
						?.get(relation[relation.type]);
				}
			}
		};

		for (const item of this.items) {
			if(!item[RESOLVED]) item[RESOLVED] = {};
			item[RESOLVED]["map"] = this.resolvedMap;
		}

		for (const match of this.matches) {
			if (match.pathConfig.resolvedPath == null) continue;

			if (match.isArray) {
				const mapped = match.relations
					.map(relation => resolve(relation))
					.filter(o => o != null);

				set(match.resolvedObject, match.pathConfig.resolvedPath, mapped);
			} else {
				const relation = match.relations[0];
				if (relation == null) {
					set(match.resolvedObject, match.pathConfig.resolvedPath, undefined);
					continue;
				}

				set(match.resolvedObject, match.pathConfig.resolvedPath, resolve(relation));
			}
		}
	}
}
