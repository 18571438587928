import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

const routeService = apiManager.getService(FpApi.Resource.RouteAircraftService);

export class RouteStore extends BaseStoreMulti<FpApi.Resource.RouteAircraft, number> {
	protected fetchAll(): Promise<FpApi.Resource.RouteAircraft[]> {
		return routeService.get(this.app.ctx);
	}

	protected itemId(item: FpApi.Resource.RouteAircraft): number {
		return item.id;
	}

	public async put(params: FpApi.Resource.RouteAircraftServicePutParams): Promise<FpApi.Resource.RouteAircraft> {
		const route = await routeService.put(this.app.ctx, params);
		this.update(route);
		return route;
	}

	public async delete(params: FpApi.Resource.RouteAircraftServiceDeleteParams): Promise<void> {
		await routeService.delete(this.app.ctx, params);
		this.remove(params.id);
	}
}
