import { faUserLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PERMISSION_LINK_TYPE } from "@tcs-rliess/fp-core";
import { Alert } from "@tcs-rliess/fp-web-ui";
import React from "react";

/**
 * Used with the `withCheck` method, should be rebuild to actually show more useful information. 
 */
export const BannerNoPermission: React.FC<{
	type: PERMISSION_LINK_TYPE;
	id?: string;
	lvl: number;
}> = function BannerNoPermission(props) {
	return <Alert color="danger">
		<FontAwesomeIcon icon={faUserLock} size="3x"/>
		<div>
			<span>No Access</span><br/>
			<span className="text-muted">
				type: {props.type}&nbsp;&mdash;&nbsp;
				{props.id == null ? null : <>
					id: {props.id}&nbsp;&mdash;&nbsp;
				</>}
				lvl: {props.lvl}
			</span>
		</div>
	</Alert>;
};
