import React from "react";
import styled, { keyframes } from "styled-components";
const breatheAnimation = keyframes `
	0% { opacity: 0.15; }
	50% { opacity: 0.20; }
	100% { opacity: 0.15; }
`;
const SkeletonRectIntern = styled.span `
	display: inline-block;
	background: ${p => p.theme.font};
	border-radius: 4px;

	animation-name: ${breatheAnimation};
	animation-duration: 2s;
	animation-iteration-count: infinite;
`;
const SkeletonRect = function SkeletonRect(props) {
    let width = props.width;
    if (typeof width === "number")
        width = width + "px";
    let height = props.height;
    if (typeof height === "number")
        height = height + "px";
    return React.createElement(SkeletonRectIntern, { style: {
            width: width || "auto",
            height: height || "auto",
        } }, props.children);
};
const SkeletonLine = function SkeletonLine(props) {
    return React.createElement(SkeletonRect, { width: props.width }, "\u200D");
};
export const Skeleton = {
    Line: SkeletonLine,
    Rect: SkeletonRect,
};
