import styled from "styled-components";
// see https://www.pdfreactor.com/product/doc_html/index.html#ContinuationMarkers
export const ContinuationMarkers = styled.div `
	&::-ro-before-break {
		content: "Continued on page " -ro-counter-offset(page, 1);
		text-align: center;
		opacity: .7;
		font-size: .6rem;
	}
	&::-ro-after-break {
		content: "Continuation from page " -ro-counter-offset(page, -1);
		text-align: center;
		opacity: .7;
		font-size: .6rem;
	}
`;
