import { ActivityReport as DefaultActivityReport } from "./customer/_global/ActivityReport";
import { FlightOrder as DefaultFlightOrder } from "./customer/_global/FlightOrder";
import { LoadCustomerManifest as DefaultLoadCustomerManifest } from "./customer/_global/LoadCustomerManifest";
import { LoadManifest as DefaultLoadManifest } from "./customer/_global/LoadManifest";
import { MovementTable as DefaultMovementTable } from "./customer/_global/MovementTable";
import { PilotsLogbook as DefaultPilotsLogbook } from "./customer/_global/PilotsLogbook";
import { Voucher as DefaultVoucher } from "./customer/_global/Voucher";
import { Voucher as HeliAustriaVoucher } from "./customer/heliaustria/Voucher";
import { FlightOrder as HTMFlightOrder } from "./customer/htm/FlightOrder";
import { FlightOrder as MeravoFlightOrder } from "./customer/meravo/FlightOrder";
import { FlightOrder as RotorflugFlightOrder } from "./customer/rotorflug/FlightOrder";
const byProject = {
    "drf": {},
    "ARA": {},
    "heliaustria": {
        "Voucher": HeliAustriaVoucher
    },
    "heli-flight": {},
    "meravo": {
        "FlightOrder": MeravoFlightOrder
    },
    "htm": {
        "FlightOrder": HTMFlightOrder
    },
    "rotorflug": {
        "FlightOrder": RotorflugFlightOrder
    },
    "feg": {},
    "_global": {
        "ActivityReport": DefaultActivityReport,
        "LoadManifest": DefaultLoadManifest,
        "LoadCustomerManifest": DefaultLoadCustomerManifest,
        "MovementTable": DefaultMovementTable,
        "Voucher": DefaultVoucher,
        "FlightOrder": DefaultFlightOrder,
        "PilotsLogbook": DefaultPilotsLogbook
    }
};
export function getByProject(project, template) {
    if (byProject[project]?.[template]) {
        return byProject[project][template];
    }
    else {
        return byProject["_global"]?.[template];
    }
}
