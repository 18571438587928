import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useController } from "react-hook-form";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
import { useFieldHook } from "../../index";
import { faHyphen } from "@fortawesome/pro-light-svg-icons";
const InternalCheckbox = function Checkbox(props) {
    const baseVariant = useThemeColor(props.variant ?? "#fffffe");
    const offVariant = useThemeColor(props.offVariant ?? "#fffffe");
    const onVariant = useThemeColor(props.onVariant ?? "#fffffe");
    return (React.createElement(StyledCheckbox, { size: props.size, style: props.style, colorEnabled: props.onVariant ? onVariant[500].main : baseVariant[500].main, colorNotEnabled: props.offVariant ? offVariant[500].main : baseVariant[200].main, fontColor: props.onVariant ? onVariant[500].font : baseVariant[500].font, borderColor: props.onVariant ? onVariant[500].main : baseVariant[500].main, value: props.value, tabIndex: 0, onKeyDown: e => {
            if (e.key === "Enter" || e.key === " ") {
                if (props.disabled)
                    return;
                props.onChange?.(!props.value);
            }
        }, onClick: () => {
            if (props.disabled)
                return;
            props.onChange?.(!props.value);
        }, "aria-disabled": props.disabled }, props.value && React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.value ? props.partialValue ? faHyphen : faCheck : null })));
};
InternalCheckbox.defaultProps = {
    size: "md",
    variant: "primary",
    offVariant: "#fffffe",
};
const InternalCheckboxHook = function CheckboxHook(props) {
    const { name } = useFieldHook() ?? {};
    const finalName = props.name || name;
    const controller = useController({ name: finalName });
    return React.createElement(InternalCheckbox, { ...props, value: controller.field.value, onChange: e => {
            controller.field.onChange(e);
            props.onChange?.(e);
        } });
};
export const Checkbox = Object.assign(InternalCheckbox, {
    Hook: InternalCheckboxHook
});
export const StyledCheckbox = styled.div `
--animation-duration: .1s;
${p => {
    switch (p.size) {
        case "sm":
            return `
				--component-size: .8em;
				--font-size: .5em;
				`;
        case "md":
            return `
				--component-size: 1em;
				--font-size: .8em;
				`;
        case "lg":
            return `
				--component-size: 1.2em;
				--font-size: 1em;
				`;
        default:
            return `
				--component-size: 1em;
				--font-size: .8em;
				`;
    }
}}

width: var(--component-size);
height: var(--component-size);
position: relative;
cursor: pointer;
background-color: ${p => p.value ? p.colorEnabled : p.colorNotEnabled};
border: .5px solid ${p => p.borderColor};
${p => p["aria-disabled"] ? css `
	opacity: .5;
	cursor: not-allowed;
	user-select: none;
` : ""}

& > svg {
	position: absolute;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	font-size: var(--font-size);
	color: ${p => p.fontColor};
}

&:focus {
	outline: 1px solid ${p => p.borderColor};
	outline-offset: 1px;
}
`;
