// Can override the following:
//
// style: PropTypes.shape({}),
// innerStyle: PropTypes.shape({}),
// reactVirtualizedListProps: PropTypes.shape({}),
// scaffoldBlockPxWidth: PropTypes.number,
// slideRegionSize: PropTypes.number,
// rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
// treeNodeRenderer: PropTypes.func,
// nodeContentRenderer: PropTypes.func,
// placeholderRenderer: PropTypes.func,
import { FleetplanAlwaysExpandContentRenderer } from "./FleetplanAlwaysExpandContentRenderer";
import { FleetplanAlwaysExpandNodeRenderer } from "./FleetplanAlwaysExpandNodeRenderer";
import { FleetplanNodeContentRenderer } from "./FleetplanNodeContentRenderer";
import { FleetplanTreeNodeRenderer } from "./FleetplanTreeNodeRenderer";
export const FleetplanTreeTheme = {
    scaffoldBlockPxWidth: 25,
    slideRegionSize: 50, // was 0
    rowHeight: 34,
    nodeContentRenderer: FleetplanNodeContentRenderer,
    treeNodeRenderer: FleetplanTreeNodeRenderer,
};
export const FleetplanTreeThemeCoursePointer = {
    scaffoldBlockPxWidth: 46,
    slideRegionSize: 50, // was 0
    rowHeight: 46,
    nodeContentRenderer: FleetplanNodeContentRenderer,
    treeNodeRenderer: FleetplanTreeNodeRenderer,
};
export const FleetplanAllExpandedTreeTheme = {
    scaffoldBlockPxWidth: 15,
    slideRegionSize: 50, // was 0
    rowHeight: 34,
    nodeContentRenderer: FleetplanAlwaysExpandContentRenderer,
    treeNodeRenderer: FleetplanAlwaysExpandNodeRenderer,
};
