import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class ShiftStore extends BaseStoreMultiSync<FpApi.Resource.Duty.Shift, string> {
	protected itemId(item: FpApi.Resource.Duty.Shift): string {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Resource.Duty.Shift[]> {
		return apiManager
			.getService(FpApi.Resource.Duty.ShiftService)
			.get(this.app.ctx);
	}
}
