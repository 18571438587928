import React from "react";
import { Button } from "../../../Components";
import { useOutsideAlerter } from "../../../lib";
import { Wrapper, Overlay } from "../util";
import { WeekPicker } from "./WeekPicker";
export const WeekPickerButton = (props) => {
    const [show, setShow] = React.useState(false);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShow(false));
    return React.createElement(Wrapper, { ref: wrapperRef },
        show && React.createElement(Overlay, null,
            React.createElement(WeekPicker, { value: props.value, onChange: (e) => {
                    props.onChange(e);
                    setShow(false);
                } })),
        React.createElement(Button, { size: props.size, onClick: () => setShow(!show) }, props.value?.toFormat(props.format ?? "'W'WW yyyy") ?? ""));
};
