import { DirectoryNode, FpApi, LandingField, ShiftUtilCore } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

export class ShiftUtil extends ShiftUtilCore {
	constructor(private app: FleetplanApp) {
		super();

	}

	protected fetchLandingField(lfId: number): LandingField {
		return this.app.store.landingField.getId(lfId);
	}
}

export class SyncShiftUtil extends ShiftUtilCore {
	public landingfields = new Map<number, LandingField>();
	private cache = new Map<string, [DateTime, DateTime, FpApi.Resource.Duty.DayShiftItem, FpApi.Resource.Duty.DayShiftItem]>();
	constructor() {
		super();

	}

	public addLandingfield(lf: LandingField): void {
		this.landingfields.set(lf.id, lf);
	}

	public syncResolveShift(start: DateTime, shift: FpApi.Resource.Duty.Shift, location?: DirectoryNode, landingField?: LandingField): [DateTime, DateTime, FpApi.Resource.Duty.DayShiftItem, FpApi.Resource.Duty.DayShiftItem] {
		const key = `${start.toISO()}|${JSON.stringify(shift)}|${location?.id}|${landingField?.id}`;
		if(this.cache.has(key)) {
			return this.cache.get(key);
		}
		const value = super.syncResolveShift(start, shift, location, landingField);
		this.cache.set(key, value);
		return value;
	}


	protected fetchLandingField(lfId: number): LandingField {
		return this.landingfields.get(lfId);
	}
}
