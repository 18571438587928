import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { DialogBody } from "./DialogBody";
import { DialogFooter, DialogFooterApplyCancel, DialogFooterCancelClose, DialogFooterClose, DialogFooterDeleteCancel, DialogFooterOk, DialogFooterRemoveCancel, DialogFooterRevokeCancel, DialogFooterSaveCancel, DialogFooterSaveCancelConfirm, DialogFooterSaveClose } from "./DialogFooter";
import { DialogHeader } from "./DialogHeader";
const DialogPropsCtx = React.createContext({});
export const useDialogProps = () => React.useContext(DialogPropsCtx);
const DialogComponent = styled.div `
	justify-content: space-between;
	z-index: 10000000001;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	outline: 0;
	overflow: auto;
`;
const DialogOverlay = styled.div `
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000000000;
	background: rgba(0, 0, 0, .4);
`;
const DialogContent = styled.div `
position: relative;
background-color: #fff;
-webkit-background-clip: padding-box;
background-clip: padding-box;
border: 1px solid #999;
border: 1px solid rgba(0,0,0,.2);
outline: 0;
-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
box-shadow: 0 3px 9px rgba(0,0,0,.5);
background: ${p => p.theme.background};
`;
const DialogWrapper = styled.div `
	margin: 30px auto;
	${props => {
    let width = "600px";
    switch (props.size) {
        case "xl":
            width = "1170px";
            break;
        case "lg":
            width = "900px";
            break;
        case "sm":
            width = "300px";
            break;
    }
    return css `
			width: ${width};
		`;
}}
`;
const InternalDialog = observer((props) => {
    React.useEffect(() => {
        if (props.isOpen) {
            if (props.onShow)
                props.onShow();
        }
    }, [props.isOpen]);
    if (!props.isOpen)
        return null;
    return ReactDOM.createPortal(React.createElement(DialogPropsCtx.Provider, { value: props },
        React.createElement(DialogOverlay, null),
        React.createElement(DialogComponent, { style: {
                top: 0,
                left: 0,
            } },
            React.createElement(DialogWrapper, { size: props.size },
                React.createElement(DialogContent, null, props.children)))), document.body);
});
InternalDialog.defaultProps = {};
export const DialogLoadingBody = () => {
    return React.createElement("div", { style: { width: "100%", margin: "auto", textAlign: "center", padding: "130px 0" } }, "Loading Content...");
};
export const Dialog = Object.assign(InternalDialog, {
    Body: DialogBody,
    Overlay: DialogOverlay,
    Header: DialogHeader,
    Footer: DialogFooter,
    LoadingBody: DialogLoadingBody,
    FooterOk: DialogFooterOk,
    FooterSaveClose: DialogFooterSaveClose,
    FooterSaveCancel: DialogFooterSaveCancel,
    FooterSaveCancelConfirm: DialogFooterSaveCancelConfirm,
    FooterApplyCancel: DialogFooterApplyCancel,
    FooterCancelClose: DialogFooterCancelClose,
    FooterDeleteCancel: DialogFooterDeleteCancel,
    FooterRevokeCancel: DialogFooterRevokeCancel,
    FooterRemoveCancel: DialogFooterRemoveCancel,
    FooterClose: DialogFooterClose,
});
