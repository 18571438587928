import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
import { Flex } from "../Flex";
import { Text } from "../Text";
const COLOR_FALLBACK = "#777777";
export const AlertStyled = styled.div `
	padding: .75rem 1.25rem;
	font-weight: 400;
	line-height: 200%;
	vertical-align: baseline;
	border: 1px solid transparent;
	margin: auto;
	background: ${props => props.background};
	${props => {
    if (props.elevation) {
        return css `
				box-shadow: -1px 2px 14px 0px ${props.fontColor} !important;
			`;
    }
    return "";
}}

	color: ${p => p.fontColor};
	& a {
		color: ${p => p.fontColor};
	}
`;
export const Alert = function Alert(props) {
    const passedProps = omit(props, "color", "fullWidth");
    const themeColor = useThemeColor(props.color, COLOR_FALLBACK);
    const borderColor = themeColor[500].main;
    const background = themeColor[100].main;
    return React.createElement(AlertStyled, { ...passedProps, fontColor: borderColor, background: background, style: {
            borderColor: borderColor,
            width: props.fullWidth ? "100%" : undefined,
            ...props.style,
        } },
        React.createElement(Flex, { gap: "1rem" },
            props.icon ?
                React.createElement(Text, { size: "lg", color: borderColor },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: props.icon })) :
                null,
            React.createElement("div", { style: { width: "100%" } }, props.children)));
};
Alert.defaultProps = {
    color: "info",
};
