var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from "mobx";
import React from "react";
export const DockedManagerCtx = React.createContext(null);
export const useDockedManager = function () {
    return React.useContext(DockedManagerCtx);
};
export class DockedManagerState {
    get isSm() { return this.size === "sm"; }
    get isMd() { return this.size === "md"; }
    get isLg() { return this.size === "lg"; }
    panelsByPosition() {
        const result = { "top": [], "right": [], "bottom": [], "left": [] };
        for (const panel of this.panels.values()) {
            if (panel.position == null)
                continue;
            result[panel.position].push(panel);
        }
        return result;
    }
    constructor(initialSize) {
        /**
         * Reference to the DOM elements panels will be rendered into using portals.
         */
        this.element = {
            "top": undefined, "right": undefined, "bottom": undefined, "left": undefined,
        };
        this.open = {
            "top": undefined, "right": undefined, "bottom": undefined, "left": undefined,
        };
        this.floating = {
            "top": false, "right": false, "bottom": false, "left": false,
        };
        /**
         * Used to create unique ID's for each panel to be used as a react `key`
         */
        this.panelUidCounter = 0;
        this.panels = new Map();
        this.handlers = new Map();
        /**
         * `sm`
         * - one panel open max
         * - always floating
         *
         * `md`
         * - one panel open max
         * - floating or sticky
         *
         * `lg`
         * - multiple panel open
         * - floating or sticky
         */
        this.size = "sm";
        this.size = initialSize;
    }
    setElement(position, element) { this.element[position] = element; }
    // @action.bound public setOpen(position: PanelPosition, panel: symbol): void { this.open[position] = panel; }
    toggleOpen(position, panel) {
        if (this.open[position] === panel) {
            this.internalClosePosition(position);
        }
        else {
            this.internalOpenPanel(panel, position);
        }
    }
    openPanel(key, position) {
        const panel = this.panels.get(key);
        if (panel == null)
            return;
        // sync side with panel
        position = panel.position ?? position ?? "right";
        panel.position = position;
        this.internalOpenPanel(key, position);
    }
    /**
     * Closes the given panel, or does nothing if it isn't open.
     * @param panel panel
     */
    closePanel(panel) {
        const position = this.getPosition(panel);
        if (position == null)
            return;
        if (this.callOnClose(panel)) {
            this.open[position] = undefined;
        }
    }
    internalOpenPanel(panel, position) {
        // panel already open?
        const currentPanel = this.open[position];
        if (currentPanel === panel)
            return;
        // open panel
        const openPanel = this.callOnOpen(panel);
        if (!openPanel)
            return;
        if (this.isLg) {
            // lg
            // we can have multiple panels open, one per position
            // call close handler
            if (currentPanel != null && this.internalClosePosition(position, false) === false)
                return;
        }
        else {
            // sm, md
            // only one panel can be open at once
            // handler blocked closing
            if (this.open.top != null && this.internalClosePosition("top") === false)
                return;
            if (this.open.right != null && this.internalClosePosition("right") === false)
                return;
            if (this.open.bottom != null && this.internalClosePosition("bottom") === false)
                return;
            if (this.open.left != null && this.internalClosePosition("left") === false)
                return;
        }
        this.open[position] = panel;
    }
    /**
     * Internal helper to close a panel at the given position.
     * @param position position to close
     * @param closePanel If the function should close the panel for you, or if you want to this yourself. Can be useful if you intend to replace the panel with a different one
     * @returns
     * - `true` handler returned action is okay
     * - `false` handler requested action to be aborted
     */
    internalClosePosition(position, closePanel = true) {
        const panel = this.open[position];
        if (this.callOnClose(panel)) {
            // action okay
            if (closePanel)
                this.open[position] = undefined;
            return true;
        }
        else {
            // handler aborted action
            return false;
        }
    }
    callOnOpen(panel) {
        const handlers = this.handlers.get(panel);
        // call all handlers
        const results = handlers.open.map(handler => handler(panel) ?? true);
        // check if any returned `false`
        if (results.includes(false)) {
            return false;
        }
        else {
            return true;
        }
    }
    callOnClose(panel) {
        const handlers = this.handlers.get(panel);
        // call all handlers
        const results = handlers.close.map(handler => handler(panel) ?? true);
        // check if any returned `false`
        if (results.includes(false)) {
            return false;
        }
        else {
            return true;
        }
    }
    /**
     * Set panels on the given side to be floating or not floating
     * @param position side
     * @param state new state
     */
    setFloating(position, state) {
        this.floating[position] = state;
    }
    addEventListener(panel, event, handler) {
        const handlers = this.handlers.get(panel);
        if (handlers == null)
            throw new Error("panel unknown");
        if (handlers[event].includes(handler))
            return; // already registered
        handlers[event].push(handler);
    }
    removeEventListener(panel, event, handler) {
        const handlers = this.handlers.get(panel);
        if (handlers == null)
            return;
        const index = handlers[event].indexOf(handler);
        if (index !== -1)
            handlers[event].splice(index, 1);
    }
    /**
     * Internal Docked Manger use only.
     *
     * Register panel with the manager, and returns a handler to unregister it again.
     * @param panel panel info
     */
    registerPanel(panel) {
        if (this.panels.has(panel.key)) {
            throw new Error(`panel with this key already exists: ${panel.key.toString()}`);
        }
        const panelInfo = {
            ...panel,
            uid: this.panelUidCounter++,
        };
        // register with the manager
        this.panels.set(panelInfo.key, panelInfo);
        this.handlers.set(panelInfo.key, {
            open: [],
            close: [],
        });
        return () => {
            // close panel if open
            const position = this.getPosition(panel.key);
            if (position != null) {
                this.open[position] = undefined;
            }
            // cleanup
            this.handlers.delete(panel.key);
            this.panels.delete(panel.key);
        };
    }
    changeLabel(panel, label) {
        this.panels.get(panel).label = label;
    }
    getPosition(panel) {
        if (this.open["top"] === panel)
            return "top";
        else if (this.open["right"] === panel)
            return "right";
        else if (this.open["bottom"] === panel)
            return "bottom";
        else if (this.open["left"] === panel)
            return "left";
    }
    getElementForPanel(panel) {
        return this.element[this.getPosition(panel)];
    }
    startResizeTop(ev) {
        this.updateMousePosition(ev);
        this.resize = "top";
    }
    startResizeRight(ev) {
        this.updateMousePosition(ev);
        this.resize = "right";
    }
    startResizeBottom(ev) {
        this.updateMousePosition(ev);
        this.resize = "bottom";
    }
    startResizeLeft(ev) {
        this.updateMousePosition(ev);
        this.resize = "left";
    }
    updateMousePosition(ev) {
        const offset = this.getOffset(this.gridRef.current);
        this.mousePosition = {
            x: ev.pageX - offset.x,
            y: ev.pageY - offset.y,
        };
    }
    stopResize() {
        if (this.resize == null)
            return;
        const panel = this.panels.get(this.open[this.resize]);
        let size = panel.size;
        switch (this.resize) {
            case "top":
                size = this.mousePosition.y;
                break;
            case "right":
                size = this.gridRef.current.offsetWidth - this.mousePosition.x;
                break;
            case "bottom":
                size = this.gridRef.current.offsetHeight - this.mousePosition.y;
                break;
            case "left":
                size = this.mousePosition.x;
                break;
        }
        size = Math.min(size, panel.maxSize);
        size = Math.max(size, panel.minSize);
        panel.size = size;
        this.resize = undefined;
    }
    cancelResize() {
        this.resize = undefined;
    }
    getOffset(startEl) {
        const offset = { x: 0, y: 0 };
        let el = startEl;
        while (el != null) {
            offset.x += el.offsetLeft;
            offset.y += el.offsetTop;
            if (el.offsetParent instanceof HTMLElement) {
                el = el.offsetParent;
            }
            else {
                el = null;
            }
        }
        return offset;
    }
}
__decorate([
    observable.ref
], DockedManagerState.prototype, "element", void 0);
__decorate([
    observable
], DockedManagerState.prototype, "open", void 0);
__decorate([
    observable
], DockedManagerState.prototype, "floating", void 0);
__decorate([
    observable
], DockedManagerState.prototype, "panels", void 0);
__decorate([
    observable
], DockedManagerState.prototype, "size", void 0);
__decorate([
    computed
], DockedManagerState.prototype, "isSm", null);
__decorate([
    computed
], DockedManagerState.prototype, "isMd", null);
__decorate([
    computed
], DockedManagerState.prototype, "isLg", null);
__decorate([
    observable
], DockedManagerState.prototype, "resize", void 0);
__decorate([
    observable
], DockedManagerState.prototype, "mousePosition", void 0);
__decorate([
    action.bound
], DockedManagerState.prototype, "setElement", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "toggleOpen", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "openPanel", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "closePanel", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "internalOpenPanel", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "internalClosePosition", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "callOnOpen", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "callOnClose", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "setFloating", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "changeLabel", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "startResizeTop", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "startResizeRight", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "startResizeBottom", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "startResizeLeft", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "updateMousePosition", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "stopResize", null);
__decorate([
    action.bound
], DockedManagerState.prototype, "cancelResize", null);
