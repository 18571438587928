
import { ISavedViewApi, ISavedView, ApiContext } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class SavedViewApi implements ISavedViewApi {
	public async get(ctx: ApiContext, params: any): Promise<ISavedView[]> {
		return ApiUtil.fetchRpc(ctx, "calender.savedView.get", {});
	}

	public async post(ctx: ApiContext, params: { data: ISavedView }): Promise<ISavedView> {
		return ApiUtil.fetchRpc(ctx, "calender.savedView.post", params);
	}

	public async put(ctx: ApiContext, params: { data:  ISavedView }): Promise<ISavedView> {
		return ApiUtil.fetchRpc(ctx, "calender.savedView.put", params);
	}

	public async delete(ctx: ApiContext, params: { id: string }): Promise<void> {
		return ApiUtil.fetchRpc(ctx, "calender.savedView.delete", params);
	}
}
