import React from "react";
import { DockedLayoutSide } from "./util";
/** @deprecated use docked manager */
export const DockerLayoutContext = React.createContext({
    side: DockedLayoutSide.Left,
    isPinned: true,
    collapseMode: "enabled",
    pinMode: "enabled",
    onCollapse: () => { },
    onPinnedToggle: () => { },
});
