import { TimeZoneHelper } from "@bryntum/core-thin";
import { AssignmentModel } from "@bryntum/schedulerpro-thin";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";
import { Bucket } from "../Bucket";
import { FpEventModel, FpResourceModel, FpResourceTimeRangeModel } from "../models";
import type { SchedulerStore } from "../SchedulerStore";

import { BuilderState, RangeBuilderState } from "./BuilderState";

export abstract class BaseBuilder {
	protected app: FleetplanApp;
	protected store: SchedulerStore;

	constructor(store: SchedulerStore, app: FleetplanApp) {
		this.app = app;
		this.store = store;
	}

	public updateConfiguration(): void {
		// noop
	}

	public abstract buildRange(state: RangeBuilderState): Promise<void>;

	public abstract handlePut(state: BuilderState, item: unknown): Promise<void>;

	public abstract handleDelete(state: BuilderState, id: unknown): Promise<void>;

	/**
	 * The Bryntum Scheduler expects all times given to it to be in the time zone it is currently set to, so we need to convert them before handing them to the
	 * scheduler. See Bryntum Forum/Github:
	 * - https://forum.bryntum.com/viewtopic.php?t=28163
	 * - https://github.com/bryntum/support/issues/8703
	 * @param date input
	 * @returns date in scheduler time zone
	 */
	protected toTimeZone(date: string | Date | DateTime): Date | null {
		// handle no date
		if (date == null) {
			return null;
		}

		// convert using brytum's own function
		if (typeof date === "string") {
			return TimeZoneHelper.toTimeZone(new Date(date), this.store.tz);
		} else if (date instanceof Date) {
			return TimeZoneHelper.toTimeZone(date, this.store.tz);
		} else if (date instanceof DateTime) {
			return TimeZoneHelper.toTimeZone(date.toJSDate(), this.store.tz);
		}
	}

	protected makeEvent(
		state: BuilderState,
		eventGen: () => FpEventModel,
		assignmentsGen: () => Generator<{ linkType: string; linkId: string | number; }, void, unknown>,
		params?: { includeAlways?: boolean; },
	): void {
		const resources = new Set<FpResourceModel>();

		// run assignment gen
		for (const link of assignmentsGen()) {
			state
				.getResources(link.linkType, link.linkId)
				.forEach(r => resources.add(r));
		}

		// drop out if we don't have any assignments
		if (params?.includeAlways !== true && resources.size === 0) {
			return;
		}

		// create actual event and assignments
		const event = eventGen();
		// we need to assign the parent, and all children
		const events = [ event, ...(event.allChildren ?? []) ];

		const assignments: AssignmentModel[] = [];
		resources.forEach(resource => {
			events.forEach(event => {
				assignments.push(new AssignmentModel({
					id: `${resource.id}:${event.id}`,
					resourceId: resource.id,
					eventId: event.id,
				}));
			});
		});

		// push into state
		state.events.push(event);
		state.assignments.push(...assignments);
	}

	protected makeResourceTimeRange(
		state: BuilderState,
		resourceTimeRange: FpResourceTimeRangeModel,
		assignmentsGen: () => Generator<{ linkType: string; linkId: string | number; }, void, unknown>,
	): void {

		for (const link of assignmentsGen()) {
			const resources = state.getResources(link.linkType, link.linkId);

			for (const resource of resources) {
				const originalData = (resourceTimeRange as any).originalData;
				const newModel = new FpResourceTimeRangeModel({
					...originalData,
					id: resourceTimeRange.id.toString() + ":" + resource.id.toString(),
					resourceId: resource.id,
				});

				state.resourceTimeRanges.push(newModel);
			}
		}

	}

	protected getBuckets(state: RangeBuilderState): Bucket[] {
		return this.store.backendStore.getBuckets(state.from, state.to);
	}
}
