import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isThemeColor, OverlayTrigger, ThemeVariantOrColor, Tooltip } from "@tcs-rliess/fp-web-ui";
import React from "react";

import { useApp } from "../../../../FleetplanApp";


export enum CrewIconStatus {
	OK,
	PARTIAL_OK,
	NOT_OK
}
export type CrewCheckIcon = {
	variant: ThemeVariantOrColor;
	tooltip?: string;
	icon?: React.ReactNode;
	status: CrewIconStatus;
}

interface CrewCheckIconWrapperProps {
	data: CrewCheckIcon;
	icon?: React.ReactNode;
}

export const CrewCheckIconWrapper: React.FC<CrewCheckIconWrapperProps> = props => {
	const app = useApp();
	const color = React.useMemo(() => {
		if(!props.data) return null;
		if(isThemeColor(props.data.variant)) return app.theme.color[props.data.variant][500].main;
		return props.data.variant;
	}, [ props.data?.variant, app.theme ]);

	if(!props.data) return null;
	if(!props.data?.tooltip) return <span style={{ color }}>
		{props.icon}
	</span>;
	return <OverlayTrigger overlay={<Tooltip><div dangerouslySetInnerHTML={{ __html: props.data.tooltip }}/></Tooltip>}>
		<span style={{ color }}>
			{props.icon ?? props.data.icon}
		</span>
	</OverlayTrigger>;
};

export const CrewCheckTabIcon = () => {
	const app = useApp();
	return <span className="fa-stack">
		<FontAwesomeIcon icon={faUsers} className="fa-stack-1x"/>
		<FontAwesomeIcon icon={faCheckCircle} color={app.theme.color.success[500].main} className="fa-stack-1x" style={{ paddingLeft: "20px", paddingTop: "14px", fontSize: "10px" }}/>
	</span>;
};
