import styled, { css } from "styled-components";
export const ArticleDiffStyles = styled.span `
	// htmlDiff styles
	ins {
		background: ${p => p.theme.color.success[300].main};
		text-decoration: none;
		img {
			border: 5px solid ${p => p.theme.color.success[300].main};
		}
	}
	del {
		background: ${p => p.theme.color.danger[300].main};
		img {
			border: 5px solid ${p => p.theme.color.danger[300].main};
		}
	}
`;
export const ArticleBodyStyles = styled(ArticleDiffStyles) `
	text-align: justify;

	// if we need to scroll vertically, only scroll the body (header etc. will stay fixed)
	width: 100%;
	overflow-x: auto;

	img {
		max-width: 100%;
		height: auto;
	}

	// legacy styles
	// -------------
	// older document styles defined in dynasite css files

	a {
		text-decoration: none;
	}

	li a {
		color: ${p => p.theme.font};
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
		/* some table are set to "width: 0px" */
		width: 100% !important;
		max-width: 100% !important;
	}

	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;

		&.alert-success {
			color: #9fbd9f;
			background-color: #dff0d8;
			border-color: #d6e9c6;
		}
		&.alert-info {
			color: #31708f;
			background-color: #d9edf7;
			border-color: #bce8f1;
		}
		&.alert-danger {
			color: #a94442;
			background-color: #f2dede;
			border-color: #ebccd1;
		}
		&.alert-warning {
			color: #8a6d3b;
			background-color: #fcf8e3;
			border-color: #faebcc;
		}
	}

	.doc-table {
		width: 100%;
		max-width: 100%;
	
		border-spacing: 0;
		border-collapse: collapse;
		background-color: transparent;
		border: 1px solid ${p => p.theme.table.borderColor};

		td, th {
			padding: 5px;
			text-align: justify;
			border-top: 1px solid ${p => p.theme.table.borderColor};
			border-bottom: 1px solid ${p => p.theme.table.borderColor};
		}

		&.doc-table-bordered {
			td, th {
				border: 1px solid ${p => p.theme.table.borderColor};
			}
		}

		&.doc-table-black-border {
			td, th {
				border: 1px solid #555 !important;
			}
		}

		th {
			vertical-align: bottom;
			text-align: left;
		}
		td {
			vertical-align: top;
			text-align: left;
		}
	}

	${p => p.theme.kind === "dark" ? css `
		// dark
		.active { background-color: #3d3d3d; }
		.success { background-color: #324f27; }
		.info { background-color: #2276a0; }
		.warning { background-color: #a79227; }
		.danger { background-color: #6d1d1d; }
	` : css `
		// light
		.active { background-color: #f5f5f5; }
		.success { background-color: #dff0d8; }
		.info { background-color: #d9edf7; }
		.warning { background-color: #fcf8e3; }
		.danger { background-color: #f2dede; }
	`}

	.label {
		display: inline;
		padding: 4px 7px;
		font-size: 11px;
		font-weight: 400;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		text-transform: uppercase;

		&.label-default { background-color: #777777; }
		&.label-primary { background-color: ${p => p.theme.color.primary[500].main}; }
		&.label-success { background-color: ${p => p.theme.color.success[500].main}; }
		&.label-warning { background-color: ${p => p.theme.color.warning[500].main}; }
		&.label-danger { background-color: ${p => p.theme.color.danger[500].main}; }
		&.label-info { background-color: ${p => p.theme.color.info[500].main}; }
	}
	
	ol ol { list-style-type: lower-alpha; }
	ol ol ol { list-style: lower-roman; }
	ol ol ol ol { list-style:decimal; }

	pre {
		display: block;
		padding: 10px;
		margin: 0 0 10px;
		font-family: monospace;
		overflow: auto;
		background-color: ${p => p.theme.kind === "dark" ? "#1f1f1f" : "#f5f5f5"};
	}

	blockquote {
		padding: 10px 20px;
		margin: 0 0 20px;
		font-size: 17.5px;
		border-left: 5px solid #eee;
	}

	/* page breaks in print, hide in web view */
	.dspagebreakafter {
		display: none;
	}

	/*
	 * ">" means cascading only to children directly inside of parent elements 
	 * so optional tables used in further contents are not affected
	 */
	table.fpmel_v3 { border-collapse: collapse;border: 1px solid #000;}

	table.fpmel_v3 > thead > tr > th, 
	table.fpmel_v3 > tbody > tr > td {padding:5px; text-align:left;}

	table.fpmel_v3 > thead > tr:nth-child(1) > th:nth-child(1) {content:"(1)";vertical-align: top;border-right:1px solid #000}
	table.fpmel_v3 > thead > tr:nth-child(2) > th:nth-child(2) {border-top:1px solid #000;}
	table.fpmel_v3 > thead > tr:nth-child(3) > th:nth-child(2) {border-top:1px solid #000; }
	table.fpmel_v3 > thead > tr:nth-child(4) > th:nth-child(2) {border-top:1px solid #000; }

	table.fpmel_v3 > thead > tr:nth-child(2) > th:nth-child(1) {border-right:1px solid #000}
	table.fpmel_v3 > thead > tr:nth-child(3) > th:nth-child(1) {border-right:1px solid #000}
	table.fpmel_v3 > thead > tr:nth-child(4) > th:nth-child(1) {border-right:1px solid #000}

	table.fpmel_v3 > thead > tr > th .fpmel_item {margin-top:30px;clear:both;}
	table.fpmel_v3 > thead > tr > th .fpmel_caption_number {padding-left:8px;}
	table.fpmel_v3 > thead > tr > th .fpmel_caption_text {padding-left:15px;}
	table.fpmel_v3 > thead > tr > th .fpmel_caption_text_chapter {padding-left:37px;}

	table.fpmel_v3 > tbody > tr:nth-child(even) {background-color:#eee}
	table.fpmel_v3 > tbody > tr:nth-child(1) > td:nth-child(1) {border-top:1px solid #000}

	table.fpmel_v3 > tbody > tr > td {width:350px;border-right:1px solid #000;vertical-align:top}
	table.fpmel_v3 > tbody > tr > td:nth-child(2) {width:30px;text-align:center}
	table.fpmel_v3 > tbody > tr > td:nth-child(3) {width:30px;text-align:center}
	table.fpmel_v3 > tbody > tr > td:nth-child(4) {width:30px;text-align:center}
	table.fpmel_v3 > tbody > tr > td:nth-child(5) {width:350px;text-align:left}
`;
export const ArticleBodyStylesExtra = styled(ArticleBodyStyles) `
	${p => p.extra}
`;
