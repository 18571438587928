import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class SecurityRoleStore extends BaseStoreMulti<FpApi.Security.Role, string> {
	protected itemId(item: FpApi.Security.Role): string {
		return item.roleName;
	}

	protected fetchAll(): Promise<FpApi.Security.Role[]> {
		return apiManager
			.getService(FpApi.Security.RoleService)
			.get(this.app.ctx);
	}
}
