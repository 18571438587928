import { TimeSpan } from "@tcs-rliess/fp-core";
import Lock from "async-lock";
import EventEmitter from "events";
import { isEqual, remove } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

const RANGE = Symbol("BaseStoreRange");

export type StoreUpdateSource = "local" | "event-stream";

export interface StoreUpdateOptions {
	source?: StoreUpdateSource;
	silent?: boolean;
}

/**
 * Async store that tracks which time spans are loaded, and loads them on demand if requested.
 * Additionally can load ID lists on demand.
 * 
 * @fires put
 * @fires delete
 */
export abstract class BaseStoreRange<ITEM, ID> extends EventEmitter {
	protected app: FleetplanApp;
	private lock = new Lock();

	private loaded: TimeSpan[] = [];
	private itemsById: Map<ID, ITEM> = new Map();

	constructor(app: FleetplanApp) {
		super();
		this.app = app;
	}

	/** return id of item */
	protected abstract itemId(item: ITEM): ID;
	/** return id of item */
	protected abstract itemDateModified(item: ITEM): DateTime;
	/** return range of the item */
	protected abstract itemRange(item: ITEM): TimeSpan;
	/** load the given range */
	protected abstract fetchRange(from: DateTime, to: DateTime): Promise<ITEM[]>;
	/** fetches the given id list */
	protected abstract fetchIdList(idList: ID[]): Promise<ITEM[]>;

	/**
	 * Updates the item in the store with new data
	 * @param item new or updated item
	 * @fires put
	 */
	public update(item: ITEM, options?: StoreUpdateOptions): void {
		this.setItems([ item ], options);
	}

	/**
	 * Removes the Item from the store
	 * @param id id of deleted item
	 * @fires delete
	 */
	public remove(id: ID): void {
		this.itemsById.delete(id);

		this.emit("delete", id);
	}

	public async getId(id: ID): Promise<ITEM> {
		const item = this.itemsById.get(id);
		if (item) return item;

		return await this.lock.acquire("getId", async () => {
			const items = await this.loadIdList([ id ]);
			return items[0];
		});
	}

	public async getIdList(idList: ID[]): Promise<ITEM[]> {
		const missing = idList.filter(id => this.itemsById.has(id) === false);
		await this.loadIdList(missing);

		return idList
			.map(id => this.itemsById.get(id))
			.filter(i => i != null);
	}

	public async getRange(from: DateTime, to: DateTime): Promise<ITEM[]> {
		return await this.lock.acquire("getRange", async () => {
			const requested = new TimeSpan(from, to);
			const load = requested.roundTo("month");

			const missingZones = this.findMissing(load);
			for (const missingZone of missingZones) {
				const items = await this.fetchRange(missingZone.start, missingZone.end);
				this.setItems(items, { silent: true });
				this.addLoaded(missingZone);
			}

			return Array.from(this.itemsById.values()).filter(item => {
				const range = item[RANGE] ?? (item[RANGE] = this.itemRange(item));
				return requested.intersects(range);
			});
		});
	}

	public removeBy(guard: (item: ITEM) => boolean): void {
		for (const item of this.itemsById.values()) {
			const remove = guard(item);

			if (remove) {
				const id = this.itemId(item);
				this.itemsById.delete(id);
				this.emit("delete", id);
			}
		}
	}

	public flush(): void {
		this.loaded = [];
		this.itemsById.clear();
	}

	protected getMaxLoadedRange(): TimeSpan | undefined {
		if (this.loaded.length === 0) return undefined;

		return new TimeSpan(
			DateTime.min(...this.loaded.map(l => l.start)),
			DateTime.max(...this.loaded.map(l => l.end)),
		);
	}

	private async loadIdList(idList: ID[]): Promise<ITEM[]> {
		if (idList.length === 0) {
			return [];
		}

		const items = await this.fetchIdList(idList);
		if (items == null) return;

		this.setItems(items, { silent: true });

		return items;
	}

	private setItems(items: ITEM[], options?: StoreUpdateOptions): void {
		const silent = options?.silent ?? false;

		for (const item of items) {
			// just a safety
			if (item == null) continue;

			const itemId = this.itemId(item);

			const existing = this.itemsById.get(itemId);
			if (existing != null) {
				// is same as the one we have
				if (isEqual(existing, item)) continue;

				// check date modified, if the new value we got is older then what we already have -> drop
				const dateModifiedNew = this.itemDateModified(item);
				const dateModifiedExisting = this.itemDateModified(existing);
				if (
					// both are valid
					dateModifiedNew?.isValid === true
					&& dateModifiedExisting?.isValid === true
					// and new item is older
					&& dateModifiedNew < dateModifiedExisting
				) {
					continue;
				}
			}

			// update map
			this.itemsById.set(itemId, item);

			// emit event
			if (silent === false) {
				this.emit("put", item, {
					previous: existing,
					source: options?.source,
				});
			}
		}
	}

	private findMissing(timeSpan: TimeSpan): TimeSpan[] {
		if (this.loaded.length === 0) {
			// nothing loaded, so everything is missing
			return [ timeSpan ];
		}

		const intersecting = this.loaded.filter(loaded => {
			return loaded.start === timeSpan.end
				|| loaded.end === timeSpan.start
				|| loaded.intersects(timeSpan);
		});

		let missing = [ timeSpan ];
		for (const loaded of intersecting) {
			missing = missing.flatMap(m => loaded.missing(m));

			if (missing.length === 0) return missing;
		}

		return missing;
	}

	private addLoaded(timeSpan: TimeSpan): void {
		if (this.loaded.length === 0) {
			// nothing loaded yet, just add this one
			this.loaded.push(timeSpan);
			return;
		}

		// find all loaded time spans intersecting
		const intersecting = remove(this.loaded, loaded => {
			return loaded.start === timeSpan.end
				|| loaded.end === timeSpan.start
				|| loaded.intersects(timeSpan);
		});

		// merge with found intersecting, and given time span into one covering all
		this.loaded.push(
			new TimeSpan(
				DateTime.min(timeSpan.start, ...intersecting.map(i => i.start)),
				DateTime.max(timeSpan.end, ...intersecting.map(i => i.end)),
			)
		);
	}
}
