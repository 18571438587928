import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class AircraftStore extends BaseStoreMultiSync<FpApi.Resource.Aircraft, number> {
	protected itemId(item: FpApi.Resource.Aircraft): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Resource.Aircraft[]> {
		return apiManager
			.getService(FpApi.Resource.AircraftService)
			.get(this.ctx);
	}
}
