import { DateTime } from "luxon";
export function toDateTime(input, zone) {
    if (input instanceof DateTime) {
        return input.setZone(zone);
    }
    else if (typeof input === "string") {
        const ret = DateTime.fromISO(input, { zone });
        if (ret.isValid)
            return ret;
        return undefined;
    }
}
