import { apiManager, FpApi, SecurityPolicyUtil as SecurityPolicyUtilCore } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../../FleetplanApp";

export class SecurityPolicyUtil extends SecurityPolicyUtilCore {
	private app: FleetplanApp;

	public securityResources: FpApi.Security.SecurityResource[];

	constructor(app: FleetplanApp) {
		super();
		this.app = app;
	}

	public updatePolicy(item: FpApi.Security.Policy): void {
		const index = this.policies.findIndex(i => i.id === item.id);
		if (index !== -1) this.policies.splice(index, 1);

		this.policies.push(item);
	}

	public removePolicy(id: string): void {
		const index = this.policies.findIndex(i => i.id === id);
		if (index !== -1) this.policies.splice(index, 1);
	}

	protected async fetch(): Promise<void> {
		this.directory = this.app.store.fpDir.directory.getTree();
		this.members = this.app.store.fpDir.directory.getMembers();

		// directory structure
		this.directoryStructure = new Map();
		this.directory.walk(info => this.directoryStructure.set(info.node.id, info.parents.map(n => n.id)));

		this.securityResources = await this.fetchSecurityResources();

		this.policies = [];

		// flags
		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		this.FLAG_INHERIT = this.app.store.settingsProject.getBoolean("security.permissionInherit") ?? false;

		// policies
		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		const policies = await apiManager
			.getService(FpApi.Security.PolicyService)
			.get(this.app.ctx);

		this.policies.push(...policies);

		// policies: certificates
		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		const certificateReq = await fetch("/api/fp-certificates/permissions/directory");
		const certificatePermissions: Array<{ dscdid: number; permissions: FpApi.Security.Policy[]; }> = await certificateReq.json();

		certificatePermissions?.forEach(item => {
			item.permissions?.forEach((policy, index) => {
				this.policies.push({
					...policy,
					id: `dscdid-${item.dscdid}-${index}`,
					resource: {
						linkType: "dscdid",
						linkId: item.dscdid.toString(),
					},
				});
			});
		});

		// policies: Certificate Sets
		//------------------------------------------------------------------------------------------------------------------------------------------------------
		await this.app.store.certificateV3Store.ensureSets(null, true);

		this.app.store.certificateV3Store.sets.flatMap((set) => {
			set.permissions?.map((policy, index) => {
				this.policies.push({
					...policy,
					id: `dscdsid-${set.id}-${index}`,
					resource: {
						linkType: "dscdsid",
						linkId: set.id.toString(),
					},
				});
			});
		});

		// policies: Controlled Documents
		//------------------------------------------------------------------------------------------------------------------------------------------------------
		const controlledDocuemntReq = await fetch("/api/cd/permissions/directory");
		const directoryPermissions: {
			rows: Array<{
				dscid: number;
				dssid: number;
				dsmid: number;
				permissions: FpApi.Security.Policy[];
			}>
		} = await controlledDocuemntReq.json();

		directoryPermissions.rows?.forEach(item => {
			item.permissions.forEach((policy, index) => {
				this.policies.push({
					...policy,
					id: `dssid-${item.dssid}-${index}`,
					resource: {
						linkType: "dssid",
						linkId: item.dssid.toString(),
					},
				});
			});
		});

		// policies: Quality Reports
		//------------------------------------------------------------------------------------------------------------------------------------------------------
		await this.app.store.qualityReport.reload();
		const reports = this.app.store.qualityReport.getAll();
		reports?.forEach(report => {
			report.permissions.forEach((policy, i) => {
				this.policies.push({
					...policy,
					id: `dsqmrepid-${report.id}-${i}`,
					resource: {
						linkType: "dsqmrepid",
						linkId: report.id.toString(),
					},
				});
			});
		});
	}

	protected async fetchSecurityResources(): Promise<FpApi.Security.SecurityResource[]> {
		const items = await apiManager
			.getService(FpApi.Security.PolicyService)
			.getResources(this.app.ctx);

		// roles
		const roles = await this.app.store.security.role.getAll();
		roles.forEach(item => {
			items.push({
				linkType: "dsrlid",
				linkId: item.id.toString(),
				name: item.name,
			});
		});

		// certificates
		await this.app.store.certificateV3Store.refresh();
		await this.app.store.certificateV3Store.ensureSets(null, true);
		this.app.store.certificateV3Store.baseCertificates.forEach(item => {
			items.push({
				linkType: "dscdid",
				linkId: item.id.toString(),
				name: item.name,
			});
		});
		this.app.store.certificateV3Store.sets.forEach(item => {
			items.push({
				linkType: "dscdsid",
				linkId: item.id.toString(),
				name: item.name,
			});
		});

		// quality reports
		const reports = await this.app.store.qualityReport.getAll();
		reports.forEach(item => {
			items.push({
				linkType: "dsqmrepid",
				linkId: item.id.toString(),
				name: item.name,
			});
		});

		return items;
	}
}
