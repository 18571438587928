var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Flex } from "../Flex";
export class StackedPanelState {
    constructor(defaultOpen) {
        this.panels = [];
        this.history = [];
        this.portals = new Map();
        this.open = defaultOpen;
        if (defaultOpen)
            this.history.push(defaultOpen);
    }
    setOpen(key, direction = "next") {
        this.history.push(this.open);
        this.oldOpen = this.open;
        this.open = key;
        this.direction = direction;
    }
    setPanels(keys) {
        this.panels = keys;
    }
    back() {
        if (this.history.length) {
            this.oldOpen = this.open;
            this.open = this.history.pop();
            this.direction = "back";
        }
    }
    /**
     * @deprecated use setOpen instead
     */
    next() {
        if ((this.panels.indexOf(this.open) + 1) !== this.panels.length) {
            if (this.open)
                this.history.push(this.open);
            this.oldOpen = this.open;
            this.open = this.panels[this.panels.indexOf(this.open) + 1];
            this.direction = "next";
        }
    }
    registerPanel(identifier) {
        this.panels.push(identifier);
    }
    unregisterPanel(identifier) {
        this.panels = this.panels.filter(e => e !== identifier);
    }
    registerPortal(identifier, portal) {
        this.portals.set(identifier, portal);
    }
    unregisterPortal(identifier) {
        this.portals.delete(identifier);
    }
    getPortal(identifier) {
        return this.portals.get(identifier);
    }
}
__decorate([
    observable
], StackedPanelState.prototype, "open", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "oldOpen", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "direction", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "panels", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "history", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "portals", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "currentElement", void 0);
__decorate([
    observable
], StackedPanelState.prototype, "nextElement", void 0);
__decorate([
    action
], StackedPanelState.prototype, "setOpen", null);
__decorate([
    action
], StackedPanelState.prototype, "setPanels", null);
__decorate([
    action
], StackedPanelState.prototype, "back", null);
__decorate([
    action
], StackedPanelState.prototype, "next", null);
__decorate([
    action
], StackedPanelState.prototype, "registerPanel", null);
__decorate([
    action
], StackedPanelState.prototype, "unregisterPanel", null);
__decorate([
    action
], StackedPanelState.prototype, "registerPortal", null);
__decorate([
    action
], StackedPanelState.prototype, "getPortal", null);
export const StackedPanelStateCtx = React.createContext(null);
const StackedPanelWrapperStyled = styled.div `
// border: 1px solid #ccc;
`;
const Container = styled.div `
	transition: all 500ms;
	width: 100%;
	display: none;
	// enter from
	&.stacked-panel-enter {
		display: block;
		position: absolute;
		${props => props.direction === "next" ? "right" : "left"}: -100%
	}

	// enter to
	&.stacked-panel-enter-active {
		display: block;
		position: absolute !important;
		${props => props.direction === "next" ? "right" : "left"}: 0;
	}

	&.stacked-panel-enter-done {
		display: block;
	}

	// exit from
	&.stacked-panel-exit {
		display: block;
		position: relative;
		${props => props.direction === "next" ? "right" : "left"}: 0;
	}

	// exit to 
	&.stacked-panel-exit-active {
		display: block;
		position: absolute;
		${props => props.direction === "next" ? "right" : "left"}: 100%;
	}
	// exit to 
	&.stacked-panel-exit-done {
		display: none;
		transform: translateX(100%);
	}
`;
const StackedPanelWrapper = observer(React.forwardRef(function StackedPanelWrapper(props, ref) {
    const [state] = React.useState(() => new StackedPanelState(props.defaultOpen));
    React.useImperativeHandle(ref, () => ({
        state,
    }));
    return React.createElement(StackedPanelStateCtx.Provider, { value: state },
        React.createElement(StackedPanelWrapperStyled, { style: { height: "100%", ...props.portalStyle } },
            React.createElement(Flex, { style: { position: "relative", height: "100%" } },
                React.createElement("div", { style: { width: "100%" } }, state.panels.map((key, i) => {
                    return React.createElement(Portal, { panelKey: key, key: i, style: props.portalStyle });
                })),
                props.children)));
}));
const Portal = (function Test(props, ref) {
    const state = React.useContext(StackedPanelStateCtx);
    const innerRef = React.useRef();
    React.useEffect(() => {
        if (innerRef.current) {
            state.registerPortal(props.panelKey, innerRef);
        }
    }, [innerRef.current]);
    return React.createElement("div", { className: "StackedPanelPortal", ref: innerRef, style: props.style });
});
const activeStackedPanelCtx = React.createContext(null);
export function useActiveStackedPanel() {
    return React.useContext(activeStackedPanelCtx);
}
const Panel = observer((props) => {
    const ctx = React.useContext(StackedPanelStateCtx);
    if (!ctx)
        throw new Error("Could not find Context: StackedPanel.Panel may only be used inside of StackedPanel");
    React.useEffect(() => {
        ctx.registerPanel(props.panelKey);
    }, []);
    const renderElement = React.useMemo(() => ctx.portals.get(props.panelKey)?.current, [ctx.portals.get(props.panelKey)?.current]);
    if (!renderElement)
        return null;
    return createPortal(React.createElement(CSSTransition, { classNames: "stacked-panel", timeout: 500, in: ctx.open === props.panelKey, out: ctx.open !== props.panelKey, mountOnEnter: props.mountOnEnter, onEnter: () => props.onEnter?.(), onEntering: () => props.onEntering?.(), onEntered: () => props.onEntered?.(), onExit: () => props.onExit?.(), onExiting: () => props.onExiting?.(), onExited: () => props.onExited?.() },
        React.createElement(activeStackedPanelCtx.Provider, { value: props.panelKey },
            React.createElement(Container, { style: { display: ctx.open === props.panelKey ? "block" : undefined, height: "100%" }, direction: ctx.direction }, props.onlyRenderOnOpen ? (ctx.open === props.panelKey && props.children) : props.children))), renderElement);
});
Panel.displayName = "StackedPanel";
Panel.defaultProps = {
    mountOnEnter: true
};
export const StackedPanel = Object.assign(StackedPanelWrapper, {
    Panel: Panel
});
