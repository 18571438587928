import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useOutsideAlerter, useWindowEvent } from "../../lib";
export const OverlayContext = React.createContext(null);
export const useOverlay = () => React.useContext(OverlayContext);
export const OverlayTrigger = function OverlayTrigger({ trigger = "hover", placement = "right", experimentalOutOfBounce = true, ...props }) {
    const ref = React.useRef(null);
    const triggerType = typeof trigger === "string" ? trigger : trigger.type;
    const [child, setChild] = React.useState();
    const [show, setShow] = React.useState(trigger === "instant");
    const rect = ref.current?.getBoundingClientRect();
    const childRect = React.useMemo(() => {
        return child?.getBoundingClientRect();
    }, [child]);
    const clickPosition = React.useRef();
    const [reposition, setReposition] = React.useState(0);
    useOutsideAlerter(ref, (event) => {
        if (triggerType === "click") {
            if (!child || !child?.contains(event.target)) {
                setShow(false);
            }
        }
    });
    /** detect out of bounce */
    if (experimentalOutOfBounce) {
        if (childRect) {
            switch (placement) {
                case "top":
                    if (childRect.top < 0) {
                        placement = "bottom";
                    }
                    break;
                case "bottom":
                    if (childRect.bottom > window.innerHeight) {
                        placement = "top";
                    }
                    break;
                case "left":
                    if (childRect.x < childRect.width) {
                        placement = "right";
                    }
                    break;
                case "right":
                    if (childRect.right > window.innerWidth) {
                        placement = "left";
                    }
            }
        }
    }
    const [top, left] = React.useMemo(() => {
        if (!rect)
            return [0, 0];
        let top = rect.top;
        let left = rect.left;
        if (placement === "top") {
            top = top - rect.height + 9; // 
            left = left - (childRect?.width ?? 0) / 2 + rect.width / 2;
        }
        if (placement === "bottom") {
            top = top + rect.height + 6;
            left = left - (childRect?.width ?? 0) / 2 + rect.width / 2;
        }
        if (placement === "left") {
            if (child) {
                const el = document.createElement("span");
                el.appendChild(child.cloneNode(true));
                el.style.display = "flex";
                el.style.position = "absolute";
                el.style.top = "0";
                el.style.left = "0";
                el.style.visibility = "hidden";
                document.body.appendChild(el);
                const innerRect = el.childNodes.item(0).getBoundingClientRect();
                document.body.removeChild(el);
                left = left - (innerRect?.width ?? 0) - 6; // 6 is the width of the tick // TODO: make this to a variable
                top = top - (innerRect?.height ?? 0) / 2 + rect.height / 2;
            }
            else {
                left = left - rect.width - (childRect?.width ?? 0) / 2 - 3; // 6 is the width of the tick // TODO: make this to a variable
                top = top - (childRect?.height ?? 0) / 2 + rect.height / 2;
            }
            // left = left - (rect.width) - (childRect?.width ?? 0) / 2 - 3; // 6 is the width of the tick // TODO: make this to a variable
            // top = top - (childRect?.height ?? 0) / 2 + rect.height / 2;
        }
        if (placement === "right") {
            left += rect.width + 6; // 6 is the width of the tick
            top = top - (childRect?.height ?? 0) / 2 + rect.height / 2;
        }
        return [top, left];
    }, [rect, placement, childRect, reposition]);
    useWindowEvent("scroll", () => {
        if (props.experimentalTracking) {
            setReposition((prev) => prev + 1);
        }
    }, true);
    const timeout = React.useRef();
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { ref: ref, style: { cursor: triggerType === "click" ? "pointer" : undefined }, onClick: (mouseEvent) => {
                if (triggerType === "click") {
                    setShow(!show);
                    clickPosition.current = {
                        pageX: mouseEvent.pageX,
                        clientX: mouseEvent.clientX,
                        screenX: mouseEvent.screenX,
                        pageY: mouseEvent.pageY,
                        clientY: mouseEvent.clientY,
                        screenY: mouseEvent.screenY,
                    };
                }
                props.onClick?.(mouseEvent);
            }, onMouseEnter: () => {
                if (typeof trigger === "object" && trigger.type === "hover" && trigger.triggerTime) {
                    timeout.current = setTimeout(() => {
                        setShow(true);
                    }, trigger.triggerTime);
                }
                else {
                    if (typeof trigger === "object" && trigger.type === "hover" || trigger === "hover") {
                        setShow(true);
                    }
                }
            }, onMouseLeave: () => {
                if (timeout.current)
                    clearTimeout(timeout.current);
                if (typeof trigger === "object" && trigger.type === "hover" && trigger.triggerTime) {
                    setShow(false);
                }
                else {
                    if (typeof trigger === "object" && trigger.type === "hover" || trigger === "hover") {
                        setShow(false);
                    }
                }
            } },
            props.children,
            React.createElement(OverlayContext.Provider, { value: {
                    register: setChild,
                    placement: {
                        top: "bottom",
                        left: "right",
                        right: "left",
                        bottom: "top",
                    }[placement],
                    close: () => setShow(false),
                    top, left, rect,
                    clickPosition: clickPosition.current,
                } }, show && createPortal(React.createElement(FadeIn, { className: placement, style: {
                    position: "absolute", top, left
                }, onClick: e => e.stopPropagation() }, props.overlay), document.body)))));
};
const FadeIn = styled.span `
z-index: 10000000000;
&.top {
	animation: fadeInTop 0.1s ease-in-out;

	@keyframes fadeInTop {
		from {
			transform: translateY(-10px);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

&.bottom {
	animation: fadeInBottom 0.1s ease-in-out;

	@keyframes fadeInBottom {
		from {
			transform: translateY(10px);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

&.left {
	animation: fadeInLeft 0.1s ease-in-out;

	@keyframes fadeInLeft {
		from {
			transform: translateX(-10px);
			opacity: 0;
		}
		to {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

&.right {
	animation: fadeInRight 0.1s ease-in-out;

	@keyframes fadeInRight {
		from {
			transform: translateX(10px);
			opacity: 0;
		}
		to {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

`;
