import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Button, Label, Text } from "../../../Components";
import { Flex } from "../../../Components/Flex";
const AutoBorderWrapper = styled(Flex) `
	border-right: none;
	button {
		border-bottom: none;
		border-left: none;
	}
	:first-child {
		border-left: unset;
	}
	button:last-child {
		border-right: unset;
	}
`;
export const MonthPicker = observer(function MonthPicker(props) {
    const now = new Date().getFullYear();
    const [date, setDate] = React.useState(props.date);
    React.useEffect(() => {
        setDate(props.date);
    }, [props.date]);
    const [year, setYear] = React.useState(date?.year);
    React.useEffect(() => {
        setYear(date?.year);
    }, [date]);
    return (React.createElement(Flex, { alignItems: "center", style: { width: "100%", overflow: "hidden" } },
        React.createElement(Flex, { column: true, grow: true, style: { width: "100%", position: "relative" } },
            React.createElement(Flex, { style: { margin: "auto" } },
                React.createElement(MonthPickerButton, { size: "xs", style: { height: 20, border: "none" }, onClick: () => setYear(e => (e ?? new Date().getFullYear()) - 1) },
                    React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
                React.createElement(Label, { variant: "#fff", style: { height: 20, textAlign: "center" }, fixedWidth: "x3" }, year ?? "-"),
                React.createElement(MonthPickerButton, { size: "xs", style: { height: 20, border: "none" }, onClick: () => setYear(e => (e ?? new Date().getFullYear()) + 1) },
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight }))),
            React.createElement(AutoBorderWrapper, { style: { width: "100%", height: 20 } },
                React.createElement(MonthPickerButton, { onClick: () => {
                        const v = props.date?.set({ year, month: props.date.month - 1 }) ?? DateTime.utc();
                        setDate(v);
                        props.onChange(v);
                    }, size: "xs", style: { height: 19, border: "none" } },
                    React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
                moment.monthsShort().map((month, i) => {
                    return React.createElement(MonthPickerButton, { size: "xs", variant: (year && date) ? ((year === date.year) && (i + 1) === date.month) ? "primary" : null : null, key: month, style: { height: 19, width: "100%", textTransform: "uppercase", lineHeight: "18px", borderLeft: "none", borderBottom: "none", border: "none" }, onClick: () => {
                            const v = props.date?.set({ year: year ?? now, month: i + 1 }) ?? DateTime.utc().set({ month: i + 1 })
                                .set({
                                second: 0,
                                millisecond: 0,
                                minute: 0,
                            });
                            if (props.date && v.equals(props.date))
                                return;
                            setDate(v);
                            props.onChange(v);
                        } },
                        React.createElement(Text, { style: { fontSize: ".9em" } }, month.slice(0, 1)));
                }),
                React.createElement(MonthPickerButton, { size: "xs", style: { height: 20, border: "none" }, onClick: () => {
                        const v = (props.date?.set({ year: year ?? now, month: props.date.month + 1 }) ?? DateTime.utc())
                            .set({
                            second: 0,
                            millisecond: 0,
                            minute: 0,
                        });
                        if (props.date && v.equals(props.date))
                            return;
                        setDate(v);
                        props.onChange(v);
                    } },
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight }))))));
});
const MonthPickerButton = styled(Button) `
padding: 1px 7px;
font-size: .9em;
`;
