import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreSingle } from "../../BaseStoreSingle";

export class ProcedureStore extends BaseStoreSingle<FpApi.Quality.Procedure.Procedure, number> {
	protected itemId(item: FpApi.Quality.Procedure.Procedure): number {
		return item.id;
	}

	protected fetchIdList(idList: number[]): Promise<FpApi.Quality.Procedure.Procedure[]> {
		return Promise.all(
			idList.map(id => {
				return apiManager
					.getService(FpApi.Quality.Procedure.ProcedureService)
					.getId(this.app.ctx, { id });
			})
		);
	}
}
