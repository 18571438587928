import { FpApi } from "@tcs-rliess/fp-core";

export class ScheduleBalanceResponse implements FpApi.Resource.Duty.ScheduleBalanceObject {
	cnt: number;
	min: number;
	day: number;
	dur: number;

	constructor(cnt: number, min: number, day: number, dur: number) {
		this.cnt = cnt ?? 0;
		this.min = min ?? 0;
		this.day = day ?? 0;
		this.dur = dur ?? 0;
	}

	public static fromObject(obj: FpApi.Resource.Duty.ScheduleBalanceObject) {
		if(!obj) return null;
		return new ScheduleBalanceResponse(obj.cnt ?? 0, obj.min ?? 0, obj.day ?? 0, obj.dur ?? 0);
	}

	public static empty() {
		return new ScheduleBalanceResponse(0, 0, 0, 0);
	}

	public add(response: ScheduleBalanceResponse) {
		return new ScheduleBalanceResponse(this.cnt + response.cnt, this.min + response.min, this.day + response.day, this.dur + response.dur);
	}

	public json(): FpApi.Resource.Duty.ScheduleBalanceObject {
		return {
			cnt: this.cnt,
			min: this.min,
			day: this.day,
			dur: this.dur,
		};
	}

	public negate() {
		return new ScheduleBalanceResponse(-this.cnt, -this.min, -this.day, -this.dur);
	}
}
