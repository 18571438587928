import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

export class ContactTargetWorkingTimeCorrectionStore extends BaseStoreMulti<FpApi.Contact.ContactTargetWorkingTimeCorrection, number> {
	protected loadedYears = new Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>();
	protected history = new Map<number, Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>>();
	protected itemId(item: FpApi.Contact.ContactTargetWorkingTimeCorrection): number {
		return item.id;
	}

	protected async fetchAll(): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		const data = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.get(this.ctx, {
				year: new Date().getFullYear(),
			});
		this.loadedYears.set(new Date().getFullYear(), data);
		return data;
	}

	public async getYear(year: number): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		if(this.loadedYears.has(year)) {
			return this.loadedYears.get(year);
		}
		const corrections = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.get(this.ctx, {
				year: year,
			});
		this.loadedYears.set(year, corrections);
		corrections.forEach(c => this.update(c));
		return corrections;
	}

	/** updates entry in year when year already loaded */
	public updateYear(entry: FpApi.Contact.ContactTargetWorkingTimeCorrection) {
		this.update(entry);
		this.loadedYears.get(entry.year)?.push(entry);
	}

	/** updates entry in year when year already loaded */
	public removeYear(entry: FpApi.Contact.ContactTargetWorkingTimeCorrection) {
		this.remove(entry.id);
		const y = this.loadedYears.get(entry.year);
		const index = y.findIndex(e => e.id === entry.id);
		if(index >= 0) {
			y.splice(index, 1);
		}
	}


	public async getHistory(dscaid: number, year: number): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		const history = this.history.get(dscaid)?.get(year);
		if(history) {
			return history;
		} else {
			const data = await apiManager.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
				.getHistory(this.ctx, {
					dscaid,
					year,
				});
			if(!this.history.has(dscaid)) {
				this.history.set(dscaid, new Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>());
			}
			this.history.get(dscaid).set(year, data);
			data.forEach(c => this.update(c));
			return data;
		}
	}

	public flushHistory(dscaid: number, year: number): void {
		this.history.get(dscaid)?.delete(year);
	}
}
