import { omit } from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useThemeColor } from "../../Css";
export const Text = function Text(props) {
    const theme = useTheme();
    let background;
    let color;
    const backgroundColor = useThemeColor(props.background);
    if (backgroundColor) {
        background = backgroundColor[500].main;
        color = backgroundColor[500].font;
    }
    const colorInfo = useThemeColor(props.color);
    if (colorInfo) {
        color = colorInfo[500].main;
    }
    if (props.muted) {
        color = theme.fontMuted;
    }
    return React.createElement(StyledText, { ...omit(props, "color", "background", "muted"), size: props.size ?? "md", italic: props.italic, style: {
            color: color,
            background: background,
            ...(props.style ?? {})
        } }, props.children);
};
const StyledText = styled.span `
	${p => {
    switch (p.size) {
        case "xs": return "--font-size: .75em;";
        case "sm": return "--font-size: .8em;";
        case "md": return "--font-size: 1em;";
        case "lg": return "--font-size: 1.25em;";
        default: return "--font-size: 1em;";
    }
}}

	font-size: var(--font-size);
	font-style: ${p => p.italic ? "italic" : "normal"};
	font-weight: ${p => p.bold ? "bold" : "normal"};
	text-decoration: ${p => p.underline ? "underline dotted" : "none"};
`;
