import { faDownload, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FpApi } from "@tcs-rliess/fp-core";
import { CommandBar, lazyComponent } from "@tcs-rliess/fp-web-ui";
import React from "react";

import type { FleetplanApp } from "../FleetplanApp";
import { handleError } from "../handleError";

import { EventStreamImpact } from "./mqtt";

const ActivityIndicator = lazyComponent(() => import("../modules/Common/Base/chunk"), m => m.ActivityIndicator);

export function handlePrint(params: {
	app: FleetplanApp;
	toast: {
		app: string;
	};
	print: () => Promise<FpApi.PrintResult> | Promise<FpApi.PrintResult>;
	onComplete?: () => void;
}): string {
	const toast = params.app.toastManager.add({
		...params.toast,
		icon: faFilePdf,
		body: "creating PDF file...",
		variant: "info",
		buttons: <CommandBar.Text>
			<React.Suspense fallback={null}><ActivityIndicator/></React.Suspense>
		</CommandBar.Text>,
		autoDismiss: false,
	});

	params.print()
		.then(printResult => {
			// sometimes the print function has no error handling and hands an server side error into our function
			// do a basic validation
			if (printResult.mk == null || printResult.key == null) {
				throw new Error("invalid printResult handed to handlePrint");
			}

			params.app.eventStream.subscribe({
				impact: EventStreamImpact.OneUserActivelyWaiting,
				filter: {
					a: "media",
					// s: "controlled_documents_revision_pdf_document",
					t: "upload",
					e: params.app.ctx.dscaid.toString(),
					mk: printResult.mk,
				},
				callback: () => {
					params.onComplete?.();

					params.app.toastManager.update(toast, {
						...params.toast,

						variant: "info",
						icon: faFilePdf,
						body: printResult.name,
						buttons: <CommandBar.Button
							variant="info"
							onClick={() => {
								window.open(`/media/file/${encodeURIComponent(printResult.key)}?inline=1`, "_blank");
							}}
						>
							<FontAwesomeIcon icon={faDownload}/>
						</CommandBar.Button>,
					});
					window.open(`/media/file/${encodeURIComponent(printResult.key)}?inline=1`, "_blank");
				},
			});
		})
		.catch(err => {
			params.app.toastManager.update(toast, {
				...params.toast,

				variant: "danger",
				icon: faFilePdf,
				body: "Error creating PDF file",
			});

			handleError(err);
		});

	return toast;
}
