import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { Flex, FlexItem } from "../Flex";
import { useDialogProps } from "./Dialog";
const Header = styled.div `
	border-bottom: 1px solid #ddd;
	user-select: none;
	padding: 12px 18px;
	font-size: 1.2rem;
	line-height: 1.42857143;

	position: sticky;
	top: 0px;
	z-index: 10000000001;
	background: ${p => p.theme.background};
`;
const Title = styled.h3 `
	margin: 0;
	line-height: 1.42857143;
`;
export const DialogHeader = observer((props) => {
    const dialog = useDialogProps();
    return React.createElement(Header, null,
        React.createElement(Flex, null,
            React.createElement(FlexItem, { grow: true },
                React.createElement(Title, null, props.children)),
            props.closeButton && React.createElement(CloseButton, { onClick: dialog.onHide },
                React.createElement(FontAwesomeIcon, { icon: faTimes }))));
});
const CloseButton = styled(FlexItem) `
cursor: pointer;
margin: auto;
opacity: 0.8;
&:hover {
	opacity: 1;
}
`;
