import { FpApi, LocationSetupStatus, LocationValidationSetupResultDate, ResourceScheduleUtil } from "@tcs-rliess/fp-core";
import { groupBy, uniq } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";
import { ServiceDataProcessor } from "../../fp-query";
import { FpEventModel, FpResourceModel, FpResourceTimeRangeModel } from "../models";
import { SchedulerStore } from "../SchedulerStore";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

interface SetupParams {
	from: DateTime;
	to: DateTime;

	dsrlsid?: number;
}

export class ResourceDutyScheduleBuilder extends BaseBuilder {
	private resourceScheduleUtil = new ResourceScheduleUtil();

	constructor(store: SchedulerStore, app: FleetplanApp) {
		super(store, app);
	}

	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.resourceSchedule?.enabled) loads.push(this.setup(state));
		if (this.store.configuration.events.resourceSchedule?.enabled) loads.push(this.resourceSchedule(state));

		await Promise.all(loads);
	}

	public async handlePut(state: BuilderState, item: FpApi.Resource.Duty.Schedule): Promise<void> {
		if (this.store.configuration.events.resourceSchedule?.enabled) {

			// const event = this.store.projectModel.eventStore.getById(`dsrschid:${item.id}`);
			const events = this.store.projectModel.eventStore.query((i: FpEventModel) => {
				return i.fpLinkType === "dsrschid"
					&& i.fpLinkId === item.id.toString()
					&& FpEventModel.isFpEventModel(i)
					&& FpEventModel.isSchedule(i);
			}, true) as FpEventModel<FpApi.Resource.Duty.Schedule>[];

			for (const event of events) {
				const date = DateTime.fromISO(event.fpData.dateFrom).startOf("day");
				await this.updateSetup(state, {
					from: date,
					to: date.plus({ day: 1 }),
					dsrlsid: event.fpData.data.dsrlsid,
				});
				// this.store.projectModel.assignmentStore.removeAssignmentsForEvent(event as any);
				this.store.projectModel.eventStore.remove(event);
			}

			this.resourceScheduleUtil.flatten([ item ]).forEach(item => {
				this.schedule2Event(state, item);
			});

			if (item.data.dsrlsid && item.data.fpdirloc) {
				const date = DateTime.fromISO(item.dateFrom).startOf("day");
				await this.updateSetup(state, {
					from: date,
					to: date.plus({ day: 1 }),
					dsrlsid: item.data.dsrlsid,
				});
			}
		}

		return Promise.resolve();
	}

	public async handleDelete(state: BuilderState, id: string): Promise<void> {
		const events = this.store.projectModel.eventStore.remove(`dsrschid:${id}`) as FpEventModel<FpApi.Resource.Duty.Schedule>[];
		const resourceTimeRange = this.store.projectModel.resourceTimeRangeStore.remove(`dsrschid:${id}`) as FpResourceTimeRangeModel<FpApi.Resource.Duty.Schedule>[];

		const duties = [
			...events.map(i => i.fpData),
			...resourceTimeRange.map(i => i.fpData),
		];

		for (const duty of duties) {
			// update setup validation
			if (duty.data.dsrlsid && duty.data.fpdirloc) {
				const date = DateTime.fromISO(duty.dateFrom).startOf("day");
				await this.updateSetup(state, {
					from: date,
					to: date.plus({ day: 1 }),

					dsrlsid: duty.data.dsrlsid,
				});
			}
		}
		return Promise.resolve();
	}

	private async resourceSchedule(state: RangeBuilderState): Promise<void> {
		let resourceDuties: FpApi.Resource.Duty.Schedule[];

		if (this.store.configuration.plugins?.contactScheduler?.enabled) {
			// scheduler in contact profile
			// only load events for this one user
			resourceDuties = await ServiceDataProcessor
				.getService(this.app.ctx, FpApi.Resource.Duty.ScheduleService)
				.get({
					params: {
						from: state.from.toISO(),
						to: state.to.toISO(),
						linkType: "dscaid",
						linkId: this.store.configuration.plugins.contactScheduler.dscaid.toString(),
					},
					cache: {
						maxAge: 30 * 60 * 1000,
					},
				});
		} else {
			resourceDuties = await this.app.store.resource.schedule.getRange(state.from, state.to);
		}

		this.resourceScheduleUtil.flatten(resourceDuties).forEach(duty => {
			this.schedule2Event(state, duty);

			if (duty.type === FpApi.Resource.Duty.ScheduleType.DutyWish) {
				this.dutyWish2ResourceTimeRange(state, duty);
			}
		});
	}

	private async setup(state: RangeBuilderState): Promise<void> {
		for (const dsrlsid of state.getResourcesIdList("dsrlsid")) {
			await this.updateSetup(state, {
				from: state.from,
				to: state.to,
				dsrlsid: parseInt(dsrlsid),
			});
		}
	}

	private async updateSetup(state: BuilderState, extra: SetupParams): Promise<void> {
		if (extra.dsrlsid == null) return;
		const setup = await this.app.store.resource.locationSetup.getId(extra.dsrlsid);
		if (setup == null) return;

		const schedules = await this.app.store.resource.schedule.getRange(extra.from, extra.to);
		const dscaids = uniq(
			schedules
				.filter(schedule => schedule.linkType === "dscaid" && schedule.data.dsrlsid === extra.dsrlsid)
				.map(schedule => +schedule.linkId)
		);
		await this.app.store.resource.locationSetupUtil.prepareDscaids(dscaids);
		const setupResult = this.app.store.resource.locationSetupUtil.validateSetup({
			schedules: schedules,
			from: extra.from,
			to: extra.to,
			setup: setup,
		});

		for (const result of setupResult.dates.values()) {
			state.getResources("dsrlsid", extra.dsrlsid).forEach(resource => {
				this.makeTimeRange(state, {
					resource: resource,
					setup: setup,
					extra: extra,

					date: result.date,
					result: result,
				});
			});
		}
	}

	/**
	 * helper to make a time range model
	 */

	private makeTimeRange(state: BuilderState, params: {
		resource: FpResourceModel;
		date: DateTime;
		setup: FpApi.Resource.Duty.LocationSetup;
		extra: SetupParams;
		result: LocationValidationSetupResultDate;
	}): void {
		this.store.projectModel.resourceTimeRangeStore.remove(`${params.resource.id}:${params.date.toISO()}`);

		const isoDate = params.date.toISO();
		const startDate = params.date.toJSDate();
		const endDate = params.date.plus({ day: 1 }).toJSDate();

		function getClass(status: LocationSetupStatus): string {
			switch (status) {
				case LocationSetupStatus.Ok: return "duty-roster-success duty-validation";
				case LocationSetupStatus.NotOk: return "duty-roster-danger duty-validation";
				case LocationSetupStatus.PartialOk: return "duty-roster-danger duty-validation";
				case LocationSetupStatus.Overbooked: return "duty-roster-warning duty-validation";
				case LocationSetupStatus.MissingPermission: return "duty-roster-locked duty-validation";
				default: return "";
			}
		}

		const createRange = (resourceId: string | number): FpResourceTimeRangeModel => {
			return new FpResourceTimeRangeModel({
				id: `${resourceId}:${isoDate}`,
				resourceId: resourceId,
				startDate: this.toTimeZone(startDate),
				endDate: this.toTimeZone(endDate),
				fpLinkType: "dsrlsid",
				fpLinkId: params.setup.id,
				fpData: params.setup,
			});
		};

		const byLinkType = groupBy(params.result.schedules, e=>e.linkType);
		const contacts = new Set(byLinkType["dscaid"]?.map(e => e.linkId)).size;
		const fulfilled = params.result.positions.filter(position => position.status < 2).length + params.result.resources.filter(position => position.status < 2).length;
		const upperLimit = params.result.positions.length + (params.result.resources?.length ?? 0);
		let status: LocationSetupStatus;
		if (fulfilled === upperLimit) {
			status = LocationSetupStatus.Ok;
		} else if (fulfilled == 0) {
			status = LocationSetupStatus.NotOk;
		} else {
			status = LocationSetupStatus.PartialOk;
		}

		const range = createRange(params.resource.id);
		range.cls = getClass(status);
		range.name = `${contacts}`;
		range.set("contacts", contacts);
		if (params.result["missing_cert"]) {
			range.name += '<i class="fa fa-circle-exclamation"></i>';
		}
		let invalid = false;
		const [ validFrom, validTo ] = [ DateTime.fromISO(params.setup.validFrom, { zone: this.store.tz }), DateTime.fromISO(params.setup.validTo ?? params.setup.data.provisionalDateUntil, { zone: this.store.tz }) ];
		if(params.date < validFrom || params.date >= validTo) {
			console.log(params.date.toISO(), validFrom.toISO(), validTo.toISO());
			invalid = true;
		}
		if(invalid) {
			if(params.setup.data.provisionalDateUntil) {
				range.cls = "duty-roster-locked duty-validation";
				range.set("setup_is_invalid", true);
				params.resource.allChildren.forEach(child => {
					this.store.projectModel.resourceTimeRangeStore.remove(`${child.id}:${isoDate}`);
					const range = createRange(child.id);
					range.cls = "duty-roster-locked duty-validation";
					state.resourceTimeRanges.push(range);
				});
			} else {
				range.cls = "duty-roster-invalid duty-validation";
				range.set("setup_is_invalid", true);
				params.resource.allChildren.forEach(child => {
					this.store.projectModel.resourceTimeRangeStore.remove(`${child.id}:${isoDate}`);
					const range = createRange(child.id);
					range.cls = "duty-roster-invalid duty-validation";
					state.resourceTimeRanges.push(range);
				});
			}
		}

		state.resourceTimeRanges.push(range);
		if(invalid) return;
		params.result.positions.forEach(position => {
			state.getResources("dsrdsidPos", `${params.extra.dsrlsid}:${position.dsrdsidPos}`).forEach(resource => {
				this.store.projectModel.resourceTimeRangeStore.remove(`${resource.id}:${isoDate}`);
				const range = createRange(resource.id);
				range.cls = getClass(position.status);
				range.name = position.used.toString();
				if (position.status === LocationSetupStatus.MissingPermission) {
					range.name = '<span title="Missing Permission" ><i class="fa fa-lock"></i></span>';
				}
				state.resourceTimeRanges.push(range);
			});
		});
	}

	private schedule2Event(state: BuilderState, schedule: FpApi.Resource.Duty.Schedule): void {
		this.makeEvent(state, () => {
			const editable = schedule.data.dswfidState == null;
			const draggable =
				schedule.type === FpApi.Resource.Duty.ScheduleType.Duty
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.Standby
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.LocalDay
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.Off;
			const resizable =
				schedule.type === FpApi.Resource.Duty.ScheduleType.Duty
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.Standby;

			// event start / end dates
			const startDate = new Date(schedule.dateFrom);
			let endDate = new Date(schedule.dateTo);
			if (
				schedule.type === FpApi.Resource.Duty.ScheduleType.Duty
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.DutyWish
				|| schedule.type === FpApi.Resource.Duty.ScheduleType.Standby
			) {
				// duties can't have multiple days, they are at most overnight, display just on the starting day
				// the scheduler requires an end date to be set, and it needs to different, otherwise we only get the "diamond" symbol
				endDate = new Date(+startDate + 1000);
			}

			const event = new FpEventModel({
				id: schedule.cid ? `dsrschid:${schedule.id}:${schedule.cid}` : `dsrschid:${schedule.id}`,
				startDate: this.toTimeZone(startDate),
				endDate: this.toTimeZone(endDate),
				allDay: schedule.type !== FpApi.Resource.Duty.ScheduleType.Stationing ? true : false, // temp

				fpLinkType: "dsrschid",
				fpLinkId: schedule.id.toString(),
				fpData: schedule,

				draggable: editable && draggable,
				resizable: editable && resizable,
			});

			return event;
		}, function*() {
			// assignment to resource
			yield { linkType: schedule.linkType, linkId: schedule.linkId };

			// assigned to a shift on a setup
			// setup sub id : pos : resource shift
			if (schedule.data.dsrlsid && schedule.data.dsrdsidPos && schedule.data.dsrsid) {
				yield { linkType: "dsrsid", linkId: `${schedule.data.dsrlsid}:${schedule.data.dsrdsidPos}:${schedule.data.dsrsid}` };
			}
			if (schedule.data.dsrlsid && schedule.data.dsrdsidRes) {
				yield { linkType: "dsrdsidRes", linkId: `${schedule.data.dsrlsid}:resource:${schedule.data.dsrdsidRes}` };
			}
		});
	}

	private dutyWish2ResourceTimeRange(state: BuilderState, schedule: FpApi.Resource.Duty.Schedule): void {
		const start = DateTime.fromISO(schedule.dateFrom);
		let end = DateTime.fromISO(schedule.dateTo);

		const days = end.diff(start, "days").days;
		if (days <= 1) {
			// shorter then a day, likely a duty
			// only show duty on their start date, mostly relevant for night shifts
			end = start.startOf("day").plus({ days: 1 });
		}

		const timeRange = new FpResourceTimeRangeModel({
			id: `dsrschid:${schedule.id}`,
			startDate: this.toTimeZone(start),
			endDate: this.toTimeZone(end),
			// name: schedule.dscatid.toString(),
			cls: `duty-schedule-${schedule.type}`,
			timeRangeColor: "purple",

			fpLinkType: "dsrschid",
			fpLinkId: schedule.id,
			fpData: schedule,
		});

		this.makeResourceTimeRange(state, timeRange, function*() {
			// assigned to a shift on a setup
			// setup sub id : pos : resource shift
			if (schedule.data.dsrlsid && schedule.data.dsrdsidPos && schedule.data.dsrsid) {
				yield { linkType: "dsrsid", linkId: `${schedule.data.dsrlsid}:${schedule.data.dsrdsidPos}:${schedule.data.dsrsid}` };
			}
		});
	}
}
