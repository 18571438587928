import { Input } from "@tcs-rliess/fp-web-ui";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

@observer
export class ErrorBoundary extends React.Component<{ children?: React.ReactNode; }> {
	@observable
	private error: Error;
	private errorInfo: React.ErrorInfo;

	@observable
	private hideDetails = true;

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		this.error = error;
		this.errorInfo = errorInfo;
	}

	public render(): React.ReactNode {
		if (this.error) {
			return <div className="alert alert-danger" style={{ "border": "1px dashed", "margin": 0, "padding": "7px" }}>
				{this.hideDetails ? (
					<h4 style={{ margin: 0 }}>
						🤕 Something went wrong&nbsp;
						<small><a onClick={() => this.hideDetails = false} style={{ cursor: "pointer" }}>show details...</a></small>
					</h4>
				) : (
					<>
						<h3 style={{ margin: 0 }}>{this.error.message}</h3>
						<pre><code>{this.error.stack}</code></pre>
						<pre><code>{this.errorInfo.componentStack}</code></pre>

						<Input.Group>
							<Input.Group.Addon>click to copy</Input.Group.Addon>
							<input
								type="text"
								className="form-control"
								value={this.stringifyError({ error: this.error, errorInfo: this.errorInfo })}
								readOnly
								onClick={this.copy}
							/>
						</Input.Group>
					</>
				)}
			</div>;
		}
		return this.props.children;
	}

	private copy(e: React.MouseEvent<HTMLInputElement>): void {
		e.currentTarget.select();
		document.execCommand("copy");
	}

	private stringifyError(err: any): string {
		return JSON.stringify(err, (key, value) => {
			if (value instanceof Error) {
				const error = {};
				Object.getOwnPropertyNames(value).forEach(function(key) {
					error[key] = value[key];
				});
				return error;
			}
			return value;
		});
	}
}
