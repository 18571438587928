import { EventDashboard, EventUtil, FpApi, FPEvent, FPEventFilter, FPEventResource, PutStatusData } from "@tcs-rliess/fp-core";
import { DateRange, DateRangePreset } from "@tcs-rliess/fp-web-ui";
import autobind from "autobind-decorator";
import EventEmitter from "events";
import { clone, cloneDeep, groupBy, isEqual, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import { action, autorun, computed, observable, reaction, transaction } from "mobx";
import moment from "moment";

import { FleetplanApp } from "../../../FleetplanApp";
import { EventStreamImpact } from "../../mqtt";

const DateRangePresets = {
	nowTillEndOfYear: {
		label: "Now +6 Months",
		getRange: (): DateRange => { return new DateRange(DateTime.now().startOf("day"), DateTime.now().plus({ months: 6 }).endOf("day")); }
	},
	thisYear: {
		label: "This Year",
		getRange: (): DateRange => { return new DateRange(DateTime.now().startOf("year"), DateTime.now().endOf("year")); }
	},
	lastYear: {
		label: "Last Year",
		getRange: (): DateRange => { return new DateRange(DateTime.now().minus({ year: 1 }).startOf("year"), DateTime.now().minus({ year: 1 }).endOf("year")); }
	},
};

// add years 2014 till now to the presets (database import/mig starts from 2014)
function extendPresets() {
	const minusTwoYears = DateTime.now().minus({ year: 2 }); // since we already have this year and last year
	for (let year = minusTwoYears.year; year >= 2014; year--) { // loop is backwards - so we have the years in right order in UI
		DateRangePresets[`y${year}`] = { // y is for the sorting problem -> without y 2014 will be on top
			label: year.toString(),
			getRange: (): DateRange => { return new DateRange(DateTime.local(year).startOf("year"), DateTime.local(year).endOf("year")); }
		};
	}
}

export class EventStore extends EventEmitter {
	private app: FleetplanApp;
	private load = new Map<string, Promise<FPEvent>>();

	@observable.ref public data = new Map<string, FPEvent>();
	@observable.ref protected _templates = new Map<string, FPEvent>();
	@observable protected _repeatMasters = new Map<string, FPEvent>();
	@observable protected loadedYears = new Set<number>();

	// used to get all children of a certain container
	@observable.ref protected byParentId = new Map<string, FPEvent[]>();

	@observable protected _dashboards = new Map<number, EventDashboard>();

	public eventDateRangePresetsAsArray: DateRangePreset[] = [];

	constructor(app: FleetplanApp) {
		super();
		this.app = app;

		extendPresets();
		this.eventDateRangePresetsAsArray = Array.from(Object.values(DateRangePresets));

		autorun(() => {
			if (app.flags.flightOpsNew) {
				void app.mqtt.subscribe("/event", this.mqttCb);

				if (this.app.ctx.isAuthorized) {
					void this.loadTemplates();
					// void this.loadYear(DateTime.now().year);
				} else {
					// wait until user logged in
					reaction(
						() => this.app.ctx.isAuthorized,
						async () => {
							if (!this.app.ctx.isAuthorized) return;
							await this.loadTemplates();
							//await this.loadYear(DateTime.now().year);
						}
					);
				}
			}
		});

		this.app.eventStream.subscribe({
			impact: EventStreamImpact.AllUsers,
			filter: { a: "media", s: "events_event" },
			callback: async (items) => {
				await this.eventStreamMediaCb(items);
			}
		});
	}

	@autobind
	private async mqttCb(...args): Promise<void> {
		const [ , data ] = args;

		const { action, data: events } = data;
		if (action === "update" || action == "put_status") {
			for (const event of events) {
				if (event.id.split("-").length === 0) return; // do not upsert containers, they cannot change and hold no important value
				await this.handleInsertEvent(event, true);
			}

			this.byParentId = new Map(this.byParentId); // remap because we don't remap in the handleInsertEvent above
			this.data = new Map(this.data); // remap because we don't remap in the handleInsertEvent above
		} else if (action === "delete") {
			for (const event of events) {
				const id = event.id;
				if (this.data.has(id)) {
					this.data.delete(id);
				}

				if (this.load.has(id)) {
					this.load.delete(id);
				}
				this.emit("delete", id);
			}
		} else {
			console.error("MQTT EventStore: unknown event ->", data);
		}
	}

	public async loadYear(year: number): Promise<void> {
		if (this.loadedYears.has(year)) {
			return;
		}

		this.loadedYears.add(year);

		await this.loadRange({
			from: DateTime.now().set({ year }).startOf("year").toUTC().toISO(),
			to: DateTime.now().set({ year }).endOf("year").toUTC().toISO()
		});
	}

	public async getRange(from: DateTime, to: DateTime) {
		// ensure correct years are loaded
		await this.app.store.event.loadYear(from.year);
		if (to.year !== from.year) await this.app.store.event.loadYear(to.year);

		const fromISO = moment(from.toISO());
		const toISO = moment(to.toISO());

		// check for all events in range of from and to
		return Array.from(this.data.values()).filter(e => {
			const start = moment(e.date_start);
			const end = moment(e.date_end);

			// also include events that do not start or end in our range but are in between
			return (start.isBetween(fromISO, toISO) || end.isBetween(fromISO, toISO) || (start.isBefore(fromISO) && end.isAfter(toISO)));
		});
	}

	@action
	public async loadRange({ from, to }): Promise<void> {
		await this.get({
			range: {
				from,
				to,
			}
		});

		// create a new map because this.data is a ref observable
		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);
	}

	@action
	private async loadTemplates(): Promise<void> {
		await this.get({
			templates: true
		});

		// this._templates = new Map(templates.map(t => [ t.id, t ]));
	}

	@computed
	public get eventsByYear(): { [key: number]: FPEvent[] } {
		return groupBy(Array.from(this.data.values()).sort((a, b) => moment(a.date_start) > moment(b.date_start) ? 1 : -1), (e) => moment(e.date_start).year());
	}

	@computed
	public get templates(): FPEvent[] {
		return Array.from(this._templates.values());
	}

	@computed
	public get repeatMasters(): FPEvent[] {
		return Array.from(this._repeatMasters.values());
	}

	@computed
	public get flightEvents(): FPEvent[] {
		return Array.from(this.data.values()).filter(e => e.type === FpApi.Calendar.Event.EventType.Flight);
	}

	@computed
	public get dashboards(): EventDashboard[] {
		return Array.from(this._dashboards.values());
	}

	protected fetchIds(ids: string[]): Promise<FPEvent[]> {
		return this.get({ ids });
	}

	protected fetchId(id: string): Promise<FPEvent> {
		return new Promise<FPEvent | null>((resolve, reject) => {
			this.get({ ids: [ id ] }).then((ev) => resolve(ev.find(e => e.id === id))).catch(reject);
		});
	}

	public getIds(ids: string[]): Promise<FPEvent[]> {
		return new Promise<FPEvent[] | null>((resolve, reject) => {
			this.get({ ids }).then((ev) => resolve(ev.filter(e => ids.includes(e.id)))).catch(reject);
		});
	}

	@action
	public async flushId(id: string): Promise<void> {
		const ev = await this.getId(id);

		// delete (and reload) all subs if necessary
		if (ev?.type === FpApi.Calendar.Event.EventType.Container) {
			for (const event of Object.values(this.byParentId.get(id))) {
				this.load.delete(event.id);
				this.data.delete(event.id);
			}

			// delete all 'fake' events with this rule
			/*if (ev.rrule) {
				const events = Array.from(this.data.values()).filter(e => e.dseidRepeatParent === ev.id);
				const realEvents = events.filter(e => e.status !== FpApi.Calendar.Event.EventStatus.Repeat);
				for (const ev of events) {
					this.load.delete(ev.id);
					this.data.delete(ev.id);
				}

				if (realEvents.length) await this.loadIds(realEvents.map(e => e.id));
			}*/
		}

		this.load.delete(id);
		this.data.delete(id);
		this._repeatMasters.delete(id);

		await this.getId(id);
	}

	@action
	public async deleteId(id: string): Promise<void> {
		const oldEv = clone(this.data.get(id) ?? this._templates.get(id) ?? this._repeatMasters.get(id));

		await this.delete(id);

		this.load.delete(id);
		this.data.delete(id);

		this._templates.delete(id);
		this._repeatMasters.delete(id);

		// if flight or general exists - refresh whole container
		if (oldEv && [ FpApi.Calendar.Event.EventType.Flight, FpApi.Calendar.Event.EventType.General ].includes(oldEv.type)) {
			const parentId = this.getParentId(id);
			if (this.byParentId.has(parentId)) {
				this.byParentId.set(parentId, this.byParentId.get(parentId).filter(e => e.id !== id));
			}
		}

		// delete virtual / fake repeat events
		if (oldEv && oldEv.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// get all events which had this event as master and are not in status repeat (status repeat = virtual)
			const events = Array.from(this.data.values()).filter(e => e.dseid_repeat_parent === oldEv.id && e.status === FpApi.Calendar.Event.EventStatus.Repeat);

			for (const fakeEv of events) {
				this.data.delete(fakeEv.id);
			}
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);
		this._templates = new Map(this._templates);
		this._repeatMasters = new Map(this._repeatMasters);

		this.emit("delete", id);
	}

	@action
	public async getId(id: string): Promise<FPEvent> {
		if (id == null) return undefined;

		if (this._repeatMasters.has(id)) {
			return this._repeatMasters.get(id);
		}

		if (this._templates.has(id)) {
			return this._templates.get(id);
		}

		if (this.data.has(id)) {
			await this.load.get(id);
			return this.data.get(id);
		} else {
			try {
				const loadEv = this.fetchId(id);
				this.load.set(id, loadEv);

				const item = await loadEv;

				if (item) await this.handleInsertEvent(item);

				return item;
			} catch (e) {
				this.load.delete(id);
				this.data.delete(id);
				throw e;
			}
		}
	}

	@action
	public async updateEventRole(event: FPEvent, role: FpApi.Calendar.Event.EventResourceRole, linkId: string): Promise<FPEvent> {
		const resource = event.resources.find(e => e.link_type === "dscaid" && e.dserrid === role);
		resource.link_id = linkId;

		const _event = await this.putEvent(event);

		return _event;
	}

	@action
	public async put(data: FPEvent): Promise<FPEvent> {
		const event = await this.putEvent(data);

		if (event.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// await eventService.updateRepeatEvents(this.app.ctx, { data });
		}

		// rrule events are special because they have fake events
		if (event.rrule) {
			// await this.flushId(event.id);
		} else {
			// await this.handleInsertEvent(event);
		}

		return event;
	}

	@action
	public async putStatus(opts: PutStatusData): Promise<FPEvent> {
		const request = await fetch("/api/events/status", {
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(opts),
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		for (const row of requestJson.rows) {
			await this.handleInsertEvent(row, true);
		}

		this.byParentId = new Map(this.byParentId);
		this.data = new Map(this.data);

		return requestJson.rows[0];
	}

	@action
	public async handleInsertEvent(event: FPEvent, noRemap = false): Promise<void> {
		// if item is container, re-set all subs
		if (event.type === FpApi.Calendar.Event.EventType.Container) {
			/*for (const subEvent of eventsFlattened) {
				if (subEvent.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
					this._repeatMasters.set(subEvent.id, subEvent);
				} else {
					this.data.set(subEvent.id, subEvent);
				}
			}*/

			/*if (event.type === FpApi.Calendar.Event.EventType.Container) {
				const bookingItemIds = event.resources.filter(r => r.linkType === "dsbiid").map(r => r.linkId);
				await this.app.store.bookingItem.get({ id: bookingItemIds });
			}*/
		} else {
			const [ parentId ] = event.id.split("-");
			if (!this.byParentId.has(parentId)) {
				this.byParentId.set(parentId, [ event ]);
			} else {
				this.byParentId.set(parentId, uniqBy([ event, ...this.byParentId.get(parentId) ], e => e.id));
			}
		}

		if (event.status === FpApi.Calendar.Event.EventStatus.RepeatMaster) {
			// create fake repeat events
			if (event.type !== FpApi.Calendar.Event.EventType.Container) {
				// cleanup old events
				const events = Array.from(this.data.values()).filter(e => e.dseid_repeat_parent === event.id);

				// only keep real events
				event.repeat_exceptions = events.filter(e => e.status !== FpApi.Calendar.Event.EventStatus.Repeat).map(e => e.date_start);

				// delete all 'fake' events with this rule, they will be re-created at the bottom
				const oldFakeEvs = events.filter(e => e.status === FpApi.Calendar.Event.EventStatus.Repeat);
				for (const oldFakeEv of oldFakeEvs) {
					this.data.delete(oldFakeEv.id);
				}

				// create new 'fake' repeat events
				const repeats = EventUtil.mapRepeatEvents(DateTime.now().startOf("year"), DateTime.now().endOf("year"), event, this.app.formatter.options.tz);
				const repeatEvents = [ ...repeats, /*...EventUtil.flattenEvents(repeats)*/ ];
				for (const repeat of repeatEvents) {
					if (!isEqual(this.data.get(repeat.id), repeat)) {
						this.data.set(repeat.id, repeat);
						this.emit("put", repeat);
					}
				}
			}

			this._repeatMasters.set(event.id, event);
		} else {
			if (event.is_template) {
				if (!isEqual(this._templates.get(event.id), event)) this._templates.set(event.id, event);
			} else {
				if (!isEqual(this.data.get(event.id), event)) {
					this.data.set(event.id, event);

					// load container if it isnt loaded yet
					if (event.type !== FpApi.Calendar.Event.EventType.Container && this.getParent(this.getParentId(event.id)) == null) {
						await this.get({ ids: [ this.getParentId(event.id) ] });
					}

					this.emit("put", event);
				}
			}
		}

		if (!noRemap) {
			this.byParentId = new Map(this.byParentId);
			this._templates = new Map(this._templates);
			this.data = new Map(this.data);
		}
	}

	@action
	public deleteFakeRepeat(id: string) {
		this.data.delete(id);
		this.data = new Map(this.data);

		this.emit("delete", id);
	}

	private async get(params: FPEventFilter, ignoreStore?: boolean): Promise<FPEvent[]> {
		const request = await fetch("/api/events/get", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ filter: params })
		});
		const requestJson = await request.json();

		// save event to store
		if (!ignoreStore) {
			// rrule events last
			requestJson.rows.sort((a: FPEvent, b) => {
				// containers need to be first, then every other type
				if (a.type === FpApi.Calendar.Event.EventType.Container) return -1;
				if (b.type === FpApi.Calendar.Event.EventType.Container) return 1;

				return a.rrule && !b.rrule ? 1 : -1;
			});

			for (const row of cloneDeep(requestJson.rows)) {
				await this.handleInsertEvent(row, true);
			}
		}

		return requestJson.rows;
	}

	public async putEvent(event: Partial<FPEvent>, ignoreStore?: boolean): Promise<FPEvent> {
		const request = await fetch(event.id ? `/api/events/${event.id}` : "/api/events/", {
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(event),
			method: "PUT"
		});
		const requestJson = await request.json();

		// save event to store
		if (!ignoreStore) {
			// containers need to be inserted first
			const rows = requestJson.rows.sort((a, b) => {
				if (a.type === FpApi.Calendar.Event.EventType.Container) return -1;
				else return 1;
			});
			for (const row of rows) {
				await this.handleInsertEvent(row, true);
			}
		}

		return requestJson.rows.sort((a, b) => a.date_modified - b.date_modified).find(e => ![ "CONTAINER" ].includes(e.type));
	}

	public async putResource(data: { event_id: string, resource: FPEventResource }): Promise<FPEventResource> {
		const request = await fetch("/api/events/resource", {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
			method: "PUT"
		});

		const requestJson = await request.json();
		return requestJson.rows.find(e => e.dsefwpid_from === data.resource.dsefwpid_from &&  e.dsefwpid_to === data.resource.dsefwpid_to && e.link_id === data.resource.link_id && e.link_type === data.resource.link_type && e.dserrid === data.resource.dserrid);
	}

	private async delete(event_id: string): Promise<FPEvent> {
		const request = await fetch(`/api/events/${event_id}`, {
			method: "DELETE"
		});

		const requestJson = await request.json();
		return requestJson.rows[0];
	}

	public getParent(eventId: string): FPEvent | null {
		const pid = this.getParentId(eventId);

		if (this._repeatMasters.has(pid)) return this._repeatMasters.get(pid);
		if (this._templates.has(pid)) return this._templates.get(pid);

		return this.data.get(pid);
	}

	public getParentId(eventId: string): string {
		if (typeof eventId !== "string") return null;

		const [ parentId ] = eventId.split("-");
		return parentId;
	}

	public getChildren(parentId: string): FPEvent[] {
		return cloneDeep(this.byParentId.get(parentId))?.sort((a, b) => {
			// always prioritize order events
			if (a.type === FpApi.Calendar.Event.EventType.Order && b.type !== FpApi.Calendar.Event.EventType.Order) return -1;
			if (a.type !== FpApi.Calendar.Event.EventType.Order && b.type == FpApi.Calendar.Event.EventType.Order) return 1;

			return moment(a.date_start) > moment(b.date_start) ? 1 : -1;
		}) ?? [];
	}

	public async getByLink(link_id: string, link_type: string): Promise<FPEvent[]> {
		const events = await this.get({ link: { link_id, link_type }});
		events.sort((a, b) => new Date(a.date_start) < new Date(b.date_start) ? -1 : 1);
		return events;
	}

	public async getByLinks(links: FPEventFilter["links"]): Promise<FPEvent[]> {
		return await this.get({ links });
	}

	public async loadDashboards(): Promise<void> {
		const request = await fetch("/api/events/dashboard", {
			method: "GET"
		});

		const requestJson = await request.json();
		this._dashboards = new Map(requestJson.rows.map(r => [ r.id, r ]));
	}

	public async getDashboardById(id: number): Promise<EventDashboard> {
		// if there are no dashboards, assume they're not loaded yet
		if (!this._dashboards.has(id)) {
			await this.loadDashboards();
		}

		return this._dashboards.get(id);
	}

	public async upsertDashboard(data: EventDashboard): Promise<EventDashboard> {
		const request = await fetch("/api/events/dashboard", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		});

		const json = await request.json();
		if (request.status === 200) {
			const dashboard = json.rows[0];
			this._dashboards.set(dashboard.id, dashboard);

			return dashboard;
		} else {
			throw json;
		}
	}

	public async deleteDashboard(id: number): Promise<void> {
		const request = await fetch(`/api/events/dashboard/${id}`, {
			method: "DELETE"
		});

		if (request.status === 200) {
			this._dashboards.delete(id);
		} else {
			throw await request.json();
		}
	}

	public isAllowed(action: "edit" | "add", params?: { id?: string; addType?: FpApi.Calendar.Event.EventType }): boolean {
		if (action === "add") {
			const { addType } = params;

			if ([ FpApi.Calendar.Event.EventType.Flight, FpApi.Calendar.Event.EventType.Order ].includes(addType)) return this.app.ctx.hasTypePermission("fpvid.aircraft", FpApi.Security.PermissionAircraftLvl.ScheduleEditor);
		} else if (action === "edit") {
			const { id } = params;

			const ev = this.data.get(id) ?? this._templates.get(id) ?? this._repeatMasters.get(id);
			if (!ev) return false;

			// repeats cannot be edited!
			if (ev.status === FpApi.Calendar.Event.EventStatus.Repeat) return false;

			const ac = ev.resources?.find(r => r.dserrid === FpApi.Calendar.Event.EventResourceRole.Aircraft);
			if (ac) {
				// if user is part of this event, he is allowed to edit
				if (ev.resources.find(e => e.link_type === FpApi.Calendar.Event.ResourceLinkType.dscaid && +e.link_id === this.app.ctx.dscaid)) {
					return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleOwnEditor);
				}

				return this.app.ctx.hasPermission("fpvid.aircraft", ac.link_id, FpApi.Security.PermissionAircraftLvl.ScheduleEditor);
			}
		}

		return this.app.ctx.isSuperUser || this.app.ctx.hasProjectPermission(FpApi.Security.ProjectPermission.GlobalManager);
	}

	public async uploadFiles(eventId: string, files: any[]): Promise<void> {
		let uploadedFiles = 0;
		const eventStreamSubcription = this.app.eventStream.subscribe({
			impact: EventStreamImpact.OneUserActivelyWaiting,
			filter: { a: "media", s: "events_event", t: "upload" },
			callback: async (items) => {
				uploadedFiles += items.length;
				await this.eventStreamMediaCb(items);
				if (uploadedFiles === files.length) {
					this.app.eventStream.unsubscribe(eventStreamSubcription);
				}
			}
		});

		const signaturesResponse = await fetch("/api/events/files/signature", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: eventId,
				files: Array.from(files).map((f: any) => ({
					name: f.name,
					size: f.size,
					type: f.type
				}))
			})
		});

		const signatures = await signaturesResponse.json();

		for (const file of files) {
			const signature = signatures.find(e => e.filename === file.name);
			await fetch(signature.signed.url, { method: "PUT", body: file, headers: signature.signed.headers });
		}
	}

	public async deleteFile(eventId: string, fileKey: string): Promise<void> {
		const eventStreamSubcription = this.app.eventStream.subscribe({
			impact: EventStreamImpact.OneUserActivelyWaiting,
			filter: { a: "media", s: "events_event", t: "delete" },
			callback: async (items) => {
				await this.eventStreamMediaCb(items);
				this.app.eventStream.unsubscribe(eventStreamSubcription);
			}
		});

		const response = await fetch("/api/events/files/delete", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				id: eventId,
				key: fileKey
			})
		});

		await response.json(); // "validate" that we got a json response
	}

	private async eventStreamMediaCb(items) {
		const idList: string[] = [];

		for (const item of items) {
			if ([ "delete", "upload" ].includes(item.t)) {
				idList.push(item.mt);
			} else if (item.t === "upload_error") {
				alert("Upload failed");
			} else {
				console.warn("MQTT EventStore: unknown event ->", item);
			}
		}

		if (idList.length) {
			// refresh event in store
			await this.get({ ids: idList });

			transaction(() => {
				// the get above doesnt trigger re-renders
				this.data = new Map(this.data);
				this._templates = new Map(this._templates);
				this._repeatMasters = new Map(this._repeatMasters);
			});
		}
	}
}
