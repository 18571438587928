import { escape } from "lodash-es";
import React from "react";

export interface AppHeadTitleProps {
	title: string;
	icon?: string;
}

export const AppHeadTitle: React.FC<AppHeadTitleProps> = function AppHeadTitle(props) {
	const ref = React.useRef<HTMLDivElement>();
	React.useEffect(() => {
		const title = escape(props.title).replace(/,/g, "&comma;");
		let optionList = `action:settitle,title:${title}`;
		if (props.icon != null) optionList += `,icon:${props.icon}`;

		// find our view element
		let element: HTMLElement = ref.current;
		while (element.parentElement.id !== "view_view_view_view") {
			element = element.parentElement;
		}

		window.DSXAPPHead?.("none", element.id, optionList);
	}, [ props.title, props.icon ]);

	return <div ref={ref} style={{ display: "none" }}/>;
};
