import { ApiContext, IAircraftBookingApi, IAircraftBooking } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class AircraftBookingApi implements IAircraftBookingApi {
	public async putFlightScheduleStatus(ctx: ApiContext, params: { fpresflightid: number; boardingStatus: number; etd: string; eta: string}): Promise<IAircraftBooking> {
		return ApiUtil.fetchRpc(ctx, "resource.aircraftBooking.putFlightScheduleStatus", params);
	}
	public async get(ctx: ApiContext, params: any): Promise<IAircraftBooking[]> {
		return ApiUtil.fetchRpc(ctx, "resource.aircraftBooking.get", params);
	}

	public async getId(ctx: ApiContext, params: any): Promise<IAircraftBooking> {
		return ApiUtil.fetchRpc(ctx, "resource.aircraftBooking.getId", params);
	}
}
