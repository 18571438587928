import { DirectoryNode, FpApi, FpDirNodeKind, FpId } from "@tcs-rliess/fp-core";
import Aigle from "aigle";
import { flatten, isMatch, mapKeys, matches, omitBy, uniqBy } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";
import { Resolver } from "../../Resolver";
import { CalendarConfigurationItem } from "../CalendarConfiguration";
import { FpResourceModel } from "../models";
import { SCHEDULER_RESOLVER_CONFIG } from "../SCHEDULER_RESOLVER_CONFIG";
import { SchedulerStore } from "../SchedulerStore";

import { RangeBuilderState } from "./BuilderState";

const DIR_KIND_ID: Record<FpDirNodeKind, string> = {
	[FpDirNodeKind.Location]: "fpdirloc",
	[FpDirNodeKind.Group]: "fpdirgrp",
	[FpDirNodeKind.Position]: "fpdirpos",
	[FpDirNodeKind.Security]: "fpdirsec",
};

const DIR_ID_PROP = {
	"fpdirloc": "loc",
	"fpdirgrp": "grp",
	"fpdirpos": "pos",
	"fpdirsec": "sec",
} as const;

interface SimpleResource {
	linkType: string;
	linkId: string;
}

interface DirNodes {
	fpdirloc?: DirectoryNode;
	fpdirgrp?: DirectoryNode;
	fpdirpos?: DirectoryNode;
	fpdirsec?: DirectoryNode;
}

export class ResourceBuilder {
	// private log = fpLog.child("ResourceBuilder");
	private app: FleetplanApp;
	private store: SchedulerStore;

	private uid = 0;

	/**
	 * map of "fleetplan id" `linkType:linkId` to calendar resources
	 */
	public resourceScheduler: Map<string, Set<FpResourceModel>>;

	constructor(store: SchedulerStore, app: FleetplanApp) {
		this.app = app;
		this.store = store;
	}

	public async buildRange(from: DateTime, to: DateTime): Promise<FpResourceModel[]> {
		const state = new RangeBuilderState({
			resourceScheduler: this.resourceScheduler,
			from: from,
			to: to,
		});

		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		// prepare
		// -----------------------------------------------------------------------------------------------------------------------------------------------------

		// note: the prepared list is simplified and may include archived contacts etc. that we won't actually display
		console.time("prepareResources");
		const prepared = await this.prepareResources(state, this.store.configuration.resources);
		console.timeEnd("prepareResources");
		console.log("prepared", prepared);

		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		// intermediary steps
		// -----------------------------------------------------------------------------------------------------------------------------------------------------

		// prepare setup util with dscaid list from the prepare run
		const dscaidSet = new Set(
			prepared
				.filter(r => r.linkType === "dscaid")
				.map(r => parseInt(r.linkId))
		);
		console.log("dscaidSet", dscaidSet);
		await this.app.store.resource.locationSetupUtil.prepareDscaids(Array.from(dscaidSet));
		// await this.app.store.resource.locationSetupUtil.prepareUtil();

		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		// build resource tree
		// -----------------------------------------------------------------------------------------------------------------------------------------------------

		const resources = await this.mapResources(state, this.store.configuration.resources);

		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		// resolver
		// -----------------------------------------------------------------------------------------------------------------------------------------------------

		// collect all and run resolver
		const allResources = [
			...resources,
			...resources.flatMap(r => r.allChildren as FpResourceModel[]),
		];
		await Resolver.resolve({
			app: this.app,
			config: SCHEDULER_RESOLVER_CONFIG,
			items: allResources,
		});

		// -----------------------------------------------------------------------------------------------------------------------------------------------------
		// diff with existing tree etc.
		// -----------------------------------------------------------------------------------------------------------------------------------------------------

		if (this.store.projectModel.resourceStore.allRecords.length === 0) {
			// first time
			// project model is empty, just set data
			(this.store.projectModel.eventStore as any).reapplyFilterOnAdd = false;
			(this.store.projectModel.eventStore as any).reapplyFilterOnUpdate = false;
			this.store.projectModel.beginBatch();
			this.store.projectModel.resourceStore.add(resources);

			this.resourceScheduler = new Map();
			for (const resource of allResources) {
				const key = `${resource.fpLinkType}:${resource.fpLinkId}`;

				if (this.resourceScheduler.has(key)) this.resourceScheduler.get(key).add(resource);
				else this.resourceScheduler.set(key, new Set([ resource ]));
			}

			this.store.projectModel.endBatch();
			(this.store.projectModel.eventStore as any).reapplyFilterOnAdd = true;
			(this.store.projectModel.eventStore as any).reapplyFilterOnUpdate = true;

			return [];
		} else {
			// already data in project model
			// compare tree and update

			// track which resources got updated, we need 
			const added: FpResourceModel[] = [];

			const traverse = (parent: FpResourceModel, currentArray: FpResourceModel[], newArray: FpResourceModel[]): void => {
				if (currentArray.length === 0 && newArray.length === 0) {
					return;
				}

				const currentMap = new Map(currentArray.map(i => [ `${i.fpLinkType}:${i.fpLinkId}:${i.fpLinkIdExtra ?? ""}`, i ]));
				const newMap = new Map(newArray.map(i => [ `${i.fpLinkType}:${i.fpLinkId}:${i.fpLinkIdExtra ?? ""}`, i ]));

				// traverse existing nodes and remove if required
				for (const currentNode of currentArray) {
					const newNode = newMap.get(`${currentNode.fpLinkType}:${currentNode.fpLinkId}:${currentNode.fpLinkIdExtra ?? ""}`);

					if (newNode == null) {
						// node is missing in new tree -> remove
						// note: this will also remove events etc. linked to this resource
						this.store.projectModel.resourceStore.remove(currentNode, true);
						continue;
					}

					traverse(
						currentNode,
						// "unfilteredChildren" isn't documented, but it seems there is no other way to access them
						((currentNode as any).unfilteredChildren ?? currentNode.children) as FpResourceModel[] ?? [],
						newNode.children as FpResourceModel[] ?? [],
					);
				}

				// find new missing nodes, and add them
				for (const newNode of newArray) {
					const currentNode = currentMap.get(`${newNode.fpLinkType}:${newNode.fpLinkId}:${newNode.fpLinkIdExtra ?? ""}`);

					if (currentNode == null) {
						const key = `${newNode.fpLinkType}:${newNode.fpLinkId}`;
						if (this.resourceScheduler.has(key)) this.resourceScheduler.get(key).add(newNode);
						else this.resourceScheduler.set(key, new Set([ newNode ]));

						added.push(newNode, ...newNode.allChildren as FpResourceModel[]);
						parent.appendChild(newNode, true);
					}
				}
			};

			(this.store.projectModel.resourceStore as any).reapplyFilterOnAdd = false;
			(this.store.projectModel.resourceStore as any).reapplyFilterOnUpdate = false;

			// run tree compare
			const rootNode = this.store.projectModel.resourceStore.rootNode as any;
			traverse(
				rootNode as FpResourceModel,
				// "unfilteredChildren" isn't documented, but it seems there is no other way to access them
				(rootNode.unfilteredChildren ?? rootNode.children) as FpResourceModel[],
				resources,
			);

			(this.store.projectModel.resourceStore as any).reapplyFilterOnAdd = true;
			(this.store.projectModel.resourceStore as any).reapplyFilterOnUpdate = true;

			await (this.store.projectModel.resourceStore as any).sort();
			await (this.store.projectModel.resourceStore as any).filter();

			return added;
		}
	}

	private async prepareResources(state: RangeBuilderState, items: CalendarConfigurationItem[]): Promise<SimpleResource[]> {
		if (items == null) return undefined;

		return flatten(await Aigle.mapSeries(items, item => {
			return this.prepareResource(state, item);
		}));
	}

	private async prepareResource(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<SimpleResource[]> {
		const resources: SimpleResource[] = [];

		const push = (...resource: SimpleResource[]): void => {
			resources.push(...resource.filter(r => r != null));
		};

		switch (item.kind) {
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "FOLDER": push({ linkType: "FOLDER", linkId: item.folder.id }); break;
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "RESOURCE": {
				switch (item.resource.linkType) {
					case "fpvid":
					case "dschsid":
					case "dsrlsid":
					case "fpdirid":
					case "dscalid":
					case "dscaid": push({ linkType: item.resource.linkType, linkId: item.resource.linkId }); break;
					default: throw new Error("unknown resource kind");
				}
				break;
			}
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "SMART": {
				switch (item.smart.linkType) {
					case "fpdirid": push(...await this.prepareSmartDirectory(state, item)); break;
					case "fpvid": push(...this.prepareSmartAircraft(state, item)); break;
					default: throw new Error("unknown resource kind");
				}
				break;
			}
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			default: throw new Error("unknown item kind");
		}

		await this.mapResources(state, item.children);

		return resources;
	}

	private async prepareSmartDirectory(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<SimpleResource[]> {
		const resources: SimpleResource[] = [];

		const directoryNodes = this.app.store.fpDir.directory.getTree();
		const directoryMembersAll = this.app.store.fpDir.directory
			.getMembers()
			// filter for members matching the displayed range
			.filter(member => {
				const from = state.from.toMillis();
				const to = state.to.toMillis();

				if (member.startdate) {
					const start = +new Date(member.startdate);
					// start after our period
					if (start > to) return false;
				}

				if (member.enddate) {
					const end = +new Date(member.enddate);
					// end before out period
					if (end < from) return false;
				}

				// keep
				return true;
			});

		const filter: {
			fpdirloc?: number;
			fpdirgrp?: number;
			fpdirpos?: number;
			fpdirsec?: number;
		} = omitBy(item.smart.fpdirid.filter, id => id == null);
		const dirNode: DirNodes = {};
		for (const key in filter) {
			dirNode[key] = directoryNodes.findKey(filter[key]);
		}

		const memberFilter = mapKeys(filter, (v, k) => DIR_ID_PROP[k]);
		const directoryMembers = directoryMembersAll.filter(matches(memberFilter));

		// sub nodes
		if (item.smart.fpdirid.recursive) {
			let dirChildren: DirectoryNode[] = [];
			if (item.smart.fpdirid.filter.kind != null) {
				dirChildren = directoryNodes.tree.filter(n => n.kind === item.smart.fpdirid.filter.kind);
			} else {
				const keys = Object.keys(filter);
				if (keys.length === 1) {
					dirChildren = dirNode[keys[0]].children;
				}
			}

			for (const childNode of dirChildren) {
				resources.push(
					...await this.prepareResource(state, {
						id: FpId.new(),
						kind: "RESOURCE",
						resource: {
							linkType: "fpdirid",
							linkId: childNode.id.toString(),
						},
						children: [{
							id: FpId.new(),
							kind: "SMART",
							smart: {
								linkType: "fpdirid",
								fpdirid: {
									...item.smart.fpdirid,
									filter: {
										...item.smart.fpdirid.filter,
										kind: undefined,
										[DIR_KIND_ID[childNode.kind]]: childNode.id,
									},
								},
							},
						}],
					})
				);
			}
		}

		if (dirNode.fpdirloc != null) {
			// Location
			// --------

			// setup
			if (item.smart.fpdirid.resourceSetup) {
				const setups = await this.getSetupsForLocation(state, dirNode);

				for (const setup of setups) {
					resources.push({ linkType: "dsrlsid", linkId: setup.id.toString() });
				}
			}

			// aircraft
			if (item.smart.fpdirid.aircraft) {
				const duties = await this.app.store.resource.schedule.getRange(state.from, state.to);
				const aircrafts = this.app.store.resource.aircraft.getAll();

				const fpvidList = Array.from(new Set([
					// assigned via duty
					...duties
						// .filter(duty => duty.linkIdType === "fpvid" && duty.fpdirloc === dirNode.fpdirloc.id)
						.filter(e => e.linkType === "fpvid" && e.dscid > 0 && e.isCurrent && e.data.fpdirloc === dirNode.fpdirloc.id)
						.map(duty => parseInt(duty.linkId)),
					// aircraft assigned to location
					...aircrafts
						.filter(a => a.fpdirloc === dirNode.fpdirloc.id)
						.map(a => a.id),
				]));

				for (const fpvid of fpvidList) {
					resources.push({ linkType: "fpvid", linkId: fpvid.toString() });
				}
			}
		}

		// contacts
		if (item.smart.fpdirid.contact?.enabled) {
			// contacts assigned to location
			directoryMembers
				.filter(m => m.linktype === "dscaid")
				.forEach(member => {
					resources.push({ linkType: member.linktype, linkId: member.linkid });
				});

			if (item.smart.fpdirid.contact.dutyContacts) {
				if (this.app.flags.dutySchedule) {
					const duties = await this.app.store.resource.schedule.getRange(state.from, state.to);

					// assigned via duty
					duties
						.filter(duty => {
							return duty.dscid > 0
								&& duty.isCurrent
								&& duty.linkType === "dscaid"
								&& isMatch(duty.data, filter);
						})
						.forEach(duty => {
							resources.push({ linkType: duty.linkType, linkId: duty.linkId });
						});
				} else {
					const duties = await this.app.store.resource.duty.getRange(state.from, state.to);

					// assigned via duty
					duties
						.filter(duty => duty.dateDeleted == null && duty.linkIdType === "dscaid" && isMatch(duty, filter))
						.forEach(duty => {
							resources.push({ linkType: duty.linkIdType, linkId: duty.linkId });
						});
				}
			}
		}

		return resources;
	}

	private prepareSmartAircraft(state: RangeBuilderState, item: CalendarConfigurationItem): SimpleResource[] {
		const resources: SimpleResource[] = [];

		const aircrafts = this.app.store.resource.aircraft.getAll();
		for (const aircraft of aircrafts) {
			resources.push({
				linkType: "fpvid",
				linkId: aircraft.id.toString(),
			});
		}

		return resources;
	}

	private async mapResources(state: RangeBuilderState, items: CalendarConfigurationItem[], parent?: FpResourceModel): Promise<FpResourceModel[]> {
		if (items == null) return undefined;

		let linkExtra: any = {};

		if (parent) {
			linkExtra = {
				...(parent.fpLinkExtra ?? {}),
				[parent.fpLinkType]: parent.fpLinkId,
			};
		}

		return flatten(await Aigle.mapSeries(items, item => {
			if (item.kind === "SMART") {
				item.smart.linkExtra = {
					...(linkExtra ?? {}),
					...(item.smart.linkExtra ?? {}),
				};
			} else if (item.kind === "RESOURCE") {
				item.resource.linkExtra = {
					...(linkExtra ?? {}),
					...(item.resource.linkExtra ?? {}),
				};
			}

			return this.mapResource(state, item);
		}));
	}

	private async mapResource(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel[]> {
		const resources: FpResourceModel[] = [];

		const push = (...resource: FpResourceModel[]): void => {
			resources.push(...resource.filter(r => r != null));
		};

		switch (item.kind) {
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "FOLDER": push(await this.mapResourceFolder(state, item)); break;
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "RESOURCE": {
				switch (item.resource.linkType) {
					case "dschsid": push(await this.mapResourceHolidaySet(state, item)); break;
					case "dscaid": push(await this.mapResourceContact(state, item)); break;
					case "fpvid": push(await this.mapResourceAircraft(state, item)); break;
					case "dscalid": push(await this.mapResourceCalendar(state, item)); break;
					case "dsrlsid": push(await this.mapResourceSetup(state, item)); break;
					case "fpdirid": push(await this.mapResourceDirectory(state, item)); break;
					default: throw new Error("unknown resource kind");
				}
				break;
			}
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			case "SMART": {
				switch (item.smart.linkType) {
					case "fpdirid": push(...(await this.mapSmartDirectory(state, item))); break;
					case "fpvid": push(...(await this.mapSmartAircraft(state, item))); break;
					default: throw new Error("unknown resource kind");
				}
				break;
			}
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			default: throw new Error("unknown item kind");
		}

		return resources;
	}

	private async mapResourceFolder(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const resource = new FpResourceModel({
			id: this.uid++,
			name: item.folder.name,
			expanded: true,

			fpLinkType: "FOLDER",
			fpLinkId: item.folder.id,
			fpLinkExtra: item.folder.linkExtra,
			fpData: item,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));

		return resource;
	}

	private async mapResourceDirectory(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const directoryNodes = this.app.store.fpDir.directory.getTree();

		const dirNode = directoryNodes.findKey(parseInt(item.resource.linkId));
		if (dirNode == null) return;

		const nameSuffix = item.resource.linkExtra.nameSuffix as string ?? "";
		const resource = new FpResourceModel({
			id: this.uid++,
			name: dirNode.name + nameSuffix,
			expanded: true,

			fpLinkType: DIR_KIND_ID[dirNode.kind],
			fpLinkId: dirNode.id,
			fpLinkIdExtra: item.resource.linkIdExtra,
			fpLinkExtra: item.resource.linkExtra,
			fpData: dirNode,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));
		return resource;
	}

	private async mapResourceHolidaySet(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const dschsid = parseInt(item.resource.linkId);
		const holidaySets = await this.app.store.holidaySet.getAll();
		const holidaySet = holidaySets.find(i => i.id === dschsid);

		if (holidaySet == null) return;

		const resource = new FpResourceModel({
			id: this.uid++,
			name: holidaySet.name,
			expanded: true,

			fpLinkType: "dschsid",
			fpLinkId: item.resource.linkId,
			fpLinkExtra: item.resource.linkExtra,
			fpLinkIdExtra: item.resource.linkIdExtra,
			fpData: holidaySet,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));
		return resource;
	}

	private async mapResourceCalendar(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const dschsid = parseInt(item.resource.linkId);
		const calendars = await this.app.store.calendar.getAll();
		const calendar = calendars.find(i => i.id === dschsid);

		if (calendar == null) return;

		const resource = new FpResourceModel({
			id: this.uid++,
			name: calendar.name,
			expanded: true,

			fpLinkType: `dscalid.${calendar.groupType}`,
			fpLinkId: item.resource.linkId,
			fpLinkIdExtra: item.resource.linkIdExtra,
			fpLinkExtra: item.resource.linkExtra,
			fpData: calendar,
			rowHeight: calendar.groupType === "aircraft" ? 100 : undefined,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));
		return resource;
	}

	private async mapResourceContact(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const dscaid = parseInt(item.resource.linkId);
		const contact = this.app.store.contact.getId(dscaid);

		if (contact == null) return;
		if (contact.isActive === false) return;

		const resource = new FpResourceModel({
			id: this.uid++,
			name: `${contact.$person.givenName} ${contact.$person.lastName}`,
			expanded: true,

			fpLinkType: "dscaid",
			fpLinkId: item.resource.linkId,
			fpLinkIdExtra: item.resource.linkIdExtra,
			fpLinkExtra: item.resource.linkExtra,
			fpData: contact,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));
		return resource;
	}

	private async mapResourceAircraft(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const fpvid = parseInt(item.resource.linkId);
		const aircraft = this.app.store.resource.aircraft.getId(fpvid);
		const acState = this.app.store.resource.aircraftState.getId(fpvid);

		if (aircraft == null) return;

		const resource = new FpResourceModel({
			id: this.uid++,
			name: aircraft.registrationCode,
			expanded: true,
			imageUrl: aircraft.avatarTn,

			eventColor: aircraft.color,
			fpLinkType: "fpvid",
			fpLinkId: item.resource.linkId,
			fpLinkIdExtra: item.resource.linkIdExtra,
			fpLinkExtra: {
				...item.resource.linkExtra,
				state: acState,
			},
			fpData: aircraft,
			rowHeight: 130,
		});

		resource.replaceChildren(await this.mapResources(state, item.children, resource));
		return resource;
	}

	private async mapResourceSetup(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel> {
		const linkExtra: {
			dsrlsid?: number;
			setup?: FpApi.Resource.Duty.LocationSetup;
		} = item.resource.linkExtra;

		const setup = await this.app.store.resource.locationSetup.getId(linkExtra.dsrlsid);
		const shifts = this.app.store.resource.shift.getAll();

		if (setup == null) return;
		linkExtra["setup"] = setup;

		const positions: FpResourceModel[] = [];

		for (const resource of setup.data.resources) {
			positions.push(
				new FpResourceModel({
					id: this.uid++,
					name: `${resource.name}`,

					fpLinkType: "dsrdsidRes",
					fpLinkId: `${setup.id}:resource:${resource.id}`,
					fpLinkIdExtra: item.resource.linkIdExtra,
					fpLinkExtra: {
						...linkExtra,
						setup,
						dsrdsidRes: resource.id,
						fpdbvmid: resource.data.fpvid.fpdbvmid,
					},
					fpData: resource,
				})
			);
		}

		for (const position of setup.data.positions) {
			positions.push(
				new FpResourceModel({
					id: this.uid++,
					name: position.name,
					expanded: true,

					children: position.shifts.map(shiftId => {
						const shift = shifts.find(s => s.id === shiftId);

						return new FpResourceModel({
							id: this.uid++,
							name: shift?.name ?? "Missing Shift",
							expanded: true,
							cls: "fp-resource-shift",

							fpLinkType: "dsrsid",
							fpLinkId: `${setup.id}:${position.id}:${shiftId}`,
							fpLinkIdExtra: item.resource.linkIdExtra,
							fpLinkExtra: {
								...linkExtra,
								dsrdsidPos: position.id,
								role: position.role,
								dsrsid: shift,
								setup,
							},
							fpData: shift,
						});
					}),

					fpLinkType: "dsrdsidPos",
					fpLinkId: `${setup.id}:${position.id}`,
					fpLinkExtra: {
						...linkExtra,
						dsrdsidPos: position.id,
						role: position.role,
						setup,
					},
					fpData: position,
				})
			);
		}

		const resource = new FpResourceModel({
			id: this.uid++,
			name: setup.name,
			expanded: setup.data.defaultExpanded ?? true,
			children: positions,

			fpLinkType: "dsrlsid",
			fpLinkId: setup.id.toString(),
			fpLinkExtra: linkExtra,
			fpData: setup,
		});

		return resource;
	}

	private async mapSmartDirectory(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel[]> {
		const resources: FpResourceModel[] = [];

		const directoryNodes = this.app.store.fpDir.directory.getTree();
		const directoryMembersAll = this.app.store.fpDir.directory
			.getMembers()
			// filter for members matching the displayed range
			.filter(member => {
				const from = state.from.toMillis();
				const to = state.to.toMillis();

				if (member.startdate) {
					const start = +new Date(member.startdate);
					// start after our period
					if (start > to) return false;
				}

				if (member.enddate) {
					const end = +new Date(member.enddate);
					// end before out period
					if (end < from) return false;
				}

				// keep
				return true;
			});

		const filter: {
			fpdirloc?: number;
			fpdirgrp?: number;
			fpdirpos?: number;
			fpdirsec?: number;
		} = omitBy(item.smart.fpdirid.filter, id => id == null);
		const dirNode: {
			fpdirloc?: DirectoryNode;
			fpdirgrp?: DirectoryNode;
			fpdirpos?: DirectoryNode;
			fpdirsec?: DirectoryNode;
		} = {};
		for (const key in filter) {
			dirNode[key] = directoryNodes.findKey(filter[key]);
		}

		const memberFilter = mapKeys(filter, (v, k) => DIR_ID_PROP[k]);
		const directoryMembers = directoryMembersAll.filter(matches(memberFilter));

		const linkExtra = {
			...item.smart.linkExtra,
			...item.smart.fpdirid.filter,
		};

		// sub nodes
		if (item.smart.fpdirid.recursive) {
			let dirChildren: DirectoryNode[] = [];
			if (item.smart.fpdirid.filter.kind != null) {
				dirChildren = directoryNodes.tree.filter(n => n.kind === item.smart.fpdirid.filter.kind);
			} else {
				const keys = Object.keys(filter);
				if (keys.length === 1) {
					dirChildren = dirNode[keys[0]].children;
				}
			}

			for (const childNode of dirChildren) {
				resources.push(
					...await this.mapResource(state, {
						id: FpId.new(),
						kind: "RESOURCE",
						resource: {
							linkType: "fpdirid",
							linkId: childNode.id.toString(),
							linkExtra: linkExtra,
						},
						children: [{
							id: FpId.new(),
							kind: "SMART",
							smart: {
								linkType: "fpdirid",
								linkExtra: linkExtra,
								fpdirid: {
									...item.smart.fpdirid,
									filter: {
										...item.smart.fpdirid.filter,
										kind: undefined,
										[DIR_KIND_ID[childNode.kind]]: childNode.id,
									},
								},
							},
						}],
					})
				);
			}
		}

		const setupList = new Set<FpApi.Resource.Duty.LocationSetup>();

		if (dirNode.fpdirloc != null) {
			// Location
			// --------

			// setup
			if (item.smart.fpdirid.resourceSetup) {
				const setups = await this.getSetupsForLocation(state, dirNode);

				for (const setup of setups) {
					setupList.add(setup);
					resources.push(
						...await this.mapResource(state, {
							id: FpId.new(),
							kind: "RESOURCE",
							resource: {
								linkType: "dsrlsid",
								linkId: setup.id.toString(),
								linkExtra: {
									...linkExtra,
									fpdirloc: dirNode.fpdirloc.id,
									dsrlsid: setup.id,
								},
							},
						}),
					);
				}
			}

			// aircraft
			if (item.smart.fpdirid.aircraft) {
				const duties = await this.app.store.resource.schedule.getRange(state.from, state.to);
				const aircrafts = this.app.store.resource.aircraft.getAll();
				const events = await this.app.store.event.getRange(state.from, state.to);

				const fpvidList = Array.from(new Set([
					// assigned via duty
					...duties
						// .filter(duty => duty.linkIdType === "fpvid" && duty.fpdirloc === dirNode.fpdirloc.id)
						.filter(e => e.linkType === "fpvid" && e.dscid > 0 && e.isCurrent && e.data.fpdirloc === dirNode.fpdirloc.id)
						.map(duty => parseInt(duty.linkId)),
					// aircraft assigned to location
					...aircrafts
						.filter(a => a.fpdirloc === dirNode.fpdirloc.id)
						.map(a => a.id),
					...events
						.filter(e => e.fpdirloc === dirNode.fpdirloc.id && e.type === FpApi.Calendar.Event.EventType.Order)
						.map(e => e.resources.find(e => e.link_type === "fpvid")?.link_id)
						.filter(Boolean)
						.map(e => +e),
				]));

				await Aigle.eachSeries(fpvidList, async fpvid => {
					resources.push(
						...await this.mapResource(state, {
							id: FpId.new(),
							kind: "RESOURCE",
							resource: {
								linkType: "fpvid",
								linkId: fpvid.toString(),
								linkExtra: linkExtra,
							},
						})
					);
				});
			}
		}

		// contacts
		if (item.smart.fpdirid.contact?.enabled) {
			const memberList: Array<{
				linkType: string;
				linkId: string;
				loc: number;
				grp: number;
				pos: number;
				sec: number;
				src: "contacts_hr" | "azure_sync" | "duty";
			}> = [];

			// contacts assigned to location
			directoryMembers
				.filter(m => m.linktype === "dscaid")
				.map(member => {
					memberList.push({
						linkType: member.linktype,
						linkId: member.linkid,
						loc: member.loc,
						grp: member.grp,
						pos: member.pos,
						sec: member.sec,
						src: member.src,
					});
				});

			if (item.smart.fpdirid.contact.dutyContacts) {
				if (this.app.flags.dutySchedule) {
					const duties = await this.app.store.resource.schedule.getRange(state.from, state.to);

					// assigned via duty
					duties
						.filter(duty => {
							return duty.dscid > 0
								&& duty.isCurrent
								&& duty.linkType === "dscaid"
								&& isMatch(duty.data, filter);
						})
						.map(duty => {
							return {
								linkType: duty.linkType,
								linkId: duty.linkId,
								loc: duty.data.fpdirloc ?? 0,
								grp: duty.data.fpdirgrp ?? 0,
								pos: duty.data.fpdirpos ?? 0,
								sec: 0,
								src: "duty" as const,
							};
						})
						.forEach(item => {
							memberList.push(item);
						});
				} else {
					const duties = await this.app.store.resource.duty.getRange(state.from, state.to);

					// assigned via duty
					duties
						.filter(duty => duty.dateDeleted == null && duty.linkIdType === "dscaid" && isMatch(duty, filter))
						.map(duty => {
							return {
								linkType: duty.linkIdType,
								linkId: duty.linkId,
								loc: duty.fpdirloc ?? 0,
								grp: duty.fpdirgrp ?? 0,
								pos: duty.fpdirpos ?? 0,
								sec: 0,
								src: "duty" as const,
							};
						})
						.forEach(item => {
							memberList.push(item);
						});
				}
			}

			if (item.smart.fpdirid.contact.groupBy === "SETUP_ROLE") {
				const dscaidSet = new Set(memberList.map(m => parseInt(m.linkId)));
				const roleMapping = this.app.store.resource.locationSetupUtil.groupContactsByValidRoles(Array.from(dscaidSet), Array.from(setupList));

				// we will remove any ID we find the object from the setup util
				const missing = new Set(dscaidSet);

				for (const [ name, dscaidList ] of roleMapping.entries()) {
					resources.push(
						...await this.mapResource(state, {
							id: FpId.new(),
							kind: "FOLDER",
							folder: {
								id: name,
								name: name,
								expanded: true,
								linkExtra: {
									...linkExtra,
									role: name,
								},
							},
							children: dscaidList.map<CalendarConfigurationItem>(dscaid => {
								missing.delete(dscaid);

								const isExternal = directoryMembersAll
									.filter(m => {
										return m.src !== "duty"
											&& m.linktype === "dscaid"
											&& m.linkid === dscaid.toString();
									})
									.every(m => {
										return m.loc !== filter.fpdirloc
											&& m.grp !== filter.fpdirgrp
											&& m.pos !== filter.fpdirpos
											&& m.sec !== filter.fpdirsec;
									});

								return {
									id: FpId.new(),
									kind: "RESOURCE",
									resource: {
										linkType: "dscaid",
										linkId: dscaid.toString(),
										linkExtra: {
											dscaidIsExternal: isExternal,
											...linkExtra,
										},
									},
								};
							}),
						})
					);
				}

				// add missing folder if needed
				if (missing.size) {
					resources.push(
						...await this.mapResource(state, {
							id: FpId.new(),
							kind: "FOLDER",
							folder: {
								id: "OTHER",
								name: "Other",
								linkExtra: linkExtra,
							},
							children: Array.from(missing).map<CalendarConfigurationItem>(dscaid => {
								const isExternal = directoryMembersAll
									.filter(m => {
										return m.src !== "duty"
											&& m.linktype === "dscaid"
											&& m.linkid === dscaid.toString();
									})
									.every(m => {
										return m.loc !== filter.fpdirloc
											&& m.grp !== filter.fpdirgrp
											&& m.pos !== filter.fpdirpos
											&& m.sec !== filter.fpdirsec;
									});

								return {
									id: FpId.new(),
									kind: "RESOURCE",
									resource: {
										linkType: "dscaid",
										linkId: dscaid.toString(),
										linkExtra: {
											dscaidIsExternal: isExternal,
											...linkExtra,
										},
									},
								};
							}),
						})
					);
				}
			} else if (item.smart.fpdirid.contact.groupBy) {
				let nodeList: Set<number>;
				switch (item.smart.fpdirid.contact.groupBy) {
					case FpDirNodeKind.Location: nodeList = new Set(memberList.map(m => m.loc)); break;
					case FpDirNodeKind.Group: nodeList = new Set(memberList.map(m => m.grp)); break;
					case FpDirNodeKind.Position: nodeList = new Set(memberList.map(m => m.pos)); break;
					case FpDirNodeKind.Security: nodeList = new Set(memberList.map(m => m.sec)); break;
				}

				for (const nodeId of nodeList) {
					let members = memberList.filter(m => {
						switch (item.smart.fpdirid.contact.groupBy) {
							case FpDirNodeKind.Location: return m.loc === nodeId;
							case FpDirNodeKind.Group: return m.grp === nodeId;
							case FpDirNodeKind.Position: return m.pos === nodeId;
							case FpDirNodeKind.Security: return m.sec === nodeId;
						}
					});
					members = uniqBy(members, m => `${m.linkType}.${m.linkId}`);

					// eslint-disable-next-line no-inner-declarations
					async function folder(mode: "internal" | "external", members: any[]): Promise<void> {
						// map into resource items
						const children = members.map<CalendarConfigurationItem>(member => {
							const isExternal = directoryMembersAll
								.filter(m => {
									return m.src !== "duty"
										&& m.linktype === member.linkType
										&& m.linkid === member.linkId;
								})
								.every(m => {
									return m.loc !== filter.fpdirloc
										&& m.grp !== filter.fpdirgrp
										&& m.pos !== filter.fpdirpos
										&& m.sec !== filter.fpdirsec;
								});

							return {
								id: FpId.new(),
								kind: "RESOURCE",
								resource: {
									linkType: member.linkType,
									linkId: member.linkId,
									linkExtra: {
										dscaidIsExternal: isExternal,
										...linkExtra,
									},
								},
							};
						});

						if (children.length === 0) {
							// noop
						} else if (nodeId === 0) {
							resources.push(
								...await this.mapResource(state, {
									id: FpId.new(),
									kind: "FOLDER",
									folder: {
										id: `ungrouped_${mode}`,
										name: "Ungrouped" + (mode === "external" ? " (External)" : ""),
										linkExtra: linkExtra,
									},
									children: children,
								})
							);
						} else {
							resources.push(
								...await this.mapResource(state, {
									id: FpId.new(),
									kind: "RESOURCE",
									resource: {
										linkType: "fpdirid",
										linkId: nodeId.toString(),
										// we have the same linkType/linkId twice (internal/external), so we need something extra to keep them unique
										linkIdExtra: mode,
										linkExtra: {
											nameSuffix: mode === "external" ? " (External)" : "",
											linkExtra,
										},
									},
									children: children,
								})
							);
						}
					}

					const membersInternal = members.filter(m => m.src === "contacts_hr");
					const membersExternal = members.filter(m => m.src !== "contacts_hr");

					await folder.bind(this)("internal", membersInternal);
					await folder.bind(this)("external", membersExternal);
				}
			} else {
				await Aigle.eachSeries(memberList, async member => {
					resources.push(
						...await this.mapResource(state, {
							id: FpId.new(),
							kind: "RESOURCE",
							resource: {
								linkType: member.linkType,
								linkId: member.linkId,
								linkExtra: linkExtra,
							},
						})
					);
				});
			}
		}

		return resources;
	}

	private async mapSmartAircraft(state: RangeBuilderState, item: CalendarConfigurationItem): Promise<FpResourceModel[]> {
		const resources: FpResourceModel[] = [];

		const aircrafts = this.app.store.resource.aircraft.getAll();
		for (const aircraft of aircrafts) {
			resources.push(
				...await this.mapResource(state, {
					id: FpId.new(),
					kind: "RESOURCE",
					resource: {
						linkType: "fpvid",
						linkId: aircraft.id.toString(),
						linkExtra: item.smart.linkExtra,
					}
				})
			);
		}

		return resources;
	}

	private async getSetupsForLocation(state: RangeBuilderState, dirNode: DirNodes): Promise<FpApi.Resource.Duty.LocationSetup[]> {
		const directoryNodes = this.app.store.fpDir.directory.getTree();
		const allSetups = await this.app.store.resource.locationSetup.getRange(state.from, state.to);

		// inherited setups
		const fpdirList = [];
		const inherit = dirNode.fpdirloc.data.location.setupInherit ?? true;
		if (inherit) {
			const dirParents = directoryNodes.findParents(dirNode.fpdirloc.id);
			for (const parent of dirParents.reverse()) {
				fpdirList.push(parent.id);

				// if inherit is off, stop here
				const inherit = parent.data.location?.setupInherit ?? true;
				if (inherit === false) break;
			}
		}

		const setups = allSetups.filter(setup => {
			return setup.validFrom != null
				&& (
					// direct
					(setup.fpdirloc === dirNode.fpdirloc.id)
					// inherit setups
					|| (setup.data.inheritance && fpdirList.includes(setup.fpdirloc))
				);
		});

		return setups;
	}
}
