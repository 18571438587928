import { FpApi } from "@tcs-rliess/fp-core";
import { Label, LabelProps } from "@tcs-rliess/fp-web-ui";
import { omit } from "lodash-es";
import React from "react";

import { useApp } from "../../../FleetplanApp";

export interface SystemCategoryLabelProps extends LabelProps {
	dscatid: number | FpApi.System.SystemCategory;
	hideText?: boolean;
	iconPosition?: "left" | "right";
	categoryDefaults?: Partial<FpApi.System.SystemCategory>;
	useAbbr?: boolean
	text?: React.ReactText;
	isText?: boolean;
}

export const SystemCategoryLabel: React.FC<SystemCategoryLabelProps > = function SystemCategoryLabel(props) {
	const app = useApp();
	const [ category, setCategory ] = React.useState<FpApi.System.SystemCategory>(typeof props.dscatid === "number" ? null : { ...props.dscatid, ...props.categoryDefaults });

	React.useEffect(() => {
		// setCategory(null);

		if (props.dscatid && typeof props.dscatid === "number") {
			const category = app.store.systemCategory.getId(props.dscatid);
			setCategory({ ...category, ...props.categoryDefaults });
		}
	}, [ props.dscatid ]);


	if (category == null) return null;

	const hasColor = category.color?.length > 0;
	const hasClassname = !!category.classNames;

	// const bgColor = Color(category.color || "#ffffff");
	// const color = bgColor.isLight() ? "#000000" : "#ffffff";
	if(!props.dscatid) return null;
	if(props.isText) return <div>
		{(category.icon && props.iconPosition === "left") && <span><i className={`${category.icon} fa-fw`}/>&nbsp;&nbsp;</span>}
		{!props.hideText && (props.useAbbr ? category.abbr : category.name)}
		{(category.icon && props.iconPosition === "right") && <span>&nbsp;&nbsp;<i className={`${category.icon} fa-fw`}/></span>}
		{props.text}
	</div>;
	return <Label
		{...omit(props, "dscatid", "hideText", "iconPosition", "categoryDefaults", "useAbbr")}
		style={{
			...props.style,
		}}
		variant={props.variant ? props.variant : hasClassname ? category.classNames : hasColor ? category.color : "info"}
	>
		{(category.icon && props.iconPosition === "left") && <span><i className={`${category.icon} fa-fw`}/>&nbsp;&nbsp;</span>}
		{!props.hideText && (props.useAbbr ? category.abbr : category.name)}
		{(category.icon && props.iconPosition === "right") && <span>&nbsp;&nbsp;<i className={`${category.icon} fa-fw`}/></span>}
		{props.text}
	</Label>;
};

SystemCategoryLabel.defaultProps = {
	hideText: false,
	iconPosition: "left",
};
