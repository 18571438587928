import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class ShopProductItemStore extends BaseStoreMulti<FpApi.Shop.ProductItem, number> {
	protected itemId(item: FpApi.Shop.ProductItem): number {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Shop.ProductItem[]> {
		return apiManager
			.getService(FpApi.Shop.ProductItemService)
			.get(this.app.ctx);
	}
}
