export function getSize(size) {
    switch (size) {
        case "x1": return 35;
        case "x1.5": return 50;
        case "x2": return 70;
        case "x3": return 100;
        case "x4": return 120;
        case "x5": return 180;
        default: return size;
    }
}
