import { CheckInPremiumUtil as CheckInPremiumUtilCore, FpApi, IContactHR }from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

export class CheckInPremiumUtil extends CheckInPremiumUtilCore {
	private app: FleetplanApp;

	public constructor(app: FleetplanApp) {
		super();
		this.app = app;
	}

	protected loadContract(dscaid: number, date: DateTime): Promise<IContactHR> {
		return this.app.store.hrContractStore.findContractForDate({ dscaid, date });
	}

	protected loadWorkPattern(id: number): Promise<FpApi.Resource.Duty.WorkPattern> {
		return this.app.store.resource.scheduleWorkPattern.getId(id);
	}

	protected loadHolidaySet(id: number): Promise<FpApi.Calendar.Holiday.HolidaySet> {
		return this.app.store.holidaySet.getId(id);
	}
}
