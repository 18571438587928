import { IContactHR } from "@tcs-rliess/fp-core";

import { FpDirClientDataProcessor } from "../../fp-query";
import { BaseStoreSingle } from "../BaseStoreSingle";

export class ActiveContractStore extends BaseStoreSingle<IContactHR, number> {
	private dirClient = new FpDirClientDataProcessor(this.app.ctx);

	protected itemId(item: IContactHR): number {
		return item.id;
	}

	protected async fetchIdList(idList: number[]): Promise<IContactHR[]> {
		const response = await this.dirClient.contractHrGetCurrentIdList({
			params: {
				list: idList,
			},
			cache: {
				maxAge: 1000 * 60 * 5,
			}
		});
		const resMap = new Map(response.map(row => [ row.id, row ] as [ number, IContactHR ]));
		idList.forEach(id => this.update(resMap.get(id)));
		return response;
	}
}
