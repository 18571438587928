import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreSingle } from "../../BaseStoreSingle";

/**
 * @deprecated use dssid if possible
 */
export class DocumentDsmidStore extends BaseStoreSingle<FpApi.ControlledDocument.Document, number> {
	protected itemId(item: FpApi.ControlledDocument.Document): number {
		return item.dssid;
	}

	protected async fetchIdList(idList: number[]): Promise<FpApi.ControlledDocument.Document[]> {
		const items: FpApi.ControlledDocument.Document[] = [];
		for (const dsmid of idList) {
			const item = await apiManager
				.getService(FpApi.ControlledDocument.DocumentService)
				.getId(this.app.ctx, { dsmid });

			items.push(item);
		}
		return items;
	}
}
