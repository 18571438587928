import { dom } from "@fortawesome/fontawesome-svg-core";

import "./IconsBrand";
import "./IconsCustom";
import "./IconsLight";
import "./IconsRegular";
import "./IconsSolid";

dom.insertCss();
dom.watch();
