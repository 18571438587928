import { faCalendarRange, faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, CommandBar, Flex } from "../../../Components";
import { DateRangePickerButton } from "../DateRange";
import { MonthPicker } from "../MonthPicker/MonthPicker";
import { DateRange } from "../util";
export const DatePickerSwitcher = function DatePickerSwitcher(props) {
    const [showRange, setShowRange] = React.useState(false);
    return (React.createElement(React.Fragment, null, showRange ? (React.createElement(Flex, null,
        React.createElement(DateRangePickerButton, { ...props.DateRangePickerProps, dateRange: props.dateRange, component: CommandBar.Button, onChange: range => props.onChange?.(range) }),
        React.createElement("div", { style: { paddingTop: 5 } },
            React.createElement(Button, { key: "1", style: { width: 50, height: 20, padding: 0, fontSize: 13 }, onClick: () => setShowRange(e => !e) },
                React.createElement(FontAwesomeIcon, { icon: faCalendarRange }))))) : (React.createElement("div", { style: { width: 300, position: "relative" } },
        React.createElement(MonthPicker, { ...props.MonthPickerProps, date: props.dateRange.from, onChange: date => props.onChange?.(new DateRange(date.startOf("month"), date.endOf("month"))) }),
        React.createElement("div", { style: { position: "absolute", top: 0, right: 0 } },
            React.createElement(Button, { key: "1", style: { width: 20, height: 20, padding: 0, fontSize: 13 }, onClick: () => setShowRange(e => !e) },
                React.createElement(FontAwesomeIcon, { icon: faCalendar })))))));
};
