import styled, { css } from "styled-components";
export const PanelButton = styled.button `
	border: none;
	background: transparent;
	line-height: 30px;
	white-space: nowrap;
	padding: 1px 10px;
	user-select: none;

	&:hover {
		background: ${p => p.theme.dockedManager.borderColor};
	}
	&:active {
		background: ${p => p.theme.dockedManager.borderColor};
	}

	${p => p.active ? css `
		box-shadow: inset 0 0 0.5em #ddd;
	` : ""}

	@media (pointer: coarse) {
		line-height: 40px;
		padding: 0 15px;
	}
`;
