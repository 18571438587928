import { faChevronDown, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { useTheme } from "styled-components";
const RowToolbar = styled.div `
	display: flex;
`;
const RowContent = styled.div `
	height: 100%;
	display: flex;
	line-height: ${p => p.lineHeight}px;
	
	&:hover ${RowToolbar} {
		background: rgba(0, 0, 0, .05);
	}
`;
const RowLabel = styled.div `
	flex: 1;
	display: flex;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const ExpandButton = styled.button `
	border: none;
	background: transparent;
	position: absolute;
	border-radius: 100%;
	width: ${p => p.scaffoldBlockPxWidth}px;
	height: 100%;
	padding: 0;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	/* make icon slightly bigger */
	font-size: 1.1em;

	&:focus {
		outline: none;
	}
`;
// eslint-disable-next-line react/prefer-stateless-function
export const FleetplanNodeContentRenderer = function FleetplanNodeContentRenderer(props) {
    const { scaffoldBlockPxWidth, toggleChildrenVisibility, connectDragPreview, connectDragSource, isDragging, canDrop, canDrag, node, title, draggedNode, path, treeIndex, isSearchMatch, isSearchFocus, icons, buttons, className, style, didDrop, lowerSiblingCounts, listIndex, swapFrom, swapLength, swapDepth, treeId, // Not needed, but preserved for other renderers
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    disableDropFromOutside, rowHeight, ...otherProps } = props;
    const nodeTitle = title || node.title;
    const theme = useTheme();
    return React.createElement("div", { style: {
            height: "100%",
            marginLeft: scaffoldBlockPxWidth * lowerSiblingCounts.length,
            background: isDragging ? theme.color.primary[100].main : null,
        }, ...otherProps },
        toggleChildrenVisibility && node.children && node.children.length > 0 && (React.createElement(ExpandButton, { type: "button", scaffoldBlockPxWidth: scaffoldBlockPxWidth, "aria-label": node.expanded ? "Collapse" : "Expand", style: {
                left: (lowerSiblingCounts.length - 0.7) * scaffoldBlockPxWidth,
            }, onClick: () => toggleChildrenVisibility({
                node,
                path,
                treeIndex,
            }) },
            React.createElement(FontAwesomeIcon, { size: "sm", icon: node.expanded ? faChevronDown : faChevronRight, fixedWidth: true }))),
        React.createElement(RowContent, { lineHeight: scaffoldBlockPxWidth === 46 ? 46 : 34, ref: e => {
                // Set the row preview to be used during drag and drop
                connectDragPreview(e);
                if (canDrag)
                    connectDragSource(e, { dropEffect: "copy" });
            } },
            React.createElement(RowLabel, null, nodeTitle),
            buttons.length > 0 ? (React.createElement(RowToolbar, null, buttons.map((btn, index) => (React.createElement(React.Fragment, { key: index }, btn))))) : null));
};
FleetplanNodeContentRenderer.defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: "",
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null,
    disableDropFromOutside: false,
};
