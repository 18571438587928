import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

export class ContactTargetWorkingTimeCorrectionDeltaDeltaStore extends BaseStoreMulti<FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta, string> {
	protected loadedYears = new Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta[]>();
	protected history = new Map<number, Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta[]>>();
	protected itemId(item: FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta): string {
		return `${item.year}-${item.dscaid}`;
	}

	protected async fetchAll(): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta[]> {
		const data = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.getDelta(this.ctx, {
				year: new Date().getFullYear(),
			});
		this.loadedYears.set(new Date().getFullYear(), data);
		return data;
	}

	public async getYear(year: number): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta[]> {
		if(this.loadedYears.has(year)) {
			return this.loadedYears.get(year);
		}
		const corrections = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.getDelta(this.ctx, {
				year: year,
			});
		this.loadedYears.set(year, corrections);
		corrections.forEach(c => this.update(c));
		return corrections;
	}

	/** updates entry in year when year already loaded */
	public updateYear(entry: FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta) {
		this.update(entry);
		this.loadedYears.get(entry.year)?.push(entry);
	}
}
