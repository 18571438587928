import { FpApi, apiManager } from "@tcs-rliess/fp-core";

import { BaseStoreSingle } from "../../BaseStoreSingle";

export class RevisionStore extends BaseStoreSingle<FpApi.ControlledDocument.Revision, number> {
	protected itemId(item: FpApi.ControlledDocument.Revision): number {
		return item.dssrid;
	}

	protected fetchIdList(idList: number[]): Promise<FpApi.ControlledDocument.Revision[]> {
		return apiManager
			.getService(FpApi.ControlledDocument.RevisionService)
			.getIds(this.app.ctx, { idList });
	}
}
