import { FpLegClient, FpLegClientState } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class AircraftStateStore extends BaseStoreMultiSync<FpLegClientState, number> {
	private legClient = new FpLegClient({ use: "AircraftStateStore" })

	protected itemId(item: FpLegClientState): number {
		return item.fpvid;
	}

	protected async fetchAll(): Promise<FpLegClientState[]> {
		// load aircraft
		await this.app.store.resource.aircraft.ensureLoad();

		const aircrafts = this.app.store.resource.aircraft.getAll();
		const fpvidList = aircrafts.map(a => a.id);

		if (fpvidList.length) {
			const result = await this.legClient.getAircraftState(this.app.dscid, fpvidList);
			return result.rows;
		}

		return [];
	}

	public nextMaintenanceAt(state: FpLegClientState, date: DateTime = DateTime.now()) {
		if(Object.keys(state?.maintenance ?? {}).length === 0)  return { dur: undefined, positive: undefined };
		const rest = (state.totals?.total_flighttime ?? 0) - (state?.maintenance?.next_maintenance_at ?? 0);
		const daysLeft = state.maintenance?.next_maintenance_at_date ? Math.floor(DateTime.fromISO(state.maintenance.next_maintenance_at_date).diff(date).as("days")) : null;
		const positive = rest < 0;
		const dur = this.app.formatter.duration(Math.abs(rest ?? 0), { unit: "minute" });
		return {
			dur, positive, days: daysLeft,
		};
	}
}
