import { PathMatch, useMatch, useResolvedPath } from "react-router";

/**
 * the normal `useMatch` only accepts an absolute path
 * - https://github.com/remix-run/react-router/issues/7920
 * - https://github.com/remix-run/react-router/issues/7954
 * 
 * @param path absolute or relative url
 * @returns match result
 */
export function useRelativeMatch<ParamKey extends string = string>(path: string): PathMatch<ParamKey> | null {
	const fullPath = useResolvedPath(path).pathname;
	return useMatch(fullPath);
}
