import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../../BaseStoreMulti";

export class StockLocationStore extends BaseStoreMulti<FpApi.Stock.Location, string> {
	protected itemId(item: FpApi.Stock.Location): string {
		return item.id;
	}

	protected fetchAll(): Promise<FpApi.Stock.Location[]> {
		return apiManager
			.getService(FpApi.Stock.LocationService)
			.get(this.app.ctx);
	}
}
