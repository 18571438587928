import Roboto100Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff";
import Roboto100Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2";
import Roboto100ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100italic.woff";
import Roboto100ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100italic.woff2";
import Roboto300Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff";
import Roboto300Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2";
import Roboto300ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff";
import Roboto300ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff2";
import Roboto500Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff";
import Roboto500Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2";
import Roboto500ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff";
import Roboto500ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff2";
import Roboto700Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff";
import Roboto700Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2";
import Roboto700ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff";
import Roboto700ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff2";
import Roboto900Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff";
import Roboto900Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2";
import Roboto900ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900italic.woff";
import Roboto900ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900italic.woff2";
import Roboto400ItalicWoff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff";
import Roboto400ItalicWoff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff2";
import Roboto400Woff2 from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff";
import Roboto400Woff from "../../../Roboto/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2";
/*
 * https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=cyrillic,cyrillic-ext,greek,greek-ext,latin,latin-ext,vietnamese
 *
 * latin
 * latin-ext
 * greek
 * greek-ext
 * cyrillic
 * cyrillic-ext
 * vietnamese
 *
 * styled-component `createGlobalStyle` doesn't play nice with @font-face or @import, causing them to be reloaded constantly.
 */
let inserted = false;
export function insertRoboto() {
    if (inserted)
        return;
    inserted = true;
    const fontFaceRoboto = `
		/* roboto-100 */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 100;
			src: local('Roboto Thin'), local('Roboto-Thin'),
				url('${Roboto100Woff2}') format('woff2'),
				url('${Roboto100Woff}') format('woff');
		}
		/* roboto-100 italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 100;
			src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
				url('${Roboto100ItalicWoff2}') format('woff2'),
				url('${Roboto100ItalicWoff}') format('woff');
		}
		/* roboto-300 */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 300;
			src: local('Roboto Light'), local('Roboto-Light'),
				url('${Roboto300Woff2}') format('woff2'),
				url('${Roboto300Woff}') format('woff');
		}
		/* roboto-300 italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 300;
			src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
				url('${Roboto300ItalicWoff2}') format('woff2'),
				url('${Roboto300ItalicWoff}') format('woff');
		}
		/* roboto-regular */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			src: local('Roboto'), local('Roboto-Regular'),
				url('${Roboto400Woff2}') format('woff2'),
				url('${Roboto400Woff}') format('woff');
		}
		/* roboto-italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 400;
			src: local('Roboto Italic'), local('Roboto-Italic'),
				url('${Roboto400ItalicWoff2}') format('woff2'),
				url('${Roboto400ItalicWoff}') format('woff');
		}
		/* roboto-500 */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			src: local('Roboto Medium'), local('Roboto-Medium'),
				url('${Roboto500Woff2}') format('woff2'),
				url('${Roboto500Woff}') format('woff');
		}
		/* roboto-500 italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 500;
			src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
				url('${Roboto500ItalicWoff2}') format('woff2'),
				url('${Roboto500ItalicWoff}') format('woff');
		}
		/* roboto-700 */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			src: local('Roboto Bold'), local('Roboto-Bold'),
				url('${Roboto700Woff2}') format('woff2'),
				url('${Roboto700Woff}') format('woff');
		}
		/* roboto-700 italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 700;
			src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
				url('${Roboto700ItalicWoff2}') format('woff2'),
				url('${Roboto700ItalicWoff}') format('woff');
		}
		/* roboto-900 */
		@font-face {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 900;
			src: local('Roboto Black'), local('Roboto-Black'),
				url('${Roboto900Woff2}') format('woff2'),
				url('${Roboto900Woff}') format('woff');
		}
		/* roboto-900 italic */
		@font-face {
			font-family: 'Roboto';
			font-style: italic;
			font-weight: 900;
			src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
				url('${Roboto900ItalicWoff2}') format('woff2'),
				url('${Roboto900ItalicWoff}') format('woff');
		}
	`;
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(fontFaceRoboto));
    document.head.append(style);
}
