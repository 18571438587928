import { ApiContext, IExportApi, IProjectArchiveOptions, IComplianceExportOptions } from "@tcs-rliess/fp-core";

import { ApiUtil } from "../ApiUtil";

export class ExportApi implements IExportApi {
	public async createProjectArchive(ctx: ApiContext, params: { options: IProjectArchiveOptions }): Promise<string> {
		return ApiUtil.fetchRpc(ctx, "system.export.createProjectArchive", params);
	}

	public async createComplianceExport(ctx: ApiContext, params: { options: IComplianceExportOptions }): Promise<string> {
		return ApiUtil.fetchRpc(ctx, "system.export.createComplianceExport", params);
	}
}
