import { formatContactName, formatCoordianteToString, formatLandingField } from "@tcs-rliess/fp-core";
import { Format, Text } from "@tcs-rliess/fp-web-ui";
import React from "react";
import { PrintContext } from "../../lib";
import { FlightOrderTable } from "../../lib/styledExportComponents";
export const FlightOrder = (props) => {
    const data = props.data;
    const printContext = React.useContext(PrintContext);
    if (!data)
        return React.createElement(React.Fragment, null, "Error! No data!");
    const doclist = ["Operational Flight Plan",
        "WX & NOTAM Briefing",
        "Weight and Balance",
        "Performance Calculation",
        "Permits (mission specific)",
        "Landingfield-Documets",
        "Documents regarding instruction(s) and briefing(s)"];
    const paddedLegs = Array.from({ ...data.legs, length: data.legs.length < 5 ? 5 : data.legs.length }, (v) => v ?? {});
    return (React.createElement(React.Fragment, null,
        React.createElement(FlightOrderTable, { "$tdPadding": "4px", "$fontSize": "inherit", "$borderColor": "#000000", style: { marginBottom: "10px" } },
            React.createElement("tr", { style: { textAlign: "center" } },
                React.createElement("td", { rowSpan: 3 },
                    React.createElement("img", { src: printContext?.customer.$dscaid?.avatarTn, style: { width: "180px", filter: "grayscale(100%)" } })),
                React.createElement("td", null,
                    React.createElement(Text, { style: { fontSize: "1.2em", fontWeight: "bold" } }, "Commercial Flight Order CAT & SPO")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement(Text, { size: "sm", bold: true, style: { whiteSpace: "nowrap" } }, "MVO-FO-1025"))),
            React.createElement("tr", null,
                React.createElement("td", { rowSpan: 2 },
                    React.createElement(Text, { size: "sm" },
                        React.createElement("div", { style: { lineHeight: "12px" } }, "THIS FLIGHT ORDER MUST BE CARRIED DURING THE FLIGHT. IT ALSO SERVES AS A COVER SHEET FOR FILING FLIGHT PREPARATION. THE EXISTENCE OF THE DOCUMENTS TO BE CARRIED AND STORED MUST BE CONFIRMED BY CHECKING OFF THE RELEVANT FIELDS."))),
                React.createElement("td", { style: { textAlign: "center", whiteSpace: "nowrap", fontSize: "0.6em" } }, "Rev. 3"),
                React.createElement("td", { style: { textAlign: "center", fontSize: "0.6em" } }, "09.10.2023")),
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 2, style: { whiteSpace: "nowrap", fontSize: "0.6em" } }, "released by FCMM- TSA"))),
        React.createElement(FlightOrderTable, { "$tdPadding": "1px 6px", "$fontSize": "inherit", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", null,
                    React.createElement(Text, { size: "md", bold: true }, "FLIGHT- AIRCRAFT BOOKING #")),
                React.createElement("td", null,
                    React.createElement(Text, { size: "md", bold: true }, "REFERENCE"))),
            React.createElement("tr", null,
                React.createElement("td", { style: { textAlign: "center", width: "30%" } },
                    React.createElement(Text, { bold: true, style: { fontSize: "1.6em", fontFamily: "Courier Prime" } }, data.event.id2)),
                React.createElement("td", null,
                    React.createElement(Text, { size: "sm", bold: true }, data.event.reference)))),
        React.createElement(FlightOrderTable, { "$tdPadding": "1px 6px", "$fontSize": "inherit", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 9, style: { borderTop: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "FLIGHTCREW"))),
            React.createElement("tr", null,
                React.createElement("td", { style: { textAlign: "center" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "ROLE"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[0]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { style: { textAlign: "center" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "DUTY"),
                    React.createElement(Text, { size: "sm" }, "\u00A0")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "3LC"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[0]?.$contactFormatted ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "LIC-NR"),
                    React.createElement(Text, { size: "sm" }, "\u00A0")),
                React.createElement("td", { colSpan: 3 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "PHONE"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[0]?.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[0]?.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0"))))),
            React.createElement("tr", null,
                React.createElement("td", { style: { textAlign: "center" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "ROLE"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[1]?.dserrid ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { style: { textAlign: "center" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "DUTY"),
                    React.createElement(Text, { size: "sm" }, "\u00A0")),
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "3LC"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[1]?.$contactFormatted ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "LIC-NR"),
                    React.createElement(Text, { size: "sm" }, "\u00A0")),
                React.createElement("td", { colSpan: 3 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "PHONE"),
                    React.createElement(Text, { size: "sm" }, data.crew.flightCrew[1]?.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (data.crew.flightCrew[1]?.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0"))))),
            data.crew.otherCrew.length
                ? React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                    React.createElement("td", { colSpan: 9 },
                        React.createElement(Text, { size: "md", bold: true }, "ADDITIONAL CREW")))
                : null,
            data.crew.otherCrew.map((crewMember, i) => (React.createElement("tr", { key: "otherCrew_" + i },
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "ROLE"),
                    React.createElement(Text, { size: "sm" }, crewMember.dserrid ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", { colSpan: 5 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "NAME"),
                    React.createElement(Text, { size: "sm" },
                        crewMember.$contact.$person?.givenName,
                        " ",
                        React.createElement("span", { style: { textTransform: "uppercase" } }, crewMember.$contact.$person?.lastName),
                        " ",
                        (crewMember.$contact.$person?.abbreviation ? `(${crewMember.$contact.$person?.abbreviation})` : ""))),
                React.createElement("td", { colSpan: 3 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "PHONE"),
                    React.createElement(Text, { size: "sm" }, crewMember.$contact.$person?.preferredMobile === "" ? React.createElement(React.Fragment, null, "\u00A0") : (crewMember.$contact.$person?.preferredMobile ?? React.createElement(React.Fragment, null, "\u00A0"))))))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 9 },
                    React.createElement(Text, { size: "md", bold: true }, "AIRCRAFT"))),
            React.createElement("tr", null,
                React.createElement("td", { rowSpan: 2, style: { verticalAlign: "bottom" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "REG"),
                    React.createElement("div", { style: { marginTop: "10px" } },
                        React.createElement(Text, { size: "sm" }, data.vehicle.aircraft.registrationCode ?? React.createElement(React.Fragment, null, "\u00A0")))),
                React.createElement("td", { rowSpan: 2, style: { verticalAlign: "bottom" } },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "TYP"),
                    React.createElement("div", { style: { marginTop: "10px" } },
                        React.createElement(Text, { size: "sm" }, data.vehicle.aircraft.$fpdbvmid.vehicleModel ?? React.createElement(React.Fragment, null, "\u00A0")))),
                React.createElement("td", { colSpan: 7 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "NEXT MAINTENANCE"))),
            React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "ITEM"),
                    React.createElement(Text, { size: "sm" }, data.vehicle.state.maintenance.next_maintenance ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "@TIME"),
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Text, { size: "sm" }, data.vehicle.state.maintenance.next_maintenance_at ? React.createElement(Format.Duration, { unit: "minutes", mask: "hh:mm" }, data.vehicle.state.maintenance.next_maintenance_at) : ""))),
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "MARGIN"),
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Text, { size: "sm" }, "\u00A0"))),
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "ITEM"),
                    React.createElement(Text, { size: "sm" }, data.vehicle.state.maintenance.next_maintenance ?? React.createElement(React.Fragment, null, "\u00A0"))),
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "@DATE"),
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Text, { size: "sm" },
                            React.createElement(Format.Date, { showTz: false, tz: printContext?.userSettings?.leg?.tz }, data.vehicle.state.maintenance.next_maintenance_at_date ?? "")))),
                React.createElement("td", null,
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "MARGIN"),
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Text, { size: "sm" }, "\u00A0"))))),
        React.createElement(FlightOrderTable, { "$tdPadding": "1px 6px", "$fontSize": "inherit", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 10, style: { borderTop: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "OPERATING AREA / ROUTING"))),
            React.createElement("tr", { style: { textAlign: "center" } },
                React.createElement("td", { style: { width: "5%", fontSize: "0.6em", fontWeight: "bold" } }, "LEG"),
                React.createElement("td", { style: { width: "7%", fontSize: "0.6em", fontWeight: "bold" } }, "MISSION"),
                React.createElement("td", { style: { width: "7%", fontSize: "0.6em", fontWeight: "bold" } }, "DATE"),
                React.createElement("td", { style: { width: "7%", fontSize: "0.6em", fontWeight: "bold" } }, "STD (lt)"),
                React.createElement("td", { style: { width: "24%", fontSize: "0.6em", fontWeight: "bold" } }, "FROM"),
                React.createElement("td", { style: { width: "21%", fontSize: "0.6em", fontWeight: "bold" } }, "TO"),
                React.createElement("td", { style: { width: "6%", fontSize: "0.6em", fontWeight: "bold" } }, "STA (lt)"),
                React.createElement("td", { style: { width: "10%", fontSize: "0.6em", fontWeight: "bold" } }, "CUSTOMER"),
                React.createElement("td", { style: { width: "5%", fontSize: "0.6em", fontWeight: "bold" } }, "RULE"),
                React.createElement("td", { style: { width: "8%", fontSize: "0.6em", fontWeight: "bold" } },
                    "MIN REQ",
                    React.createElement("br", null),
                    "FUEL")),
            paddedLegs.map((leg, i) => (React.createElement("tr", { key: "leglist_" + i, style: { textAlign: "center", fontSize: "0.6em" } },
                React.createElement("td", null, (i + 1)),
                React.createElement("td", null, leg.mission),
                React.createElement("td", null,
                    React.createElement(Format.Date, { showTz: false, tz: printContext?.userSettings?.tz }, leg.std)),
                React.createElement("td", null,
                    React.createElement(Format.Time, { showTz: false, tz: printContext?.userSettings?.tz }, leg.std)),
                React.createElement("td", { style: { textAlign: "left" } },
                    typeof leg.from === "string" ? leg.from : formatLandingField(leg.from),
                    React.createElement("br", null),
                    React.createElement(Text, { muted: true },
                        typeof leg.from === "string" ? "" : formatCoordianteToString(leg.from?.latitude_deg, "lat"),
                        " ",
                        typeof leg.from === "string" ? "" : formatCoordianteToString(leg.from?.longitude_deg, "lon"))),
                React.createElement("td", { style: { textAlign: "left" } },
                    typeof leg.to === "string" ? leg.to : formatLandingField(leg.to),
                    React.createElement("br", null),
                    React.createElement(Text, { muted: true },
                        typeof leg.to === "string" ? "" : formatCoordianteToString(leg.to?.latitude_deg, "lat"),
                        " ",
                        typeof leg.to === "string" ? "" : formatCoordianteToString(leg.to?.longitude_deg, "lon"))),
                React.createElement("td", null,
                    React.createElement(Format.Time, { showTz: false, tz: printContext?.userSettings?.tz }, leg.sta)),
                React.createElement("td", null,
                    formatContactName(leg.customer),
                    React.createElement("br", null),
                    React.createElement("span", null, leg.comdoc?.length ? `SO-${leg.comdoc[0].id}` : "")),
                React.createElement("td", null, leg.rule),
                React.createElement("td", null))))),
        React.createElement(FlightOrderTable, { "$tdPadding": "1px 6px", "$fontSize": "inherit", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 3, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { style: { fontSize: "0.6em" } }, "TO BE COMPLETED BY HAND")),
                React.createElement("td", { colSpan: 2, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { style: { fontSize: "0.6em" } }, "TO BE COMPLETED BY HAND"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 3, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "ENVELOPE"),
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "The following documents must be presented when the PIC issues the flight order")),
                React.createElement("td", { colSpan: 2, style: { borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "OPS RETURN"),
                    React.createElement("div", { style: { fontSize: "0.6em", fontWeight: "bold" } }, "The following documents must be retained for a period of 3 months"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center", lineHeight: "1em" } },
                React.createElement("td", { style: { width: "20%", borderTop: "none", borderRight: "none", fontSize: "0.6em", fontWeight: "bold" } }, "DOCUMENT"),
                React.createElement("td", { style: { width: "15%", borderTop: "none", borderLeft: "none", borderRight: "none", fontSize: "0.6em", fontWeight: "bold" } }, "APPLICABLE (3LC)"),
                React.createElement("td", { style: { width: "15%", borderTop: "none", borderLeft: "none", fontSize: "0.6em", fontWeight: "bold" } },
                    "NOT APPLICABLE",
                    React.createElement("br", null),
                    "(3LC)"),
                React.createElement("td", { style: { width: "25%", borderTop: "none", borderRight: "none", fontSize: "0.6em", fontWeight: "bold" } }, "PAPERS CHECKED & STORED (3LC)"),
                React.createElement("td", { style: { width: "25%", borderTop: "none", borderLeft: "none", fontSize: "0.6em", fontWeight: "bold" } }, "UPLOAD TO FLEETPLAN (3LC)")),
            doclist.map((value, i) => (React.createElement("tr", { key: "doclist_" + i, style: { height: "30px" } },
                React.createElement("td", { style: { fontSize: "0.6em" } }, (value)),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", null)))),
            React.createElement("tr", { style: { height: "30px" } },
                React.createElement("td", { colSpan: 3, style: { fontSize: "0.6em" } }, "Incident documentation"),
                React.createElement("td", null),
                React.createElement("td", null))),
        React.createElement(FlightOrderTable, { "$tdPadding": "1px 6px", "$fontSize": "inherit", "$borderColor": "#000000" },
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { colSpan: 2, style: { paddingTop: "8px", paddingBottom: "8px", borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "FLIGHTOPERATION DEPARTMENT")),
                React.createElement("td", { colSpan: 2, style: { paddingTop: "8px", paddingBottom: "8px", borderTop: "none", borderBottom: "none" } },
                    React.createElement(Text, { size: "md", bold: true }, "FLIGHTCREW"))),
            React.createElement("tr", { style: { color: "#ffffff", backgroundColor: "#808080" } },
                React.createElement("td", { style: { width: "25%", textAlign: "center", borderTop: "none", borderRight: "none", fontSize: "0.6em", fontWeight: "bold" } }, "POSITION"),
                React.createElement("td", { style: { width: "25%", textAlign: "center", borderTop: "none", borderLeft: "none", fontSize: "0.6em", fontWeight: "bold" } }, "SIGNATURE"),
                React.createElement("td", { colSpan: 2, style: { width: "50%", borderTop: "none" } })),
            React.createElement("tr", null,
                React.createElement("td", null),
                React.createElement("td", null),
                React.createElement("td", { colSpan: 2, style: { fontSize: "0.6em", lineHeight: "8px" } },
                    "The pilot insures, neither through the consumption of alcoholic or other intoxicating substances, nor as a result of mental or physical deficiencies in the awarness to be disabled in carrying out his duties as the aircraft pilot in command.",
                    React.createElement("br", null),
                    "He assures that he carried out the flight preparation of the aircraft is ready for flight and properly fueled and loaded according to the flight order, and all documents according to CAT.GEN.MPA.180 are carried.",
                    React.createElement("br", null),
                    "With his signature, the pilot assures that he can demonstrate the ongoing flight experience required in accordance with ORO.FC.105.")),
            React.createElement("tr", null,
                React.createElement("td", { rowSpan: 2, colSpan: 2, style: { fontSize: "0.6em", lineHeight: "8px" } },
                    "Intended changes to the flight order must be communicated to the person issuing the flight order as soon as they become known. The following changes require the consent of the person issuing the flight order:",
                    React.createElement("ul", { style: { marginBottom: "0", marginTop: "0" } },
                        React.createElement("li", null, "Change of type of operation / change of SOP,"),
                        React.createElement("li", null, "Change of flight crew and flight crew composition,"),
                        React.createElement("li", null, "Change of deployment date of more than 48 hours (postponements due to weather are not affected here).Consent to the change and the re-issuance of a flight order can be made both in writing and orally.")),
                    "The following persons are authorized to issue flight orders and to transfer the right to issue a flight order to other persons: Bastian NOWOTNY (ACM), Ralf TROJCA (NPFO), Uta HAUBER (NPGO)"),
                React.createElement("td", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center", padding: "5px", borderRightColor: "white", fontSize: "0.6em", fontWeight: "bold" } }, "SIGNATURE PIC"),
                React.createElement("td", { style: { color: "#ffffff", backgroundColor: "#808080", textAlign: "center", padding: "5px", fontSize: "0.6em", fontWeight: "bold" } }, "SIGNATURE SIC / TS / ACM")),
            React.createElement("tr", null,
                React.createElement("td", null),
                React.createElement("td", null)))));
};
