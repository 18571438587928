import { ActivityReportUtil } from "@tcs-rliess/fp-core";
import { Format, Table } from "@tcs-rliess/fp-web-ui";
import React from "react";
import styled from "styled-components";
import { ContinuationMarkers, DefaultFooter } from "../../lib";
const DataTh = styled.th `
	text-align: right;
	width: 70px;
`;
export const ActivityReport = function ActivityReport(props) {
    return React.createElement("div", null,
        React.createElement(DefaultFooter, null),
        React.createElement("h1", null,
            "Duty Time Summary",
            React.createElement("sub", null,
                " ",
                React.createElement(Format.Date, null, props.start),
                " ",
                "\u2014",
                " ",
                React.createElement(Format.Date, null, props.end.minus({ day: 1 })),
                " ",
                "(",
                props.tz,
                ")")),
        React.createElement(ContinuationMarkers, null,
            React.createElement(Table, { size: "sm" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 4 }),
                        React.createElement("th", { colSpan: 5 }, "ACTUAL DUTY"),
                        React.createElement("th", { colSpan: 3 }, "ASSIGNED DUTY")),
                    React.createElement("tr", null,
                        React.createElement("th", { style: { width: 40 } }, "#"),
                        React.createElement("th", null, "Pilot"),
                        React.createElement("th", { style: { width: 110 } }, "Base"),
                        React.createElement("th", { style: { width: 110 } }, "Duty Type"),
                        React.createElement(DataTh, null, "Block (h)"),
                        React.createElement(DataTh, null, "Work (d)"),
                        React.createElement(DataTh, null, "Work (h)"),
                        React.createElement(DataTh, null, "\u2300 per day"),
                        React.createElement(DataTh, null, "Work (d)"),
                        React.createElement(DataTh, null, "Work (h)"),
                        React.createElement(DataTh, null, "\u2300 per day"))),
                React.createElement("tbody", null, props.data.map((row, i) => (React.createElement(ReportContact, { key: row.contact.id, index: i + 1, contact: row.contact, result: row.result, locations: props.locations, categories: props.categories, tz: props.tz })))))));
};
const ReportContact = function ReportContact(props) {
    const { contact, result, tz } = props;
    const util = new ActivityReportUtil();
    const report = util.fromResult(result, {
        tz,
    });
    const rows = Math.max(1, report.groups.length);
    return React.createElement(React.Fragment, null,
        new Array(rows).fill(0).map((_, row) => {
            const dataProps = report.groups[row];
            const rowSpan = report.groups.length > 1 ? report.groups.length + 1 : 1;
            const location = dataProps == null ? null : props.locations.find(i => i.id === dataProps.location);
            const category = dataProps == null ? null : props.categories.find(i => i.node.id === dataProps.category);
            return React.createElement("tr", { key: row },
                row === 0 ? React.createElement(React.Fragment, null,
                    React.createElement("td", { rowSpan: rowSpan },
                        props.index,
                        "."),
                    React.createElement("td", { rowSpan: rowSpan },
                        contact.$person?.lastName,
                        ", ",
                        contact.$person?.givenName)) : null,
                dataProps == null ? React.createElement(React.Fragment, null,
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null)) : (React.createElement(React.Fragment, null,
                    React.createElement("td", null, dataProps.location === -1 ? (React.createElement("span", { className: "text-muted" }, "Uncategorized")) : ((location?.name || location?.data.shortName))),
                    React.createElement("td", null, dataProps.category === -1 ? (React.createElement("span", { className: "text-muted" }, "Uncategorized")) : (category?.name ?? dataProps.category)),
                    React.createElement(ReportData, { sum: dataProps.sum }))));
        }),
        report.groups.length > 1 ? React.createElement("tr", { style: { fontWeight: "bold" } },
            React.createElement("td", null),
            React.createElement("td", null, "Total"),
            React.createElement(ReportData, { sum: report.total })) : null);
};
const DataTd = styled.td `
	text-align: right;
`;
const ReportData = function ReportData(props) {
    const sum = props.sum;
    return React.createElement(React.Fragment, null,
        React.createElement(DataTd, null, sum.actual.block > 0 ? React.createElement(Format.Number, { decimalDigits: 1 }, sum.actual.block / 1000 / 60 / 60) : null),
        React.createElement(DataTd, null, sum.actual.days > 0 ? React.createElement(Format.Number, null, sum.actual.days) : null),
        React.createElement(DataTd, null, sum.actual.work > 0 ? React.createElement(Format.Number, { decimalDigits: 1 }, sum.actual.work / 1000 / 60 / 60) : null),
        React.createElement(DataTd, null, sum.actual.avg > 0 ? React.createElement(Format.Number, { decimalDigits: 1 }, sum.actual.avg / 1000 / 60 / 60) : null),
        React.createElement(DataTd, null, sum.scheduled.days > 0 ? React.createElement(Format.Number, null, sum.scheduled.days) : null),
        React.createElement(DataTd, null, sum.scheduled.work > 0 ? React.createElement(Format.Number, { decimalDigits: 1 }, sum.scheduled.work / 1000 / 60 / 60) : null),
        React.createElement(DataTd, null, sum.scheduled.avg > 0 ? React.createElement(Format.Number, { decimalDigits: 1 }, sum.scheduled.avg / 1000 / 60 / 60) : null));
};
